import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import values from 'lodash/values';

import { makeStyles, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {
  useKamReviewStatusButton,
  REVIEW_STATUS,
} from 'utils/kamReviewStatuses';

const useStyles = makeStyles((theme) => ({
  dot: {
    borderRadius: '50%',
    border: `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
    backgroundColor: (props) => props.color,
  },
  text: {
    color: (props) => (props.isNone ? theme.palette.text.primary : props.color),
  },
}));

const KamReviewStatusButton = ({ kamReview, ...otherProps }) => {
  const statusButton = useKamReviewStatusButton(kamReview?.status);
  const classes = useStyles({
    color: statusButton.color,
    isNone: !kamReview?.status,
  });
  const intl = useIntl();

  return (
    <Box display="flex" alignItems="center" {...otherProps}>
      <Box className={classes.dot} width="24px" height="24px" mr={1} />
      <Typography noWrap className={classes.text} variant="subtitle1">
        {intl.formatMessage(statusButton.label)}
      </Typography>
    </Box>
  );
};

KamReviewStatusButton.propTypes = {
  kamReview: PropTypes.shape({
    status: PropTypes.oneOf(values(REVIEW_STATUS)),
  }),
};

KamReviewStatusButton.defaultProps = {
  kamReview: {
    status: REVIEW_STATUS.NEW,
  },
};

export default KamReviewStatusButton;

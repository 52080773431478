import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import * as Icons from 'react-feather';

import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';

import { Feature } from 'utils/features';
import { formatDate, formatNumber, trimUrl } from 'utils/formatting';

import Subheader from './Subheader';
import StaticListItem from './StaticListItem';

const strings = defineMessages({
  authorAndCopyRight: {
    id: 'components.InfluencerAssetInspector.headline.authorAndCopyRight',
    defaultMessage: 'Author and copyright',
  },
  creationDate: {
    id: 'components.InfluencerAssetInspector.labels.creationDate',
    defaultMessage: 'Creation Date',
  },
  campaign: {
    id: 'components.InfluencerAssetInspector.labels.campaign',
    defaultMessage: 'Campaign',
  },
  buyOutPrice: {
    id: 'components.InfluencerAssetInspector.labels.buyOutPrice',
    defaultMessage: 'Buyout Price',
  },
  copyright: {
    id: 'components.InfluencerAssetInspector.labels.copyright',
    defaultMessage: 'Copyright',
  },
});

const InfluencerAssetInspectorAuthorData = ({
  influencerName,
  influencerProfileUrl,
  campaignTitle,
  buyOutPrice,
  copyright,
  createdAt,
}) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <List
      subheader={
        <Subheader label={intl.formatMessage(strings.authorAndCopyRight)} />
      }
    >
      <StaticListItem
        disableTopPadding
        value={influencerName}
        label={trimUrl(influencerProfileUrl)}
      >
        {influencerProfileUrl && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              component="a"
              href={influencerProfileUrl}
              target="_blank"
            >
              <Icons.ExternalLink color={theme.palette.primary.main} />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </StaticListItem>
      <Divider variant="middle" component="li" />
      <StaticListItem
        label={intl.formatMessage(strings.creationDate)}
        value={formatDate({
          isoDate: createdAt,
          formatter: intl.formatDate,
        })}
      />
      <Divider variant="middle" component="li" />
      <StaticListItem
        label={intl.formatMessage(strings.campaign)}
        value={campaignTitle}
      />
      <Feature feature="influencerAssetsBuyOutPrice">
        <Divider variant="middle" component="li" />
        <StaticListItem
          label={intl.formatMessage(strings.buyOutPrice)}
          value={formatNumber({
            number: buyOutPrice,
            format: 'currency',
            formatter: intl.formatNumber,
          })}
        />
      </Feature>
      <Divider variant="middle" component="li" />
      <StaticListItem
        label={intl.formatMessage(strings.copyright)}
        value={copyright}
      />
      <Divider variant="middle" component="li" />
    </List>
  );
};

InfluencerAssetInspectorAuthorData.propTypes = {
  buyOutPrice: PropTypes.number,
  campaignTitle: PropTypes.string,
  copyright: PropTypes.string,
  influencerName: PropTypes.string,
  influencerProfileUrl: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
};

export default InfluencerAssetInspectorAuthorData;

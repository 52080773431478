import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';
import { SortObjectPropType } from 'utils/sorting';

const PublishedPinterestApplicationsTableHead = ({
  currentSort,
  onSort,
  ...otherProps
}) => (
  <TableHead {...otherProps}>
    <TableRow>
      <TableCell align="left" />
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.pinterest.username.tooltip"
            defaultMessage="Pinterest @channelname handle"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.pinterest.username"
          defaultMessage="@channelname"
        />
      </TableCellHead>
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.location.tooltip"
            defaultMessage="As provided by the Influencer on the eqolot platform"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.location"
          defaultMessage="Location"
        />
      </TableCellHead>
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="reach"
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.pinterest.impressions.tooltip"
            defaultMessage="Median Impressions for last 10 idea pins"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.pinterest.impressions"
          defaultMessage="Impressions"
        />
      </TableCellHead>
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="publishedAt"
        noWrap
      >
        <FormattedMessage
          id="components.ChannelsTable.published"
          defaultMessage="Published"
        />
      </TableCellHead>
      <TableCellHead>
        <FormattedMessage
          id="components.ChannelsTable.link"
          defaultMessage="Link"
        />
      </TableCellHead>
    </TableRow>
  </TableHead>
);

PublishedPinterestApplicationsTableHead.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
};

PublishedPinterestApplicationsTableHead.defaultProps = {};

export default PublishedPinterestApplicationsTableHead;

import React from 'react';
import { useIntl } from 'react-intl';

import ButtonSwitch from 'components/ButtonSwitch';

const GenderFilter = ({ ...otherProps }) => {
  const intl = useIntl();

  const filters = [
    {
      value: '',
      label: intl.formatMessage({
        id: 'scenes.influencers.filter.all',
        defaultMessage: 'All',
      }),
    },
    {
      value: 'M',
      label: intl.formatMessage({
        id: 'scenes.influencers.filter.male',
        defaultMessage: 'Male',
      }),
    },
    {
      value: 'F',
      label: intl.formatMessage({
        id: 'scenes.influencers.filter.female',
        defaultMessage: 'Female',
      }),
    },
    {
      value: 'D',
      label: intl.formatMessage({
        id: 'scenes.influencers.filter.diverse',
        defaultMessage: 'Diverse',
      }),
    },
  ];

  return <ButtonSwitch filters={filters} {...otherProps} />;
};

export default GenderFilter;

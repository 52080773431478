import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { useClient } from 'utils/client';

export const tagsStrings = defineMessages({
  ulifestyle: {
    id: 'preferences.influencerAssetsTags.ulifestyle',
    defaultMessage: 'Urban Lifestyle',
  },
  innovations: {
    id: 'preferences.influencerAssetsTags.innovations',
    defaultMessage: 'Innovations',
  },
  egaming: {
    id: 'preferences.influencerAssetsTags.egaming',
    defaultMessage: 'Entertainment & Gaming',
  },
  trends: {
    id: 'preferences.influencerAssetsTags.trends',
    defaultMessage: 'Trends',
  },
  lessons: {
    id: 'preferences.influencerAssetsTags.lessons',
    defaultMessage: 'Lessons',
  },
  events: {
    id: 'preferences.influencerAssetsTags.events',
    defaultMessage: 'Events',
  },
  professionals: {
    id: 'preferences.influencerAssetsTags.professionals',
    defaultMessage: 'Professionals',
  },
  people: {
    id: 'preferences.influencerAssetsTags.people',
    defaultMessage: 'People',
  },
  electric: {
    id: 'preferences.influencerAssetsTags.electric',
    defaultMessage: 'Electric',
  },
  concert: {
    id: 'preferences.influencerAssetsTags.concert',
    defaultMessage: 'Concert',
  },
  rejected: {
    id: 'preferences.influencerAssetsTags.rejected',
    defaultMessage: 'Rejected',
  },
  preselected: {
    id: 'preferences.influencerAssetsTags.preselected',
    defaultMessage: 'Preselected',
  },
  selected: {
    id: 'preferences.influencerAssetsTags.selected',
    defaultMessage: 'Selected',
  },
});

export const useTags = () => {
  const { client } = useClient();
  const intl = useIntl();

  const tags = React.useMemo(
    () => client?.tags?.influencerAssets || [],
    [client],
  );

  return React.useMemo(
    () => ({
      tags,
      getTagLabelByValue: (tag) => intl.formatMessage(tagsStrings[tag]),
    }),
    [tags, intl],
  );
};

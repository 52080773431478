import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import LineClamp from 'shiitake';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import ApplicationClientReviewIcon from 'components/applications/ApplicationClientReviewIcon';
import ApplicationClientReviewStatus from 'components/applications/ApplicationClientReviewStatus';

import {
  useStatus as useCampaignStatus,
  statuses as campaignStatuses,
} from 'utils/campaign';
import {
  InfluencerCRMApplicationPropType,
  ClientApplicationReviewPropType,
} from 'utils/application';
import { formatDate } from 'utils/formatting';

const useItemStyles = makeStyles((theme) => ({
  icon: {
    minWidth: theme.spacing(5),
  },
  dot: {
    color: (props) => props.campaignStatusColor,
    margin: theme.spacing(0, 0.5),
  },
}));

const CampaignListItem = ({
  campaignId,
  applicationId,
  clientReview,
  campaignName,
  campaignStatus,
  applicationStatus,
  applicationDate,
}) => {
  const campaignStatusInfo = useCampaignStatus(campaignStatus);
  const classes = useItemStyles({
    campaignStatusColor: campaignStatusInfo.color,
  });
  const intl = useIntl();

  const secondaryText = React.useMemo(() => {
    const formattedApplicationDate = formatDate({
      isoDate: applicationDate,
      formatter: intl.formatDate,
    });

    return (
      <Box display="flex">
        <Typography color="textPrimary">{formattedApplicationDate}</Typography>
        <Typography className={classes.dot}>•</Typography>
        <Typography color="textPrimary" noWrap>
          {campaignStatusInfo.label}
        </Typography>
      </Box>
    );
  }, [applicationDate, campaignStatus]);

  const isPrehistoric =
    applicationStatus === 'approved' && clientReview.status === 'pending';

  return (
    <ListItem
      component={Link}
      to={
        isPrehistoric
          ? `/campaigns/${campaignId}/live?sort=-publishedAt`
          : `/campaigns/${campaignId}/applications?inspect=${applicationId}`
      }
      button
    >
      <ListItemIcon className={classes.icon}>
        {isPrehistoric ? (
          <ApplicationClientReviewIcon status="accepted" />
        ) : (
          <ApplicationClientReviewStatus clientReview={clientReview} />
        )}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="subtitle1">
            <LineClamp lines={2}>{campaignName}</LineClamp>
          </Typography>
        }
        secondary={secondaryText}
      />
    </ListItem>
  );
};

CampaignListItem.propTypes = {
  campaignId: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  clientReview: ClientApplicationReviewPropType.isRequired,
  campaignName: PropTypes.string.isRequired,
  campaignStatus: PropTypes.string.isRequired,
  applicationStatus: PropTypes.string.isRequired,
  applicationDate: PropTypes.string.isRequired,
};

CampaignListItem.defaultProps = {};

const ChannelCardCampaignsList = ({ applications }) => {
  const filteredApplications = React.useMemo(
    () =>
      applications.filter(
        (application) =>
          application.campaignSummaryStatus !== campaignStatuses.HIDDEN,
      ),
    [applications],
  );

  return (
    <List>
      {filteredApplications.map(
        ({
          campaignId,
          applicationId,
          campaignName,
          campaignSummaryStatus,
          applicationStatus,
          createdAt,
          clientApplicationReview,
        }) => (
          <React.Fragment key={applicationId}>
            <CampaignListItem
              campaignId={campaignId}
              applicationId={applicationId}
              campaignName={campaignName}
              campaignStatus={campaignSummaryStatus}
              applicationStatus={applicationStatus}
              applicationDate={createdAt}
              clientReview={clientApplicationReview}
            />
            <Divider />
          </React.Fragment>
        ),
      )}
      {isEmpty(applications) && <Typography>No campaigns</Typography>}
    </List>
  );
};

ChannelCardCampaignsList.propTypes = {
  applications: PropTypes.arrayOf(InfluencerCRMApplicationPropType),
};

ChannelCardCampaignsList.defaultProps = {
  applications: [],
};

export default ChannelCardCampaignsList;

import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  secondaryBackground: {
    backgroundColor: theme.palette.secondary.light,
    '&:focus': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  label: {
    transform: 'translate(16px, -6px) scale(0.75)',
    padding: '2px 8px 0',
    margin: '-2px -8px 0',
    borderRadius: 4,
  },
}));

const PrivateNetworkSelect = ({
  privateNetworks,
  value,
  onChange,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const label = intl.formatMessage({
    id: 'scenes.privateNetwork.networkSelect.label',
    defaultMessage: 'Client',
  });

  return (
    <FormControl variant="outlined" {...otherProps}>
      <InputLabel
        id="network-select-label"
        className={clsx(classes.secondaryBackground, classes.label)}
      >
        {label}
      </InputLabel>
      <Select
        className={classes.secondaryBackground}
        classes={{ root: classes.secondaryBackground }}
        id="network-select"
        labelId="network-select-label"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        label={label}
        margin="dense"
      >
        {privateNetworks.map((privateNetwork) => (
          <MenuItem key={privateNetwork.id} value={privateNetwork.id}>
            {privateNetwork.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

PrivateNetworkSelect.propTypes = {
  privateNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
PrivateNetworkSelect.defaultProps = {};

export default PrivateNetworkSelect;

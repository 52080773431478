import { defineMessages } from 'react-intl';
import sanitizeFilename from 'sanitize-filename';
import values from 'lodash/values';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import countries from 'i18n-iso-countries';

import SpreadSheet from 'utils/SpreadSheet';
import { formatDate, formatDateTime } from 'utils/formatting';
import { deliveryAddressFields } from 'utils/application';

const columnHeadersStrings = defineMessages({
  instagramChannel: {
    id: 'preparationView.spreadsheet.column.instagramChannel',
    defaultMessage: '@channelname',
  },
  pinterestChannel: {
    id: 'preparationView.spreadsheet.column.pinterestChannel',
    defaultMessage: '@channelname',
  },
  tiktokChannel: {
    id: 'preparationView.spreadsheet.column.tiktokChannel',
    defaultMessage: '@channelname',
  },
  websiteChannel: {
    id: 'preparationView.spreadsheet.column.websiteChannel',
    defaultMessage: 'Website',
  },
  profile: {
    id: 'preparationView.spreadsheet.column.profile',
    defaultMessage: 'Profile',
  },
  status: {
    id: 'preparationView.spreadsheet.column.status',
    defaultMessage: 'Status',
  },
  publishDate: {
    id: 'preparationView.spreadsheet.column.publishDate',
    defaultMessage: 'Expected publishing date',
  },
  kamNote: {
    id: 'preparationView.spreadsheet.column.kamNote',
    defaultMessage: "AM's Note",
  },
  clientNote: {
    id: 'preparationView.spreadsheet.column.clientNote',
    defaultMessage: 'Note for AM',
  },
  productChoice: {
    id: 'preparationView.spreadsheet.column.productChoice',
    defaultMessage: 'Product',
  },
});

const deliveryAddressColumnsStrings = defineMessages({
  [deliveryAddressFields.FIRSTNAME]: {
    id: 'preparationView.spreadsheet.column.deliveryAddress.firstName',
    defaultMessage: 'First Name',
  },
  [deliveryAddressFields.LASTNAME]: {
    id: 'preparationView.spreadsheet.column.deliveryAddress.lastName',
    defaultMessage: 'Last Name',
  },
  [deliveryAddressFields.STREET]: {
    id: 'preparationView.spreadsheet.column.deliveryAddress.streetName',
    defaultMessage: 'Street Name',
  },
  [deliveryAddressFields.STREET_NUMBER]: {
    id: 'preparationView.spreadsheet.column.deliveryAddress.streetNumber',
    defaultMessage: 'Street Number',
  },
  [deliveryAddressFields.POSTAL_CODE]: {
    id: 'preparationView.spreadsheet.column.deliveryAddress.postalCode',
    defaultMessage: 'Postal Code',
  },
  [deliveryAddressFields.CITY]: {
    id: 'preparationView.spreadsheet.column.deliveryAddress.city',
    defaultMessage: 'City',
  },
  [deliveryAddressFields.COUNTRY]: {
    id: 'preparationView.spreadsheet.column.deliveryAddress.country',
    defaultMessage: 'Country',
  },
});

export default async ({
  name,
  campaign,
  applications: applicationsProp,
  productShipmentEnabled,
  hideKamNotesColumn,
  platform,
  intl,
}) => {
  // replace tasks array on every application with tasks map
  const applications = applicationsProp.map((originalApplication) => {
    const tasksMap = keyBy(originalApplication.tasks, 'id');
    const application = { ...originalApplication, tasks: tasksMap };

    return application;
  });

  const instagramUsernameColumn = {
    name: intl.formatMessage(columnHeadersStrings.instagramChannel),
    key: 'channel',
    data: 'channel.name',
    width: 15,
  };

  const pinterestUsernameColumn = {
    name: intl.formatMessage(columnHeadersStrings.pinterestChannel),
    key: 'channel',
    data: 'channel.name',
    width: 15,
  };

  const tiktokUsernameColumn = {
    name: intl.formatMessage(columnHeadersStrings.tiktokChannel),
    key: 'channel',
    data: 'channel.name',
    width: 15,
  };

  const websiteNameColumn = {
    name: intl.formatMessage(columnHeadersStrings.websiteChannel),
    key: 'channel',
    data: 'channel.name',
    width: 32,
  };

  const profileColumn = {
    name: intl.formatMessage(columnHeadersStrings.profile),
    key: 'profile',
    data: (application) => {
      const channelUrl = application?.channel?.data?.url;

      return {
        text: channelUrl,
        hyperlink: channelUrl,
        tooltip: channelUrl,
      };
    },
    width: 32,
  };

  const statusColumn = {
    name: intl.formatMessage(columnHeadersStrings.status),
    key: 'status',
    data: (application) => {
      const status = application?.mission?.status;

      if (status === 'confirmed') {
        return 'Started';
      }

      return 'Pending';
    },
    width: 15,
  };

  const publishDateColumn = {
    name: intl.formatMessage(columnHeadersStrings.publishDate),
    key: 'publishDate',
    data: (application) => {
      const publishDate = application?.mission?.publishDate;

      if (!publishDate) {
        return '—';
      }

      return formatDate({
        isoDate: publishDate,
        formatter: intl.formatDate,
      });
    },
    width: 15,
  };

  const kamNoteColumn = {
    name: intl.formatMessage(columnHeadersStrings.kamNote),
    key: 'kamNote',
    data: 'kamNotes.note',
    width: 32,
    cellProperties: {
      alignment: { wrapText: true },
    },
  };

  const clientNoteColumn = {
    name: intl.formatMessage(columnHeadersStrings.clientNote),
    key: 'clientNote',
    data: 'clientNotes.note',
    width: 32,
    cellProperties: {
      alignment: { wrapText: true },
    },
  };

  const clientTaskColumn = (task) => ({
    name: task.clientLabel,
    key: `tasks.${task.id}`,
    data: `tasks.${task.id}.body`,
    width: 32,
    cellProperties: {
      alignment: { wrapText: true },
    },
  });

  const productChoiceColumn = {
    name: intl.formatMessage(columnHeadersStrings.productChoice),
    key: 'productChoice',
    data: 'productShipment.product',
    width: 32,
  };

  const channelColumn = {
    instagram: instagramUsernameColumn,
    pinterest: pinterestUsernameColumn,
    tiktok: tiktokUsernameColumn,
    ga: websiteNameColumn,
    website: websiteNameColumn,
  };

  const columns = [
    channelColumn[platform],
    profileColumn,
    statusColumn,
    publishDateColumn,
  ];

  if (!hideKamNotesColumn) {
    columns.push(kamNoteColumn);
  }

  columns.push(clientNoteColumn);

  (campaign.tasks || []).forEach((task) => {
    const column = clientTaskColumn(task);
    columns.push(column);
  });

  if (productShipmentEnabled) {
    const addressFieldName = 'productShipment.address';

    const deliveryAddressColumns = values(deliveryAddressFields).map(
      (field) => ({
        name: intl.formatMessage(deliveryAddressColumnsStrings[field]),
        key: field,
        data:
          field === 'country'
            ? (application) => {
                const countryCode = get(
                  application,
                  `${addressFieldName}.country`,
                );

                if (!countryCode) {
                  return '';
                }

                return countries.getName(countryCode, intl.locale);
              }
            : `${addressFieldName}.${field}`,
        width: 20,
      }),
    );
    columns.push(...deliveryAddressColumns);
  }

  if (productShipmentEnabled) {
    columns.push(productChoiceColumn);
  }

  const spreadSheet = new SpreadSheet({
    name,
    columns,
  });

  spreadSheet.insertData(applications);

  const worksheet = spreadSheet.sheet;

  const headerRow = worksheet.getRow(1);

  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFEBECED' },
    };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });

  // Empty row for space
  worksheet.addRow();

  spreadSheet.addMergedRow(
    intl.formatMessage(
      {
        id: 'preparationView.spreadsheet.downloadInfo',
        defaultMessage:
          'Downloaded from eqolot Business Manager on {downloadTime}',
      },
      {
        downloadTime: formatDateTime({
          isoDate: new Date().toISOString(),
          formatter: intl.formatDate,
        }),
      },
    ),
  );

  spreadSheet.addMergedRow({
    text: name,
    hyperlink: window.location.href,
    tooltip: name,
  });

  spreadSheet.addMergedRow(
    intl.formatMessage({
      id: 'preparationView.spreadsheet.copyright',
      defaultMessage:
        '(C) Copyright eqolot 2021. The data in this file is confidential information. DO NOT SHARE!',
    }),
    {
      font: {
        color: { argb: 'FF9B9A97' },
      },
    },
  );

  await spreadSheet.download(sanitizeFilename(name, { replacement: '-' }));
};

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import Page from 'layout/Page';
import InsiderHeader from './InsiderHeader';

const InsiderPage = ({
  title,
  pageTitle,
  subtitle,
  showHeaderImage,
  children,
}) => (
  <Page pageTitle={pageTitle} pageTitleRoot="EQOLOT Insider" showTitle={false}>
    <Box maxWidth={1200} margin="auto">
      <InsiderHeader
        title={title}
        subtitle={subtitle}
        showImage={showHeaderImage}
      />
      {children}
    </Box>
  </Page>
);

InsiderPage.propTypes = {
  title: PropTypes.string,
  pageTitle: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  showHeaderImage: PropTypes.bool,
};
InsiderPage.defaultProps = {
  title: '',
  pageTitle: '',
  subtitle: '',
  showHeaderImage: true,
};

export default InsiderPage;

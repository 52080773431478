import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import LineClamp from 'shiitake';

import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import GenderIcon from 'icons/Gender';
import CountryLabel from 'components/CountryLabel';
import { formatNumber as numberFormatter } from 'utils/formatting';
import { ApplicationPropType } from 'utils/application';
import { LocationLabel } from 'utils/user';
import { useCategories } from 'utils/categories';
import { getChannelMetrics } from 'utils/kpi';
import { getCountryCodeByName } from 'utils/countries';

import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellNumber from 'components/table/TableCellNumber';
import TableCellChannelAvatar from 'components/table/TableCellChannelAvatar';
import TableCellChannel from 'components/table/TableCellChannel';
import TableCellTags from 'components/table/TableCellTags';
import TableCellClientReviewStatus from 'components/table/TableCellClientReviewStatus';

const BlogApplicationTableRow = ({
  application,
  gravatarBackgroundColor,
  hideKamNotesColumn,
  ...otherProps
}) => {
  const {
    clientApplicationReview,
    channel,
    user,
    reach,
    advertiserPrice,
    kamNotes,
  } = application;
  const { categories } = channel;

  const intl = useIntl();
  const { getFullCategories } = useCategories();

  const fullCategories = React.useMemo(
    () => getFullCategories(categories),
    [categories, getFullCategories],
  );

  const formatPercentage = React.useCallback(
    (number) =>
      numberFormatter({
        formatter: intl.formatNumber,
        number: number / 100,
        format: 'percent',
      }),
    [intl],
  );

  const metrics = getChannelMetrics(channel);
  const topCountry = last(sortBy(metrics.countries, 'percentage'));
  const topCountryCode = topCountry && getCountryCodeByName(topCountry.country);

  return (
    <TableRow {...otherProps}>
      <TableCellClientReviewStatus
        clientReview={clientApplicationReview}
        sortDirection={false}
      />
      <TableCellChannelAvatar
        channel={channel}
        user={user}
        gravatarBackgroundColor={gravatarBackgroundColor}
      />
      <TableCellChannel channel={channel} />
      {!hideKamNotesColumn && (
        <TableCellText noWrap={false}>
          <Box minWidth={130}>
            <LineClamp lines={2}>{kamNotes?.note}</LineClamp>
          </Box>
        </TableCellText>
      )}
      <TableCell align="center" sortDirection={false}>
        <GenderIcon gender={user.salutation} />
      </TableCell>
      <TableCellText>
        <LocationLabel user={user} />
      </TableCellText>
      <TableCellNumber value={reach} roundingDigits={2} />
      <TableCellNumber value={advertiserPrice} format="currency" />
      <TableCell align="left">
        {topCountry ? (
          <CountryLabel countryCode={topCountryCode}>
            <Typography noWrap>
              {formatPercentage(topCountry.percentage)}
            </Typography>
          </CountryLabel>
        ) : (
          <Typography noWrap>—</Typography>
        )}
      </TableCell>
      <TableCellTags
        tags={fullCategories}
        getTagValue={(tag) => tag.code}
        getTagLabel={(tag) => tag.label}
        sortBy="weight"
      />
    </TableRow>
  );
};

BlogApplicationTableRow.propTypes = {
  application: ApplicationPropType.isRequired,
  gravatarBackgroundColor: PropTypes.string,
  hideKamNotesColumn: PropTypes.bool,
  // from otherProps
  hover: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

BlogApplicationTableRow.defaultProps = {
  gravatarBackgroundColor: undefined,
  hideKamNotesColumn: false,
};

export default BlogApplicationTableRow;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import omit from 'lodash/omit';
import { useIntl, FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import MarkdownNote from 'components/MarkdownNote';
import Tabs from 'components/Tabs';
import ChannelCardAudienceKPISection from 'components/channels/card/ChannelCardAudienceKPISection';
import ChannelCardPlatformLink from 'components/channels/card/ChannelCardPlatformLink';
import ChannelCardCategories from 'components/channels/card/ChannelCardCategories';
import ChannelCardBody from 'components/channels/card/ChannelCardBody';
import ChannelCardClientTags from 'components/channels/card/ChannelCardClientTags';
import ChannelCardPerformanceKPISection from 'components/channels/card/ChannelCardPerformanceKPISection';

import ChannelCardCampaignsList from 'components/channels/card/ChannelCardCampaignsList';
import NewChannelNoteDialog from 'components/channels/card/NewChannelNoteDialog';
import ChannelCardNotesList from 'components/channels/card/ChannelCardNotesList';

import { Feature, useFeatures } from 'utils/features';
import { ChannelPropType } from 'utils/channel';
import { formatDateTime } from 'utils/formatting';
import { useClient } from 'utils/client';
import useAuth from 'auth/useAuth';
import { PrivateNetworkApplicationPropType } from 'utils/privateNetwork';

import PrivateNetworkApplicationCardHeader from '../components/PrivateNetworkApplicationCardHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: theme.typography.pxToRem(320),
    backgroundColor: '#fff',
  },
  body: {
    height: '100%',
    // The review button is 85px high
    paddingBottom: `calc(${theme.spacing(3)} + 85px)`,
  },
  internalNotesTitle: {
    marginTop: theme.spacing(1),
  },
}));

const TABS = {
  APPLICATION: 0,
  INSIGHTS: 1,
  CAMPAIGNS: 2,
  NOTES: 3,
};

const PrivateNetworkApplicationClientCard = ({
  selectedChannel,
  channels,
  onSelectChannel,
  application,
  onUpdateChannelTags,
  className,
  loading,
  updateChannelNotes,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { client } = useClient();
  const { user } = useAuth();
  const { isFeatureAvailable } = useFeatures();
  const [selectedTab, setSelectedTab] = React.useState(TABS.APPLICATION);

  const [newNoteDialogOpen, setNewNoteDialogOpen] = React.useState(false);

  const displayChannel = React.useMemo(
    () => selectedChannel || channels[0],
    [selectedChannel, channels[0]],
  );

  const handleSelectTab = (newTabIndex) => {
    if (newTabIndex === TABS.APPLICATION) {
      onSelectChannel(null);
      setSelectedTab(newTabIndex);
    } else {
      onSelectChannel(displayChannel.id);
      setSelectedTab(newTabIndex);
    }
  };

  const handleSelectChannel = (newChannelId) => {
    if (!selectedChannel?.id) {
      setSelectedTab(TABS.INSIGHTS);
    }
    onSelectChannel(newChannelId);
  };

  React.useEffect(() => {
    if (!selectedChannel?.id) {
      setSelectedTab(TABS.APPLICATION);
    } else {
      setSelectedTab(
        selectedTab === TABS.APPLICATION ? TABS.INSIGHTS : selectedTab,
      );
    }
  }, [selectedChannel?.id]);

  const submitNewNote = (newNote) => {
    const newNotePayload = {
      createdAt: new Date(),
      createdBy: user.email,
      creatorName: user.name,
      note: newNote,
    };

    const cleanNotes = selectedChannel.notes.map((note) =>
      omit(note, '__typename'),
    );

    updateChannelNotes([...cleanNotes, newNotePayload]);
  };

  const tabLabels = [
    intl.formatMessage({
      id: 'components.PrivateNetworkApplicationCard.tabs.application',
      defaultMessage: 'Request',
    }),
    intl.formatMessage({
      id: 'components.ApplicationCard.tabs.insights',
      defaultMessage: 'Insights',
    }),
  ];

  if (isFeatureAvailable('privateNetworkCollaboratorFilter')) {
    tabLabels.push(
      intl.formatMessage(
        {
          id: 'components.PrivateNetworkApplicationCard.tabs.campaigns',
          defaultMessage: 'Camp... ({numberOfApplications})',
        },
        {
          numberOfApplications: displayChannel?.applications?.length,
        },
      ),
      intl.formatMessage(
        {
          id: 'components.PrivateNetworkApplicationCard.tabs.notes',
          defaultMessage: 'Notes ({numberOfNotes})',
        },
        {
          numberOfNotes: displayChannel?.notes?.length,
        },
      ),
    );
  }

  const isApplicationView =
    selectedTab === TABS.APPLICATION || !selectedChannel;

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <PrivateNetworkApplicationCardHeader
        channels={channels}
        selectedChannel={displayChannel}
        onSelectChannel={handleSelectChannel}
        user={application.user}
        reviewStatus={application?.clientReview?.status}
        hideSelection={isApplicationView}
        hideChannelName={isApplicationView}
        pb={0}
      />
      <Tabs
        selectedTab={selectedTab}
        onSelectTab={handleSelectTab}
        labels={tabLabels}
      >
        <ChannelCardBody className={classes.body} key="application">
          <Box display="flex" flexDirection="column">
            {application?.kamNote?.note && (
              <MarkdownNote
                label={
                  <FormattedMessage
                    id="components.PrivateNetworkApplicationCard.kamNoteHeadline"
                    defaultMessage="Account Manager's Note"
                  />
                }
                note={application?.kamNote?.note}
                timestamp={application?.kamNote?.updatedAt}
                updaterName="eqolot"
              />
            )}
            <MarkdownNote
              label={
                <FormattedMessage
                  id="components.PrivateNetworkApplicationCard.pitchHeadline"
                  defaultMessage="Request to Join"
                />
              }
              note={application?.userPitch.pitch}
              timestamp={application?.createdAt}
            />
          </Box>
        </ChannelCardBody>
        <ChannelCardBody className={classes.body} key="insights">
          <ChannelCardPlatformLink channel={displayChannel} />
          <ChannelCardCategories channel={displayChannel} />
          <Feature feature="influencerFinderTags">
            <ChannelCardClientTags
              channel={displayChannel}
              onSubmit={onUpdateChannelTags}
              loading={loading}
            />
          </Feature>
          <ChannelCardPerformanceKPISection channel={displayChannel} />
          <ChannelCardAudienceKPISection channel={displayChannel} />
        </ChannelCardBody>
        <Feature feature="privateNetworkCollaboratorFilter">
          <ChannelCardBody py={1}>
            <Typography variant="overline" color="textSecondary">
              {intl.formatMessage({
                id: 'components.ChannelCard.tabs.campaigns.lastUpdatedLabel',
                defaultMessage: 'Last Updated',
              })}
              &nbsp;
              {formatDateTime({
                isoDate: displayChannel.updatedAt,
                formatter: intl.formatDate,
              })}
            </Typography>
            <ChannelCardCampaignsList
              applications={displayChannel.applications}
            />
          </ChannelCardBody>
        </Feature>
        <Feature feature="privateNetworkCollaboratorFilter">
          <ChannelCardBody>
            <NewChannelNoteDialog
              open={newNoteDialogOpen}
              onClose={() => setNewNoteDialogOpen(false)}
              onSave={submitNewNote}
            />
            <Box display="flex" justifyContent="space-between">
              <Typography
                className={classes.internalNotesTitle}
                variant="overline"
                color="textSecondary"
              >
                {intl.formatMessage(
                  {
                    id: 'components.ChannelCard.tabs.notes.title',
                    defaultMessage: '{clientName} Internal Notes',
                  },
                  {
                    clientName: client.name,
                  },
                )}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setNewNoteDialogOpen(true)}
              >
                +
              </Button>
            </Box>
            <ChannelCardNotesList notes={displayChannel.notes} />
          </ChannelCardBody>
        </Feature>
      </Tabs>
    </Box>
  );
};

PrivateNetworkApplicationClientCard.propTypes = {
  selectedChannel: ChannelPropType,
  channels: PropTypes.arrayOf(ChannelPropType).isRequired,
  onSelectChannel: PropTypes.func.isRequired,
  onUpdateChannelTags: PropTypes.func.isRequired,
  updateChannelNotes: PropTypes.func.isRequired,
  application: PrivateNetworkApplicationPropType.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

PrivateNetworkApplicationClientCard.defaultProps = {
  selectedChannel: null,
  className: '',
};

export default PrivateNetworkApplicationClientCard;

import React from 'react';
import PropTypes from 'prop-types';
import fileSaver from 'file-saver';

import { useGA } from 'utils/gtm';

import InfluencerAssetCompact from './InfluencerAssetCardCompact';
import InfluencerAssetFullScreen from './InfluencerAssetCardFullScreen';

const InfluencerAssetCard = React.memo((props) => {
  const { fullScreen, ...cardProps } = props;

  const { trackEvent } = useGA();

  const handleInspect = (e) => {
    e.stopPropagation();

    const { id, onInspect, selected } = props;

    if (selected) {
      onInspect(null);
    } else {
      onInspect(id);
    }
  };

  const handleDownload = React.useCallback(
    (e) => {
      e.stopPropagation();
      const { id, mediaSrc } = props;

      fileSaver.saveAs(mediaSrc);

      trackEvent({
        action: 'content_card_download_triggered',
        label: `Content Card: ${id}`,
      });
    },
    [trackEvent, props.mediaSrc, props.id],
  );

  if (fullScreen) {
    return (
      <InfluencerAssetFullScreen
        {...cardProps}
        onDownload={handleDownload}
        onInspect={handleInspect}
      />
    );
  }

  return (
    <InfluencerAssetCompact
      {...cardProps}
      onDownload={handleDownload}
      onInspect={handleInspect}
    />
  );
});

InfluencerAssetCard.propTypes = {
  id: PropTypes.string.isRequired,
  onInspect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  fullScreen: PropTypes.bool,
  mediaSrc: PropTypes.string.isRequired,
};

InfluencerAssetCard.defaultProps = {
  fullScreen: false,
  selected: false,
};

export default InfluencerAssetCard;

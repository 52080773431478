import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

import PlatformLabel from 'components/PlatformLabel';
import { CampaignPropType } from 'utils/campaign';
import { AlignPropType } from 'utils/table';

const TableCellCampaignName = ({ campaign, align, ...otherProps }) => {
  const theme = useTheme();

  return (
    <TableCell align={align} {...otherProps}>
      <PlatformLabel
        platform={campaign.platform}
        channelName={campaign.name}
        variant="subtitle2"
        color={theme.palette.text.primary}
      />
    </TableCell>
  );
};

TableCellCampaignName.propTypes = {
  align: AlignPropType,
  campaign: CampaignPropType.isRequired,
};

TableCellCampaignName.defaultProps = {
  align: 'left',
};

export default TableCellCampaignName;

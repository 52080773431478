import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Context = React.createContext();

const useStyles = makeStyles((theme) => ({
  snackbar: {
    left: `calc(50% + ${theme.sidebar.width / 2}px)`,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ToastsProvider = ({ children }) => {
  const classes = useStyles();

  const [toasts, setToasts] = React.useState([]);

  const showToast = React.useCallback(
    ({ key, type, color, message, autoHideDuration }) => {
      const onClose = () => {
        setToasts(toasts.filter((toast) => toast.key !== key));
      };

      setToasts([
        ...toasts,
        { key, type, color, message, autoHideDuration, onClose },
      ]);
    },
    [toasts, setToasts],
  );

  return (
    <Context.Provider value={showToast}>
      {toasts.map((toast) => (
        <Snackbar
          key={toast.key}
          className={classes.snackbar}
          open
          onClose={toast.onClose}
          autoHideDuration={toast.autoHideDuration}
        >
          <Alert
            severity={toast.type}
            color={toast.color}
            className={classes.alert}
            onClose={toast.onClose}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      ))}
      {children}
    </Context.Provider>
  );
};

ToastsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useShowToast = () => React.useContext(Context);

import React from 'react';
import PropTypes from 'prop-types';

import { ApplicationPropType } from 'utils/application';
import { PlatformPropType } from 'utils/channel';

import Instagram from './instagram/TableMetaLabel';
import Pinterest from './pinterest/TableMetaLabel';
import Tiktok from './tiktok/TableMetaLabel';
import Blog from './blog/TableMetaLabel';

const componentsByPlatform = {
  instagram: Instagram,
  pinterest: Pinterest,
  tiktok: Tiktok,
  website: Blog,
  ga: Blog,
};

const PublishedApplicationsTableMetaLabel = ({
  applications,
  platform,
  ...otherProps
}) => {
  const Component = componentsByPlatform[platform];
  return <Component applications={applications} {...otherProps} />;
};

PublishedApplicationsTableMetaLabel.propTypes = {
  platform: PlatformPropType.isRequired,
  applications: PropTypes.arrayOf(ApplicationPropType).isRequired,
};

PublishedApplicationsTableMetaLabel.defaultProps = {};

export default PublishedApplicationsTableMetaLabel;

import React from 'react';
import { useIntl } from 'react-intl';

import { ChannelPropType } from 'utils/channel';

import ChannelCardSection from './ChannelCardSection';
import ChannelCardKPIRow from './ChannelCardKPIRow';
import ChannelCardRating from './ChannelCardRating';

const ChannelCardCollaborationRatingsSection = ({ channel }) => {
  const intl = useIntl();
  const { collaborationRatings } = channel;

  return (
    <ChannelCardSection
      title={intl.formatMessage({
        id: 'components.ChannelCard.ratingsSection.title',
        defaultMessage: 'Collaboration Ratings',
      })}
    >
      <ChannelCardKPIRow>
        <ChannelCardRating
          label={intl.formatMessage({
            id: 'components.ChannelCard.rating.visualCreativity',
            defaultMessage: 'Visual Creativity',
          })}
          value={collaborationRatings?.visualCreativity}
          bold
        />
        <ChannelCardRating
          label={intl.formatMessage({
            id: 'components.ChannelCard.rating.copyWriting',
            defaultMessage: 'Copy Writing',
          })}
          format="percent"
          value={collaborationRatings?.copyWriting}
          bold
        />
      </ChannelCardKPIRow>
      <ChannelCardKPIRow>
        <ChannelCardRating
          label={intl.formatMessage({
            id: 'components.ChannelCard.rating.punctuality',
            defaultMessage: 'Punctuality',
          })}
          value={collaborationRatings?.punctuality}
        />
        <ChannelCardRating
          label={intl.formatMessage({
            id: 'components.ChannelCard.rating.professionalism',
            defaultMessage: 'Professionalism',
          })}
          value={collaborationRatings?.professionalism}
        />
      </ChannelCardKPIRow>
    </ChannelCardSection>
  );
};

ChannelCardCollaborationRatingsSection.propTypes = {
  channel: ChannelPropType.isRequired,
};

ChannelCardCollaborationRatingsSection.defaultProps = {};

export default ChannelCardCollaborationRatingsSection;

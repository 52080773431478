import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  closeIcon: {
    top: 4,
    right: 6,
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
  },
});

const PreviewContentModal = ({ children, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Modal {...otherProps}>
      <Box className={classes.wrapper}>
        <IconButton className={classes.closeIcon}>
          <Icons.XCircle color="white" onClick={otherProps.onClose} />
        </IconButton>
        {children}
      </Box>
    </Modal>
  );
};

PreviewContentModal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PreviewContentModal;

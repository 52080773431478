import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const isDefaultValue = (internalValue, steps) => {
  const [minIndex, maxIndex] = internalValue;
  const lastStepIndex = steps.length - 1;

  return minIndex === 0 && maxIndex === lastStepIndex;
};

const toInternal = (value, steps) => {
  const { min, max } = value;
  const minIndex = min ? steps.findIndex((s) => s.value === min) : 0;
  const maxIndex = max
    ? steps.findIndex((s) => s.value === max)
    : steps.length - 1;
  return [minIndex, maxIndex];
};

const toExternal = (value, steps) => {
  const [minIndex, maxIndex] = value;
  const external = {
    min: steps[minIndex].value,
    max: steps[maxIndex].value,
  };

  if (external.max === null) {
    delete external.max;
  }

  return external;
};

const useStyles = makeStyles({
  label: {
    marginTop: -3,
  },
  // The slider has some media queries that change the padding that mess up our layout
  sliderRoot: {
    padding: 0,
  },
});

const ReachFilter = ({
  className,
  steps,
  value,
  onChange,
  valueLabelDisplay,
  hideLabelsForDefaultValues,
  label,
  ...otherProps
}) => {
  const classes = useStyles();

  const [internalValue, setInternalValue] = React.useState(
    toInternal(value, steps),
  );

  React.useEffect(() => {
    setInternalValue(toInternal(value, steps));
  }, [value]);

  const handleChange = (e, updatedValue) => {
    const [minIndex] = updatedValue;

    if (
      !isEqual(internalValue, updatedValue) &&
      minIndex !== steps.length - 1
    ) {
      setInternalValue(updatedValue);
    }
  };

  const handleChangeCommitted = (e, updatedValue) => {
    onChange(toExternal(updatedValue, steps));
  };

  const scaleText = (value) => steps[value].label;

  const isDefaultValueSelected = isDefaultValue(internalValue, steps);

  return (
    <Box className={className} {...otherProps}>
      <Typography id="reachRange" className={classes.label}>
        {label}
      </Typography>
      <Slider
        value={internalValue}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        aria-labelledby="reachRange"
        min={0}
        max={steps.length - 1}
        scale={scaleText}
        step={1}
        valueLabelDisplay={
          hideLabelsForDefaultValues && isDefaultValueSelected
            ? 'off'
            : valueLabelDisplay
        }
        marks
        classes={{
          root: classes.sliderRoot,
        }}
      />
    </Box>
  );
};

ReachFilter.propTypes = {
  valueLabelDisplay: PropTypes.oneOf(['on', 'off', 'auto']),
  hideLabelsForDefaultValues: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  value: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number })
    .isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ReachFilter.defaultProps = {
  hideLabelsForDefaultValues: true,
  valueLabelDisplay: 'auto',
  className: '',
};

export default ReachFilter;

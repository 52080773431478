import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { useUploadWidget } from 'utils/cloudinary';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(3),
  },
  input: {
    '& input:disabled': {
      color: theme.palette.text.primary,
    },
  },
}));

const ImageUpload = React.forwardRef(
  ({ label, value, name, onChange, onBlur, onError, ...otherProps }, ref) => {
    const classes = useStyles();

    const handleSuccess = React.useCallback(
      ({ url }) => {
        onChange(url);
      },
      [onChange],
    );

    const uploadWidget = useUploadWidget({
      onSuccess: handleSuccess,
      onError,
    });

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        {...otherProps}
      >
        <TextField
          className={classes.input}
          ref={ref}
          label={label}
          name={name}
          onBlur={onBlur}
          disabled
          variant="outlined"
          fullWidth
        />
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disableTouchRipple
          onClick={() => uploadWidget.open()}
        >
          <FormattedMessage
            id="components.ImageUploadInput.buttonLabel"
            defaultMessage="Upload"
          />
        </Button>
      </Box>
    );
  },
);

ImageUpload.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

ImageUpload.defaultProps = {};

export default ImageUpload;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ListItemText from '@material-ui/core/ListItemText';

import { getAudienceGenderFilter } from 'utils/kpi';

import TagsInput from 'components/TagsInput';

const options = [
  'femalePrevalence',
  'femaleMajority',
  'balanced',
  'maleMajority',
  'malePrevalence',
];

const AudienceGendersFilter = ({ value, onChange, ...otherProps }) => {
  const intl = useIntl();

  const handleChange = (event, newValues) => {
    onChange(newValues);
  };

  const filterOptions = React.useCallback(
    (options, { inputValue }) =>
      options.filter((filterId) => {
        const filterOption = getAudienceGenderFilter(filterId);

        const title = intl.formatMessage(filterOption.title);
        const subtitle = intl.formatMessage(filterOption.subtitle);

        const lowercaseInputValue = inputValue.toLowerCase();
        return (
          title.toLowerCase().includes(lowercaseInputValue) ||
          subtitle.toLowerCase().includes(lowercaseInputValue)
        );
      }),
    [intl, getAudienceGenderFilter],
  );

  const renderOption = React.useCallback(
    (filterId) => {
      const filterOption = getAudienceGenderFilter(filterId);

      const title = intl.formatMessage(filterOption.title);
      const subtitle = intl.formatMessage(filterOption.subtitle);

      return <ListItemText primary={title} secondary={subtitle} />;
    },
    [intl, getAudienceGenderFilter],
  );

  const getTagLabel = React.useCallback(
    (filterId) => {
      const filterOption = getAudienceGenderFilter(filterId);
      return intl.formatMessage(filterOption.title);
    },
    [intl, getAudienceGenderFilter],
  );

  return (
    <TagsInput
      value={value}
      options={options}
      renderOption={renderOption}
      getTagLabel={getTagLabel}
      onChange={handleChange}
      label={intl.formatMessage({
        id: 'components.AudienceGendersFilter.label',
        defaultMessage: 'Audience Gender',
      })}
      filterOptions={filterOptions}
      {...otherProps}
    />
  );
};

AudienceGendersFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};
AudienceGendersFilter.defaultProps = {
  value: [],
};

export default AudienceGendersFilter;

import React from 'react';
import PropTypes from 'prop-types';

import SvgIconWrapper from './SvgIconWrapper';

const Pinterest = ({ color, ...otherProps }) => (
  <SvgIconWrapper stroke={color} {...otherProps}>
    <path
      d="M6.44223 20.3146C3.7635 18.5205 2 15.4662 2 12C2 6.47716 6.47715 2 12 2C17.5228 2 22 6.47716 22 12C22 17.5229 17.5228 22 12 22C11.6856 22 11.3745 21.9855 11.0675 21.9571"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.16993 14.8652C5.46373 12.6987 5.71088 9.4274 8.37231 7.17024C9.75295 5.99932 12.5358 5.67602 14.4646 6.52275C16.2194 7.29311 17.4319 8.58969 17.7287 11.1049C17.8979 12.5392 17.1352 15.9859 14.6624 16.9322C13.4321 17.403 11.8445 17.231 11.3736 16.5337C10.0472 14.5693 9.96416 11.9516 10.1867 11.1049C10.4846 9.97139 11.3348 10.4285 11.2005 10.8558C10.5824 12.8232 9.27797 18.8077 8.37231 22"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconWrapper>
);

Pinterest.propTypes = {
  color: PropTypes.string,
};

Pinterest.defaultProps = {
  color: '#000',
};

export default Pinterest;

import React from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';

import isBefore from 'date-fns/isBefore';

const QUERY = gql`
  query Updates {
    insiderUpdates {
      id
      releasedAt
      publishedAt
      createdAt
    }
  }
`;

const Context = React.createContext();

export const WhatsNewProvider = ({ children }) => {
  const [fetchUpdates, { data: updatesData }] = useLazyQuery(QUERY);

  const [viewedIds, setViewedIds] = React.useState(() => {
    const viewedUpdatesRaw = localStorage.getItem('viewedUpdates');

    return JSON.parse(viewedUpdatesRaw || '[]');
  });

  React.useEffect(() => {
    fetchUpdates();
  }, []);

  const hasUpdateBeenSeen = React.useCallback(
    (update) => {
      const hasBeenViewed = viewedIds.includes(update.id);

      const releaseDate = new Date(
        update.releasedAt || update.publishedAt || update.createdAt,
      );
      const cutoffDate = new Date('2021-11-22T00:00:00.000Z');
      const isPastUpdate = isBefore(releaseDate, cutoffDate);

      return hasBeenViewed || isPastUpdate;
    },
    [viewedIds],
  );

  const setUpdateHasBeenSeen = React.useCallback(
    (update) => {
      if (!hasUpdateBeenSeen(update)) {
        const newViewedIds = [...viewedIds, update.id];
        setViewedIds(newViewedIds);

        localStorage.setItem('viewedUpdates', JSON.stringify(newViewedIds));
      }
    },
    [hasUpdateBeenSeen],
  );

  const hasUnseenUpdates = React.useMemo(
    () =>
      Boolean(
        updatesData?.insiderUpdates?.some(
          (update) => !hasUpdateBeenSeen(update),
        ),
      ),
    [hasUpdateBeenSeen, updatesData?.insiderUpdates],
  );

  return (
    <Context.Provider
      value={{
        hasUnseenUpdates,
        hasUpdateBeenSeen,
        setUpdateHasBeenSeen,
      }}
    >
      {children}
    </Context.Provider>
  );
};

WhatsNewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useWhatsNew = () => React.useContext(Context);

import React from 'react';
import pick from 'lodash/pick';
import { useForm } from 'react-hook-form';
import { useIntl, FormattedMessage, defineMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import ImageUpload from 'components/ImageUpload';
import HiddenToggle from 'components/HiddenToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    '& > $formSection:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  formSection: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
  boldField: {
    '& input, textarea': {
      fontWeight: 'bold',
    },
  },
}));

const featuresFields = [
  {
    id: 'insider',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.insider.label',
      defaultMessage: 'eqolot Insider',
    }),
    defaultValue: true,
  },
  {
    id: 'campaigns',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.campaigns.label',
      defaultMessage: 'Campaigns',
    }),
    defaultValue: true,
  },
  {
    id: 'audienceGendersColumn',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.audienceGendersColumn.label',
      defaultMessage: 'Audience Genders',
    }),
    defaultValue: true,
  },
  {
    id: 'influencerFinder',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.influencerFinder.label',
      defaultMessage: 'Influencer CRM',
    }),
    defaultValue: false,
  },
  {
    id: 'collaboratorFilter',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.collaboratorFilter.label',
      defaultMessage: 'Collaborators Filter',
    }),
    defaultValue: false,
  },
  {
    id: 'influencerFinderTags',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.influencerFinderTags.label',
      defaultMessage: 'Influencer CRM Tags',
    }),
    defaultValue: false,
  },
  {
    id: 'topCountriesFilter',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.topCountriesFilter.label',
      defaultMessage: 'Influencer CRM Top Countries Filter',
    }),
    defaultValue: false,
  },
  {
    id: 'audienceGendersFilter',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.audienceGendersFilter.label',
      defaultMessage: 'Audience Genders Filter',
    }),
    defaultValue: false,
  },
  {
    id: 'privateNetwork',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.privateNetwork.label',
      defaultMessage: 'Private Network',
    }),
    defaultValue: false,
  },
  {
    id: 'privateNetworkCollaboratorFilter',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.privateNetworkCollaboratorFilter.label',
      defaultMessage: 'Private Network Collaborators Filter',
    }),
    defaultValue: false,
  },
  {
    id: 'influencerAssets',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.influencerAssets.label',
      defaultMessage: 'Influencer Assets',
    }),
    defaultValue: false,
  },
  {
    id: 'influencerAssetsBuyOutPrice',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.influencerAssetsBuyOutPrice.label',
      defaultMessage: 'Influencer Assets - Buyout Price',
    }),
    defaultValue: false,
  },
  {
    id: 'publicationHistory',
    label: defineMessage({
      id: 'scenes.clients.form.details.features.publicationHistory.label',
      defaultMessage: 'Publication History',
    }),
    defaultValue: false,
  },
];

const ClientForm = ({ formData, managers, onSubmit, onCancel }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [submitEnabled, setSubmitEnabled] = React.useState(false);

  const defaultFeatures = React.useMemo(
    () =>
      featuresFields.reduce(
        (results, field) => ({
          ...results,
          [field.id]: field.defaultValue,
        }),
        { clientManagement: false },
      ),
    [featuresFields],
  );

  const {
    setValue,
    formState,
    watch,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      ...formData,
      features: {
        ...defaultFeatures,
        ...formData.features,
      },
    },
  });

  const { name, companyName, kamNote, theme, manager, features } = watch();

  const submitForm = (data) => {
    const payload = pick(data, [
      'name',
      'companyName',
      'kamNote.note',
      'theme.sidebarColor',
      'theme.logoUrl',
      'salesforceId',
      'manager.id',
      'features',
    ]);

    if (payload?.manager?.id) {
      payload.managerId = payload.manager.id;
      delete payload.manager;
    }

    if (!payload.salesforceId) {
      payload.salesforceId = '';
    }

    onSubmit(payload);
    setSubmitEnabled(false);
  };

  React.useEffect(() => {
    setSubmitEnabled(
      name && companyName && kamNote && theme && manager && formState.isDirty,
    );
  }, [name, companyName, kamNote, theme, manager, formState.isDirty]);

  React.useEffect(() => {
    reset(formData);
    setSubmitEnabled(false);
  }, [formData, reset, setSubmitEnabled]);

  const handleLogoUrlUpload = React.useCallback(
    (newUrl) => {
      setValue('theme.logoUrl', newUrl, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  return (
    <form className={classes.root} onSubmit={handleSubmit(submitForm)}>
      <Box className={classes.formSection}>
        <Typography variant="h5">
          <FormattedMessage
            id="scenes.clients.form.details.infoSection.title"
            defaultMessage="Client Information"
          />
        </Typography>
        <TextField
          {...register('name', { required: true })}
          className={classes.boldField}
          error={Boolean(errors.name)}
          label={intl.formatMessage({
            id: 'scenes.clients.form.details.name.label',
            defaultMessage: 'Short Client Name',
          })}
          helperText={intl.formatMessage({
            id: 'scenes.clients.form.details.name.helperText',
            defaultMessage: 'Used in the Business Manager and Creator App',
          })}
          variant="outlined"
          fullWidth
        />
        <TextField
          {...register('companyName', { required: true })}
          className={classes.boldField}
          error={Boolean(errors.companyName)}
          label={intl.formatMessage({
            id: 'scenes.clients.form.details.companyName.label',
            defaultMessage: 'Full Company Name',
          })}
          helperText={intl.formatMessage({
            id: 'scenes.clients.form.details.companyName.helperText',
            defaultMessage: 'Full company name for internal use',
          })}
          variant="outlined"
          fullWidth
        />
        <TextField
          {...register('kamNote.note', { required: false })}
          className={classes.boldField}
          error={Boolean(errors.kamNote)}
          label={intl.formatMessage({
            id: 'scenes.clients.form.details.kamNote.label',
            defaultMessage: 'Internal KAM Note',
          })}
          variant="outlined"
          multiline
          fullWidth
          minRows={3}
          maxRows={25}
        />
        <TextField
          {...register('salesforceId', { required: false })}
          className={classes.boldField}
          error={Boolean(errors.salesforceId)}
          label={intl.formatMessage({
            id: 'scenes.clients.form.details.salesforceId.label',
            defaultMessage: 'Salesforce ID',
          })}
          variant="outlined"
          fullWidth
        />
        <TextField
          {...register('manager.id', { required: true })}
          id="managerId"
          variant="outlined"
          className={classes.boldField}
          error={Boolean(errors.manager)}
          label={intl.formatMessage({
            id: 'scenes.clients.form.details.manager.label',
            defaultMessage: 'KAM Manager',
          })}
          value={manager.id}
          select
        >
          {managers.map((manager) => (
            <MenuItem key={manager.id} value={manager.id}>
              {manager.name} ({manager.email})
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box className={classes.formSection}>
        <Typography variant="h5">
          <FormattedMessage
            id="scenes.clients.form.details.featuresSection.title"
            defaultMessage="Business Manager Features"
          />
        </Typography>
        <ImageUpload
          {...register('theme.logoUrl', { required: true, readOnly: true })}
          onChange={handleLogoUrlUpload}
          className={classes.boldField}
          onError={(error) => {
            if (error) {
              errors.theme = error;
            }
          }}
          label={intl.formatMessage({
            id: 'scenes.clients.form.details.theme.logoUrl.label',
            defaultMessage: 'Logo Image',
          })}
        />
        <TextField
          {...register('theme.sidebarColor', { required: true })}
          className={classes.boldField}
          error={Boolean(errors.theme)}
          label={intl.formatMessage({
            id: 'scenes.clients.form.details.theme.sidebarColor.label',
            defaultMessage: 'Sidebar CSS color, gradient or image',
          })}
          variant="outlined"
          fullWidth
        />
        <Box display="flex" flexDirection="column">
          {featuresFields.map((field) => (
            <Box key={field.id}>
              <HiddenToggle
                {...register(`features.${field.id}`, { required: false })}
                py={2}
                label={intl.formatMessage(field.label)}
                onChange={(newValue) => {
                  setValue(`features.${field.id}`, newValue, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                value={features[field.id] || false}
              />
              <Divider />
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.actions}>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          {intl.formatMessage({
            id: 'scenes.clients.details.form.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          type="submit"
        >
          {intl.formatMessage({
            id: 'scenes.clients.details.form.save',
            defaultMessage: 'Save',
          })}
        </Button>
      </Box>
    </form>
  );
};

ClientForm.propTypes = {
  formData: PropTypes.shape({
    name: PropTypes.string,
    companyName: PropTypes.string,
    features: PropTypes.object,
  }),
  managers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  ),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ClientForm.defaultProps = {
  formData: {
    name: '',
    companyName: '',
  },
};

export default ClientForm;

import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import LineClamp from 'shiitake';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import { CampaignPropType } from 'utils/campaign';
import { ApplicationPropType, MissionStatusLabel } from 'utils/application';

import Address from 'components/Address';
import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellDate from 'components/table/TableCellDate';
import TableCellChannelAvatar from 'components/table/TableCellChannelAvatar';
import TableCellChannel from 'components/table/TableCellChannel';

import NoteIcon from 'icons/Note';

const propsEqual = (prevProps, nextProps) => {
  const areSelectedEqual = prevProps.selected === nextProps.selected;
  const areApplicationIdsEqual =
    prevProps.application.id === nextProps.application.id;

  const isGravatarColorEqual =
    prevProps.gravatarBackgroundColor === nextProps.gravatarBackgroundColor;

  const isHideKamNotesColumnEqual =
    prevProps.hideKamNotesColumn === nextProps.hideKamNotesColumn;

  const areNotesEqual =
    isEqual(prevProps.clientNotes === nextProps.clientNotes) &&
    isEqual(prevProps.kamNotes === nextProps.kamNotes);

  return (
    areSelectedEqual &&
    areApplicationIdsEqual &&
    isGravatarColorEqual &&
    isHideKamNotesColumnEqual &&
    areNotesEqual
  );
};

const useStyles = makeStyles((theme) => ({
  task: {
    color: theme.palette.eqolot.infoBlue,
  },
}));

const ApplicationTableRow = React.memo(
  ({
    application,
    gravatarBackgroundColor,
    productShipmentEnabled,
    hideKamNotesColumn,
    ...otherProps
  }) => {
    const theme = useTheme();
    const classes = useStyles();
    const { channel, user, mission, kamNotes, clientNotes } = application;

    const addressCell = React.useMemo(() => {
      if (productShipmentEnabled) {
        return (
          <TableCell align="left">
            <Address
              address={application?.productShipment?.address}
              variant="body2"
              noWrap
            />
          </TableCell>
        );
      }

      return null;
    }, [application, productShipmentEnabled]);

    return (
      <TableRow {...otherProps}>
        <TableCellChannelAvatar
          channel={channel}
          user={user}
          gravatarBackgroundColor={gravatarBackgroundColor}
        />
        <TableCellChannel channel={channel} />
        <TableCellText>
          <MissionStatusLabel status={mission.status} />
        </TableCellText>
        <TableCellDate value={mission.publishDate} align="right" />
        {!hideKamNotesColumn && (
          <TableCellText noWrap={false}>
            <Box minWidth={130}>
              <LineClamp lines={2}>{kamNotes?.note}</LineClamp>
            </Box>
          </TableCellText>
        )}
        <TableCell align="center">
          {clientNotes?.note && (
            <Tooltip title={clientNotes.note}>
              <Box>
                <NoteIcon stroke={theme.palette.eqolot.infoBlue} />
              </Box>
            </Tooltip>
          )}
        </TableCell>
        {productShipmentEnabled && (
          <TableCellText>{application?.productShipment?.product}</TableCellText>
        )}
        {addressCell}
        {(application.tasks || []).map((task) => (
          <TableCellText classes={{ root: classes.task }} key={task.id}>
            {task.body}
          </TableCellText>
        ))}
      </TableRow>
    );
  },
  propsEqual,
);

ApplicationTableRow.propTypes = {
  campaign: PropTypes.oneOfType([CampaignPropType, PropTypes.object]),
  application: ApplicationPropType.isRequired,
  gravatarBackgroundColor: PropTypes.string,
  productShipmentEnabled: PropTypes.bool,
  hideKamNotesColumn: PropTypes.bool,
  // from otherProps
  hover: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ApplicationTableRow.defaultProps = {
  campaign: undefined,
  gravatarBackgroundColor: undefined,
  productShipmentEnabled: false,
  hideKamNotesColumn: false,
};

export default ApplicationTableRow;

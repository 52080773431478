import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

import TableContainer from 'components/table/TableContainer';

const useStyles = makeStyles({
  table: {
    position: 'relative',
  },
});

const PrivateNetworkTable = ({ metaLabel, children, ...otherProps }) => {
  const classes = useStyles();

  return (
    <TableContainer label={metaLabel}>
      <Table
        className={classes.table}
        aria-label="Applications Table"
        size="medium"
        {...otherProps}
      >
        {children}
      </Table>
    </TableContainer>
  );
};

PrivateNetworkTable.propTypes = {
  metaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

PrivateNetworkTable.defaultProps = {};

export default PrivateNetworkTable;

import React from 'react';
import * as Icons from 'react-feather';
import { defineMessage } from 'react-intl';

import { ROLES } from 'utils/roles';

import CampaignsListPage from 'scenes/campaigns/campaignsList/CampaignsListPage';
import CampaignDetailsPage from 'scenes/campaigns/campaignDetails/CampaignDetailsPage';
import InfluencersSearchPage from 'scenes/influencerSearch/InfluencersSearchPage';
import InfluencerAssetsPage from 'scenes/influencerAssets/InfluencerAssetsPage';
import InfluencerAssetCardPage from 'scenes/influencerAssets/InfluencerAssetCardPage';
import PrivateNetworkPage from 'scenes/privateNetwork/PrivateNetworkPage';
import InsiderArticlesPage from 'scenes/insider/articles/ArticlesPage';
import WhatsNewPage from 'scenes/insider/whatsNew/WhatsNewPage';
import HelpPage from 'scenes/insider/help/HelpPage';
import ClientsListPage from 'scenes/clients/ClientsListPage';
import ClientDetailsPage from 'scenes/clients/ClientDetailsPage';

export const defaultRoute = '/campaigns';

const routes = [
  // authorized app routes
  {
    path: '/clients',
    component: ClientsListPage,
    exact: true,
    roles: [ROLES.KAM],
    feature: 'clientManagement',
  },
  {
    path: '/clients/:id/:view?',
    component: ClientDetailsPage,
    roles: [ROLES.KAM],
    feature: 'clientManagement',
  },
  {
    path: '/campaigns',
    component: CampaignsListPage,
    exact: true,
    feature: 'campaigns',
  },
  {
    path: '/campaigns/:id/:view?',
    component: CampaignDetailsPage,
    feature: 'campaigns',
  },
  {
    path: '/influencers',
    component: InfluencersSearchPage,
    feature: 'influencerFinder',
  },
  {
    path: '/influencer-assets',
    component: InfluencerAssetsPage,
    exact: true,
    feature: 'influencerAssets',
  },
  {
    path: '/influencer-assets/:id',
    component: InfluencerAssetCardPage,
  },
  {
    path: '/private-network',
    component: PrivateNetworkPage,
    exact: true,
    feature: 'privateNetwork',
  },
  {
    path: '/insider/articles/:slug?',
    component: InsiderArticlesPage,
    feature: 'insider',
  },
  {
    path: '/insider/whats-new/:slug?',
    component: WhatsNewPage,
  },
  {
    path: '/help/:slug?',
    component: HelpPage,
  },
];

export const navRoutes = [
  {
    label: defineMessage({
      id: 'sidebar.links.clients',
      defaultMessage: 'Clients',
    }),
    url: '/clients',
    icon: <Icons.Briefcase />,
    roles: [ROLES.KAM],
    feature: 'clientManagement',
  },
  {
    label: defineMessage({
      id: 'sidebar.links.campaigns',
      defaultMessage: 'Campaigns',
    }),
    url: '/campaigns',
    icon: <Icons.Package />,
    feature: 'campaigns',
  },
  {
    label: defineMessage({
      id: 'sidebar.links.influencerAssets',
      defaultMessage: 'Influencer Assets',
    }),
    url: '/influencer-assets',
    icon: <Icons.Image />,
    feature: 'influencerAssets',
  },
  {
    label: defineMessage({
      id: 'sidebar.links.privateNetwork',
      defaultMessage: 'Private Network',
    }),
    url: '/private-network',
    icon: <Icons.Award />,
    feature: 'privateNetwork',
  },
  {
    label: defineMessage({
      id: 'sidebar.links.influencers',
      defaultMessage: 'Influencer Finder',
    }),
    url: '/influencers',
    icon: <Icons.Users />,
    feature: 'influencerFinder',
  },
  {
    label: defineMessage({
      id: 'sidebar.links.insider',
      defaultMessage: 'eqolot Insider',
    }),
    url: '/insider/articles',
    icon: <Icons.Star />,
    feature: 'insider',
  },
];

export default routes;

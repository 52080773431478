import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@material-ui/core/Box';

import TagsInputForm from 'components/TagsInputForm';
import { useClient } from 'utils/client';
import { ChannelPropType, CRMChannelPropType } from 'utils/channel';

const ChannelCardClientTags = ({ channel, onSubmit, loading }) => {
  const intl = useIntl();
  const { client, tags: clientTags } = useClient();

  const label = intl.formatMessage({
    id: 'components.ChannelCard.clientTags.label',
    defaultMessage: 'Edit channel tags',
  });
  const header = intl.formatMessage(
    {
      id: 'components.ChannelCard.clientTags.header',
      defaultMessage: '{client} tags',
    },
    { client: client?.name },
  );

  return (
    <Box mb={2}>
      <TagsInputForm
        allowNew
        formData={channel}
        tagsOptions={clientTags}
        onSubmit={onSubmit}
        loading={loading}
        label={label}
        header={header}
      />
    </Box>
  );
};

ChannelCardClientTags.propTypes = {
  channel: PropTypes.oneOfType([ChannelPropType, CRMChannelPropType])
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ChannelCardClientTags.defaultProps = {
  loading: false,
};

export default ChannelCardClientTags;

import React from 'react';

import SvgIconWrapper from './SvgIconWrapper';

export default (props) => (
  <SvgIconWrapper {...props}>
    <path
      d="M12.5 14.5C15.8137 14.5 18.5 11.8137 18.5 8.5C18.5 5.18629 15.8137 2.5 12.5 2.5C9.18629 2.5 6.5 5.18629 6.5 8.5C6.5 11.8137 9.18629 14.5 12.5 14.5ZM12.5 14.5V18.75M12.5 23V18.75M12.5 18.75H18M12.5 18.75H7"
      stroke="black"
      strokeOpacity="0.54"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconWrapper>
);

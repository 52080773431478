import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { PlatformPropType } from 'utils/channel';
import { trimUrl } from 'utils/formatting';
import PlatformIcon from 'icons/Platform';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    color: (props) => props.color,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

const PlatformLabel = (props) => {
  const {
    className,
    platform,
    channelName: name,
    variant,
    color,
    ...otherProps
  } = props;
  const classes = useStyles(props);

  const channelName = ['website', 'ga'].includes(platform)
    ? trimUrl(name)
    : name;

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <PlatformIcon platform={platform} color={color} />
      <Typography variant={variant} noWrap>
        {channelName}
      </Typography>
    </Box>
  );
};

PlatformLabel.propTypes = {
  platform: PlatformPropType.isRequired,
  channelName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

PlatformLabel.defaultProps = {
  className: '',
  variant: undefined,
};

export default PlatformLabel;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: '#fff',
    padding: theme.spacing(1, 2),
  },
}));

const ChannelCardBody = ({ children, className, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      {children}
    </Box>
  );
};

ChannelCardBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ChannelCardBody.defaultProps = {
  className: '',
};

export default ChannelCardBody;

import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

export const Context = React.createContext();

export const CookiesPolicyProvider = ({ children }) => {
  const cookiesAccepted = JSON.parse(localStorage.getItem('cookiesAccepted'));

  const [cookiesAllowed, setCookiesAllowed] = React.useState(cookiesAccepted);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', JSON.stringify(true));
    setCookiesAllowed(true);
  };

  const refuseCookies = () => {
    localStorage.setItem('cookiesAccepted', JSON.stringify(false));
    setCookiesAllowed(false);
  };

  return (
    <Context.Provider
      value={{
        cookiesFlagSet: cookiesAllowed !== null,
        cookiesAllowed,
        acceptCookies,
        refuseCookies,
      }}
    >
      {children}
    </Context.Provider>
  );
};

CookiesPolicyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCookiesPolicy = () => React.useContext(Context);

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.text.primary,
    width: theme.typography.pxToRem(440),
  },
  link: {
    color: theme.palette.primary.contrastText,
  },
}));

export const CookiePolicySnackbar = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  const { cookiesFlagSet, acceptCookies, refuseCookies } = useCookiesPolicy();

  const actions = (
    <Box>
      <Button color="secondary" onClick={acceptCookies}>
        {intl.formatMessage({
          id: 'general.cookiesPolicy.acceptLabel',
          defaultMessage: 'I ACCEPT',
        })}
      </Button>
      <Button color="secondary" onClick={refuseCookies}>
        {intl.formatMessage({
          id: 'general.cookiesPolicy.refuseLabel',
          defaultMessage: 'I REFUSE',
        })}
      </Button>
    </Box>
  );

  const message = (
    <FormattedMessage
      id="general.cookiesPolicy.message"
      defaultMessage="<typo>We use cookies and Google Analytics to make this website as useful as possible for you.</typo>{br}<typo>Are you okay with that? (You can change your decision later.)</typo> <a>Privacy Policy</a>."
      values={{
        a: (...chunks) => (
          <Link
            className={classes.link}
            target="_blank"
            rel="noreferrer"
            underline="always"
            href={intl.formatMessage({
              id: 'general.cookiesPolicy.url',
              defaultMessage: 'https://link.blogfoster.com/BJsyssdaI',
            })}
          >
            {chunks}
          </Link>
        ),
        typo: (...chunks) => (
          <Typography component="span" variant="body2">
            {chunks}
          </Typography>
        ),
        br: <br />,
      }}
    />
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!cookiesFlagSet}
      {...props}
    >
      <SnackbarContent
        className={classes.content}
        message={message}
        action={actions}
      />
    </Snackbar>
  );
};

CookiePolicySnackbar.propTypes = {};

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles({
  root: {
    fill: 'none',
  },
});

export default (props) => {
  const classes = useStyles();

  return <SvgIcon {...props} classes={classes} />;
};

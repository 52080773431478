import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import LineClamp from 'shiitake';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { ArticlePropType, getInfoLine } from 'utils/insider';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 320,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 12,
  },
  media: {
    height: 150,
  },
  actionArea: {
    height: '100%',
    padding: theme.spacing(1.5),
  },
  content: {
    padding: 0,
    paddingTop: theme.spacing(1.5),
  },
  new: {
    color: '#F1453D',
  },
}));

const InsiderArticleCard = ({
  slugRoot,
  article,
  isNew,
  className,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const articleUrl = `${slugRoot}/${article.slug}`;

  return (
    <Card className={clsx(classes.root, className)} {...otherProps}>
      <CardActionArea
        className={classes.actionArea}
        component={Link}
        to={articleUrl}
      >
        <CardMedia
          className={classes.media}
          image={article.coverImage}
          title={article.title}
          component="div"
        />
        <CardContent className={classes.content}>
          <Box display="flex" mb={1}>
            <Typography noWrap variant="body1" color="primary">
              {getInfoLine({ intl, article })}
            </Typography>
            {isNew && (
              <>
                &nbsp;
                <Typography className={classes.new} variant="subtitle1">
                  {intl.formatMessage({
                    id: 'scenes.insider.whatsNew.newLabel',
                    defaultMessage: 'NEW',
                  })}
                </Typography>
              </>
            )}
          </Box>
          <Typography variant="h5">
            <LineClamp lines={3}>{article.title}</LineClamp>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

InsiderArticleCard.propTypes = {
  article: ArticlePropType.isRequired,
  className: PropTypes.string,
  slugRoot: PropTypes.string,
  isNew: PropTypes.bool,
};

InsiderArticleCard.defaultProps = {
  className: '',
  isNew: false,
  slugRoot: '/',
};

export default InsiderArticleCard;

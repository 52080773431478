import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import TableCellHead from 'components/table/TableCellHead';

import { SortObjectPropType } from 'utils/sorting';

const ClientUsersTableHead = ({ currentSort, onSort, ...otherProps }) => (
  <TableHead {...otherProps}>
    <TableRow>
      <TableCell align="left" />
      <TableCellHead
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="name"
      >
        <FormattedMessage
          id="scenes.clients.details.usersTable.name"
          defaultMessage="Name"
        />
      </TableCellHead>
      <TableCellHead
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="email"
      >
        <FormattedMessage
          id="scenes.clients.details.usersTable.email"
          defaultMessage="Email"
        />
      </TableCellHead>
      <TableCellHead
        align="center"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="createdAt"
      >
        <FormattedMessage
          id="scenes.clients.details.usersTable.createdAt"
          defaultMessage="Created"
        />
      </TableCellHead>
      <TableCellHead
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="lastLoginAt"
      >
        <FormattedMessage
          id="scenes.clients.details.usersTable.lastLoginAt"
          defaultMessage="Last Login"
        />
      </TableCellHead>
    </TableRow>
  </TableHead>
);

ClientUsersTableHead.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
};
ClientUsersTableHead.defaultProps = {};

export default ClientUsersTableHead;

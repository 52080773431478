import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import noop from 'lodash/noop';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

const SearchField = ({ value, onChange, onEnter, onClear, ...otherProps }) => {
  const handleClearSearchClick = () => {
    if (value) {
      onClear();
    }
  };

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onChange(value);
      onEnter(value);
    }
  };

  return (
    <TextField
      variant="outlined"
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!value}
              onClick={handleClearSearchClick}
              edge="end"
              size="small"
            >
              {value ? <Icons.XCircle /> : <Icons.Search />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...otherProps}
    />
  );
};

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
};
SearchField.defaultProps = {
  value: '',
  onEnter: noop,
};

export default SearchField;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl, defineMessages } from 'react-intl';
import omit from 'lodash/omit';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Tabs from 'components/Tabs';
import ChannelCardHeader from 'components/channels/card/ChannelCardHeader';
import ChannelCardAudienceKPISection from 'components/channels/card/ChannelCardAudienceKPISection';
import ChannelCardPlatformLink from 'components/channels/card/ChannelCardPlatformLink';
import ChannelCardCategories from 'components/channels/card/ChannelCardCategories';
import ChannelCardBody from 'components/channels/card/ChannelCardBody';
import ChannelCardCampaignsList from 'components/channels/card/ChannelCardCampaignsList';
import NewChannelNoteDialog from 'components/channels/card/NewChannelNoteDialog';
import ChannelCardNotesList from 'components/channels/card/ChannelCardNotesList';
import ChannelCardClientTags from 'components/channels/card/ChannelCardClientTags';
import ChannelCardPerformanceKPISection from 'components/channels/card/ChannelCardPerformanceKPISection';

import { Feature, useFeatures } from 'utils/features';
import { ApplicationPropType } from 'utils/application';
import { CampaignPropType } from 'utils/campaign';
import { ChannelPropType } from 'utils/channel';
import { formatDateTime } from 'utils/formatting';
import { useClient } from 'utils/client';
import useAuth from 'auth/useAuth';

import ApplicationCardNotes from './ApplicationCardNotes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: theme.typography.pxToRem(320),
    backgroundColor: '#fff',
  },
  body: {
    height: '100%',
    // The review button is 85px high
    paddingBottom: `calc(${theme.spacing(3)} + 85px)`,
  },
  internalNotesTitle: {
    marginTop: theme.spacing(1),
  },
}));

const ApplicationCard = ({
  campaign,
  application,
  selectedChannel,
  channels,
  onSelectChannel,
  loadingChannels,
  updateChannelNotes,
  updateChannelTags,
  updateClientNote,
  updateCampaignTask,
  updateApplicationTask,
  className,
  loading,
  isPreparationStep,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { client } = useClient();
  const { user } = useAuth();
  const { isFeatureAvailable } = useFeatures();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [newNoteDialogOpen, setNewNoteDialogOpen] = React.useState(false);

  // crmChannel can be null if channel has just been created,
  // but channelSearch collection has not been rebuilt yet
  const crmChannel = selectedChannel || application.crmChannel;
  const isCrmChannelReady = !!application.crmChannel;

  const submitNewNote = (newNote) => {
    const newNotePayload = {
      createdAt: new Date(),
      createdBy: user.email,
      creatorName: user.name,
      note: newNote,
    };

    const cleanNotes = crmChannel.notes.map((note) => omit(note, '__typename'));

    updateChannelNotes([...cleanNotes, newNotePayload]);
  };

  const handleEditClientNote = (newNote) => {
    updateClientNote(newNote);
  };

  const handleEditCampaignTask = (taskId, newTaskBody) => {
    updateCampaignTask(taskId, newTaskBody);
  };

  const handleEditApplicationTask = (taskId, newTaskBody) => {
    updateApplicationTask(taskId, newTaskBody);
  };

  const content = React.useMemo(() => {
    const insights = (
      <ChannelCardBody className={classes.body} key="insights">
        <ChannelCardPlatformLink channel={selectedChannel} />
        <ChannelCardCategories channel={selectedChannel} />
        {isCrmChannelReady && (
          <Feature feature="influencerFinderTags">
            <ChannelCardClientTags
              channel={crmChannel}
              onSubmit={updateChannelTags}
              loading={loading}
            />
          </Feature>
        )}
        <ChannelCardPerformanceKPISection channel={selectedChannel} />
        <ChannelCardAudienceKPISection channel={selectedChannel} />
      </ChannelCardBody>
    );

    const tabLabelsStrings = defineMessages({
      application: {
        id: 'components.ApplicationCard.tabs.application',
        defaultMessage: 'Application',
      },
      preparation: {
        id: 'components.ApplicationCard.tabs.preparation',
        defaultMessage: 'Preparation',
      },
      insights: {
        id: 'components.ApplicationCard.tabs.insights',
        defaultMessage: 'Insights',
      },
      campaigns: {
        id: 'components.ApplicationCard.tabs.campaigns',
        defaultMessage: 'Camp... ({numberOfApplications})',
      },
      notes: {
        id: 'components.ApplicationCard.tabs.notes',
        defaultMessage: 'Notes ({numberOfNotes})',
      },
    });

    const tabLabels = [
      isPreparationStep
        ? intl.formatMessage(tabLabelsStrings.preparation)
        : intl.formatMessage(tabLabelsStrings.application),
      intl.formatMessage(tabLabelsStrings.insights),
    ];

    if (isFeatureAvailable('collaboratorFilter') && isCrmChannelReady) {
      tabLabels.push(
        intl.formatMessage(tabLabelsStrings.campaigns, {
          numberOfApplications: crmChannel?.applications?.length,
        }),
        intl.formatMessage(tabLabelsStrings.notes, {
          numberOfNotes: crmChannel?.notes?.length,
        }),
      );
    }

    const tabs = [
      <ChannelCardBody className={classes.body} key="application">
        <ApplicationCardNotes
          campaign={campaign}
          application={application}
          isPreparationStep={isPreparationStep}
          onEditClientNote={handleEditClientNote}
          onEditCampaignTask={handleEditCampaignTask}
          onEditApplicationTask={handleEditApplicationTask}
        />
        {application?.productShipment?.product && isPreparationStep && (
          <Box display="flex" flexDirection="column">
            <Typography variant="overline" color="textSecondary">
              {intl.formatMessage({
                id: 'components.ApplicationCard.productShipment.title',
                defaultMessage: 'Product',
              })}
            </Typography>
            <Box mt={1}>
              <Typography>{application?.productShipment?.product}</Typography>
            </Box>
          </Box>
        )}
      </ChannelCardBody>,
      insights,
    ];

    if (isCrmChannelReady) {
      tabs.push(
        <Feature feature="collaboratorFilter" key="campaigns">
          <ChannelCardBody py={1}>
            <Typography variant="overline" color="textSecondary">
              {intl.formatMessage({
                id: 'components.ApplicationCard.tabs.campaigns.lastUpdatedLabel',
                defaultMessage: 'Last Updated',
              })}
              &nbsp;
              {formatDateTime({
                isoDate: crmChannel.updatedAt,
                formatter: intl.formatDate,
              })}
            </Typography>
            <ChannelCardCampaignsList applications={crmChannel.applications} />
          </ChannelCardBody>
        </Feature>,
        <Feature feature="collaboratorFilter" key="notes">
          <ChannelCardBody>
            <NewChannelNoteDialog
              open={newNoteDialogOpen}
              onClose={() => setNewNoteDialogOpen(false)}
              onSave={submitNewNote}
            />
            <Box display="flex" justifyContent="space-between">
              <Typography
                className={classes.internalNotesTitle}
                variant="overline"
                color="textSecondary"
              >
                {intl.formatMessage(
                  {
                    id: 'components.ApplicationCard.tabs.notes.title',
                    defaultMessage: '{clientName} Internal Notes',
                  },
                  {
                    clientName: client.name,
                  },
                )}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setNewNoteDialogOpen(true)}
              >
                +
              </Button>
            </Box>
            <ChannelCardNotesList notes={crmChannel.notes} />
          </ChannelCardBody>
        </Feature>,
      );
    }

    return (
      <Tabs
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
        labels={tabLabels}
      >
        {tabs}
      </Tabs>
    );
  }, [application, submitNewNote, intl]);

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <ChannelCardHeader
        selectedChannel={selectedChannel}
        channels={channels}
        onSelectChannel={onSelectChannel}
        user={application.user}
        loading={loadingChannels}
        pb={0}
      />
      {content}
    </Box>
  );
};

ApplicationCard.propTypes = {
  campaign: CampaignPropType.isRequired,
  application: ApplicationPropType.isRequired,
  selectedChannel: ChannelPropType,
  channels: PropTypes.arrayOf(ChannelPropType),
  onSelectChannel: PropTypes.func,
  loadingChannels: PropTypes.bool,
  updateChannelNotes: PropTypes.func.isRequired,
  updateChannelTags: PropTypes.func.isRequired,
  updateClientNote: PropTypes.func,
  updateCampaignTask: PropTypes.func,
  updateApplicationTask: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool,
  isPreparationStep: PropTypes.bool,
};

ApplicationCard.defaultProps = {
  className: '',
  isPreparationStep: false,
  channels: [],
  loadingChannels: true,
  selectedChannel: null,
};

export default ApplicationCard;

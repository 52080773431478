import React from 'react';
import isEqual from 'lodash/isEqual';

import { ApplicationPropType } from 'utils/application';
import { PlatformPropType } from 'utils/channel';

import Instagram from './instagram/TableRow';
import Pinterest from './pinterest/TableRow';
import Tiktok from './tiktok/TableRow';
import Blog from './blog/TableRow';

const componentsByPlatform = {
  instagram: Instagram,
  pinterest: Pinterest,
  tiktok: Tiktok,
  website: Blog,
  ga: Blog,
};

const applicationPropsAreEqual = (prevProps, nextProps) => {
  const areSelectedEqual = prevProps.selected === nextProps.selected;
  const areApplicationIdsEqual =
    prevProps.application.id === nextProps.application.id;
  const isClientReviewEqual = isEqual(
    prevProps.application.clientApplicationReview,
    nextProps.application.clientApplicationReview,
  );
  const isColorEqual = prevProps.color === nextProps.color;
  const isGravatarColorEqual =
    prevProps.gravatarBackgroundColor === nextProps.gravatarBackgroundColor;

  const isHideKamNotesColumnEqual =
    prevProps.hideKamNotesColumn === nextProps.hideKamNotesColumn;

  return (
    areSelectedEqual &&
    areApplicationIdsEqual &&
    isClientReviewEqual &&
    isColorEqual &&
    isGravatarColorEqual &&
    isHideKamNotesColumnEqual
  );
};

const ApplicationsTableRow = React.memo(
  ({ application, platform, ...otherProps }) => {
    const Component = componentsByPlatform[platform];
    return <Component application={application} {...otherProps} />;
  },
  applicationPropsAreEqual,
);

ApplicationsTableRow.propTypes = {
  platform: PlatformPropType.isRequired,
  application: ApplicationPropType.isRequired,
};

ApplicationsTableRow.defaultProps = {};

export default ApplicationsTableRow;

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import InspectorDrawer from 'components/inspector/InspectorDrawer';

import ClientUserInspectorHeader from './ClientUserInspectorHeader';
import ClientUserInspectorBody from './ClientUserInspectorBody';

export const drawerWidth = 320;

const ClientUserInspector = ({
  clientId,
  user,
  onClose,
  loading,
  ...otherProps
}) => (
  <InspectorDrawer
    isOpen={!!user}
    width={drawerWidth}
    onClose={onClose}
    {...otherProps}
  >
    {user && (
      <Box width={drawerWidth}>
        <ClientUserInspectorHeader clientId={clientId} user={user} />
        <ClientUserInspectorBody clientId={clientId} user={user} />
      </Box>
    )}
  </InspectorDrawer>
);

ClientUserInspector.propTypes = {
  clientId: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    salesforceId: PropTypes.string,
    kamNote: PropTypes.shape({
      note: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
ClientUserInspector.defaultProps = {
  loading: false,
  user: null,
  clientId: null,
};

export default ClientUserInspector;

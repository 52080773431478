import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { PlatformPropType } from 'utils/channel';
import { ApplicationPropType } from 'utils/application';
import { formatDate, formatDateTime } from 'utils/formatting';

import ChannelCardHeader from 'components/channels/card/ChannelCardHeader';
import ChannelCardBody from 'components/channels/card/ChannelCardBody';

import PreviewDate from './PreviewDate';
import InstagramBody from './instagram/InspectorBody';
import PinterestBody from './pinterest/InspectorBody';
import TiktokBody from './tiktok/InspectorBody';
import BlogBody from './blog/InspectorBody';

const bodyComponentsByPlatform = {
  instagram: InstagramBody,
  pinterest: PinterestBody,
  tiktok: TiktokBody,
  website: BlogBody,
  ga: BlogBody,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: ({ drawerWidth }) => theme.typography.pxToRem(drawerWidth),
    backgroundColor: '#fff',
  },
  body: {
    // The review button is 85px high
    paddingBottom: `calc(${theme.spacing(3)} + 85px)`,
  },
  updatedLabel: {
    color: theme.palette.eqolot.brightOrange,
    cursor: 'default',
    marginTop: theme.spacing(-0.5),
    '& > svg': {
      marginLeft: theme.spacing(0.5),
    },
  },
}));

const ContentPreviewInspectorCard = ({
  application: { channel, user, channels, contentPreview, mission },
  platform,
  drawerWidth,
  className,
  ...otherProps
}) => {
  const classes = useStyles({ drawerWidth });
  const intl = useIntl();

  const BodyContent = bodyComponentsByPlatform[platform];

  const submittedAt = contentPreview.updatedAt
    ? formatDateTime({
        isoDate: contentPreview.updatedAt,
        formatter: intl.formatDate,
      })
    : '—';

  const publishAt = formatDate({
    isoDate: mission.publishDate,
    formatter: intl.formatDate,
  });

  const submissionDateTitle = React.useMemo(() => {
    if (contentPreview.status === 'updated') {
      return (
        <Box
          className={classes.updatedLabel}
          display="flex"
          alignItems="center"
        >
          <Typography variant="subtitle1">
            {intl.formatMessage({
              id: 'components.ContentPreviewInspectorCard.updatedPreviewLabel',
              defaultMessage: 'Updated',
            })}
          </Typography>
          <Tooltip
            placement="bottom"
            title={intl.formatMessage({
              id: 'components.ContentPreviewInspectorCard.updatedPreviewLabel.tooltip',
              defaultMessage:
                'The Influencer has submitted a new version of the content, taking into account your comments.',
            })}
          >
            <Icons.HelpCircle size={15} />
          </Tooltip>
        </Box>
      );
    }

    return intl.formatMessage({
      id: 'components.ContentPreviewInspectorCard.submissionDate',
      defaultMessage: 'Submission Date',
    });
  }, [contentPreview.status, intl]);

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <ChannelCardHeader
        selectedChannel={channel}
        channels={channels}
        user={user}
      />
      <ChannelCardBody className={classes.body}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <PreviewDate title={submissionDateTitle} date={submittedAt} />
          <PreviewDate
            title={intl.formatMessage({
              id: 'components.ContentPreviewInspectorCard.publicationDate',
              defaultMessage: 'Publication Date',
            })}
            date={publishAt}
          />
        </Box>
        <BodyContent preview={contentPreview} drawerWidth={drawerWidth} />
      </ChannelCardBody>
    </Box>
  );
};

ContentPreviewInspectorCard.propTypes = {
  platform: PlatformPropType.isRequired,
  application: ApplicationPropType.isRequired,
  drawerWidth: PropTypes.number,
  className: PropTypes.string,
};

ContentPreviewInspectorCard.defaultProps = {
  className: '',
  drawerWidth: 320,
};

export default ContentPreviewInspectorCard;

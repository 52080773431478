import React from 'react';

import SvgIconWrapper from './SvgIconWrapper';

export default (props) => (
  <SvgIconWrapper {...props}>
    <path
      d="M19.5444 6.48909L18.1854 1.49143M18.1854 1.49143L13.1137 2.83055M18.1854 1.49143L14.1775 8.27022M11.9998 16.5C14.4604 16.5 16.4552 14.5259 16.4552 12.1012C16.4552 9.67652 14.4604 7.71095 11.9998 7.71095C9.53917 7.71095 7.54443 9.67652 7.54443 12.1012C7.54443 14.5259 9.53917 16.5 11.9998 16.5ZM11.9998 16.5V20M11.9998 20H15.4998M11.9998 20H8.49981M11.9998 20V23.5"
      stroke="black"
      strokeOpacity="0.54"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconWrapper>
);

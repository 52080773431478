import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { formatDate } from 'utils/formatting';
import {
  getChannelMetrics,
  InstagramKPIPropType,
  PinterestKPIPropType,
  TikTokKPIPropType,
  YoutubeKPIPropType,
  BlogKPIPropType,
} from 'utils/kpi';
import { ChannelPropType } from 'utils/channel';

import ChannelCardSection from './ChannelCardSection';
import ChannelCardKPI from './ChannelCardKPI';
import ChannelCardKPIRow from './ChannelCardKPIRow';

export const InstagramKPIs = ({ kpi }) => {
  const intl = useIntl();

  const { reach, engagementRate, postImpressionRatio, storyImpressionRatio } =
    kpi;

  return (
    <>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.instagram.followers',
            defaultMessage: 'Followers',
          })}
          value={reach}
          roundingDigits={2}
          bold
        />
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.instagram.engagementRate',
            defaultMessage: 'Engagement Rate',
          })}
          format="percent"
          value={engagementRate}
          tooltip={
            <FormattedMessage
              id="components.ChannelCard.instagram.engagementRate.tooltip"
              defaultMessage="Engagement rate, as the ratio of engagements to the number of followers of the influencer"
            />
          }
          bold
        />
      </ChannelCardKPIRow>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.instagram.storyImpressionRatio',
            defaultMessage: 'Story Impression Ratio',
          })}
          format="percent"
          value={storyImpressionRatio}
          tooltip={
            <FormattedMessage
              id="components.ChannelCard.instagram.storyImpressionRatio.tooltip"
              defaultMessage="Ratio of net impressions on stories compared with followers"
            />
          }
        />
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.instagram.postImpressionRatio',
            defaultMessage: 'Post Impression Ratio',
          })}
          value={postImpressionRatio}
          format="percent"
          tooltip={
            <FormattedMessage
              id="components.ChannelCard.instagram.postImpressionRatio.tooltip"
              defaultMessage="Ratio of net impressions on postings compared with followers"
            />
          }
        />
      </ChannelCardKPIRow>
    </>
  );
};

InstagramKPIs.propTypes = {
  kpi: InstagramKPIPropType.isRequired,
};

export const PinterestKPIs = ({ kpi }) => {
  const intl = useIntl();

  const { reach, followers, engagementRatio, pinClickRatio, saveRatio } = kpi;

  return (
    <>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.pinterest.impressions',
            defaultMessage: 'Impressions',
          })}
          value={reach}
          bold
        />
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.pinterest.followers',
            defaultMessage: 'Followers',
          })}
          value={followers}
          bold
        />
      </ChannelCardKPIRow>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.pinterest.engagementRatio',
            defaultMessage: 'Engagement Ratio',
          })}
          format="percent"
          value={engagementRatio}
          bold
        />
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.pinterest.pinClickRatio',
            defaultMessage: 'Pin Click Ratio',
          })}
          format="percent"
          value={pinClickRatio}
          bold
        />
      </ChannelCardKPIRow>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.pinterest.saveRatio',
            defaultMessage: 'Save Ratio',
          })}
          format="percent"
          value={saveRatio}
          bold
        />
      </ChannelCardKPIRow>
    </>
  );
};

PinterestKPIs.propTypes = {
  kpi: PinterestKPIPropType.isRequired,
};

export const TikTokKPIs = ({ kpi }) => {
  const intl = useIntl();

  const { reach, viewsTotal, viewsPerVideoMedian } = kpi;

  return (
    <>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.tiktok.followers',
            defaultMessage: 'Followers',
          })}
          value={reach}
          bold
        />
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.tiktok.views',
            defaultMessage: 'Views',
          })}
          value={viewsTotal}
          roundingDigits={2}
          bold
        />
      </ChannelCardKPIRow>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.tiktok.viewsPerVideoMedian',
            defaultMessage: 'Views Per Video',
          })}
          value={viewsPerVideoMedian}
          tooltip={
            <FormattedMessage
              id="components.ChannelCard.tiktok.viewsPerVideoMedian.tooltip"
              defaultMessage="Views per video median tooltip"
            />
          }
        />
      </ChannelCardKPIRow>
    </>
  );
};

TikTokKPIs.propTypes = {
  kpi: TikTokKPIPropType.isRequired,
};

export const YoutubeKPIs = ({ kpi }) => {
  const intl = useIntl();

  const { reach, views } = kpi;

  return (
    <>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.youtube.subscribers',
            defaultMessage: 'Followers',
          })}
          value={reach}
          roundingDigits={2}
          bold
        />
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.youtube.views',
            defaultMessage: 'Views',
          })}
          value={views}
          roundingDigits={2}
          bold
        />
      </ChannelCardKPIRow>
    </>
  );
};

YoutubeKPIs.propTypes = {
  kpi: YoutubeKPIPropType.isRequired,
};

export const BlogKPIs = ({ kpi }) => {
  const intl = useIntl();

  const { views } = kpi;

  return (
    <>
      <ChannelCardKPIRow>
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.blog.impressions',
            defaultMessage: 'Impressions',
          })}
          value={views}
          roundingDigits={2}
          bold
          tooltip={
            <FormattedMessage
              id="components.ChannelCard.blog.impressions.tooltip"
              defaultMessage="Page views for past 30 days"
            />
          }
        />
      </ChannelCardKPIRow>
    </>
  );
};

BlogKPIs.propTypes = {
  kpi: BlogKPIPropType.isRequired,
};

const kpiComponentByPlatform = {
  instagram: InstagramKPIs,
  pinterest: PinterestKPIs,
  tiktok: TikTokKPIs,
  youtube: YoutubeKPIs,
  website: BlogKPIs,
  ga: BlogKPIs,
};

const ChannelCardPerformanceKPISection = ({ channel }) => {
  const intl = useIntl();

  const metrics = getChannelMetrics(channel);

  const lastUpdatedDate = metrics.updatedByAt
    ? formatDate({
        isoDate: metrics.updatedByAt,
        formatter: intl.formatDate,
      })
    : '—';

  const lastUpdatedLabel = intl.formatMessage(
    { id: 'general.lastUpdated', defaultMessage: 'Last updated: {date}' },
    { date: lastUpdatedDate },
  );

  const KPIComponent = kpiComponentByPlatform[channel.platform];

  return (
    <ChannelCardSection
      title={intl.formatMessage({
        id: 'components.ChannelCard.performanceKPISection.title',
        defaultMessage: 'Performance KPIs',
      })}
      subtitle={lastUpdatedLabel}
    >
      <KPIComponent kpi={metrics} />
    </ChannelCardSection>
  );
};

ChannelCardPerformanceKPISection.propTypes = {
  channel: ChannelPropType.isRequired,
};

ChannelCardPerformanceKPISection.defaultProps = {};

export default ChannelCardPerformanceKPISection;

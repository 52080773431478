module.exports = {
  graphQLServer: {
    url: process.env.GRAPHQL_SERVER_URL,
    token: process.env.GRAPHQL_SERVER_TOKEN,
  },

  cloudinary: {
    cloudName: 'eqolot',
    clientAssets: {
      uploadPreset: 'bm-assets',
    },
  },

  sentry: {
    dsn: process.env.SENTRY_DSN,
    tracesSampleRate: 1.0,
  },

  gtm: {
    containerId: 'GTM-MHFKH35',
  },

  auth: {
    usersLinkDomain: 'dev-xaajm3kl',
    domain: process.env.AUTH_DOMAIN,
    clientID: process.env.AUTH_CLIENT_ID,
    audience: process.env.AUTH_AUDIENCE,
    scope: 'openid email',
    metaDataNamespace: 'https://eqolot.com',
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useSectionStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

const PageSection = (props) => {
  const {
    title,
    TitleProps,
    children,
    className,
    classes: classesProp,
    component,
    ...otherProps
  } = props;
  const classes = useSectionStyles(props);

  return (
    <Box
      component={component}
      className={clsx(classes.root, className)}
      {...otherProps}
    >
      <Typography
        className={classes.title}
        variant="h1"
        color="textPrimary"
        {...TitleProps}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

PageSection.propTypes = {
  title: PropTypes.string,
  TitleProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  component: PropTypes.elementType,
};

PageSection.defaultProps = {
  title: '',
  TitleProps: {},
  className: '',
  classes: {},
  component: Paper,
};

export default PageSection;

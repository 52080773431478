module.exports = {
  graphQLServer: {
    url: process.env.GRAPHQL_SERVER_URL,
    token: null,
  },

  cloudinary: {
    cloudName: 'eqolot',
    clientAssets: {
      uploadPreset: 'bm-assets',
    },
  },

  sentry: {
    disabled: true,
  },

  gtm: {
    disabled: true,
  },

  auth: {
    usersLinkDomain: 'dev-xaajm3kl',
    domain: 'dev-xaajm3kl.eu.auth0.com',
    clientID: 'bISc9z19o02H8Dfa6tEBLZhYjQcCjTUc',
    audience: 'https://dev-xaajm3kl.eu.auth0.com/userinfo',
    scope: 'openid email',
    metaDataNamespace: 'https://eqolot.com',
  },
};

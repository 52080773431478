import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { useGA } from 'utils/gtm';

import ButtonSwitch from 'components/ButtonSwitch';

const CollaboratorFilter = ({ enableAll, onChange, ...otherProps }) => {
  const intl = useIntl();

  const { trackEvent } = useGA();

  const handleChange = (newValue) => {
    trackEvent({
      action: 'collaborator_filter_clicked',
      label: `Collaborator Filter Status: ${newValue}`,
    });
    onChange(newValue);
  };

  const filters = [
    {
      value: 'off',
      label: intl.formatMessage({
        id: 'scenes.influencers.filter.all',
        defaultMessage: 'All',
      }),
      disabled: !enableAll,
    },
    {
      value: 'on',
      label: intl.formatMessage({
        id: 'scenes.influencers.filter.collaborators',
        defaultMessage: 'Collaborators',
      }),
    },
  ];

  return (
    <ButtonSwitch filters={filters} onChange={handleChange} {...otherProps} />
  );
};

CollaboratorFilter.propTypes = {
  enableAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
CollaboratorFilter.defaultProps = {
  enableAll: false,
};

export default CollaboratorFilter;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { ContentPreviewMediaPropTypeWithId } from 'utils/application';

import MediaItem from './MediaItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

const ContentPreviewInspectorMediaGrid = ({
  items,
  selectedItem,
  onSelectItem,
  className,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <Typography variant="h6" color="textSecondary" className={classes.title}>
        {intl.formatMessage(
          {
            id: 'components.ContentPreviewInspectorCard.numberOfItems',
            defaultMessage:
              '{numberOfItems, plural, =0 {No content items} one {# content item} other {# content items}}',
          },
          { numberOfItems: items.length },
        )}
      </Typography>
      <Grid container spacing={2}>
        {items.map((item, idx) => (
          <Grid key={item.id} item xs={6}>
            <MediaItem
              badgeIndex={idx + 1}
              mediaItem={item}
              selected={selectedItem && selectedItem.id === item.id}
              onClick={() => onSelectItem(item.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

ContentPreviewInspectorMediaGrid.propTypes = {
  items: PropTypes.arrayOf(ContentPreviewMediaPropTypeWithId),
  selectedItem: ContentPreviewMediaPropTypeWithId,
  onSelectItem: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ContentPreviewInspectorMediaGrid.defaultProps = {
  items: [],
  selectedItem: null,
  className: '',
};

export default ContentPreviewInspectorMediaGrid;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { navRoutes } from 'app/routing/routes';
import Sidebar from 'layout/Sidebar';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const AppLayout = ({ children, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...otherProps}>
      <Sidebar routes={navRoutes} />
      {children}
    </Box>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

AppLayout.defaultProps = {};

export default AppLayout;

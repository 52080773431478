import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';

import { AlignPropType } from 'utils/table';
import { ClientReviewStatusPropType } from 'utils/clientReviewStatuses';
import ApplicationClientReviewStatus from 'components/applications/ApplicationClientReviewStatus';

const TableCellClientReviewStatus = ({
  align,
  clientReview,
  ...otherProps
}) => (
  <TableCell align={align} {...otherProps}>
    <ApplicationClientReviewStatus clientReview={clientReview} />
  </TableCell>
);

TableCellClientReviewStatus.propTypes = {
  align: AlignPropType,
  clientReview: PropTypes.shape({
    status: ClientReviewStatusPropType,
    note: PropTypes.string,
  }).isRequired,
};

TableCellClientReviewStatus.defaultProps = {
  align: 'center',
};

export default TableCellClientReviewStatus;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  content: {
    flex: 1,
    minWidth: 0,
    cursor: 'pointer',
    zIndex: 1,
  },
  chevron: {
    padding: 0,
  },
}));

const CollapsiblePanel = ({
  className,
  collapsed,
  onToggleClick,
  labelContent,
  children,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Box
      component={Paper}
      className={clsx(classes.root, className)}
      {...otherProps}
    >
      {labelContent}
      <Box mx={2} className={classes.content} onClick={onToggleClick}>
        <Collapse in={!collapsed} collapsedSize={24}>
          {children}
        </Collapse>
      </Box>
      <Box>
        <IconButton className={classes.chevron} onClick={onToggleClick}>
          {collapsed ? <Icons.ChevronDown /> : <Icons.ChevronUp />}
        </IconButton>
      </Box>
    </Box>
  );
};

CollapsiblePanel.propTypes = {
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  onToggleClick: PropTypes.func.isRequired,
  labelContent: PropTypes.node,
  children: PropTypes.node,
};
CollapsiblePanel.defaultProps = {
  className: '',
  collapsed: true,
  labelContent: null,
  children: null,
};

export default CollapsiblePanel;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import capitalize from 'lodash/capitalize';
import toUpper from 'lodash/toUpper';
import head from 'lodash/head';

export const UserPropType = PropTypes.shape({
  salutation: PropTypes.string,
  channels: PropTypes.arrayOf(PropTypes.object),
  city: PropTypes.string,
  country: PropTypes.string,
});

export const AuthUserPropType = PropTypes.shape({
  email: PropTypes.string,
  name: PropTypes.string,
  nickname: PropTypes.string,
});

export const LocationLabel = ({ user }) => {
  const city = capitalize(user.city);
  const countryCode = toUpper(user.country);

  if (city) {
    return `${city}, ${countryCode}`;
  }

  return countryCode;
};

LocationLabel.propTypes = {
  user: UserPropType.isRequired,
};

const genderStrings = defineMessages({
  f: {
    id: 'general.gender.f',
    defaultMessage: 'Female',
  },
  m: {
    id: 'general.gender.m',
    defaultMessage: 'Male',
  },
  d: {
    id: 'general.gender.d',
    defaultMessage: 'Diverse',
  },
});

export const GenderLabel = ({ user }) => {
  const intl = useIntl();

  if (!user.salutation) {
    return null;
  }

  return (
    <>{intl.formatMessage(genderStrings[user.salutation.toLowerCase()])}</>
  );
};

GenderLabel.propTypes = {
  user: UserPropType.isRequired,
};

export const getInitials = (fullName = '') => {
  const [firstName, lastName] = fullName.split(' ').filter(Boolean);
  const firstNameInitial = head(firstName);
  const lastNameInitial = head(lastName);

  return `${toUpper(firstNameInitial)}${toUpper(lastNameInitial)}`;
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Icons from 'react-feather';
import fileSaver from 'file-saver';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';

import { useGA } from 'utils/gtm';
import { ContentPreviewMediaPropTypeWithId } from 'utils/application';

import CardMedia from 'components/CardMedia';

const useStyles = makeStyles((theme) => ({
  actionArea: {
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: theme.spacing(0.5),
    borderRadius: 8,
    backgroundColor: theme.palette.action.hover,
  },
  media: {
    borderRadius: 4,
    objectFit: 'contain',
    height: 'auto',
    width: 'auto',
    maxHeight: `calc(${theme.typography.pxToRem(136)} - 4px)`,
    maxWidth: `calc(${theme.typography.pxToRem(136)} - 4px)`,
  },
  actions: {
    justifyContent: 'center',
    paddingTop: theme.spacing(0.5),
    padding: 0,
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  withIndexBadge: {
    position: 'relative',
    '&::after': {
      cursor: 'zoom-in',
      position: 'absolute',
      padding: '2px 8px',
      borderRadius: 6,
      top: 9,
      left: 9,
      zIndex: 1,
      content: (props) => `"${props.badgeIndex}"`,
      backgroundColor: theme.palette.primary.dark,
      color: '#fff',
    },
  },
}));

const ContentPreviewMediaItem = ({
  mediaItem,
  selected,
  onClick,
  className,
  badgeIndex,
  ...otherProps
}) => {
  const classes = useStyles({ badgeIndex });
  const { trackEvent } = useGA();

  const handleDownload = React.useCallback(
    (e) => {
      e.stopPropagation();

      fileSaver.saveAs(mediaItem.url);

      trackEvent({
        action: 'content_preview_download_triggered',
        label: `Content Preview Download: ${mediaItem.url}`,
      });
    },
    [trackEvent, mediaItem.url],
  );

  return (
    <Card
      elevation={0}
      onClick={onClick}
      className={clsx(className, {
        [classes.withIndexBadge]: badgeIndex !== null,
      })}
      {...otherProps}
    >
      <CardActionArea
        component="div"
        className={clsx(classes.actionArea, {
          [classes.selected]: selected,
        })}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={136}
          width={136}
        >
          <Paper>
            <CardMedia
              className={classes.media}
              src={mediaItem.url}
              type={mediaItem.type}
              assetId={mediaItem.assetId}
              playable={false}
              autoPlay={false}
              clickable
            />
          </Paper>
        </Box>
        <CardActions className={classes.actions} disableSpacing>
          <IconButton size="small" color="primary" onClick={onClick}>
            <Icons.ZoomIn />
          </IconButton>
          <IconButton color="primary" size="small" onClick={handleDownload}>
            <Icons.Download />
          </IconButton>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

ContentPreviewMediaItem.propTypes = {
  mediaItem: ContentPreviewMediaPropTypeWithId.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  badgeIndex: PropTypes.number,
  className: PropTypes.string,
};

ContentPreviewMediaItem.defaultProps = {
  selected: false,
  badgeIndex: null,
  className: '',
};

export default ContentPreviewMediaItem;

import React from 'react';
import { Router, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import CssBaseline from '@material-ui/core/CssBaseline';

import { QueryParamProvider } from 'utils/routing';
import ThemeProvider from 'theming/ThemeProvider';
import GraphQLProvider from 'utils/GraphQLProvider';
import AuthProvider from 'auth/AuthProvider';
import { ClientProvider } from 'utils/client';
import { ToastsProvider } from 'utils/toasts';
import { CookiesPolicyProvider } from 'utils/cookies';

import config from 'config';

import App from './App';

const Package = require('../../package.json');

const { name, version } = Package;

const history = createBrowserHistory();

if (!config.sentry.disabled) {
  Sentry.init({
    release: `${name}@${version}`,
    dsn: config.sentry.dsn,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: config.sentry.tracesSampleRate,
  });
}

const Main = () => (
  <Router history={history}>
    <QueryParamProvider ReactRouterRoute={Route}>
      <CookiesPolicyProvider>
        <AuthProvider>
          <GraphQLProvider>
            <ClientProvider>
              <ThemeProvider>
                <ToastsProvider>
                  <CssBaseline />
                  <App />
                </ToastsProvider>
              </ThemeProvider>
            </ClientProvider>
          </GraphQLProvider>
        </AuthProvider>
      </CookiesPolicyProvider>
    </QueryParamProvider>
  </Router>
);

export default hot(Main);

if (module.hot) {
  module.hot.accept();
}

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';

import { ApplicationPropType } from 'utils/application';
import { formatNumber as numberFormatter } from 'utils/formatting';
import { AlignPropType } from 'utils/table';
import ChannelAvatarGroup from 'components/avatars/ChannelAvatarGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
}));

const TableCellInfluencers = ({ applications, align, size, ...otherProps }) => {
  const intl = useIntl();
  const classes = useStyles();
  const label = React.useMemo(() => {
    const influencersNumber = applications.length;

    const completedCampaignsLabel = intl.formatMessage(
      {
        id: 'components.TableCellInfluencers.label',
        defaultMessage:
          '{influencersNumber, plural, one {influencer} other {influencers}}',
      },
      {
        influencersNumber,
      },
    );

    const formatNumber = (number) =>
      numberFormatter({ formatter: intl.formatNumber, number });

    return [formatNumber(influencersNumber), completedCampaignsLabel].join(' ');
  }, [applications, intl]);

  return (
    <TableCell
      className={classes.root}
      padding="none"
      align={align}
      {...otherProps}
    >
      <Box display="flex" alignItems="center">
        {label}
        <Box ml={3}>
          <ChannelAvatarGroup applications={applications} size={size} max={3} />
        </Box>
      </Box>
    </TableCell>
  );
};

TableCellInfluencers.propTypes = {
  align: AlignPropType,
  applications: PropTypes.arrayOf(ApplicationPropType),
  size: PropTypes.number,
};

TableCellInfluencers.defaultProps = {
  applications: [],
  align: 'left',
  size: 32,
};

export default TableCellInfluencers;

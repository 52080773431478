import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import noop from 'lodash/noop';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import EditIcon from 'icons/Edit';

const EditableText = ({
  required,
  label,
  value,
  onSave,
  onCancel,
  validate,
  ...otherProps
}) => {
  const intl = useIntl();

  const [text, setText] = React.useState(value);

  const handleNoteChange = (event) => {
    setText(event.target.value);
  };

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSave(text);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setText(value);
    onCancel();
  };

  const isValid = () => {
    if (required) {
      return text && validate(text);
    }

    return validate(text);
  };

  return (
    <Box display="flex" flexDirection="column" {...otherProps}>
      <TextField
        variant="outlined"
        label={label}
        onChange={handleNoteChange}
        value={text}
        required={required}
        fullWidth
        autoFocus
      />
      <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
        <Button color="primary" onClick={handleCancel}>
          {intl.formatMessage({
            id: 'components.EditableTextField.EditableText.cancelLabel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button color="primary" onClick={handleSave} disabled={!isValid()}>
          {intl.formatMessage({
            id: 'components.EditableTextField.EditableText.saveLabel',
            defaultMessage: 'Save',
          })}
        </Button>
      </Box>
    </Box>
  );
};

EditableText.propTypes = {
  label: PropTypes.node,
  value: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  required: PropTypes.bool,
  validate: PropTypes.func,
};
EditableText.defaultProps = {
  label: '',
  value: '',
  onSave: noop,
  onCancel: noop,
  required: false,
  validate: () => true,
};

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
  contentArea: {
    position: 'relative',
    paddingRight: 28,
  },
  editIcon: {
    position: 'absolute',
    top: -2,
    right: 0,
  },
});

const EditableTextField = (props) => {
  const {
    className,
    content,
    label,
    value,
    onChange,
    required,
    validate,
    cancelOnOutsideClick,
    classes: classesProp,
    ...otherProps
  } = props;

  const classes = useStyles({ classes: classesProp });
  const rootRef = React.useRef(null);

  const [isEditing, setIsEditing] = React.useState(false);

  const handleSave = (newNote) => {
    onChange(newNote);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  React.useEffect(() => {
    if (cancelOnOutsideClick) {
      const handleClickOutside = (event) => {
        if (rootRef.current && !rootRef.current.contains(event.target)) {
          setIsEditing(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }

    return () => {};
  }, [rootRef]);

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      onClick={() => setIsEditing(true)}
      className={clsx(classes.root, className)}
      ref={rootRef}
      {...otherProps}
    >
      {!isEditing && (
        <Box className={classes.contentArea}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            mb={0.5}
          >
            {content}
          </Box>
          <Box className={classes.editIcon}>
            <EditIcon />
          </Box>
        </Box>
      )}
      {isEditing && (
        <EditableText
          label={label}
          value={value}
          onSave={handleSave}
          onCancel={handleCancel}
          required={required}
          validate={validate}
        />
      )}
      {!isEditing && <Typography variant="body1">{value || '—'}</Typography>}
    </Box>
  );
};

EditableTextField.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  content: PropTypes.node,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.func,
  cancelOnOutsideClick: PropTypes.bool,
};
EditableTextField.defaultProps = {
  className: '',
  cancelOnOutsideClick: true,
  classes: {},
  content: undefined,
  required: false,
  validate: noop,
};

export default EditableTextField;

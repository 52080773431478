import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { trimUrl } from 'utils/formatting';

const useStyles = makeStyles({
  link: {
    color: (props) => props.color,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const Url = (props) => {
  const {
    className,
    variant,
    url,
    trimUrl: shouldTrimUrl,
    children,
    color,
    ...otherProps
  } = props;
  const classes = useStyles(props);

  const href = shouldTrimUrl ? trimUrl(url) : url;

  const baseProps = {
    component: 'a',
    href: `//${href}`,
    underline: 'none',
    target: '_blank',
    rel: 'noreferrer',
    className: clsx(classes.link, className),
  };

  return (
    <Box {...baseProps} {...otherProps}>
      <Typography variant={variant} component="span">
        {children || href}
      </Typography>
    </Box>
  );
};

Url.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
  trimUrl: PropTypes.bool,
  color: PropTypes.string,
  url: PropTypes.string,
};

Url.defaultProps = {
  className: '',
  color: 'inherit',
  variant: undefined,
  trimUrl: true,
};

export default Url;

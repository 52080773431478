import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import noop from 'lodash/noop';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { formatDateTime } from 'utils/formatting';

import NameChip from 'components/NameChip';
import Markdown from 'components/Markdown';
import EditableMarkdown from 'components/EditableMarkdown';

const useStyles = makeStyles((theme) => ({
  editableMarkdown: {
    color: theme.palette.eqolot.infoBlue,
  },
  editableMarkdownTextArea: {
    color: theme.palette.eqolot.infoBlue,
    fontWeight: 'bold',
  },
  newNote: {
    fontStyle: 'italic',
  },
}));

const MarkdownNote = ({
  editable,
  onEdit,
  label,
  editLabel,
  note,
  timestamp,
  updaterEmail,
  updaterName,
  className,
  ...otherProps
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const formattedTimestamp = timestamp
    ? formatDateTime({
        isoDate: timestamp,
        formatter: intl.formatDate,
      })
    : intl.formatMessage({
        id: 'components.MarkdownNote.noDate',
        defaultMessage: 'No date',
      });

  const showTimestamp = timestamp && note;

  if (editable) {
    return (
      <Box display="flex" flexDirection="column" {...otherProps}>
        <Box mb={1}>
          <Typography variant="overline" color="textSecondary">
            {label}
          </Typography>
        </Box>
        <EditableMarkdown
          classes={{
            markdown: classes.editableMarkdown,
            textArea: classes.editableMarkdownTextArea,
          }}
          content={
            <>
              {showTimestamp && (
                <Typography variant="body1" color="textSecondary">
                  {formattedTimestamp}
                </Typography>
              )}
              {!showTimestamp && !note && (
                <Box mb={2.5}>
                  <Typography
                    className={classes.newNote}
                    variant="body1"
                    color="textSecondary"
                  >
                    <FormattedMessage
                      id="components.MarkdownNote.editable.newNote"
                      defaultMessage="Add a note…"
                    />
                  </Typography>
                </Box>
              )}
              {(updaterName || updaterEmail) && (
                <NameChip
                  maxWidth="40%"
                  name={updaterName || updaterEmail}
                  tooltip={updaterName ? updaterEmail : ''}
                />
              )}
            </>
          }
          label={editLabel}
          value={note}
          onChange={onEdit}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" {...otherProps}>
      <Box mb={1}>
        <Typography variant="overline" color="textSecondary">
          {label}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={0.5}>
        <Typography noWrap variant="body1" color="textSecondary">
          {formattedTimestamp}
        </Typography>
        {(updaterName || updaterEmail) && (
          <Box ml={1} maxWidth="50%">
            <NameChip
              maxWidth="100%"
              name={updaterName || updaterEmail}
              tooltip={updaterName ? updaterEmail : ''}
            />
          </Box>
        )}
      </Box>
      <Markdown source={note} />
    </Box>
  );
};

MarkdownNote.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  editLabel: PropTypes.string,
  note: PropTypes.string,
  timestamp: PropTypes.string,
  updaterName: PropTypes.string,
  updaterEmail: PropTypes.string,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
};
MarkdownNote.defaultProps = {
  className: '',
  editable: false,
  editLabel: '',
  onEdit: noop,
  note: '',
  timestamp: '',
  updaterName: '',
  updaterEmail: '',
};

export default MarkdownNote;

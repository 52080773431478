import React from 'react';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import { useIntl } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import { AlignPropType } from 'utils/table';
import { formatNumber as numberFormatter } from 'utils/formatting';
import { PlatformPropType } from 'utils/channel';

const TableCellInfluencersReach = ({
  applications,
  platform,
  align,
  ...otherProps
}) => {
  const intl = useIntl();
  const reach = React.useMemo(
    () => sumBy(applications, 'reach'),
    [applications],
  );

  const labels = React.useMemo(() => {
    const blog = intl.formatMessage(
      {
        id: 'components.TableCellInfluencersReach.blog.label',
        defaultMessage:
          '{reach, plural, one {monthly page view} other {monthly page views}}',
      },
      {
        reach,
      },
    );
    const instagram = intl.formatMessage(
      {
        id: 'components.TableCellInfluencersReach.instagram.label',
        defaultMessage: '{reach, plural, one {follower} other {followers}}',
      },
      {
        reach,
      },
    );
    const tiktok = intl.formatMessage(
      {
        id: 'components.TableCellInfluencersReach.tiktok.label',
        defaultMessage: '{reach, plural, one {follower} other {followers}}',
      },
      {
        reach,
      },
    );
    return {
      ga: blog,
      website: blog,
      instagram,
      tiktok,
    };
  }, [reach]);

  const formatNumber = (number) =>
    numberFormatter({ formatter: intl.formatNumber, number });

  const label = [formatNumber(reach), labels[platform]].join(' ');

  return (
    <TableCell align={align} {...otherProps}>
      {label}
    </TableCell>
  );
};

TableCellInfluencersReach.propTypes = {
  align: AlignPropType,
  applications: PropTypes.arrayOf(PropTypes.object),
  platform: PlatformPropType.isRequired,
};

TableCellInfluencersReach.defaultProps = {
  applications: [],
  align: 'left',
};

export default TableCellInfluencersReach;

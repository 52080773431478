import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import BottomCenterFloat from './BottomCenterFloat';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
    borderRadius: 30,
  },
}));

const pagerPropsAreEqual = (prevProps, nextProps) => {
  const arePagesEqual = prevProps.page === nextProps.page;
  const areCountsEqual = prevProps.count === nextProps.count;
  const areClassesEqual = prevProps.classes === nextProps.classes;

  return arePagesEqual && areCountsEqual && areClassesEqual;
};

const FloatingTablePagination = React.memo((props) => {
  const classes = useStyles(props);
  const intl = useIntl();

  return (
    <BottomCenterFloat className={classes.root}>
      <TablePagination
        component={Paper}
        elevation={2}
        className={classes.paper}
        backIconButtonText={intl.formatMessage({
          id: 'general.pagination.back',
          defaultMessage: 'Previous page',
        })}
        nextIconButtonText={intl.formatMessage({
          id: 'general.pagination.next',
          defaultMessage: 'Next page',
        })}
        {...props}
      />
    </BottomCenterFloat>
  );
}, pagerPropsAreEqual);

FloatingTablePagination.propTypes = {
  // from props
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};
FloatingTablePagination.defaultProps = {};

export default FloatingTablePagination;

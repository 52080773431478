import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/react';
import isEmpty from 'lodash/isEmpty';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTheme } from '@material-ui/core/styles';

import { useQueryParams, StringParam } from 'utils/routing';
import LoadingPage from 'layout/LoadingPage';
import routes, { defaultRoute } from 'app/routing/routes';
import Route from 'app/routing/Route';
import {
  getStrings,
  browserLocale,
  defaultLocale,
  supportedLocales,
} from 'app/i18n';
import useAuth from 'auth/useAuth';
import { CookiePolicySnackbar } from 'utils/cookies';
import { GTMMarkup } from 'utils/gtm';
import { CloudinaryUploadWidgetMarkup } from 'utils/cloudinary';
import { dateLocaleMap } from 'utils/formatting';
import { useFeatures } from 'utils/features';
import { useClient } from 'utils/client';
import { WhatsNewProvider } from 'utils/whatsNew';
import ErrorPage404 from 'scenes/error/404';

import AppLayout from './AppLayout';

const systemLocale = browserLocale || defaultLocale;

const App = () => {
  const theme = useTheme();
  const { loading: loadingAuth } = useAuth();
  const { loading: loadingClient, client } = useClient();
  const { isFeatureAvailable } = useFeatures();
  const [query] = useQueryParams({
    locale: StringParam,
  });

  const storedLocale = localStorage.getItem('locale');

  const [locale, setLocale] = React.useState(
    () => query.locale || storedLocale || systemLocale,
  );

  React.useEffect(() => {
    if (!supportedLocales.includes(locale)) {
      setLocale(defaultLocale);
    }

    localStorage.setItem('locale', locale);
  }, [locale]);

  const renderedRoutes = routes
    .filter((route) => {
      if (!route.feature) {
        return true;
      }

      if (Array.isArray(route.feature)) {
        return route.feature.every((feature) => isFeatureAvailable(feature));
      }

      return isFeatureAvailable(route.feature);
    })
    .filter((route) => {
      if (isEmpty(route.roles)) {
        return true;
      }

      const clientRole = client?.role;

      if (!clientRole) {
        return false;
      }

      return route.roles.includes(clientRole);
    })
    .map((route) => <Route key={route.path} {...route} />);

  return (
    <IntlProvider
      locale={locale}
      messages={getStrings(locale)}
      defaultLocale={defaultLocale}
      wrapRichTextChunksInFragment
    >
      {(loadingAuth || loadingClient || theme.loading) && <LoadingPage />}
      {!loadingAuth && !theme.loading && !loadingClient && (
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={dateLocaleMap[locale]}
        >
          <WhatsNewProvider>
            <AppLayout>
              <GTMMarkup />
              <CloudinaryUploadWidgetMarkup />
              <CookiePolicySnackbar />
              <Switch>
                <Redirect exact from="/" to={defaultRoute} />
                <Redirect from="/updates-and-change" to="/insider/whats-new" />
                {renderedRoutes}
                <Route path="*">
                  <ErrorPage404 />
                </Route>
              </Switch>
            </AppLayout>
          </WhatsNewProvider>
        </MuiPickersUtilsProvider>
      )}
    </IntlProvider>
  );
};

export default Sentry.withProfiler(App);

if (module.hot) {
  module.hot.accept();
}

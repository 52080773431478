import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import createAuth0Client from '@auth0/auth0-spa-js';

import { defaultRoute } from 'app/routing/routes';
import { supportedLocales } from 'app/i18n';
import { useGA } from 'utils/gtm';
import config from 'config';

export const Context = React.createContext();

const Provider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [client, setClient] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [popupOpen, setPopupOpen] = React.useState(false);

  const { trackEvent } = useGA();

  const trackLogin = (user) => {
    trackEvent({
      action: 'user_login',
      label: `User: ${user.email} logged in.`,
    });
  };

  React.useEffect(() => {
    const initAuth0 = async () => {
      const auth0Client = await createAuth0Client({
        domain: config.auth.domain,
        client_id: config.auth.clientID,
        redirect_uri: window.location.origin,
        scope: config.auth.scope,
        audience: config.auth.audience,
        ui_locales: supportedLocales.join(' '),
      });
      setClient(auth0Client);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        await auth0Client.handleRedirectCallback();

        let redirectLocation;

        try {
          redirectLocation = JSON.parse(
            localStorage.getItem('redirectLocation'),
          );
        } catch (err) {
          redirectLocation = { pathname: '/', search: '', hash: '' };
        }

        if (!redirectLocation || redirectLocation.pathname === '/') {
          history.push(defaultRoute);
        } else {
          history.push(redirectLocation);
        }
      }

      const isAuthenticated = await auth0Client.isAuthenticated();

      if (!isAuthenticated) {
        localStorage.setItem('redirectLocation', JSON.stringify(location));
      }

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const auth0User = await auth0Client.getUser();
        setUser(auth0User);
        trackLogin(auth0User);
      } else {
        await auth0Client.loginWithRedirect();
      }

      setLoading(false);
    };

    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    setLoading(true);

    try {
      await client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    const auth0User = await client.getUser();
    setUser(auth0User);
    trackLogin(auth0User);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const loginWithRedirect = async (params = {}) => {
    setLoading(true);

    await client.loginWithRedirect(params);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);

    await client.handleRedirectCallback();
    const auth0User = await client.getUser();

    setLoading(false);
    setIsAuthenticated(true);

    setUser(auth0User);
  };

  const actions = React.useMemo(
    () => ({
      getIdTokenClaims: (...args) => client.getIdTokenClaims(...args),
      getTokenSilently: (...args) => client.getTokenSilently(...args),
      checkSession: (...args) => client.checkSession(...args),
      getTokenWithPopup: (...args) => client.getTokenWithPopup(...args),
      logout: (...args) => client.logout(...args),
    }),
    [client],
  );

  return (
    <Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        loginWithRedirect,
        handleRedirectCallback,
        ...actions,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;

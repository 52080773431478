import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import InspectorDrawer from 'components/inspector/InspectorDrawer';
import ApplicationReviewButton from 'components/applications/ApplicationReviewButton';

import { ChannelPropType } from 'utils/channel';

import {
  useUpdateChannelTags,
  useUpdateChannelNotes,
  useUpdateApplicationClientReview,
} from '../data/privateNetworkHooks';
import PrivateNetworkApplicationClientCard from './PrivateNetworkApplicationClientCard';

export const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  reviewButton: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: (drawerWidth - 275) / 2,
  },
}));

const PrivateNetworkApplicationClientInspector = ({
  selectedChannel,
  channels,
  onSelectChannel,
  application,
  loading,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { updateChannelTags, loading: tagsUpdateLoading } =
    useUpdateChannelTags();

  const { updateChannelNotes, loading: notesUpdateLoading } =
    useUpdateChannelNotes();

  const { updateApplicationClientReview, loading: appReviewUpdateLoading } =
    useUpdateApplicationClientReview();

  const handleUpdateChannelTags = (data) => {
    if (selectedChannel) {
      updateChannelTags({
        id: selectedChannel.id,
        payload: data.tags,
      });
    }
  };

  const handleUpdateChannelNotes = (notes) => {
    updateChannelNotes({
      id: selectedChannel?.id,
      payload: notes,
    });
  };

  const handleApplicationUpdate = React.useCallback(
    (newClientReviewStatus) => {
      if (application) {
        updateApplicationClientReview({
          id: application.id,
          payload: newClientReviewStatus,
        });
      }
    },
    [updateApplicationClientReview, application],
  );

  const combinedLoading = [
    loading,
    tagsUpdateLoading,
    notesUpdateLoading,
    appReviewUpdateLoading,
  ].some(Boolean);

  return (
    <InspectorDrawer isOpen={!!application} width={drawerWidth} {...otherProps}>
      {application && (
        <Box>
          <PrivateNetworkApplicationClientCard
            selectedChannel={selectedChannel}
            channels={channels}
            onSelectChannel={onSelectChannel}
            application={application}
            onUpdateChannelTags={handleUpdateChannelTags}
            updateChannelNotes={handleUpdateChannelNotes}
            loading={combinedLoading}
            height="100%"
          />
          <ApplicationReviewButton
            className={classes.reviewButton}
            status={application.clientReview.status}
            note={application.clientReview.note}
            description={intl.formatMessage({
              id: 'components.PrivateNetworkApplicationReviewButton.dialog.info_MD',
              defaultMessage:
                'Accept or Reject this Influencer’s application to the Private Network. If you reject an Influencer, please explain briefly the reason for your decision.',
            })}
            title={intl.formatMessage({
              id: 'components.PrivateNetworkApplicationReviewButton.dialog.title.reviewApplication',
              defaultMessage: 'Review Application',
            })}
            onUpdate={handleApplicationUpdate}
          />
        </Box>
      )}
    </InspectorDrawer>
  );
};

PrivateNetworkApplicationClientInspector.propTypes = {
  selectedChannel: ChannelPropType,
  channels: PropTypes.arrayOf(ChannelPropType),
  onSelectChannel: PropTypes.func.isRequired,
  application: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

PrivateNetworkApplicationClientInspector.defaultProps = {
  application: undefined,
  channels: [],
  selectedChannel: null,
};

export default PrivateNetworkApplicationClientInspector;

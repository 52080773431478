import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { views as campaignViews } from 'utils/campaign';
import ErrorBoundary from 'utils/ErrorBoundary';
import ErrorPage404 from 'scenes/error/404';

import CampaignDraftPage from './draft/CampaignDraftPage';
import CampaignApplicationsPage from './applications/CampaignApplicationsPage';
import CampaignPreparationPage from './preparation/CampaignPreparationPage';
import CampaignContentReviewPage from './contentReview/CampaignContentReviewPage';
import PublishedApplicationsPage from './publishedApplications/PublishedApplicationsPage';

const CampaignDetailsPage = () => {
  const { params } = useRouteMatch();
  const { id, view } = params;

  const isNew = id === 'new';

  let content;

  switch (view) {
    case campaignViews.BRIEFING:
      content = <CampaignDraftPage campaignId={id} isNew={isNew} />;
      break;
    case campaignViews.APPLICATIONS:
      content = <CampaignApplicationsPage campaignId={id} />;
      break;
    case campaignViews.PREPARATION:
      content = <CampaignPreparationPage campaignId={id} />;
      break;
    case campaignViews.CONTENT_REVIEW:
      content = <CampaignContentReviewPage campaignId={id} />;
      break;
    case campaignViews.LIVE:
      content = <PublishedApplicationsPage campaignId={id} />;
      break;
    default: {
      content = null;
    }
  }

  if (!content) {
    return <ErrorPage404 />;
  }

  return <ErrorBoundary>{content}</ErrorBoundary>;
};

CampaignDetailsPage.propTypes = {};

export default CampaignDetailsPage;

import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import { defineMessages, useIntl } from 'react-intl';

import Typography from '@material-ui/core/Typography';

import { TaskPropType } from 'utils/task';
import { PlatformPropType, ChannelPropType, platforms } from 'utils/channel';
import { formatNumber as numberFormatter } from 'utils/formatting';
import { ClientReviewStatusPropType } from 'utils/clientReviewStatuses';

const missionStatus = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  REFUSED: 'refused',
};

export const MissionStatusPropType = PropTypes.oneOf(values(missionStatus));

const missionStatusStrings = defineMessages({
  [missionStatus.PENDING]: {
    id: 'application.missionStatus.pending',
    defaultMessage: 'Pending',
  },
  [missionStatus.CONFIRMED]: {
    id: 'application.missionStatus.confirmed',
    defaultMessage: 'Started',
  },
  [missionStatus.REFUSED]: {
    id: 'application.missionStatus.refused',
    defaultMessage: 'Refused',
  },
});

export const MissionStatusLabel = ({ status }) => {
  const intl = useIntl();

  const string = missionStatusStrings[status];

  if (!string) {
    return '—';
  }

  return intl.formatMessage(string);
};

MissionStatusLabel.propTypes = {
  status: MissionStatusPropType.isRequired,
};

MissionStatusLabel.defaultProps = {};

export const deliveryAddressFields = {
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  STREET: 'street',
  STREET_NUMBER: 'streetNumber',
  POSTAL_CODE: 'postalCode',
  CITY: 'city',
  COUNTRY: 'country',
};

export const DeliveryAddressPropType = PropTypes.shape({
  [deliveryAddressFields.FIRSTNAME]: PropTypes.string,
  [deliveryAddressFields.LASTNAME]: PropTypes.string,
  [deliveryAddressFields.STREET]: PropTypes.string,
  [deliveryAddressFields.STREET_NUMBER]: PropTypes.string,
  [deliveryAddressFields.POSTAL_CODE]: PropTypes.string,
  [deliveryAddressFields.CITY]: PropTypes.string,
  [deliveryAddressFields.COUNTRY]: PropTypes.string,
});

export const ClientContentPreviewStatusPropType = PropTypes.oneOf([
  'new',
  'updated',
  'reviewed',
  'readonly',
]);

export const ContentPreviewMediaPropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['image', 'video']).isRequired,
});

export const ContentPreviewMediaPropTypeWithId = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['image', 'video']).isRequired,
  assetId: PropTypes.string,
});

const InstagramContentPreviewPropType = PropTypes.shape({
  media: PropTypes.arrayOf(ContentPreviewMediaPropType).isRequired,
  caption: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  status: ClientContentPreviewStatusPropType.isRequired,
});

const BlogContentPreviewPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  articleSanitized: PropTypes.string.isRequired,
  articleWordsCount: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
  status: ClientContentPreviewStatusPropType.isRequired,
});

export const ContentPreviewPropType = PropTypes.oneOfType([
  InstagramContentPreviewPropType,
  BlogContentPreviewPropType,
]);

export const ClientApplicationReviewPropType = PropTypes.shape({
  status: ClientReviewStatusPropType,
  note: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
});

export const ProductShipmentPropType = PropTypes.shape({
  status: PropTypes.oneOf(['pending', 'sent']),
  product: PropTypes.string,
  address: DeliveryAddressPropType,
});

export const ApplicationNotePropType = PropTypes.shape({
  note: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const ApplicationPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  clientApplicationReview: ClientApplicationReviewPropType,
  clientContentReview: PropTypes.shape({
    status: ClientReviewStatusPropType,
    note: PropTypes.string,
    verified: PropTypes.bool.isRequired,
  }),
  mission: PropTypes.shape({
    status: MissionStatusPropType.isRequired,
    publishDate: PropTypes.string,
  }),
  contentPreview: ContentPreviewPropType,
  pitch: PropTypes.string.isRequired,
  reach: PropTypes.number.isRequired,
  advertiserPrice: PropTypes.number.isRequired,
  channel: ChannelPropType.isRequired,
  createdAt: PropTypes.string.isRequired,
  productShipment: ProductShipmentPropType,
  kamNotes: ApplicationNotePropType,
  clientNotes: ApplicationNotePropType,
  tasks: PropTypes.arrayOf(TaskPropType),
});

export const InfluencerCRMApplicationPropType = PropTypes.shape({
  campaignId: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  campaignName: PropTypes.string.isRequired,
  campaignStatus: PropTypes.string.isRequired,
  applicationStatus: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  clientApplicationReview: ClientApplicationReviewPropType.isRequired,
});

const selectedApplicationsLabelsStrings = defineMessages({
  instagram: {
    id: 'selectedApplications.instagram',
    defaultMessage:
      '{influencersCount, plural, one {influencer selected, reaching} other {influencers selected, reaching}}',
  },
  pinterest: {
    id: 'selectedApplications.pinterest',
    defaultMessage:
      '{influencersCount, plural, one {influencer selected, reaching} other {influencers selected, reaching}}',
  },
  tiktok: {
    id: 'selectedApplications.tiktok',
    defaultMessage:
      '{influencersCount, plural, one {influencer selected, reaching} other {influencers selected, reaching}}',
  },
  blog: {
    id: 'selectedApplications.blog',
    defaultMessage:
      '{influencersCount, plural, one {influencer selected, reaching} other {influencers selected, reaching}}',
  },
});

const selectedApplicationsReachLabelsStrings = defineMessages({
  instagram: {
    id: 'selectedApplicationsReach.instagram',
    defaultMessage: '{totalReach, plural, one {follower} other {followers}}',
  },
  pinterest: {
    id: 'selectedApplicationsReach.pinterest',
    defaultMessage:
      '{totalReach, plural, one {impression} other {impressions}}',
  },
  tiktok: {
    id: 'selectedApplicationsReach.tiktok',
    defaultMessage: '{totalReach, plural, one {follower} other {followers}}',
  },
  blog: {
    id: 'selectedApplicationsReach.blog',
    defaultMessage:
      '{totalReach, plural, one {page view for last 30 days} other {page views for last 30 days}}',
  },
});

export const SelectedApplicationsLabel = ({
  platform: platformProp,
  influencersCount,
  totalReach,
  ...otherProps
}) => {
  const intl = useIntl();

  let platform = platformProp;

  if (platform === 'ga' || platform === 'website') {
    platform = 'blog';
  }

  if (!platforms.includes(platformProp)) {
    return '';
  }

  const selectedApplicationsLabel = intl.formatMessage(
    selectedApplicationsLabelsStrings[platform],
    {
      influencersCount,
    },
  );

  const selectedApplicationsReachLabel = intl.formatMessage(
    selectedApplicationsReachLabelsStrings[platform],
    {
      totalReach,
    },
  );

  const formatNumber = (number) =>
    numberFormatter({ formatter: intl.formatNumber, number });

  return (
    <Typography {...otherProps}>
      {`${formatNumber(
        influencersCount,
      )} ${selectedApplicationsLabel} ${formatNumber(
        totalReach,
      )} ${selectedApplicationsReachLabel}`}
    </Typography>
  );
};

SelectedApplicationsLabel.propTypes = {
  platform: PlatformPropType,
  influencersCount: PropTypes.number,
  totalReach: PropTypes.number,
};

SelectedApplicationsLabel.defaultProps = {
  platform: undefined,
  influencersCount: 0,
  totalReach: 0,
};

export const isCompleteNotesObj = (noteObj) =>
  noteObj?.note && noteObj?.updatedAt && noteObj?.updatedBy;

import * as React from 'react';

import { useClient } from 'utils/client';
import { ROLES } from 'utils/roles';
import LoadingPage from 'layout/LoadingPage';

import PrivateNetworkClientPage from './client/PrivateNetworkClientPage';
import PrivateNetworkKamPage from './kam/PrivateNetworkKamPage';

export default (props) => {
  const { loading, client } = useClient();

  if (loading || !client) {
    return <LoadingPage withSidebar />;
  }

  if (client.role === ROLES.KAM) {
    return <PrivateNetworkKamPage {...props} />;
  }

  return <PrivateNetworkClientPage client={client} {...props} />;
};

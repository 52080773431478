import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ListItemText from '@material-ui/core/ListItemText';

import { getChannelPlatformsFilter } from 'utils/channelPlatforms';

import TagsInput from 'components/TagsInput';

const options = ['instagram', 'youtube', 'tiktok', 'ga', 'pinterest'];

const PlatformsFilter = ({ value, onChange, ...otherProps }) => {
  const intl = useIntl();

  const handleChange = (event, newValues) => {
    onChange(newValues);
  };

  const filterOptions = React.useCallback(
    (options, { inputValue }) =>
      options.filter((filterId) => {
        const filterOption = getChannelPlatformsFilter(filterId);

        const title = intl.formatMessage(filterOption.title);

        const lowercaseInputValue = inputValue.toLowerCase();
        return title.toLowerCase().includes(lowercaseInputValue);
      }),
    [intl, getChannelPlatformsFilter],
  );

  const renderOption = React.useCallback(
    (filterId) => {
      const filterOption = getChannelPlatformsFilter(filterId);

      const title = intl.formatMessage(filterOption.title);

      return <ListItemText primary={title} />;
    },
    [intl, getChannelPlatformsFilter],
  );

  const getTagLabel = React.useCallback(
    (filterId) => {
      const filterOption = getChannelPlatformsFilter(filterId);
      return intl.formatMessage(filterOption.title);
    },
    [intl, getChannelPlatformsFilter],
  );

  return (
    <TagsInput
      value={value}
      options={options}
      renderOption={renderOption}
      getTagLabel={getTagLabel}
      onChange={handleChange}
      label={intl.formatMessage({
        id: 'components.ChannelPlatformsFilter.title',
        defaultMessage: 'With channels on',
      })}
      placeholder={intl.formatMessage({
        id: 'components.ChannelPlatformsFilter.placeholder',
        defaultMessage: 'Any platform',
      })}
      filterOptions={filterOptions}
      inputLabelShrinkProps={{ shrink: true }}
      {...otherProps}
    />
  );
};

PlatformsFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};
PlatformsFilter.defaultProps = {
  value: [],
};

export default PlatformsFilter;

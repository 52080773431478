import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import CardMedia from 'components/CardMedia';
import Tags from 'components/Tags';
import { Feature } from 'utils/features';
import { NonPropagatingLink } from 'utils/routing';
import { DateLabel, PriceLabel } from 'utils/formatting';

import InfluencerAssetCardWrapper from './InfluencerAssetCardWrapper';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 0,
  },
  actions: {
    paddingTop: theme.spacing(1),
  },
}));

const InfluencerAssetCardCompact = ({
  id,
  mediaType,
  mediaSrc,
  tags,
  getTagLabel,
  createdAt,
  buyOutPrice,
  onInspect,
  onDownload,
  selected,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <InfluencerAssetCardWrapper
      selectable
      onSelect={onInspect}
      selected={selected}
      {...otherProps}
    >
      <NonPropagatingLink to={`/influencer-assets/${id}`}>
        <CardMedia
          type={mediaType}
          src={mediaSrc}
          playable={false}
          autoPlay={false}
          clickable
        />
      </NonPropagatingLink>
      <CardContent className={classes.content}>
        <Tags tags={tags} getTagLabel={getTagLabel} />
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        <Feature
          feature="influencerAssetsBuyOutPrice"
          otherwise={
            <Tooltip
              title={intl.formatMessage({
                id: 'components.ContentCard.tooltips.creationDate',
                defaultMessage: 'Creation Date',
              })}
            >
              <DateLabel
                date={createdAt}
                mr="auto"
                ml={1}
                color="textSecondary"
              />
            </Tooltip>
          }
        >
          <Tooltip
            title={intl.formatMessage({
              id: 'components.ContentCard.tooltips.buyOutPrice',
              defaultMessage: 'Buyout price',
            })}
          >
            <PriceLabel
              value={buyOutPrice}
              mr="auto"
              ml={1}
              color="textSecondary"
              label={intl.formatMessage({
                id: 'components.ContentCard.labels.price',
                defaultMessage: 'Price',
              })}
            />
          </Tooltip>
        </Feature>

        <IconButton onClick={onDownload} color="primary">
          <Icons.Download />
        </IconButton>
        <IconButton
          onClick={onInspect}
          color={selected ? 'secondary' : 'primary'}
        >
          <Icons.Info />
        </IconButton>
        <IconButton
          component={NonPropagatingLink}
          to={`/influencer-assets/${id}`}
          color="primary"
        >
          <Icons.ZoomIn />
        </IconButton>
      </CardActions>
    </InfluencerAssetCardWrapper>
  );
};

InfluencerAssetCardCompact.propTypes = {
  id: PropTypes.string.isRequired,
  mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
  mediaSrc: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  getTagLabel: PropTypes.func.isRequired,
  createdAt: PropTypes.string.isRequired,
  buyOutPrice: PropTypes.number,
  selected: PropTypes.bool,
  onInspect: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
};

InfluencerAssetCardCompact.defaultProps = {
  tags: [],
  selected: false,
};

export default InfluencerAssetCardCompact;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createEnumParam } from 'use-query-params';

import { parseSort, serializeSort } from 'utils/sorting';
import { platforms } from 'utils/channel';

export * from 'use-query-params';

export const SortParam = {
  encode: serializeSort,
  decode: parseSort,
};

export const PlatformParam = createEnumParam(platforms);

const killPropagation = (e) => {
  e.stopPropagation();
};

export const NonPropagatingLink = React.forwardRef(
  ({ disabled, onClick, children, ...otherProps }, ref) => {
    if (disabled) {
      return children;
    }

    const handleClick = (...args) => {
      killPropagation(args[0]);
      if (onClick) {
        onClick(...args);
      }
    };

    return (
      <Link {...otherProps} onClick={handleClick} ref={ref}>
        {children}
      </Link>
    );
  },
);

NonPropagatingLink.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

NonPropagatingLink.defaultProps = {
  disabled: false,
  onClick: null,
  children: null,
};

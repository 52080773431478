import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as Icons from 'react-feather';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    width: '100%',
  },
});

const HiddenStatusButton = ({ hidden, onChange, ...otherProps }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = () => {
    onChange(!hidden);
  };

  return (
    <Box
      className={classes.root}
      onClick={handleChange}
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      {...otherProps}
    >
      <Box display="flex" alignItems="center">
        <Box display="flex" mr={1}>
          {hidden ? (
            <Icons.ToggleLeft color={theme.palette.primary.main} />
          ) : (
            <Icons.ToggleRight color={theme.palette.primary.main} />
          )}
        </Box>
        <Box>
          <Typography variant="subtitle2">
            {hidden ? (
              <FormattedMessage
                id="scenes.privateNetwork.kam.hiddenSelect.hidden.label"
                defaultMessage="Hidden from Client"
              />
            ) : (
              <FormattedMessage
                id="scenes.privateNetwork.kam.hiddenSelect.shown.label"
                defaultMessage="Shown to Client"
              />
            )}
          </Typography>
          <Typography variant="caption">
            {hidden ? (
              <FormattedMessage
                id="scenes.privateNetwork.kam.hiddenSelect.hidden.caption"
                defaultMessage="Click to make it visible"
              />
            ) : (
              <FormattedMessage
                id="scenes.privateNetwork.kam.hiddenSelect.shown.caption"
                defaultMessage="Click to hide it"
              />
            )}
          </Typography>
        </Box>
      </Box>
      {hidden ? (
        <Icons.EyeOff color={theme.palette.primary.main} />
      ) : (
        <Icons.Eye color={theme.palette.primary.main} />
      )}
    </Box>
  );
};

HiddenStatusButton.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
HiddenStatusButton.defaultProps = {};

export default HiddenStatusButton;

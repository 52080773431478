import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ContactCard from 'components/ContactCard';
import { useClient } from 'utils/client';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
}));

const CampaignDraftConfirmed = ({ className, ...otherProps }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { client } = useClient();

  const managerName = client?.manager?.name;

  const contactCardTitle =
    managerName === 'Marcus Hammer'
      ? intl.formatMessage({
          id: 'scenes.campaigns.managerInfo.male.title.',
          defaultMessage: 'Your expert for any campaign questions',
        })
      : intl.formatMessage({
          id: 'scenes.campaigns.managerInfo.female.title.',
          defaultMessage: 'Your expert for any campaign questions',
        });

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <Typography variant="body1">
        {intl.formatMessage(
          {
            id: 'scenes.campaigns.details.message',
            defaultMessage:
              "The campaign has been published and can't be directly edited anymore.{br}{br}Please get in touch with your eqolot key account manager for more information or to request further changes.",
          },
          { br: <br /> },
        )}
      </Typography>
      <ContactCard
        title={contactCardTitle}
        maxWidth={490}
        mx="auto"
        manager={client?.manager}
      />
      <Button component={Link} color="primary" to="/campaigns">
        {intl.formatMessage({
          id: 'scenes.campaigns.details.backToCampaigns',
          defaultMessage: '← Back to Campaigns',
        })}
      </Button>
    </Box>
  );
};

CampaignDraftConfirmed.propTypes = {
  className: PropTypes.string,
};
CampaignDraftConfirmed.defaultProps = {
  className: '',
};

export default CampaignDraftConfirmed;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 0,
    transition: theme.drawer.transitions.createGrow('margin'),
    marginRight: (props) => -props.drawerWidth,
    width: '100%',
  },
  contentShift: {
    transition: theme.drawer.transitions.createShrink('margin'),
    marginRight: '0px !important',
  },
}));

const InspectorDrawerContent = (props) => {
  const { drawerOpen, children, drawerWidth, ...otherProps } = props;

  const classes = useStyles(props);

  return (
    <Box
      className={clsx(classes.content, {
        [classes.contentShift]: drawerOpen,
      })}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

InspectorDrawerContent.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  drawerWidth: PropTypes.number.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default InspectorDrawerContent;

import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ErrorPage404Image from 'images/errorPage404.jpg';

import ErrorPage from './ErrorPage';

const useStyles = makeStyles((theme) => ({
  message: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}));

export default () => {
  const classes = useStyles();
  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'errorPages.404.title',
    defaultMessage: 'Page not found',
  });

  return (
    <ErrorPage title={title} image={ErrorPage404Image}>
      <Box pt={6} pb={6}>
        <FormattedMessage
          id="errorPages.404.message"
          defaultMessage="<typo>The page you are looking for is not here.</typo><typo>You may say “it’s 404” if you want to be technical.</typo>"
          values={{
            typo: (...chunks) => (
              <Typography className={classes.message} variant="subtitle1">
                {chunks}
              </Typography>
            ),
          }}
        />
      </Box>
    </ErrorPage>
  );
};

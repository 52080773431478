import React from 'react';

import { PlatformPropType } from 'utils/channel';

import Instagram from './instagram/TableHead';
import Pinterest from './pinterest/TableHead';
import Tiktok from './tiktok/TableHead';
import Blog from './blog/TableHead';

const componentsByPlatform = {
  instagram: Instagram,
  pinterest: Pinterest,
  tiktok: Tiktok,
  website: Blog,
  ga: Blog,
};

const PublishedApplicationsTableHead = React.memo(
  ({ platform, ...otherProps }) => {
    const Component = componentsByPlatform[platform];

    return <Component {...otherProps} />;
  },
);

PublishedApplicationsTableHead.propTypes = {
  platform: PlatformPropType.isRequired,
};

PublishedApplicationsTableHead.defaultProps = {};

export default PublishedApplicationsTableHead;

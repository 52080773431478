import { defineMessages } from 'react-intl';
import values from 'lodash/values';
import memoize from 'lodash/memoize';
import omit from 'lodash/omit';

import { alpha } from '@material-ui/core/styles';

export const REVIEW_STATUS = {
  ALL: 'all',
  NONE: 'none',
  NEW: 'new',
  REVIEW: 'review',
  SENT_TO_CLIENT: 'sentToClient',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  BANNED: 'banned',
  NOT_INTERESTED: 'notInterested',
};

const statusLabels = defineMessages({
  [REVIEW_STATUS.ALL]: {
    id: 'scenes.privateNetwork.kamReview.status.all',
    defaultMessage: 'All',
  },
  [REVIEW_STATUS.NONE]: {
    id: 'scenes.privateNetwork.kamReview.status.none',
    defaultMessage: 'N/A',
  },
  [REVIEW_STATUS.NEW]: {
    id: 'scenes.privateNetwork.kamReview.status.new',
    defaultMessage: 'New',
  },
  [REVIEW_STATUS.REVIEW]: {
    id: 'scenes.privateNetwork.kamReview.status.review',
    defaultMessage: 'Shortlist',
  },
  [REVIEW_STATUS.SENT_TO_CLIENT]: {
    id: 'scenes.privateNetwork.kamReview.status.sentToClient',
    defaultMessage: 'Sent to Client',
  },
  [REVIEW_STATUS.APPROVED]: {
    id: 'scenes.privateNetwork.kamReview.status.approved',
    defaultMessage: 'Approved',
  },
  [REVIEW_STATUS.REJECTED]: {
    id: 'scenes.privateNetwork.kamReview.status.rejected',
    defaultMessage: 'Rejected',
  },
  [REVIEW_STATUS.BANNED]: {
    id: 'scenes.privateNetwork.kamReview.status.banned',
    defaultMessage: 'Banned',
  },
  [REVIEW_STATUS.NOT_INTERESTED]: {
    id: 'scenes.privateNetwork.kamReview.status.notInterested',
    defaultMessage: 'Not Interested',
  },
});

export const allStatuses = values(REVIEW_STATUS);
export const editableStatuses = omit(allStatuses, REVIEW_STATUS.NONE);
export const getStatusLabel = memoize((status) => {
  if (!status) {
    return statusLabels[REVIEW_STATUS.NONE];
  }

  return statusLabels[status];
});

const statusButtonMap = {
  [REVIEW_STATUS.NONE]: {
    label: statusLabels[REVIEW_STATUS.NONE],
    color: '#FFFFFF',
  },
  [REVIEW_STATUS.NEW]: {
    label: statusLabels[REVIEW_STATUS.NEW],
    color: '#F57C00',
  },
  [REVIEW_STATUS.REVIEW]: {
    label: statusLabels[REVIEW_STATUS.REVIEW],
    color: '#2196F3',
  },
  [REVIEW_STATUS.SENT_TO_CLIENT]: {
    label: statusLabels[REVIEW_STATUS.SENT_TO_CLIENT],
    color: '#7B61FF',
  },
  [REVIEW_STATUS.APPROVED]: {
    label: statusLabels[REVIEW_STATUS.APPROVED],
    color: '#4CAF50',
  },
  [REVIEW_STATUS.REJECTED]: {
    label: statusLabels[REVIEW_STATUS.REJECTED],
    color: alpha('#000', 0.5),
  },
  [REVIEW_STATUS.BANNED]: {
    label: statusLabels[REVIEW_STATUS.BANNED],
    color: '#F44336',
  },
  [REVIEW_STATUS.NOT_INTERESTED]: {
    label: statusLabels[REVIEW_STATUS.NOT_INTERESTED],
    color: alpha('#000', 0.38),
  },
};

export const useKamReviewStatusButton = (status) => {
  if (!status) {
    return statusButtonMap[REVIEW_STATUS.NONE];
  }

  return statusButtonMap[status];
};

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import groupBy from 'lodash/groupBy';

import { ApplicationPropType } from 'utils/application';
import {
  formatNumber as numberFormatter,
  sumWithRounding,
} from 'utils/formatting';

import metaLabels from '../metaLabels';

const InstagramTableMetaLabel = ({ applications }) => {
  const intl = useIntl();

  const metaLabel = React.useMemo(() => {
    const reach = sumWithRounding(applications, 'reach');

    const influencersNumber = Object.keys(
      groupBy(applications, 'user.id'),
    ).length;

    const contentItemsLabel = intl.formatMessage(metaLabels.contentItems, {
      contentItemsNumber: applications.length,
    });

    const publishedBy = intl.formatMessage(metaLabels.publishedBy);

    const influencersLabel = intl.formatMessage(metaLabels.influencers, {
      influencersNumber,
    });

    const withLabel = intl.formatMessage(metaLabels.with);

    const pageviewsCountLabel = intl.formatMessage(metaLabels.pageviewsCount, {
      pageviewsCount: reach,
    });

    const formatNumber = (number) =>
      numberFormatter({ formatter: intl.formatNumber, number });

    return [
      formatNumber(applications.length),
      contentItemsLabel,
      publishedBy,
      formatNumber(influencersNumber),
      influencersLabel,
      withLabel,
      formatNumber(reach),
      pageviewsCountLabel,
    ].join(' ');
  }, [applications, intl]);

  return metaLabel;
};

InstagramTableMetaLabel.propTypes = {
  applications: PropTypes.arrayOf(ApplicationPropType),
};

InstagramTableMetaLabel.defaultProps = {
  applications: [],
};

export default InstagramTableMetaLabel;

import React from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import lowerCase from 'lodash/lowerCase';
import intersection from 'lodash/intersection';

import { sumWithRounding } from 'utils/formatting';
import { getCollaborationRatingsFilter } from 'utils/collaborationRatings';
import { getAudienceGenderFilter } from 'utils/kpi';
import { useFeatures } from 'utils/features';
import { sortToQuery } from 'utils/sorting';
import {
  useQueryParams,
  SortParam,
  StringParam,
  NumberParam,
  PlatformParam,
  JsonParam,
  withDefault,
} from 'utils/routing';

import { useMany as useChannelsSearch } from './channelsSearchHooks';

export const supportedFilterCountries = ['de', 'at', 'ch'];

const defaultState = {
  platform: 'instagram',
  channels: [],
  meta: {},
  sort: {
    by: 'reach',
    direction: 'desc',
  },
  filters: {
    reach: { min: 1000 },
    salutation: '',
    countries: [],
    topCountries: [],
    audienceGenders: [],
    collaborationRatings: [],
    tags: {},
    campaigns: {},
    collaborator: 'on',
  },
  currentPage: 1,
};

const actionTypes = {
  SET_DATA: 'set_data',
  INSPECT: 'inspect',
  FILTER_BY: 'filter_by',
  SORT_BY: 'sort_by',
  SET_PAGE: 'set_page',
  SET_PLATFORM: 'set_platform',
};

const defaultQueryFilters = {
  reach: { min: 1000 },
  categories: { expression: 'or', values: [] },
};

const mergeFilterIntoState = (filtersState, newFilter) => {
  const { key, value } = newFilter;

  if (isEmpty(value)) {
    unset(filtersState, key);
    return {
      ...filtersState,
    };
  }

  return {
    ...filtersState,
    [key]: value,
  };
};

const filtersToQuery = (filters, platform) => {
  const queryFilters = {
    ...defaultQueryFilters,
    // useQuery checks the object reference here, if it remains the same
    // it will not detect updates to the filter
    categories: {
      ...defaultQueryFilters.categories,
    },
    platforms: [platform],
  };

  if (!isEmpty(filters.countries)) {
    set(queryFilters, 'user.countries', filters.countries);
  } else {
    set(queryFilters, 'user.countries', supportedFilterCountries);
  }

  if (!isEmpty(filters.topCountries)) {
    set(queryFilters, 'metrics.topCountries', filters.topCountries);
  } else {
    // No default topCountries filter, as it might not be set
    unset(queryFilters, 'metrics.topCountries');
  }

  if (!isEmpty(filters.audienceGenders)) {
    const values = filters.audienceGenders.map(
      (filterId) => getAudienceGenderFilter(filterId).value,
    );
    set(queryFilters, 'metrics.genders', values);
  } else {
    unset(queryFilters, 'metrics.genders');
  }

  if (!isEmpty(filters.collaborationRatings)) {
    const values = filters.collaborationRatings.reduce((memo, filterId) => {
      memo[filterId] = getCollaborationRatingsFilter(filterId).value;
      return memo;
    }, {});
    set(queryFilters, 'collaborationRatings', values);
  } else {
    unset(queryFilters, 'collaborationRatings');
  }

  if (!isEmpty(filters.categories)) {
    set(
      queryFilters,
      'categories.values',
      filters.categories.map((code) => ({ code, weight: 1 })),
    );
  } else {
    set(queryFilters, 'categories.values', []);
  }

  if (filters?.tags?.clientId && !isEmpty(filters?.tags?.values)) {
    set(queryFilters, 'tags', filters.tags);
  } else {
    unset(queryFilters, 'tags');
  }
  if (!isEmpty(filters?.campaigns?.ids)) {
    set(queryFilters, 'campaigns', filters.campaigns);
  } else {
    unset(queryFilters, 'campaigns');
  }

  if (filters.name) {
    set(queryFilters, 'name', filters.name);
  } else {
    unset(queryFilters, 'name');
  }

  if (filters.reach) {
    set(queryFilters, 'reach', filters.reach);
  } else {
    unset(queryFilters, 'reach');
  }

  if (filters.salutation) {
    set(queryFilters, 'user.salutations', [filters.salutation]);
  } else {
    unset(queryFilters, 'user.salutations');
  }

  if (filters.collaborator === 'on') {
    set(queryFilters, 'collaborator', true);
  } else {
    unset(queryFilters, 'collaborator');
  }

  return queryFilters;
};

const parseFilters = (filtersString) => {
  if (filtersString) {
    const parsedFilters = JSON.parse(filtersString);

    if (!isEmpty(parsedFilters.countries)) {
      const sanitizedCountries = intersection(
        supportedFilterCountries,
        parsedFilters.countries.map(lowerCase),
      );
      set(parsedFilters, 'countries', sanitizedCountries);
    }

    return parsedFilters;
  }

  return defaultState.filters;
};

export const getQuerySortPage = ({ filters, sort, currentPage, platform }) => ({
  query: filtersToQuery(filters, platform),
  sort: sortToQuery(sort),
  page: currentPage,
});

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_DATA: {
      const { loading, data } = action.payload;
      const channels = get(data, 'channelSearch.data', []);
      const count = get(
        data,
        'channelSearch.meta.counters.channels.filtered',
        0,
      );
      const reach = sumWithRounding(channels, 'reach');

      const inspectedChannelId = get(state, 'inspectedChannel.id');
      const inspectedChannel =
        channels.find((channel) => channel.id === inspectedChannelId) || null;

      return {
        ...state,
        loading,
        inspectedChannel,
        channels,
        meta: {
          count,
          reach,
        },
      };
    }

    case actionTypes.INSPECT: {
      const { channels } = state;
      const id = action.payload;

      if (state.inspectedChannel && id === state.inspectedChannel.id) {
        return state;
      }

      if (!id) {
        return {
          ...state,
          inspectedChannel: null,
        };
      }

      const channel = channels.find((channel) => channel.id === id);

      if (!channel) {
        return {
          ...state,
          inspectedChannel: null,
        };
      }

      return {
        ...state,
        inspectedChannel: channel,
      };
    }

    case actionTypes.FILTER_BY: {
      return {
        ...state,
        filters: mergeFilterIntoState(state.filters, action.payload),
      };
    }

    case actionTypes.SORT_BY: {
      const { by, direction } = action.payload;

      if (!direction) {
        return {
          ...state,
          sort: { ...defaultState.sort },
        };
      }

      return {
        ...state,
        sort: {
          by,
          direction,
        },
      };
    }

    case actionTypes.SET_PAGE: {
      const newPage = action.payload;

      if (newPage === state.currentPage) {
        return state;
      }

      return {
        ...state,
        currentPage: newPage,
      };
    }

    case actionTypes.SET_PLATFORM: {
      return {
        ...state,
        platform: action.payload,
        filters: action.defaultFilters || { ...defaultState.filters },
      };
    }

    default:
      throw new Error(`Action "${action.type}" is not defined`);
  }
};

const FiltersParam = {
  encode: JsonParam.encode,
  decode: parseFilters,
};

export default () => {
  const [query, setQuery] = useQueryParams({
    filters: FiltersParam,
    inspect: StringParam,
    inspectChannel: StringParam,
    platform: withDefault(PlatformParam, defaultState.platform),
    page: withDefault(NumberParam, defaultState.currentPage),
    sort: withDefault(SortParam, defaultState.sort),
  });
  const { isFeatureAvailable } = useFeatures();

  const collaboratorDefaultFilters = isFeatureAvailable('collaboratorFilter')
    ? {
        collaborator: query.filters.collaborator || 'on',
        reach: { min: 0 },
      }
    : {};

  const [state, dispatch] = React.useReducer(reducer, {
    ...defaultState,
    channels: [],
    meta: { count: 0, reach: 0 },
    loading: true,
    filters: {
      ...query.filters,
      ...collaboratorDefaultFilters,
    },
    sort: query.sort,
    currentPage: query.page,
    platform: query.platform,
  });

  const searchParams = getQuerySortPage(state);

  const { loading, error, data } = useChannelsSearch({
    variables: searchParams,
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    throw new Error(
      `Failed to fetch channels search: ${JSON.stringify(error)}`,
    );
  }

  React.useEffect(() => {
    if (!isEmpty(state.filters)) {
      if (!isFeatureAvailable('topCountriesFilter')) {
        delete state.filters.topCountries;
      }

      if (!isFeatureAvailable('audienceGendersFilter')) {
        delete state.filters.audienceGenders;
      }

      if (!isFeatureAvailable('influencerFinderTags')) {
        delete state.filters.influencerFinderTags;
      }

      if (!isFeatureAvailable('collaboratorFilter')) {
        delete state.filters.collaborator;
      }
    }
  }, [state.filters]);

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_DATA,
      payload: {
        loading,
        data,
      },
    });
  }, [loading, data]);

  const inspectChannel = React.useCallback((id) => {
    dispatch({
      type: actionTypes.INSPECT,
      payload: id,
    });
    setQuery({ inspect: id || undefined });
  }, []);

  React.useEffect(() => {
    if (!state.loading && query.inspect !== state?.inspectedChannel?.id) {
      dispatch({
        type: actionTypes.INSPECT,
        payload: query.inspect,
      });
    }
  }, [state.loading, query.inspect, state?.inspectedChannel]);

  // Filters
  const filterBy = React.useCallback((newFilter) => {
    dispatch({
      type: actionTypes.FILTER_BY,
      payload: newFilter,
    });
    dispatch({
      type: actionTypes.SET_PAGE,
      payload: 1,
    });
    setQuery({ page: 1 });
  }, []);

  React.useEffect(() => {
    setQuery({ filters: state.filters }, 'replaceIn');
  }, [state.filters]);

  // Sort
  const sortBy = React.useCallback((newSort) => {
    dispatch({
      type: actionTypes.SORT_BY,
      payload: newSort,
    });
    setQuery({ sort: newSort });
  }, []);

  React.useEffect(() => {
    if (!isEqual(query.sort, state.sort)) {
      dispatch({
        type: actionTypes.SORT_BY,
        payload: query.sort,
      });
    }
  }, [query.sort, state.sort]);

  // Pagination
  const setPage = React.useCallback((newPage) => {
    dispatch({
      type: actionTypes.SET_PAGE,
      payload: newPage,
    });
    setQuery({ page: newPage });
  }, []);

  React.useEffect(() => {
    if (query.page !== state.currentPage) {
      dispatch({
        type: actionTypes.SET_PAGE,
        payload: query.page,
      });
    }
  }, [query.page, state.currentPage]);

  // Platform
  const setPlatform = React.useCallback(
    (newPlatform) => {
      dispatch({
        type: actionTypes.SET_PLATFORM,
        payload: newPlatform,
        defaultFilters: {
          ...defaultState.filters,
          ...collaboratorDefaultFilters,
        },
      });
      setQuery({ platform: newPlatform });
    },
    [collaboratorDefaultFilters, defaultState.filters],
  );

  React.useEffect(() => {
    if (query.platform !== state.platform) {
      dispatch({
        type: actionTypes.SET_PLATFORM,
        payload: query.platform,
        defaultFilters: {
          ...defaultState.filters,
          ...collaboratorDefaultFilters,
        },
      });
    }
  }, [
    query.platform,
    state.platform,
    defaultState.filters,
    collaboratorDefaultFilters,
  ]);

  const actions = React.useMemo(
    () => ({
      inspectChannel,
      filterBy,
      sortBy,
      setPage,
      setPlatform,
    }),
    [inspectChannel, filterBy, sortBy, setPage, setPlatform],
  );

  return {
    state,
    actions,
  };
};

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Markdown from 'components/Markdown';

const useStyles = makeStyles((theme) => ({
  markdownHeading: {
    'h1&': {
      fontSize: `calc(${theme.typography.body1.fontSize} * 1.45)`,
      lineHeight: `calc(${theme.typography.body1.lineHeight} * 1.45)`,
    },
    'h2&': {
      fontSize: `calc(${theme.typography.body1.fontSize} * 1.3)`,
      lineHeight: `calc(${theme.typography.body1.lineHeight} * 1.3)`,
    },
    'h3&': {
      fontSize: `calc(${theme.typography.body1.fontSize} * 1.15)`,
      lineHeight: `calc(${theme.typography.body1.lineHeight} * 1.15)`,
    },
    'h4&': {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
  },
}));

const PreviewMarkdown = ({ source, renderers: renderersProp }) => {
  const classes = useStyles();

  const renderers = React.useMemo(
    () => ({
      // eslint-disable-next-line react/prop-types
      heading: ({ level, ...otherProps }) => (
        <Typography
          variant={`h${level}`}
          className={classes.markdownHeading}
          {...otherProps}
        />
      ),
      thematicBreak: () => <Divider />,
      ...renderersProp,
    }),
    [classes, renderersProp],
  );

  return <Markdown source={source} renderers={renderers} />;
};

PreviewMarkdown.propTypes = {
  renderers: PropTypes.object,
  source: PropTypes.string,
};

PreviewMarkdown.defaultProps = {
  renderers: {},
  source: '',
};

export default PreviewMarkdown;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isNumber from 'lodash/isNumber';
import { Video, Image, Transformation } from 'cloudinary-react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiCardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import PlayVideoIcon from 'icons/PlayVideo';

import config from 'config';

const useSkeletonStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

const MediaSkeleton = () => {
  const classes = useSkeletonStyles();
  return (
    <Paper square elevation={0} className={classes.root}>
      <Skeleton variant="rect" animation="wave" height="100%" />
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: (props) => (props.clickable ? 'zoom-in' : 'default'),
    '&:focus': {
      outline: 'none',
    },
    objectFit: ({ type, assetId, playable }) => {
      if (type === 'video') {
        return playable ? 'contain' : 'cover';
      }

      if (type === 'image' && assetId) {
        return 'cover';
      }

      return false;
    },
    height: (props) =>
      isNumber(props.height)
        ? theme.typography.pxToRem(props.height)
        : props.height,
  },
  playVideoIconBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    '& > svg': {
      width: theme.typography.pxToRem(56),
      height: theme.typography.pxToRem(72),
    },
  },
}));

const CardMedia = (props) => {
  const {
    type,
    src,
    assetId,
    clickable,
    playable,
    height,
    className,
    ...otherProps
  } = props;

  const classes = useStyles(props);
  const [loaded, setLoaded] = React.useState(false);
  const setMediaLoaded = () => setLoaded(true);

  const combinedClassName = clsx(classes.root, className);

  if (type === 'image') {
    return (
      <Box position="relative" height="100%">
        {!assetId && (
          <MuiCardMedia
            image={src}
            component="img"
            className={combinedClassName}
            onLoad={setMediaLoaded}
            {...otherProps}
          />
        )}
        {assetId && (
          <MuiCardMedia
            secure
            component={Image}
            className={combinedClassName}
            onLoad={setMediaLoaded}
            cloudName={config.cloudinary.cloudName}
            publicId={assetId}
            width="100%"
            {...otherProps}
          >
            <Transformation transformation={['influencer-asset']} />
          </MuiCardMedia>
        )}
        {!loaded && <MediaSkeleton className={classes.skeleton} />}
      </Box>
    );
  }

  const videoMedia = (
    <Box position="relative" height="100%">
      {!assetId && (
        <MuiCardMedia
          component="video"
          src={src}
          controls={playable}
          controlsList="nodownload"
          className={combinedClassName}
          onLoadedData={setMediaLoaded}
          {...otherProps}
        />
      )}
      {assetId && (
        <MuiCardMedia
          secure
          component={Video}
          controls={playable}
          controlsList="nodownload"
          className={combinedClassName}
          onLoadedData={setMediaLoaded}
          cloudName={config.cloudinary.cloudName}
          publicId={assetId}
          format="mp4"
          {...otherProps}
        >
          <Transformation fetchFormat="auto" />
        </MuiCardMedia>
      )}
      {!loaded && <MediaSkeleton className={classes.skeleton} />}
    </Box>
  );

  // Might seem a bit counter-intuitive, but if it's playable
  // the video controls will show and we won't need the
  // play icon, the icon is only for non-playable media to
  // show that it is a video and not a picture
  if (!playable) {
    return (
      <Box position="relative">
        <Box className={classes.playVideoIconBox}>
          <PlayVideoIcon />
        </Box>
        {videoMedia}
      </Box>
    );
  }

  return videoMedia;
};

CardMedia.propTypes = {
  type: PropTypes.oneOf(['image', 'video']).isRequired,
  src: PropTypes.string.isRequired,
  assetId: PropTypes.string,
  playable: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  clickable: PropTypes.bool,
  className: PropTypes.string,
};

CardMedia.defaultProps = {
  playable: false,
  clickable: true,
  height: 230,
  className: '',
};

export default CardMedia;

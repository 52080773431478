import PropTypes from 'prop-types';

import { UserPropType } from 'utils/user';
import { ChannelPropType } from 'utils/channel';

export const PrivateNetworkApplicationPropType = PropTypes.shape({
  id: PropTypes.string,
  pitch: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  user: UserPropType,
  channels: PropTypes.arrayOf(ChannelPropType),
});

export const PrivateNetworkPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  clientId: PropTypes.string,
  applications: PropTypes.arrayOf(PrivateNetworkApplicationPropType),
});

import React from 'react';

import { ApplicationPropType } from 'utils/application';
import { LocationLabel } from 'utils/user';
import { removeQueryStringFromUrl } from 'utils/formatting';

import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellNumber from 'components/table/TableCellNumber';
import TableCellChannelAvatar from 'components/table/TableCellChannelAvatar';
import TableCellLink from 'components/table/TableCellLink';
import TableCellDate from 'components/table/TableCellDate';

const PublishedTiktokApplicationsTableRow = ({
  application,
  ...otherProps
}) => {
  const { channel, user, contentPublication, publishedAt, reach } = application;

  return (
    <TableRow {...otherProps}>
      <TableCellChannelAvatar channel={channel} user={user} />
      <TableCellText variant="subtitle2">{channel.name}</TableCellText>
      <TableCellText>
        <LocationLabel user={user} />
      </TableCellText>
      <TableCellNumber value={reach} roundingDigits={2} />
      <TableCellDate value={publishedAt} align="right" />
      <TableCellLink
        platform={channel.platform}
        url={removeQueryStringFromUrl(contentPublication?.url)}
      />
    </TableRow>
  );
};

PublishedTiktokApplicationsTableRow.propTypes = {
  application: ApplicationPropType.isRequired,
};

PublishedTiktokApplicationsTableRow.defaultProps = {};

export default PublishedTiktokApplicationsTableRow;

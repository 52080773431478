import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  italic: {
    fontStyle: 'italic',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

const Markdown = ({
  source,
  renderers: renderersProp,
  plugins,
  smallFont,
  className,
}) => {
  const classes = useStyles();

  const bodyVariant = smallFont ? 'body2' : 'body1';
  const subtitleVariant = smallFont ? 'subtitle2' : 'subtitle1';

  const renderers = React.useMemo(
    () => ({
      /* eslint-disable react/prop-types */
      heading: ({ level, children }) => (
        <Typography variant={`h${level}`}>{children}</Typography>
      ),
      paragraph: ({ children }) => (
        <Typography variant={bodyVariant} paragraph>
          {children}
        </Typography>
      ),
      link: ({ href, title, children }) => (
        <Box display="inline">
          <a href={href} title={title} target="_blank" rel="noreferrer">
            {children}
          </a>
        </Box>
      ),
      linkReference: ({ href, title, children }) => (
        <Box display="inline">
          <a href={href} title={title} target="_blank" rel="noreferrer">
            {children}
          </a>
        </Box>
      ),
      emphasis: ({ children }) => (
        <Typography
          className={classes.italic}
          variant={bodyVariant}
          component="span"
        >
          {children}
        </Typography>
      ),
      strong: ({ children }) => (
        <Typography variant={subtitleVariant} component="span">
          {children}
        </Typography>
      ),
      thematicBreak: () => <Divider />,
      listItem: ({ children }) => (
        <Typography component="li" variant={bodyVariant}>
          {children}
        </Typography>
      ),
      image: ({ src, alt, title }) => (
        <img src={src} alt={alt} title={title} className={classes.image} />
      ),
      imageReference: ({ src, alt, title }) => (
        <img src={src} alt={alt} title={title} className={classes.image} />
      ),
      ...renderersProp,
      /* eslint-enable react/prop-types */
    }),
    [classes, renderersProp],
  );

  return (
    <Box className={clsx(classes.root, className)}>
      <ReactMarkdown
        source={source}
        renderers={renderers}
        plugins={[gfm, breaks, ...plugins]}
      />
    </Box>
  );
};

Markdown.propTypes = {
  plugins: PropTypes.arrayOf(PropTypes.func),
  renderers: PropTypes.object,
  source: PropTypes.string,
  smallFont: PropTypes.bool,
  className: PropTypes.string,
};

Markdown.defaultProps = {
  plugins: [],
  renderers: {},
  source: '',
  smallFont: false,
  className: '',
};

export default Markdown;

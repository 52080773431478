import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import { SortObjectPropType } from 'utils/sorting';
import { AlignPropType } from 'utils/table';

const useStyles = makeStyles((theme) => ({
  typo: {
    fontWeight: 'bold',
    color: (props) =>
      props.sortProperty
        ? theme.palette.primary.main
        : theme.palette.text.disabled,
  },
  tooltipPopper: {
    top: '10px !important',
  },
  contentWithTooltip: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    cursor: (props) => (props.sortProperty ? 'pointer' : 'default'),
    '& > svg': {
      minWidth: 12,
      minHeight: 12,
      marginLeft: 4,
      marginBottom: 4,
      color: theme.palette.primary.light,
    },
    borderBottom: `1px dotted ${theme.palette.primary.light}`,
    '&:hover': {
      '& > svg': {
        color: theme.palette.primary.main,
      },
      borderBottom: `1px dotted ${theme.palette.primary.main}`,
    },
  },
}));

const TableCellHead = (props) => {
  const {
    children,
    onSort,
    currentSort,
    sortProperty,
    noWrap,
    align,
    tooltip,
    ...otherProps
  } = props;
  const classes = useStyles(props);

  const isActive = currentSort && currentSort.by === sortProperty;

  const handleSort = () => {
    let newDirection;

    if (currentSort.direction === 'asc') {
      newDirection = 'desc';
    } else {
      newDirection = 'asc';
    }

    onSort({ by: sortProperty, direction: newDirection });
  };

  const label = (
    <Typography noWrap={noWrap} className={classes.typo} variant="subtitle2">
      {tooltip ? (
        <Tooltip
          title={tooltip}
          placement="top"
          classes={{ popper: classes.tooltipPopper }}
        >
          <Box className={classes.contentWithTooltip}>
            {children}
            {<Icons.HelpCircle size={12} />}
          </Box>
        </Tooltip>
      ) : (
        children
      )}
    </Typography>
  );

  const content = sortProperty ? (
    <TableSortLabel
      active={isActive}
      direction={currentSort && currentSort.direction}
      onClick={handleSort}
    >
      {label}
    </TableSortLabel>
  ) : (
    label
  );

  return (
    <TableCell
      align={align}
      sortDirection={currentSort && currentSort.direction}
      {...otherProps}
    >
      {content}
    </TableCell>
  );
};

TableCellHead.propTypes = {
  children: PropTypes.node.isRequired,
  noWrap: PropTypes.bool,
  align: AlignPropType,
  sortProperty: PropTypes.string,
  currentSort: SortObjectPropType,
  tooltip: PropTypes.node,
  onSort: PropTypes.func,
};

TableCellHead.defaultProps = {
  noWrap: false,
  align: 'left',
  sortProperty: '',
  currentSort: null,
  onSort: null,
};

export default TableCellHead;

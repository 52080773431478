import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useQueryParams, SortParam, withDefault } from 'utils/routing';
import { views as campaignViews } from 'utils/campaign';
import PublishedApplicationsTable from 'components/applications/table/published/PublishedApplicationsTable';
import PublishedApplicationsTableMetaLabel from 'components/applications/table/published/PublishedApplicationsTableMetaLabel';

import CampaignDetailsPageContainer from '../CampaignDetailsPageContainer';
import { useOne as useCampaign } from '../../data/campaignHooks';

const PublishedApplicationsPage = ({ campaignId }) => {
  const [query, setQuery] = useQueryParams({
    sort: withDefault(SortParam, { by: 'publishedAt', direction: 'desc' }),
  });

  const { data, loading, error } = useCampaign({
    id: campaignId,
    applicationsFilter: 'byPublishedContent',
  });

  if (error) {
    throw new Error(`Error fetching campaign: ${error}`);
  }

  const campaign = data?.campaign || { applications: [] };

  const [displayApplications, setDisplayApplications] = React.useState(
    campaign.applications,
  );

  const [currentSort, setCurrentSort] = React.useState(() => query.sort);

  React.useEffect(() => {
    setDisplayApplications(campaign.applications);
  }, [campaign, loading]);

  const prevSort = React.useRef(null);

  React.useEffect(() => {
    if (
      isEqual(prevSort.current, currentSort) ||
      isEmpty(campaign.applications)
    ) {
      return;
    }

    prevSort.current = currentSort;

    const sortedApplications = sortBy(campaign.applications, currentSort.by);

    setDisplayApplications(
      currentSort.direction === 'desc'
        ? sortedApplications.reverse()
        : sortedApplications,
    );

    setQuery({ sort: currentSort });
  }, [campaign.applications, currentSort]);

  return (
    <CampaignDetailsPageContainer
      view={campaignViews.LIVE}
      campaign={campaign}
      loading={loading}
    >
      <PublishedApplicationsTable
        loading={loading}
        platform={campaign.platform}
        applications={displayApplications}
        currentSort={currentSort}
        onSort={setCurrentSort}
        headerLabel={
          campaign.platform && (
            <PublishedApplicationsTableMetaLabel
              applications={displayApplications}
              platform={campaign.platform}
            />
          )
        }
      />
      <Box display="flex" flexDirection="column" justifyContent="center" mt={3}>
        <Typography variant="body1" align="center" component="div">
          <FormattedMessage
            id="scenes.campaigns.details.publishedPublicationsView.message"
            defaultMessage="The Influencer-created content that is now Live, is listed and linked here."
          />
          {campaign.platform === 'instagram' && (
            <Box mt={2}>
              <FormattedMessage
                id="scenes.campaigns.details.publishedPublicationsView.message.instagram"
                defaultMessage="Please note: Instagram stories don’t have a public URL and can only be viewed by the followers on Instagram."
              />
            </Box>
          )}
        </Typography>
      </Box>
    </CampaignDetailsPageContainer>
  );
};

PublishedApplicationsPage.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
PublishedApplicationsPage.defaultProps = {};

export default PublishedApplicationsPage;

import { defineMessages } from 'react-intl';
import memoize from 'lodash/memoize';

const platformsStrings = defineMessages({
  instagram: {
    id: 'channel.platforms.instagram',
    defaultMessage: 'Instagram',
  },
  pinterest: {
    id: 'channel.platforms.pinterest',
    defaultMessage: 'Pinterest',
  },
  tiktok: {
    id: 'channel.platforms.tiktok',
    defaultMessage: 'TikTok',
  },
  youtube: {
    id: 'channel.platforms.youtube',
    defaultMessage: 'YouTube',
  },
  ga: {
    id: 'channel.platforms.blog',
    defaultMessage: 'Blog',
  },
});

const platformsMap = {
  instagram: {
    title: platformsStrings.instagram,
    value: 'instagram',
  },
  pinterest: {
    title: platformsStrings.pinterest,
    value: 'pinterest',
  },
  tiktok: {
    title: platformsStrings.tiktok,
    value: 'tiktok',
  },
  youtube: {
    title: platformsStrings.youtube,
    value: 'youtube',
  },
  ga: {
    title: platformsStrings.ga,
    value: 'ga',
  },
};

export const getChannelPlatformsFilter = memoize(
  (filterId) => platformsMap[filterId],
);

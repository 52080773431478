import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Feature } from 'utils/features';
import { useClient } from 'utils/client';
import { getStatusLabel as getClienReviewStatusLabel } from 'utils/clientReviewStatuses';

import GenderFilter from 'components/filters/GenderFilter';
import CountriesFilter from 'components/filters/CountriesFilter';
import SearchFilter from 'components/filters/SearchFilter';
import ClientChannelTagsFilter from 'components/filters/ClientChannelTagsFilter';
import CollaborationRatingsFilter from 'components/filters/CollaborationRatingsFilter';
import ChannelPlatformsFilter from 'components/filters/ChannelPlatformsFilter';
import TagsFilter from 'components/filters/TagsFilter';
import FiltersContainer, {
  useStyles as useFilterStyles,
} from 'components/filters/FiltersContainer';

import { drawerWidth as channelInspectorDrawerWidth } from './PrivateNetworkApplicationClientInspector';

const PrivateNetworkClientFilters = ({
  filters,
  onFilter,
  supportedCountries,
  isInspected,
}) => {
  const filterClasses = useFilterStyles();
  const intl = useIntl();
  const { client, tags: clientTags } = useClient();

  const createFilterBy = (key) => (value) => {
    onFilter({
      key,
      value,
    });
  };

  return (
    <FiltersContainer
      isInspected={isInspected}
      inspectorWidth={channelInspectorDrawerWidth}
    >
      <TagsFilter
        getFilterLabel={getClienReviewStatusLabel}
        options={['pending', 'accepted', 'rejected', 'undecided']}
        label={intl.formatMessage({
          id: 'scenes.privateNetwork.client.clientReviewStatusFilter',
          defaultMessage: 'Status',
        })}
        className={filterClasses.filter}
        onChange={createFilterBy('clientReviewStatus')}
        value={filters.clientReviewStatus}
      />
      <SearchFilter
        className={filterClasses.filter}
        onChange={createFilterBy('userName')}
        value={filters.userName}
        label={intl.formatMessage({
          id: 'components.SearchFilter.firstName.label',
          defaultMessage: 'First name',
        })}
      />
      <SearchFilter
        className={filterClasses.filter}
        onChange={createFilterBy('channelName')}
        value={filters.channelName}
        label={intl.formatMessage({
          id: 'components.SearchFilter.channelName.label',
          defaultMessage: '@channelname',
        })}
      />
      <ChannelPlatformsFilter
        className={filterClasses.filter}
        onChange={createFilterBy('platforms')}
        value={filters.platforms}
      />
      <GenderFilter
        className={filterClasses.filter}
        onChange={createFilterBy('salutation')}
        value={filters.salutation}
      />
      <CountriesFilter
        label={intl.formatMessage({
          id: 'scenes.influencers.filter.userCountries',
          defaultMessage: "Influencer's Location",
        })}
        className={filterClasses.filter}
        onChange={createFilterBy('countries')}
        value={filters.countries}
        supportedCountries={supportedCountries}
      />
      {client && (
        <Feature feature="influencerFinderTags">
          <ClientChannelTagsFilter
            className={filterClasses.filter}
            client={client}
            onChange={createFilterBy('tags')}
            value={filters.tags}
            options={clientTags}
          />
        </Feature>
      )}
      <CollaborationRatingsFilter
        className={filterClasses.filter}
        value={filters.collaborationRatings}
        onChange={createFilterBy('collaborationRatings')}
      />
    </FiltersContainer>
  );
};

PrivateNetworkClientFilters.propTypes = {
  filters: PropTypes.shape({
    userName: PropTypes.string,
    channelName: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.string),
    clientReviewStatus: PropTypes.arrayOf(PropTypes.string),
    countries: PropTypes.arrayOf(PropTypes.string),
    salutation: PropTypes.string,
    collaborationRatings: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.shape({
      clientId: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
    collaborator: PropTypes.string,
  }),
  supportedCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilter: PropTypes.func.isRequired,
  isInspected: PropTypes.bool,
};
PrivateNetworkClientFilters.defaultProps = {
  filters: {
    userName: '',
    channelName: '',
    platforms: [],
    clientReviewStatus: [],
    countries: [],
    tags: {},
    collaborationRatings: [],
    collaborator: true,
  },
  isInspected: false,
};

export default PrivateNetworkClientFilters;

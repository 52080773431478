import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';
import { SortObjectPropType } from 'utils/sorting';

const BlogApplicationTableHead = React.memo(
  ({ currentSort, onSort, hideKamNotesColumn, ...otherProps }) => (
    <TableHead {...otherProps}>
      <TableRow>
        <TableCellHead
          align="center"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="clientApplicationReview.status"
        >
          <FormattedMessage
            id="components.ApplicationsTable.clientReviewStatus"
            defaultMessage="Accept/Reject"
          />
        </TableCellHead>
        <TableCell align="left" />
        <TableCellHead>
          <FormattedMessage
            id="components.ChannelsTable.blog.website"
            defaultMessage="Website"
          />
        </TableCellHead>
        {!hideKamNotesColumn && (
          <TableCellHead align="left" noWrap>
            <FormattedMessage
              id="components.ChannelsTable.kamNote"
              defaultMessage="AM's Note"
            />
          </TableCellHead>
        )}
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.gender.tooltip"
              defaultMessage="As provided by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.gender"
            defaultMessage="Gender"
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.location.tooltip"
              defaultMessage="As provided by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.location"
            defaultMessage="Location"
          />
        </TableCellHead>
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="reach"
        >
          <FormattedMessage
            id="components.ChannelsTable.blog.pageviews"
            defaultMessage="Page views"
          />
        </TableCellHead>
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="advertiserPrice"
        >
          <FormattedMessage
            id="components.ChannelsTable.price"
            defaultMessage="Price"
          />
        </TableCellHead>
        <TableCellHead
          noWrap
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.topCountry.tooltip"
              defaultMessage="Location of the Influencers Follower"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.topCountry"
            defaultMessage="Top Country"
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.categories.tooltip"
              defaultMessage="As defined by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.categories"
            defaultMessage="Categories"
          />
        </TableCellHead>
      </TableRow>
    </TableHead>
  ),
);

BlogApplicationTableHead.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  hideKamNotesColumn: PropTypes.bool,
};

BlogApplicationTableHead.defaultProps = {
  hideKamNotesColumn: false,
};

export default BlogApplicationTableHead;

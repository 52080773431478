import React from 'react';
import { useIntl } from 'react-intl';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';

import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import GenderIcon from 'icons/Gender';
import CountryLabel from 'components/CountryLabel';
import { formatNumber as numberFormatter } from 'utils/formatting';
import { ChannelPropType } from 'utils/channel';
import { LocationLabel } from 'utils/user';
import { useCategories } from 'utils/categories';
import { getChannelMetrics } from 'utils/kpi';
import { Feature } from 'utils/features';

import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellNumber from 'components/table/TableCellNumber';
import TableCellChannelAvatar from 'components/table/TableCellChannelAvatar';
import TableCellChannel from 'components/table/TableCellChannel';
import TableCellTags from 'components/table/TableCellTags';

const InstagramTableRow = ({ channel, ...otherProps }) => {
  const { categories, user, tags } = channel;

  const metrics = getChannelMetrics(channel);

  const intl = useIntl();

  const formatPercentage = React.useCallback(
    (number) =>
      numberFormatter({
        formatter: intl.formatNumber,
        number,
        format: 'percent',
      }),
    [intl],
  );

  const topCountryCode = !isEmpty(metrics.topCountry)
    ? keys(metrics.topCountry)[0]
    : null;

  const { getFullCategories } = useCategories();

  const fullCategories = React.useMemo(
    () => getFullCategories(categories),
    [categories, getFullCategories],
  );

  return (
    <TableRow {...otherProps}>
      <TableCellChannelAvatar channel={channel} user={channel.user} />
      <TableCellChannel channel={channel} />
      <TableCell align="center" sortDirection={false}>
        <GenderIcon gender={user.salutation} />
      </TableCell>
      <TableCellText>
        <LocationLabel user={user} />
      </TableCellText>
      <TableCellNumber value={metrics.reach} roundingDigits={2} />
      <TableCellNumber value={metrics.engagementRate} format="percent" />
      <TableCellNumber value={metrics.storyImpressionRatio} format="percent" />
      <TableCellNumber value={metrics.postImpressionRatio} format="percent" />
      <Feature feature="audienceGendersColumn">
        <TableCellNumber value={metrics.femaleAudience} format="percent" />
      </Feature>

      <TableCell align="left">
        {topCountryCode ? (
          <CountryLabel countryCode={topCountryCode}>
            <Typography noWrap>
              {formatPercentage(metrics.topCountry[topCountryCode])}
            </Typography>
          </CountryLabel>
        ) : (
          <Typography>—</Typography>
        )}
      </TableCell>
      <TableCellTags tags={tags} variant="default" />
      <TableCellTags
        tags={fullCategories}
        getTagValue={(tag) => tag.code}
        getTagLabel={(tag) => tag.label}
        sortBy="weight"
      />
    </TableRow>
  );
};

InstagramTableRow.propTypes = {
  channel: ChannelPropType.isRequired,
};

InstagramTableRow.defaultProps = {};

export default InstagramTableRow;

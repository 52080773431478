import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { makeStyles } from '@material-ui/core/styles';

import InfluencerAssetInspectorContent from 'components/influencerAssetInspector/InfluencerAssetInspectorContent';
import InspectorDrawer from 'components/inspector/InspectorDrawer';

import { useUpdateInfluencerAsset } from './data/influencerAssetHooks';
import { useTags } from './data/tags';

export const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  form: {
    backgroundColor: theme.palette.background.default,
  },
}));

const ContentInspector = React.memo(
  ({ influencerAsset, actions, dialogsOpen, ...otherProps }) => {
    const classes = useStyles();
    const { updateInfluencerAsset: updateContent, loading } =
      useUpdateInfluencerAsset();
    const { tags, getTagLabelByValue } = useTags();

    const updateInfluencerAsset = (data) => {
      updateContent({
        id: influencerAsset.id,
        payload: { tags: data.tags },
      }).then(() => {
        actions.inspectCard(influencerAsset.id);
      });
    };

    return (
      <InspectorDrawer
        isOpen={!!influencerAsset}
        width={drawerWidth}
        {...otherProps}
      >
        {!isEmpty(influencerAsset) && (
          <InfluencerAssetInspectorContent
            influencerAsset={influencerAsset}
            tagsOptions={tags}
            getTagOptionLabel={getTagLabelByValue}
            actions={{ ...actions, updateInfluencerAsset }}
            dialogsOpen={dialogsOpen}
            className={classes.form}
            loading={loading}
          />
        )}
      </InspectorDrawer>
    );
  },
);

ContentInspector.propTypes = {
  influencerAsset: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    openDialog: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    addPublication: PropTypes.func.isRequired,
    deletePublication: PropTypes.func.isRequired,
    inspectCard: PropTypes.func.isRequired,
  }).isRequired,
  dialogsOpen: PropTypes.shape({
    addPublication: PropTypes.bool,
    deletePublication: PropTypes.bool,
  }).isRequired,
};
ContentInspector.defaultProps = {
  influencerAsset: null,
};

export default ContentInspector;

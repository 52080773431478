import countries from 'i18n-iso-countries';
import countryCodeLookup from 'country-code-lookup';

export const getCountryCodeByName = (countryName) => {
  let countryCode = countries.getAlpha2Code(countryName, 'en');

  if (!countryCode) {
    countryCode = countryCodeLookup.byCountry(countryName)?.iso2;
  }

  return countryCode;
};

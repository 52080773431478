import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 6,
    zIndex: 1,
  },
});

const PersistentDrawer = ({
  children,
  className,
  isOpen,
  onClose,
  classes: drawerClasses,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isOpen}
      classes={drawerClasses}
      className={className}
      {...otherProps}
    >
      <Box display="flex" position="relative" height="100%">
        <IconButton onClick={onClose} className={classes.closeButton}>
          <Icons.XCircle />
        </IconButton>
        {children}
      </Box>
    </Drawer>
  );
};

PersistentDrawer.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

PersistentDrawer.defaultProps = {
  className: '',
  classes: {},
  isOpen: false,
};

export default PersistentDrawer;

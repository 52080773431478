import React from 'react';

import get from 'lodash/get';
import max from 'lodash/max';
import head from 'lodash/head';
import find from 'lodash/find';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';

import {
  useQueryParams,
  StringParam,
  SortParam,
  withDefault,
} from 'utils/routing';

import { REVIEW_STATUS as KAM_REVIEW_STATUS } from 'utils/kamReviewStatuses';
import { getChannelTopCountry } from 'utils/kpi';
import { getAvgRating as getAvgChannelRating } from 'utils/channel';

const defaultSort = {
  by: 'reach',
  direction: 'desc',
};

const reachSorter = (direction) => (applications) =>
  sortBy(applications, (application) => {
    const highestReachChannel = maxBy(application.channels, 'reach');

    if (direction === 'asc') {
      return highestReachChannel.reach;
    }

    return -highestReachChannel.reach;
  });

const topCountrySorter = (direction) => (applications) =>
  sortBy(applications, (application) => {
    const highestTopCountryValue = max(
      application.channels.map(
        (channel) => head(values(getChannelTopCountry(channel))) || 0,
      ),
    );

    if (direction === 'asc') {
      return highestTopCountryValue;
    }

    return -highestTopCountryValue;
  });

const ratingSorter = (direction) => (applications) =>
  sortBy(applications, (application) => {
    const highestRatingValue = max(
      application.channels.map(getAvgChannelRating),
    );

    if (direction === 'asc') {
      return highestRatingValue;
    }

    return -highestRatingValue;
  });

const clientStatusValues = {
  new: 4,
  undecided: 3,
  approved: 2,
  rejected: 1,
};
const kamStatusValues = {
  [KAM_REVIEW_STATUS.NONE]: 8,
  [null]: 8,
  [KAM_REVIEW_STATUS.NEW]: 7,
  [KAM_REVIEW_STATUS.REVIEW]: 6,
  [KAM_REVIEW_STATUS.SENT_TO_CLIENT]: 5,
  [KAM_REVIEW_STATUS.APPROVED]: 4,
  [KAM_REVIEW_STATUS.REJECTED]: 3,
  [KAM_REVIEW_STATUS.BANNED]: 2,
  [KAM_REVIEW_STATUS.NOT_INTERESTED]: 1,
};

const statusSorter = (direction, reviewKey, statusValues) => (applications) =>
  sortBy(applications, (application) => {
    const value = get(statusValues, application[reviewKey].status, 0);

    return direction === 'asc' ? value : -value;
  });

const sortersByProperty = {
  reach: {
    desc: reachSorter('desc'),
    asc: reachSorter('asc'),
  },
  topCountry: {
    desc: topCountrySorter('desc'),
    asc: topCountrySorter('asc'),
  },
  rating: {
    desc: ratingSorter('desc'),
    asc: ratingSorter('asc'),
  },
  clientReviewStatus: {
    desc: statusSorter('desc', 'clientReview', clientStatusValues),
    asc: statusSorter('asc', 'clientReview', clientStatusValues),
  },
  kamReviewStatus: {
    desc: statusSorter('desc', 'kamReview', kamStatusValues),
    asc: statusSorter('asc', 'kamReview', kamStatusValues),
  },
};

export default (privateNetwork = {}) => {
  const [query, setQuery] = useQueryParams({
    application: StringParam,
    sort: withDefault(SortParam, defaultSort),
  });

  const [currentSort, setSort] = React.useState(query.sort || defaultSort);
  const [expandedRows, setExpandedRows] = React.useState({});
  const [selectedApplicationId, setSelectedApplicationId] = React.useState(
    query?.application,
  );

  React.useEffect(() => {
    setQuery({ application: selectedApplicationId });
  }, [selectedApplicationId]);

  const sortBy = React.useCallback((newSort) => {
    setSort((prevSort) => {
      if (!isEqual(prevSort, newSort)) {
        return newSort;
      }
      return prevSort;
    });
  }, []);

  React.useEffect(() => {
    setQuery({ sort: currentSort });
  }, [currentSort.by, currentSort.direction]);

  const displayApplications = React.useMemo(() => {
    const sortFn = sortersByProperty[currentSort.by][currentSort.direction];

    return sortFn(privateNetwork?.applications);
  }, [privateNetwork?.applications, currentSort.by, currentSort.direction]);

  const firstApplication = head(displayApplications);

  React.useEffect(() => {
    setExpandedRows({});
    sortBy(defaultSort);
  }, [privateNetwork.id]);

  React.useEffect(() => {
    if (firstApplication) {
      setExpandedRows({
        [firstApplication.id]: true,
      });
    }
  }, [firstApplication]);

  const selectApplicationRow = React.useCallback((applicationId) => {
    setSelectedApplicationId(applicationId);
  }, []);

  const expandApplicationRow = React.useCallback((applicationId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [applicationId]: isUndefined(prevExpandedRows[applicationId])
        ? true
        : !prevExpandedRows[applicationId],
    }));
  }, []);

  const selectedApplication = React.useMemo(
    () => find(privateNetwork?.applications, { id: selectedApplicationId }),
    [privateNetwork?.applications, selectedApplicationId],
  );
  const isExpanded = (applicationId) => !!expandedRows[applicationId];
  const isSelected = (applicationId) => selectedApplicationId === applicationId;

  return {
    currentSort,
    sortBy,
    displayApplications,
    selectApplicationRow,
    expandApplicationRow,
    isExpanded,
    isSelected,
    selectedApplication,
    selectApplication: (applicationId) => {
      setSelectedApplicationId(applicationId);
    },
    unselectApplication: () => {
      setSelectedApplicationId(null);
    },
  };
};

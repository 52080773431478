import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import without from 'lodash/without';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import SearchField from 'components/SearchField';

const useStyles = makeStyles((theme) => ({
  searchField: {
    fontWeight: 'bold',
    '& input': {
      color: theme.palette.primary.main,
    },
  },
  categoriesContainer: {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  categoryChip: {
    fontWeight: 'normal',
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.light,
    '&:focus, &:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    color: theme.palette.primary.main,
  },
  categoryChipSelected: {
    backgroundColor: theme.palette.primary.main,
    '&:focus, &:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: '#fff',
  },
}));

const ArticlesControls = ({
  searchTerm,
  onSearch,
  categories,
  selectedCategories,
  onSelectCategory,
  numberOfResults,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const isSelectedCategory = (category) =>
    selectedCategories.includes(category);

  const toggleCategory = (category) => {
    if (isSelectedCategory(category)) {
      return onSelectCategory(without(selectedCategories, category));
    }

    return onSelectCategory([...selectedCategories, category]);
  };

  return (
    <Box display="flex" flexDirection="column" {...otherProps}>
      <SearchField
        className={classes.searchField}
        fullWidth
        variant="outlined"
        value={searchTerm}
        onChange={onSearch}
        onClear={() => onSearch('')}
        placeholder={intl.formatMessage({
          id: 'scenes.insider.articles.search.placeholder',
          defaultMessage: 'Find Article',
        })}
      />
      <Box ml={1} mt={1}>
        <Box display="flex" alignItems="center" width="100%">
          <Typography variant="subtitle1" color="primary">
            <FormattedMessage
              id="scenes.insider.articles.categories.label"
              defaultMessage="Categories:"
            />
            &nbsp;&nbsp;&nbsp;
          </Typography>
          <Box
            className={classes.categoriesContainer}
            display="flex"
            alignItems="center"
          >
            {categories.map((category) => (
              <Chip
                key={category}
                disableRipple
                className={clsx(classes.categoryChip, {
                  [classes.categoryChipSelected]: isSelectedCategory(category),
                })}
                size="medium"
                label={category}
                onClick={() => toggleCategory(category)}
              />
            ))}
          </Box>
        </Box>
        <Typography variant="body1" color="primary">
          <FormattedMessage
            id="scenes.insider.articles.search.found"
            defaultMessage="{numberOfResults} hits"
            values={{
              numberOfResults,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

ArticlesControls.propTypes = {
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func.isRequired,

  categories: PropTypes.arrayOf(PropTypes.string),
  selectedCategories: PropTypes.arrayOf(PropTypes.string),
  onSelectCategory: PropTypes.func.isRequired,

  numberOfResults: PropTypes.number.isRequired,
};
ArticlesControls.defaultProps = {};

export default ArticlesControls;

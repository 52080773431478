import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export const useStyles = makeStyles((theme) => ({
  filter: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    marginBottom: theme.spacing(3),
    minWidth: theme.typography.pxToRem(200),
  },
  noMinWidth: {
    minWidth: 0,
  },
  reachFilter: {
    marginTop: 2,
    // Visually, the filter goes beyond it's constraints a little bit,
    // so we add some padding to the sides so the spacing is the same as
    // for the other filters, we also increase the minWidth by this so the
    // width stays consistent
    padding: '0 6px',
    minWidth: theme.typography.pxToRem(200 + 6),
    // The point where the slider switches to its own line
    '@media (max-width: 831px)': {
      marginTop: 0,
    },
  },
}));

const FiltersContainer = ({ children, isInspected, inspectorWidth }) => {
  const theme = useTheme();

  const getFiltersContainerWidth = () => {
    const sidebarWidth = `${theme.sidebar.width}px`;
    const spacing = theme.spacing(2);

    if (isInspected) {
      const inspectorDrawerWidth = `${inspectorWidth}px`;

      return `calc(100vw - ${sidebarWidth} - ${spacing} - ${inspectorDrawerWidth})`;
    }

    return `calc(100vw - ${sidebarWidth} - ${spacing})`;
  };

  const [filtersWidth, setFiltersWidth] = React.useState(() =>
    getFiltersContainerWidth(),
  );

  const recalculateFiltersContainerWidth = debounce(() => {
    const newWidth = getFiltersContainerWidth();

    setFiltersWidth(newWidth);
  });

  React.useEffect(() => {
    window.addEventListener('resize', recalculateFiltersContainerWidth);

    return () =>
      window.removeEventListener('resize', recalculateFiltersContainerWidth);
  }, [recalculateFiltersContainerWidth]);

  React.useEffect(recalculateFiltersContainerWidth, [isInspected]);

  return (
    <Box width={filtersWidth} mb={2}>
      <Box
        display="flex"
        width="100%"
        alignItems="flex-start"
        flexWrap="wrap"
        mb={-2}
      >
        {children}
      </Box>
    </Box>
  );
};

FiltersContainer.propTypes = {
  isInspected: PropTypes.bool,
  inspectorWidth: PropTypes.number,
  children: PropTypes.node,
};
FiltersContainer.defaultProps = {
  inspectorWidth: 320,
  isInspected: false,
};

export default FiltersContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Typography from '@material-ui/core/Typography';

import { loadingString } from 'components/LoadingState';

const GridStatusMessage = ({ influencerAssets, loading, ...otherProps }) => {
  const intl = useIntl();

  const formattedMessage = React.useMemo(() => {
    if (loading) {
      return intl.formatMessage(loadingString);
    }

    const numberOfPieces = influencerAssets.length;
    const numberOfImages = influencerAssets.filter(
      (piece) => piece.mediaType === 'image',
    ).length;
    const numberOfVideos = influencerAssets.filter(
      (piece) => piece.mediaType === 'video',
    ).length;

    const allItemsString = intl.formatMessage(
      {
        id: 'scenes.influencerAssets.statusMessage.items',
        defaultMessage:
          '{numberOfItems, plural, =0 {No items} one {# item} other {# items}}',
      },
      { numberOfItems: numberOfPieces },
    );
    const numberOfImagesString = intl.formatMessage(
      {
        id: 'scenes.influencerAssets.statusMessage.images',
        defaultMessage:
          '{numberOfItems, plural, =0 {} one {# image} other {# images}}',
      },
      { numberOfItems: numberOfImages },
    );
    const numberOfVideosString = intl.formatMessage(
      {
        id: 'scenes.influencerAssets.statusMessage.videos',
        defaultMessage:
          '{numberOfItems, plural, =0 {} one {# video} other {# videos}}',
      },
      { numberOfItems: numberOfVideos },
    );
    const andString = intl.formatMessage({
      id: 'scenes.influencerAssets.statusMessage.and',
      defaultMessage: 'and',
    });

    let message = allItemsString;

    if (numberOfImagesString) {
      message = `${message} - ${numberOfImagesString}`;

      // Images and videos
      if (numberOfVideos) {
        message = `${message} ${andString} ${numberOfVideosString}`;
      }
      // Only videos (no `and`)
    } else if (numberOfVideos) {
      message = `${message} - ${numberOfVideosString}`;
    }

    return message;
  }, [influencerAssets, loading, intl]);

  return (
    <Typography variant="subtitle1" {...otherProps}>
      {formattedMessage}
    </Typography>
  );
};

GridStatusMessage.propTypes = {
  loading: PropTypes.bool,
  influencerAssets: PropTypes.arrayOf(PropTypes.object),
};

GridStatusMessage.defaultProps = {
  loading: false,
  influencerAssets: [],
};

export default GridStatusMessage;

import React from 'react';
import PropTypes from 'prop-types';

import GenderMaleIcon from './GenderMale';
import GenderFemaleIcon from './GenderFemale';
import GenderDiverseIcon from './GenderDiverse';

const iconsByGenderStr = {
  m: GenderMaleIcon,
  f: GenderFemaleIcon,
  d: GenderDiverseIcon,
};

const Gender = ({ gender, ...otherProps }) => {
  if (!gender) {
    return '—';
  }

  const IconComponent = iconsByGenderStr[gender.toLowerCase()];
  return <IconComponent {...otherProps} />;
};

Gender.propTypes = {
  gender: PropTypes.oneOf(['M', 'F', 'D']),
};

Gender.defaultProps = {
  gender: null,
};

export default Gender;

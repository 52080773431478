import React from 'react';

import find from 'lodash/find';
import head from 'lodash/head';

import { useQueryParams, StringParam } from 'utils/routing';

export default (application) => {
  const [query, setQuery] = useQueryParams({
    channel: StringParam,
  });

  const [allChannels, defaultChannel] = React.useMemo(
    () => [application?.channels, head(application?.channels)],
    [application?.id, application?.channels],
  );

  const [selectedChannelId, setSelectedChannelId] = React.useState(
    query?.channel || defaultChannel?.id,
  );

  const selectedChannel = React.useMemo(() => {
    if (!selectedChannelId) {
      return null;
    }

    return find(allChannels, { id: selectedChannelId });
  }, [application?.id, allChannels, selectedChannelId]);

  React.useEffect(() => {
    setQuery({
      channel: selectedChannel?.id,
    });
  }, [selectedChannel?.id]);

  return {
    allChannels,
    selectedChannel,
    selectChannel: setSelectedChannelId,
  };
};

import React from 'react';
import { useIntl } from 'react-intl';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import iabCategories, { categoriesStrings } from 'src/data/iabCategories';

export const activeCategories = sortBy(
  iabCategories.filter((category) => category.active),
  'parent',
);

export const noCategoryLabel = categoriesStrings.noCategory;

export const useCategories = () => {
  const intl = useIntl();

  const getCategory = React.useCallback(
    (code) => {
      const category = find(activeCategories, { code });

      return {
        ...category,
        label: intl.formatMessage(categoriesStrings[code]),
      };
    },
    [activeCategories, intl, categoriesStrings],
  );

  const getFullCategories = React.useCallback(
    (categoriesList) => {
      if (isEmpty(categoriesList)) {
        return [];
      }
      return categoriesList.map((category) => ({
        ...category,
        ...getCategory(category.code),
      }));
    },
    [getCategory],
  );

  const allCategories = React.useMemo(
    () => getFullCategories(activeCategories),
    [activeCategories, getFullCategories],
  );

  return {
    allCategories,
    getCategory,
    getFullCategories,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import assign from 'lodash/assign';
import get from 'lodash/get';
import countBy from 'lodash/countBy';
import { useIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {
  useQueryParams,
  StringParam,
  SortParam,
  withDefault,
} from 'utils/routing';
import { views as campaignViews } from 'utils/campaign';
import { isCompleteNotesObj as isCompleteApplicationNote } from 'utils/application';

import ApplicationsTable from 'components/applications/table/preparation/ApplicationsTable';
import ApplicationCard from 'components/applications/card/ApplicationCard';
import InspectorDrawer from 'components/inspector/InspectorDrawer';

import downloadSpreadsheet from './downloadSpreadsheet';
import CampaignDetailsPageContainer from '../CampaignDetailsPageContainer';
import {
  useOne as useCampaign,
  useUpdateChannelNotes,
  useUpdateChannelTags,
  useUpdateClientNote,
  useUpdateCampaignTask,
  useUpdateApplicationTask,
} from '../../data/campaignHooks';

export const drawerWidth = 320;

const defaultCampaign = { applications: [] };

const CampaignPreparationPage = ({ campaignId }) => {
  const intl = useIntl();

  const { updateChannelTags, loading: tagsUpdateLoading } =
    useUpdateChannelTags();

  const { updateChannelNotes, loading: notesUpdateLoading } =
    useUpdateChannelNotes();

  const { updateClientNote, loading: clientNoteUpdateLoading } =
    useUpdateClientNote();

  const { updateCampaignTask, loading: campaignTaskUpdateLoading } =
    useUpdateCampaignTask();

  const { updateApplicationTask, loading: applicationTaskUpdateLoading } =
    useUpdateApplicationTask();

  const [query, setQuery] = useQueryParams({
    inspect: StringParam,
    sort: withDefault(SortParam, {
      by: 'mission.publishDate',
      direction: 'asc',
    }),
  });

  const { data, loading, error } = useCampaign({
    id: campaignId,
    applicationsFilter: 'byInPreparation',
  });

  if (error) {
    throw new Error(`Error fetching campaign: ${error}`);
  }

  const campaign = data?.campaign || defaultCampaign;

  const [selectedApplicationId, setSelectedApplicationId] = React.useState(
    query.inspect,
  );
  const [displayApplications, setDisplayApplications] = React.useState(
    campaign.applications,
  );

  const [currentSort, setCurrentSort] = React.useState(() => query.sort);

  const selectedApplication = React.useMemo(() => {
    if (!selectedApplicationId) {
      return null;
    }

    return find(campaign.applications, { id: selectedApplicationId });
  }, [selectedApplicationId, campaign.applications]);

  const selectedChannel = assign(
    {},
    selectedApplication?.crmChannel,
    selectedApplication?.channel,
  );

  const inspectApplication = (id) => {
    setSelectedApplicationId(id);
    setQuery({ inspect: id });
  };

  const uninspectApplication = () => {
    setSelectedApplicationId(null);
    setQuery({ inspect: undefined });
  };

  const handleClose = () => {
    uninspectApplication();
  };

  // Sync query param with state (when back button is used, for example)
  React.useEffect(() => {
    if (query.inspect !== selectedApplicationId) {
      setSelectedApplicationId(query.inspect);
    }
  }, [query.inspect, selectedApplicationId]);

  React.useEffect(() => {
    setDisplayApplications(campaign.applications);
  }, [campaign.applications, loading]);

  const prevSort = React.useRef(null);

  React.useEffect(() => {
    if (
      isEqual(prevSort.current, currentSort) ||
      isEmpty(campaign.applications)
    ) {
      return;
    }

    prevSort.current = currentSort;

    const getSortValue = (fn) => (application) => {
      let value = get(application, currentSort.by);

      if (fn) {
        value = fn(value);
      }

      return currentSort.direction === 'desc' ? -value : value;
    };

    const sorter =
      currentSort.by === 'mission.publishDate'
        ? getSortValue((v) => new Date(v))
        : getSortValue();

    setDisplayApplications(sortBy(campaign.applications, sorter));

    setQuery({ sort: currentSort });
  }, [campaign.applications, currentSort]);

  const metaLabel = React.useMemo(() => {
    const allApplications = campaign?.applications || [];

    const { confirmed: numberOfStartedMissions } = countBy(
      allApplications,
      'mission.status',
    );

    return intl.formatMessage(
      {
        id: 'scenes.campaigns.preparation.startedMissionsLabel',
        defaultMessage:
          '{numberOfStartedMissions} of {numberOfApplications} started the mission',
      },
      {
        numberOfStartedMissions,
        numberOfApplications: allApplications.length,
      },
    );
  });

  const noApplicationsHaveKamNotes = React.useMemo(() => {
    const allApplications = campaign?.applications || [];

    const applicationsWithCompleteNotes = allApplications.filter(
      (application) => isCompleteApplicationNote(application?.kamNotes),
    );

    return applicationsWithCompleteNotes.length === 0;
  }, [campaign?.applications]);

  const handleDownload = async () => {
    await downloadSpreadsheet({
      campaign,
      name: campaign.name,
      applications: displayApplications,
      platform: campaign.platform,
      productShipmentEnabled: campaign?.productShipment?.enabled,
      hideKamNotesColumn: noApplicationsHaveKamNotes,
      intl,
    });
  };

  const handleUpdateChannelNotes = (notes) => {
    updateChannelNotes({
      id: selectedApplication?.channel?.id,
      payload: notes,
    });
  };

  const handleUpdateChannelTags = (data) => {
    updateChannelTags({
      id: selectedApplication?.channel?.id,
      payload: data.tags,
    });
  };

  const handleUpdateClientNote = (newNote) => {
    updateClientNote({
      id: selectedApplication?.id,
      payload: {
        note: newNote,
      },
    });
  };

  const handleUpdateCampaignTask = (taskId, newTaskBody) => {
    updateCampaignTask({
      id: campaign?.id,
      taskId,
      payload: {
        body: newTaskBody,
      },
    });
  };

  const handleUpdateApplicationTask = (taskId, newTaskBody) => {
    updateApplicationTask({
      id: selectedApplication?.id,
      taskId,
      payload: {
        body: newTaskBody,
      },
    });
  };

  return (
    <CampaignDetailsPageContainer
      campaign={campaign}
      drawerOpen={!!selectedApplicationId}
      drawerWidth={drawerWidth}
      loading={loading}
      view={campaignViews.PREPARATION}
      renderDrawer={() => (
        <InspectorDrawer
          isOpen={!!selectedApplication}
          width={drawerWidth}
          onClose={handleClose}
        >
          {selectedApplication && (
            <Box>
              <ApplicationCard
                campaign={campaign}
                application={selectedApplication}
                selectedChannel={selectedChannel}
                channels={[selectedChannel]}
                loadingChannels={false}
                height="100%"
                updateChannelNotes={handleUpdateChannelNotes}
                updateChannelTags={handleUpdateChannelTags}
                updateClientNote={handleUpdateClientNote}
                updateCampaignTask={handleUpdateCampaignTask}
                updateApplicationTask={handleUpdateApplicationTask}
                loading={
                  tagsUpdateLoading ||
                  notesUpdateLoading ||
                  clientNoteUpdateLoading ||
                  campaignTaskUpdateLoading ||
                  applicationTaskUpdateLoading
                }
                isPreparationStep
              />
            </Box>
          )}
        </InspectorDrawer>
      )}
    >
      <ApplicationsTable
        platform={campaign.platform}
        loading={loading}
        campaign={campaign}
        productShipmentEnabled={campaign?.productShipment?.enabled}
        applications={displayApplications}
        currentSort={currentSort}
        onSort={setCurrentSort}
        selectedApplication={selectedApplication}
        onSelectApplication={inspectApplication}
        headerLabel={metaLabel}
        hideKamNotesColumn={noApplicationsHaveKamNotes}
        headerContent={
          <Button color="primary" variant="contained" onClick={handleDownload}>
            {intl.formatMessage({
              id: 'preparationView.button.downloadXLSX',
              defaultMessage: 'Download XLSX',
            })}
          </Button>
        }
      />
      <Box display="flex" justifyContent="center" mt={3}>
        <Typography variant="body1" align="center">
          {intl.formatMessage({
            id: 'scenes.campaigns.preparation.message',
            defaultMessage:
              'The selected influencers will review and accept the campaign terms and requirements, select the expected publishing date and confirm the mission.',
          })}
        </Typography>
      </Box>
    </CampaignDetailsPageContainer>
  );
};

CampaignPreparationPage.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
CampaignPreparationPage.defaultProps = {};

export default CampaignPreparationPage;

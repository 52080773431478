import React from 'react';
import PropTypes from 'prop-types';

import SvgIconWrapper from './SvgIconWrapper';

const TikTok = ({ color, ...otherProps }) => (
  <SvgIconWrapper stroke={color} {...otherProps}>
    <path
      d="M21 8C18 8 15 5.58985 15 2V16C15 18.7614 12.7614 21 10 21C7.23858 21 5 18.7614 5 16C5 13.2386 7.23858 11 10 11C11 11 11.9844 11.3438 12.2969 11.5625"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconWrapper>
);

TikTok.propTypes = {
  color: PropTypes.string,
};

TikTok.defaultProps = {
  color: '#000',
};

export default TikTok;

import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { views as clientViews } from 'utils/client';
import ErrorBoundary from 'utils/ErrorBoundary';
import ErrorPage404 from 'scenes/error/404';

import ClientSettingsPage from './ClientSettingsPage';
import ClientUsersPage from './ClientUsersPage';

const ClientDetailsPage = () => {
  const { params } = useRouteMatch();
  const { id, view } = params;

  if (!id) {
    return <ErrorPage404 />;
  }

  let content;

  switch (view) {
    case clientViews.SETTINGS.id:
      content = <ClientSettingsPage clientId={id} />;
      break;
    case clientViews.USERS.id:
      content = <ClientUsersPage clientId={id} />;
      break;
    default: {
      content = <ClientSettingsPage clientId={id} />;
    }
  }

  if (!content) {
    return <ErrorPage404 />;
  }

  return <ErrorBoundary>{content}</ErrorBoundary>;
};

ClientDetailsPage.propTypes = {};

export default ClientDetailsPage;

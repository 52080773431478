import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { formatDate } from 'utils/formatting';

const useStyles = makeStyles((theme) => ({
  selectionConfirmed: {
    color: theme.palette.eqolot.infoBlue,
  },
}));

const CampaignInfluencerSelectionConfirmed = ({ confirmedAt, confirmedBy }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Typography variant="subtitle1" className={classes.selectionConfirmed}>
      <FormattedMessage
        id="scenes.campaigns.details.preparationView.confirmationStatusMessage"
        defaultMessage="Selection confirmed on {confirmedAt} by {confirmedBy}"
        values={{
          confirmedAt: formatDate({
            isoDate: confirmedAt,
            formatter: intl.formatDate,
          }),
          confirmedBy,
        }}
      />
    </Typography>
  );
};

CampaignInfluencerSelectionConfirmed.propTypes = {
  confirmedAt: PropTypes.string.isRequired,
  confirmedBy: PropTypes.string.isRequired,
};

CampaignInfluencerSelectionConfirmed.defaultProps = {};

export default CampaignInfluencerSelectionConfirmed;

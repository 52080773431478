import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Feature } from 'utils/features';
import { useClient } from 'utils/client';
import { getStatusLabel as getClientReviewStatusLabel } from 'utils/clientReviewStatuses';
import {
  allStatuses,
  getStatusLabel as getKamReviewStatusLabel,
} from 'utils/kamReviewStatuses';

import GenderFilter from 'components/filters/GenderFilter';
import CountriesFilter from 'components/filters/CountriesFilter';
import SearchFilter from 'components/filters/SearchFilter';
import ClientChannelTagsFilter from 'components/filters/ClientChannelTagsFilter';
import CollaborationRatingsFilter from 'components/filters/CollaborationRatingsFilter';
import CollaboratorFilter from 'components/filters/CollaboratorFilter';
import ChannelPlatformsFilter from 'components/filters/ChannelPlatformsFilter';
import FiltersContainer, {
  useStyles as useFilterStyles,
} from 'components/filters/FiltersContainer';

import { drawerWidth as channelInspectorDrawerWidth } from './PrivateNetworkApplicationKamInspector';
import PrivateNetworkSelect from '../components/PrivateNetworkSelect';
import StatusFilter from './StatusFilter';

const PrivateNetworkKamFilters = ({
  privateNetworks,
  onSelectPrivateNetwork,
  selectedPrivateNetworkId,
  filters,
  onFilter,
  supportedCountries,
  isInspected,
}) => {
  const filterClasses = useFilterStyles();
  const intl = useIntl();
  const { client, tags: clientTags } = useClient();

  const createFilterBy = (key) => (value) => {
    onFilter({
      key,
      value,
    });
  };

  return (
    <FiltersContainer
      isInspected={isInspected}
      inspectorWidth={channelInspectorDrawerWidth}
    >
      <PrivateNetworkSelect
        className={filterClasses.filter}
        privateNetworks={privateNetworks}
        onChange={onSelectPrivateNetwork}
        value={selectedPrivateNetworkId}
      />
      <StatusFilter
        id="kam-review-status"
        options={allStatuses.map((status) => ({
          value: status,
          label: intl.formatMessage(getKamReviewStatusLabel(status)),
        }))}
        label={intl.formatMessage({
          id: 'scenes.privateNetwork.kam.kamReviewStatusFilter',
          defaultMessage: 'Status',
        })}
        className={filterClasses.filter}
        onChange={createFilterBy('kamReviewStatus')}
        value={filters.kamReviewStatus}
      />
      <StatusFilter
        id="client-review-status"
        options={['all', 'pending', 'accepted', 'rejected', 'undecided'].map(
          (status) => ({
            value: status,
            label: intl.formatMessage(getClientReviewStatusLabel(status)),
          }),
        )}
        label={intl.formatMessage({
          id: 'scenes.privateNetwork.kam.clientReviewStatusFilter',
          defaultMessage: 'Client Status',
        })}
        className={filterClasses.filter}
        onChange={createFilterBy('clientReviewStatus')}
        value={filters.clientReviewStatus}
      />
      <SearchFilter
        className={filterClasses.filter}
        onChange={createFilterBy('userName')}
        value={filters.userName}
        label={intl.formatMessage({
          id: 'components.SearchFilter.firstName.label',
          defaultMessage: 'First name',
        })}
      />
      <SearchFilter
        className={filterClasses.filter}
        onChange={createFilterBy('channelName')}
        value={filters.channelName}
        label={intl.formatMessage({
          id: 'components.SearchFilter.channelName.label',
          defaultMessage: '@channelname',
        })}
      />
      <ChannelPlatformsFilter
        className={filterClasses.filter}
        onChange={createFilterBy('platforms')}
        value={filters.platforms}
      />
      <CollaboratorFilter
        className={clsx(filterClasses.filter, filterClasses.noMinWidth)}
        onChange={createFilterBy('collaborator')}
        value={filters.collaborator}
        enableAll
      />
      <GenderFilter
        className={filterClasses.filter}
        onChange={createFilterBy('salutation')}
        value={filters.salutation}
      />
      <CountriesFilter
        label={intl.formatMessage({
          id: 'scenes.influencers.filter.userCountries',
          defaultMessage: "Influencer's Location",
        })}
        className={filterClasses.filter}
        onChange={createFilterBy('countries')}
        value={filters.countries}
        supportedCountries={supportedCountries}
      />
      {client && (
        <Feature feature="influencerFinderTags">
          <ClientChannelTagsFilter
            className={filterClasses.filter}
            client={client}
            onChange={createFilterBy('tags')}
            value={filters.tags}
            options={clientTags}
          />
        </Feature>
      )}
      <CollaborationRatingsFilter
        className={filterClasses.filter}
        value={filters.collaborationRatings}
        onChange={createFilterBy('collaborationRatings')}
      />
    </FiltersContainer>
  );
};

PrivateNetworkKamFilters.propTypes = {
  filters: PropTypes.shape({
    userName: PropTypes.string,
    channelName: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.string),
    clientReviewStatus: PropTypes.string,
    kamReviewStatus: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
    salutation: PropTypes.string,
    collaborationRatings: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.shape({
      clientId: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
    collaborator: PropTypes.string,
  }),
  privateNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      client: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  onSelectPrivateNetwork: PropTypes.func.isRequired,
  selectedPrivateNetworkId: PropTypes.string,
  supportedCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilter: PropTypes.func.isRequired,
  isInspected: PropTypes.bool,
};
PrivateNetworkKamFilters.defaultProps = {
  filters: {
    userName: '',
    channelName: '',
    platforms: [],
    clientReviewStatus: 'all',
    kamReviewStatus: 'new',
    countries: [],
    tags: {},
    collaborationRatings: [],
    collaborator: true,
  },
  isInspected: false,
};

export default PrivateNetworkKamFilters;

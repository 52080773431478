import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Markdown from 'components/Markdown';

const DeletePublicationDialog = ({ onConfirm, onCancel, ...otherProps }) => {
  const intl = useIntl();

  return (
    <Dialog aria-labelledby="delete-publication-dialog-title" {...otherProps}>
      <DialogTitle id="delete-publication-dialog-title">
        {intl.formatMessage({
          id: 'components.InfluencerAssetInspector.deletePublication.labels.dialogTitle',
          defaultMessage: 'Delete Publication History',
        })}
      </DialogTitle>
      <DialogContent>
        <Markdown
          source={intl.formatMessage({
            id: 'components.InfluencerAssetInspector.deletePublication.labels.dialogText_MD',
            defaultMessage: "This action can't be undone.",
          })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {intl.formatMessage({
            id: 'components.InfluencerAssetInspector.deletePublication.labels.cancelButton',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button onClick={onConfirm} color="secondary">
          {intl.formatMessage({
            id: 'components.InfluencerAssetInspector.deletePublication.labels.confirmButton',
            defaultMessage: 'Delete',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeletePublicationDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DeletePublicationDialog.defaultProps = {};

export default DeletePublicationDialog;

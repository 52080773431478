import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { getChannelLink, ChannelPropType } from 'utils/channel';
import { trimUrl } from 'utils/formatting';
import { TrackedLink } from 'utils/gtm';

import PlatformLabel from './PlatformLabel';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
  compactLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const ChannelLink = (props) => {
  const { className, compact, channel, color, variant, ...otherProps } = props;
  const classes = useStyles(props);

  const href = getChannelLink(channel);

  const baseProps = {
    component: TrackedLink,
    href,
    eventLabel: `${channel.platform} link: ${channel.name}`,
    underline: 'none',
    target: '_blank',
    rel: 'noreferrer',
    className: clsx(
      classes.link,
      {
        [classes.compactLink]: compact,
      },
      className,
    ),
  };

  if (compact) {
    return (
      <PlatformLabel
        platform={channel.platform}
        variant={variant}
        channelName={channel.name}
        color={color}
        {...baseProps}
      />
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...baseProps}
      {...otherProps}
    >
      <Typography color="textPrimary" variant={variant} noWrap>
        {trimUrl(href)}
      </Typography>
      <Icons.ExternalLink color={color} />
    </Box>
  );
};

ChannelLink.propTypes = {
  className: PropTypes.string,
  channel: ChannelPropType.isRequired,
  color: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  variant: PropTypes.string,
};

ChannelLink.defaultProps = {
  compact: false,
  className: '',
  variant: undefined,
};

export default ChannelLink;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import values from 'lodash/values';
import without from 'lodash/without';

import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { formatNumber } from 'utils/formatting';

import { TaskPropType } from 'utils/task';
import { PlatformPropType } from './channel';
import { ApplicationPropType, SelectedApplicationsLabel } from './application';

export const views = {
  BRIEFING: 'briefing',
  APPLICATIONS: 'applications',
  PREPARATION: 'preparation',
  CONTENT_REVIEW: 'content-review',
  LIVE: 'live',
};

export const statuses = {
  BRIEFING: 'briefing',
  PUBLISHED: 'published',
  PREPARATION: 'preparation',
  CONTENT_REVIEW: 'clientContentPreview',
  LIVE: 'live',
  COMPLETED: 'completed',
  DONE: 'done',
  ARCHIVED: 'archived',
  HIDDEN: 'hidden',
};

export const statusStrings = defineMessages({
  [statuses.BRIEFING]: {
    id: 'campaign.status.briefing',
    defaultMessage: 'Briefing',
  },
  [statuses.PUBLISHED]: {
    id: 'campaign.status.published',
    defaultMessage: 'Influencer Selection',
  },
  [statuses.PREPARATION]: {
    id: 'campaign.status.preparation',
    defaultMessage: 'Preparation',
  },
  [statuses.CONTENT_REVIEW]: {
    id: 'campaign.status.clientContentPreview',
    defaultMessage: 'Content Review',
  },
  [statuses.LIVE]: { id: 'campaign.status.live', defaultMessage: 'Live' },
  [statuses.COMPLETED]: {
    id: 'campaign.status.completed',
    defaultMessage: 'Completed',
  },
  [statuses.DONE]: {
    id: 'campaign.status.done',
    defaultMessage: 'Done',
  },
  [statuses.ARCHIVED]: {
    id: 'campaign.status.archived',
    defaultMessage: 'Archived',
  },
});

export const supportedStatuses = without(values(statuses), statuses.HIDDEN);

export const getViewByStatus = (status) => {
  if (status === statuses.BRIEFING) {
    return views.BRIEFING;
  }
  if (status === statuses.PUBLISHED) {
    return views.APPLICATIONS;
  }
  if (status === statuses.PREPARATION) {
    return views.PREPARATION;
  }
  if (status === statuses.CONTENT_REVIEW) {
    return views.CONTENT_REVIEW;
  }
  if (status === statuses.LIVE) {
    return views.LIVE;
  }
  if (status === statuses.COMPLETED) {
    return views.LIVE;
  }
  if (status === statuses.DONE) {
    return views.LIVE;
  }
  if (status === statuses.ARCHIVED) {
    return views.LIVE;
  }
  return '';
};

export const useStatus = (status) => {
  const intl = useIntl();
  const theme = useTheme();

  const colorsMap = {
    [statuses.BRIEFING]: theme.palette.primary.light,
    [statuses.PUBLISHED]: theme.palette.eqolot.shiningBlue,
    [statuses.PREPARATION]: theme.palette.eqolot.shiningBlue,
    [statuses.CONTENT_REVIEW]: theme.palette.eqolot.tomatoRed,
    [statuses.LIVE]: theme.palette.eqolot.sweetGreen,
    [statuses.COMPLETED]: theme.palette.primary.dark,
    [statuses.DONE]: theme.palette.primary.dark,
    [statuses.ARCHIVED]: theme.palette.primary.dark,
  };

  return {
    color: colorsMap[status],
    view: getViewByStatus(status),
    label: intl.formatMessage(statusStrings[status]),
  };
};

export const CampaignStatusPropType = PropTypes.oneOf(supportedStatuses);
export const CampaignViewPropType = PropTypes.oneOf(values(views));
export const CampaignInfluencerSelectionPropType = PropTypes.shape({
  status: PropTypes.oneOf(['confirmed', 'pending']).isRequired,
  selectionType: PropTypes.oneOf(['budget', 'reach']),
  campaignBudget: PropTypes.number,
  confirmedBudget: PropTypes.number,
  campaignReach: PropTypes.number,
  confirmedReach: PropTypes.number,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
});
export const CampaignInfluencerSelectionSummaryPropType = PropTypes.shape({
  status: PropTypes.oneOf(['confirmed', 'pending']).isRequired,
  selectionType: PropTypes.oneOf(['budget', 'reach']).isRequired,
  campaignBudget: PropTypes.number,
  confirmedBudget: PropTypes.number,
  campaignReach: PropTypes.number,
  confirmedReach: PropTypes.number,
  acceptedApplicationsCount: PropTypes.number,
});

export const ProductShipmentPropType = PropTypes.shape({
  enabled: PropTypes.bool,
  description: PropTypes.string,
  productChoices: PropTypes.arrayOf(PropTypes.string),
  allowOtherChoice: PropTypes.bool,
});

export const CampaignPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  previewUrl: PropTypes.string,
  status: CampaignStatusPropType.isRequired,
  applications: PropTypes.arrayOf(ApplicationPropType),
  platform: PlatformPropType.isRequired,
  hasClientReviewableContent: PropTypes.bool.isRequired,
  clientInfluencerSelection: CampaignInfluencerSelectionPropType,
  influencersApplied: PropTypes.number.isRequired,
  influencersAccepted: PropTypes.number.isRequired,
  influencersPublished: PropTypes.number.isRequired,
  applicationsOpenedAt: PropTypes.string,
  firstPostAt: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  productShipment: ProductShipmentPropType,
  tasks: PropTypes.arrayOf(TaskPropType),
});

export const InfluencerSelectionBudgetSummaryLabel = ({
  campaignBudget,
  confirmedBudget,
  ...otherProps
}) => {
  const intl = useIntl();

  const formatPrice = (number) =>
    formatNumber({
      number,
      format: 'currency',
      formatter: intl.formatNumber,
    });

  return (
    <Typography {...otherProps}>
      {intl.formatMessage(
        {
          id: 'campaign.budgetStatus',
          defaultMessage:
            'Using {confirmedBudget} of the {campaignBudget} budget',
        },
        {
          confirmedBudget: formatPrice(confirmedBudget),
          campaignBudget: formatPrice(campaignBudget),
        },
      )}
    </Typography>
  );
};

InfluencerSelectionBudgetSummaryLabel.propTypes = {
  campaignBudget: PropTypes.number,
  confirmedBudget: PropTypes.number,
};

InfluencerSelectionBudgetSummaryLabel.defaultProps = {
  campaignBudget: 0,
  confirmedBudget: 0,
};

export const InfluencerSelectionReachSummaryLabel = ({
  platform: platformProp,
  influencersCount,
  campaignReach,
  confirmedReach,
  ...otherProps
}) => {
  const intl = useIntl();

  let platform = platformProp;

  if (platform === 'ga' || platform === 'website') {
    platform = 'blog';
  }

  const messagesByPlatform = defineMessages({
    instagram: {
      id: 'campaign.reachStatus.instagram',
      defaultMessage:
        '{influencersCount, plural, one {# influencer} other {# influencers}} selected reaching {confirmedReach} followers of {campaignReach} booked reach',
    },
    pinterest: {
      id: 'campaign.reachStatus.pinterest',
      defaultMessage:
        '{influencersCount, plural, one {# influencer} other {# influencers}} selected reaching {confirmedReach} impressions of {campaignReach} booked reach',
    },
    tiktok: {
      id: 'campaign.reachStatus.tiktok',
      defaultMessage:
        '{influencersCount, plural, one {# influencer} other {# influencers}} selected reaching {confirmedReach} followers of {campaignReach} booked reach',
    },
    blog: {
      id: 'campaign.reachStatus.blog',
      defaultMessage:
        '{influencersCount, plural, one {# influencer} other {# influencers}} selected reaching {confirmedReach} page views of {campaignReach} booked reach',
    },
  });

  return (
    <Typography {...otherProps}>
      {intl.formatMessage(messagesByPlatform[platform], {
        influencersCount,
        campaignReach: formatNumber({
          number: campaignReach,
          formatter: intl.formatNumber,
        }),
        confirmedReach: formatNumber({
          number: confirmedReach,
          formatter: intl.formatNumber,
        }),
      })}
    </Typography>
  );
};

InfluencerSelectionReachSummaryLabel.propTypes = {
  platform: PlatformPropType.isRequired,
  influencersCount: PropTypes.number,
  campaignReach: PropTypes.number,
  confirmedReach: PropTypes.number,
};

InfluencerSelectionReachSummaryLabel.defaultProps = {
  influencersCount: 0,
  campaignReach: 0,
  confirmedReach: 0,
};

export const CampaignInfluencerSelectionLabel = ({
  selectionSummary,
  platform,
  withLineBreak,
}) => {
  if (selectionSummary.selectionType === 'budget') {
    return (
      <>
        <InfluencerSelectionBudgetSummaryLabel
          variant="subtitle1"
          campaignBudget={selectionSummary.campaignBudget}
          confirmedBudget={selectionSummary.confirmedBudget}
        />
        {withLineBreak && <br />}
        <SelectedApplicationsLabel
          variant="subtitle1"
          platform={platform}
          influencersCount={selectionSummary.acceptedApplicationsCount}
          totalReach={selectionSummary.confirmedReach}
        />
      </>
    );
  }

  return (
    <>
      <InfluencerSelectionReachSummaryLabel
        variant="subtitle1"
        platform={platform}
        influencersCount={selectionSummary.acceptedApplicationsCount}
        campaignReach={selectionSummary.campaignReach}
        confirmedReach={selectionSummary.confirmedReach}
      />
    </>
  );
};

CampaignInfluencerSelectionLabel.propTypes = {
  selectionSummary: CampaignInfluencerSelectionSummaryPropType.isRequired,
  platform: PlatformPropType.isRequired,
  withLineBreak: PropTypes.bool,
};
CampaignInfluencerSelectionLabel.defaultProps = {
  withLineBreak: false,
};

export const calculateBookedValuesDifference = ({ booked, confirmed }) => {
  const differenceAbsolute = Math.abs(booked - confirmed);
  return differenceAbsolute / booked;
};

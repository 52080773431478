import React from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Box from '@material-ui/core/Box';

import Page from 'layout/Page';
import LoadingPage from 'layout/LoadingPage';

import { useQueryParam, StringParam, withDefault } from 'utils/routing';
import { ROLES } from 'utils/roles';
import InspectorDrawerContent from 'components/inspector/InspectorDrawerContent';
import TableBody from 'components/table/TableBody';
import { loadingString } from 'components/LoadingState';

import usePrivateNetworkState, { supportedFilterCountries } from './kamState';

import useTableState from '../data/useTableState';
import useInspectorState from '../data/useInspectorState';
import useTableMetaLabel from '../data/useTableMetaLabel';
import { useMany as usePrivateNetworks } from '../data/privateNetworkHooks';

import PrivateNetworkTable from '../components/PrivateNetworkTable';
import PrivateNetworkKamTableHead from './PrivateNetworkKamTableHead';
import PrivateNetworkApplicationTableRow from '../components/PrivateNetworkApplicationTableRow';
import PrivateNetworkApplicationKamInspector, {
  drawerWidth as inspectorDrawerWidth,
} from './PrivateNetworkApplicationKamInspector';
import PrivateNetworkKamFilters from './PrivateNetworkKamFilters';

const PrivateNetworkKamPage = (props) => {
  const intl = useIntl();
  const { data: privateNetworksData, loading: loadingPrivateNetworks } =
    usePrivateNetworks();

  const allPrivateNetworks = privateNetworksData?.privateNetworks || [];

  const [querySelectedNetworkId, setQuerySelectedNetworkId] = useQueryParam(
    'privateNetwork',
    withDefault(StringParam, ''),
  );
  const [selectedPrivateNetworkId, setSelectedPrivateNetworkId] =
    React.useState(querySelectedNetworkId || null);

  React.useEffect(() => {
    setSelectedPrivateNetworkId((prevId) => {
      if (!isEmpty(allPrivateNetworks) && !prevId) {
        return allPrivateNetworks[0].id;
      }

      return prevId;
    });
  }, [allPrivateNetworks]);

  React.useEffect(() => {
    setQuerySelectedNetworkId(selectedPrivateNetworkId);
  }, [selectedPrivateNetworkId]);

  const { state, actions } = usePrivateNetworkState({
    selectedPrivateNetworkId,
  });
  const { privateNetwork, loading, filters } = state;
  const { filterBy } = actions;

  const metaLabel = useTableMetaLabel(privateNetwork?.applications);
  const tableState = useTableState(privateNetwork);

  const inspectorState = useInspectorState(tableState.selectedApplication);

  if (loadingPrivateNetworks) {
    return <LoadingPage withSidebar />;
  }

  const handleClickChannelRow = (applicationId, channelId) => {
    tableState.selectApplication(applicationId);
    inspectorState.selectChannel(channelId);
  };

  const handleClickApplicationRow = (applicationId) => {
    tableState.selectApplicationRow(applicationId);
    inspectorState.selectChannel(null);
  };

  const pageTitle = intl.formatMessage({
    id: 'scenes.privateNetwork.pageTitle',
    defaultMessage: 'Private Network',
  });

  return (
    <Page title={pageTitle} {...props}>
      <Box display="flex" height="100%">
        <InspectorDrawerContent
          drawerOpen={!!tableState.selectedApplication}
          drawerWidth={inspectorDrawerWidth}
        >
          <PrivateNetworkKamFilters
            filters={filters}
            onFilter={filterBy}
            isInspected={!!tableState.selectedApplication}
            supportedCountries={supportedFilterCountries}
            privateNetworks={allPrivateNetworks}
            selectedPrivateNetworkId={selectedPrivateNetworkId}
            onSelectPrivateNetwork={setSelectedPrivateNetworkId}
          />
          <PrivateNetworkTable
            metaLabel={loading ? intl.formatMessage(loadingString) : metaLabel}
          >
            <PrivateNetworkKamTableHead
              clientName={privateNetwork?.client?.name}
              currentSort={tableState.currentSort}
              onSort={tableState.sortBy}
            />
            <TableBody loading={loading} />
            {!loading &&
              tableState.displayApplications.map((application) => (
                <PrivateNetworkApplicationTableRow
                  key={application.id}
                  role={ROLES.KAM}
                  application={application}
                  onClick={() => {
                    handleClickApplicationRow(application.id);
                  }}
                  onExpand={() => {
                    tableState.expandApplicationRow(application.id);
                  }}
                  onClickChannel={(channelId) =>
                    handleClickChannelRow(application.id, channelId)
                  }
                  expanded={tableState.isExpanded(application.id)}
                  selected={tableState.isSelected(application.id)}
                  selectedChannel={inspectorState.selectedChannel}
                />
              ))}
          </PrivateNetworkTable>
        </InspectorDrawerContent>
        {!loading && (
          <PrivateNetworkApplicationKamInspector
            selectedChannel={inspectorState.selectedChannel}
            channels={inspectorState.allChannels}
            onSelectChannel={inspectorState.selectChannel}
            application={tableState.selectedApplication}
            onClose={tableState.unselectApplication}
            loading={loading}
          />
        )}
      </Box>
    </Page>
  );
};

PrivateNetworkKamPage.propTypes = {};

export default PrivateNetworkKamPage;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import groupBy from 'lodash/groupBy';

import { ApplicationPropType } from 'utils/application';
import {
  formatNumber as numberFormatter,
  sumWithRounding,
} from 'utils/formatting';

import metaLabels from '../metaLabels';

const PinterestTableMetaLabel = ({ applications }) => {
  const intl = useIntl();

  const metaLabel = React.useMemo(() => {
    const reach = sumWithRounding(applications, 'reach');

    const influencersNumber = Object.keys(
      groupBy(applications, 'user.id'),
    ).length;

    const contentItemsLabel = intl.formatMessage(metaLabels.contentItems, {
      contentItemsNumber: applications.length,
    });

    const publishedBy = intl.formatMessage(metaLabels.publishedBy);

    const influencersLabel = intl.formatMessage(metaLabels.influencers, {
      influencersNumber,
    });

    const withLabel = intl.formatMessage(metaLabels.with);

    const impressionsCountLabel = intl.formatMessage(
      metaLabels.impressionsCount,
      {
        impressionsNumber: reach,
      },
    );

    const formatNumber = (number) =>
      numberFormatter({ formatter: intl.formatNumber, number });

    return [
      formatNumber(applications.length),
      contentItemsLabel,
      publishedBy,
      formatNumber(influencersNumber),
      influencersLabel,
      withLabel,
      formatNumber(reach),
      impressionsCountLabel,
    ].join(' ');
  }, [applications, intl]);

  return metaLabel;
};

PinterestTableMetaLabel.propTypes = {
  applications: PropTypes.arrayOf(ApplicationPropType),
};

PinterestTableMetaLabel.defaultProps = {
  applications: [],
};

export default PinterestTableMetaLabel;

import React from 'react';
import { useIntl } from 'react-intl';

import Page from 'layout/Page';
import InfluencersSearch from './InfluencersSearch';

const InfluencersSearchPage = (props) => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({
    id: 'scenes.influencers.pageTitle',
    defaultMessage: 'Influencer Finder',
  });

  return (
    <Page title={pageTitle} fullWidth {...props}>
      <InfluencersSearch />
    </Page>
  );
};

export default InfluencersSearchPage;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

import { CampaignPropType } from 'utils/campaign';
import { SortObjectPropType } from 'utils/sorting';

import TableContainer from 'components/table/TableContainer';
import TableBody from 'components/table/TableBody';

import CampaignsTableHead from './CampaignsTableHead';
import CampaignsTableRow from './CampaignsTableRow';

const useStyles = makeStyles({
  table: {
    position: 'relative',
  },
});

const CampaignsTable = ({
  campaigns,
  currentSort,
  onSort,
  headerLabel,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <TableContainer label={headerLabel}>
      <Table
        className={classes.table}
        aria-label="Campaigns Table"
        size="small"
        {...otherProps}
      >
        <CampaignsTableHead currentSort={currentSort} onSort={onSort} />
        <TableBody>
          {campaigns.map((campaign) => (
            <CampaignsTableRow key={campaign.id} campaign={campaign} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CampaignsTable.propTypes = {
  campaigns: PropTypes.arrayOf(CampaignPropType),
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  headerLabel: PropTypes.node,
};

CampaignsTable.defaultProps = {
  campaigns: [],
  headerLabel: '',
};

export default CampaignsTable;

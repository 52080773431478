import isNumber from 'lodash/isNumber';
import { createTheme, alpha } from '@material-ui/core/styles';
import { enUS as en, deDE as de } from '@material-ui/core/locale';

const muiLocales = { en, de };

export default (
  { clientOverrides = {}, ...otherOptions } = {},
  BASE_UNIT_IN_PX = 8,
  fontSize = 14,
) => {
  const locale =
    typeof localStorage !== 'undefined' ? localStorage.getItem('locale') : 'de';
  const muiTranslations = muiLocales[locale];

  const theme = createTheme(
    {
      typography: {
        fontFamily: 'Lato, sans-serif',
        htmlFontSize: fontSize,
        fontSize,
      },
      palette: {
        primary: {
          main: '#657C98',
          light: '#D4E0E4',
          dark: '#46566A',
          contrastText: '#FFF',
        },
        secondary: {
          main: '#F45C25',
          light: '#F7DDD4',
          dark: '#AA4019',
          contrastText: '#FFF',
        },
        background: {
          paper: '#FFF',
          default: '#F9FDFF',
        },
        text: {
          primary: '#29404E',
          secondary: alpha('#29404E', 0.67),
          hint: alpha('#29404E', 0.56),
          disabled: alpha('#000', 0.38),
        },
        action: {
          selected: alpha('#F45C25', 0.12),
          selectedDark: alpha('#F45C25', 0.16),
        },
        eqolot: {
          sweetGreen: '#78E3A9',
          shiningBlue: '#02ACE9',
          tomatoRed: '#F44336',
          infoBlue: '#2196F3',
          brightOrange: '#FF9800',
          softGreen: '#4CAF50',
        },
        divider: alpha('#000', 0.12),
      },
      sidebar: {
        width: 240,
        color: clientOverrides.sidebarColor,
      },
      props: {
        MuiTextField: {
          size: 'small',
        },
        MuiChip: {
          size: 'small',
        },
        MuiCard: {
          elevation: 12,
        },
      },
      overrides: {
        MuiCssBaseline: {},
      },
      ...otherOptions,
    },
    muiTranslations,
  );

  // We want to use rem as our base spacing unit
  const spacingConverter = (factor) =>
    isNumber(factor)
      ? theme.typography.pxToRem(factor * BASE_UNIT_IN_PX)
      : factor;
  theme.spacing = (...factors) => factors.map(spacingConverter).join(' ');
  theme.overrides.MuiCssBaseline['@global'] = {
    html: {
      minHeight: '100vh',
      width: '100%',
      fontSize,
    },
    body: {
      height: '100%',
    },
    '#root': {
      height: '100%',
    },
    '.sentry-error-embed-wrapper': {
      zIndex: '1200 !important',
    },
  };

  theme.drawer = {
    transitions: {
      createShrink: (property) =>
        theme.transitions.create(property, {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      createGrow: (property) =>
        theme.transitions.create(property, {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
  };

  theme.logo = {
    src: clientOverrides.logoUrl,
    width: theme.sidebar.width,
  };

  theme.overrides.MuiChip = {
    root: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      fontWeight: 'bold',
      borderRadius: 6,
    },
    sizeSmall: {
      maxHeight: 22,
    },
  };

  theme.overrides.MuiTab = {
    root: {
      minWidth: '0 !important',
      padding: '6px 8px',
      whiteSpace: 'nowrap',
    },
  };

  theme.overrides.MuiButton = {
    root: {
      textTransform: 'none',
    },
    label: {
      fontWeight: 'bold',
    },
  };

  theme.overrides.MuiTooltip = {
    tooltip: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      letterSpacing: theme.typography.pxToRem(0.4),
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      padding: '4px 10px',
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    },
  };

  theme.overrides.MuiTextField = {
    root: {
      backgroundColor: '#fff',
    },
  };

  theme.overrides.MuiButtonBase = {
    root: {
      textTransform: 'none',
    },
  };

  theme.typography.h1 = {
    ...theme.typography.h1,
    fontSize: theme.typography.pxToRem(48),
    lineHeight: theme.typography.pxToRem(58),
    letterSpacing: theme.typography.pxToRem(-1.5),
  };
  theme.typography.h2 = {
    ...theme.typography.h2,
    fontSize: theme.typography.pxToRem(25),
    lineHeight: theme.typography.pxToRem(32),
    fontWeight: 'bold',
  };
  theme.typography.h3 = {
    ...theme.typography.h3,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
  };
  theme.typography.h4 = {
    ...theme.typography.h4,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.25),
    fontWeight: 'bold',
  };
  theme.typography.h5 = {
    ...theme.typography.h5,
    fontSize: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(23),
  };
  theme.typography.h6 = {
    ...theme.typography.h6,
    fontSize: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(23),
    fontWeight: 'bold',
  };
  theme.typography.body1 = {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  };
  theme.typography.body2 = {
    ...theme.typography.body2,
    fontSize: theme.typography.pxToRem(12.5),
    lineHeight: theme.typography.pxToRem(16),
  };
  theme.typography.subtitle1 = {
    ...theme.typography.subtitle1,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(0.15),
    fontWeight: 'bold',
  };
  theme.typography.subtitle2 = {
    ...theme.typography.subtitle2,
    fontSize: theme.typography.pxToRem(12.5),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 'bold',
  };
  theme.typography.button1 = {
    ...theme.typography.button1,
    fontSize: theme.typography.pxToRem(12.5),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 'bold',
  };
  theme.typography.button2 = {
    ...theme.typography.button2,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 'bold',
  };
  theme.typography.caption = {
    ...theme.typography.caption,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.4),
  };
  theme.typography.overline = {
    ...theme.typography.overline,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(1),
    fontWeight: 'bold',
  };
  theme.typography.ellipsis = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  theme.getCustomButtonColorStyles = (color) => ({
    contained: {
      backgroundColor: color,
      color: theme.palette.getContrastText(color),
      '&:hover': {
        backgroundColor: theme.palette.augmentColor({ main: color }).dark,
        '@media (hover: none)': {
          backgroundColor: color,
        },
      },
    },
  });

  return theme;
};

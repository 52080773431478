import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
  CampaignInfluencerSelectionLabel,
  CampaignInfluencerSelectionSummaryPropType,
  calculateBookedValuesDifference,
} from 'utils/campaign';
import { PlatformPropType } from 'utils/channel';

const useDialogStyles = makeStyles((theme) => ({
  confirmButton: {
    color: theme.palette.eqolot.infoBlue,
  },
}));

export const ConfirmationDialog = ({
  platform,
  selectionSummary,
  onConfirm,
  onCancel,
  ...otherProps
}) => {
  const classes = useDialogStyles();

  const confirmationMessage = React.useMemo(() => {
    const {
      selectionType,
      campaignBudget,
      confirmedBudget,
      campaignReach,
      confirmedReach,
    } = selectionSummary;

    const bookedValuesDifference = calculateBookedValuesDifference({
      booked: selectionType === 'reach' ? campaignReach : campaignBudget,
      confirmed: selectionType === 'reach' ? confirmedReach : confirmedBudget,
    });

    if (bookedValuesDifference * 100 >= 5) {
      return (
        <Typography>
          <FormattedMessage
            id="components.CampaignInfluencerSelectionButton.dialog.message.bookedValuesDifference"
            defaultMessage="Your selection of influencers differs by more than 5% different from the contractually agreed budget or reach for the campaign. By confirming your selection, you declare that you are aware of this. We will then contact you to update the contract."
          />
        </Typography>
      );
    }

    return (
      <Typography>
        <FormattedMessage
          id="components.CampaignInfluencerSelectionButton.dialog.message.confirmation"
          defaultMessage="Please confirm that these fine folks are the ones you want to work with in this campaign. After you confirm the selection, the Influencers will be contractually bound to work with us, and you won’t be able to change the selection anymore."
        />
      </Typography>
    );
  }, [selectionSummary]);

  return (
    <Dialog
      aria-labelledby="campaign-influencer-selection-dialog-title"
      onClose={onCancel}
      {...otherProps}
    >
      <DialogTitle id="campaign-influencer-selection-dialog-title">
        <FormattedMessage
          id="components.CampaignInfluencerSelectionButton.dialog.title"
          defaultMessage="Confirm final selection"
        />
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <CampaignInfluencerSelectionLabel
            platform={platform}
            selectionSummary={selectionSummary}
            withLineBreak
          />
          <br />
          {confirmationMessage}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          <FormattedMessage
            id="components.CampaignInfluencerSelectionButton.dialog.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button className={classes.confirmButton} onClick={onConfirm}>
          <FormattedMessage
            id="components.CampaignInfluencerSelectionButton.dialog.confirm"
            defaultMessage="Confirm"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  platform: PlatformPropType.isRequired,
  selectionSummary: CampaignInfluencerSelectionSummaryPropType.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: theme.getCustomButtonColorStyles(theme.palette.eqolot.infoBlue)
    .contained,
}));

const CampaignInfluencerSelectionButton = ({
  platform,
  selectionSummary,
  onConfirm,
  className,
  ...otherProps
}) => {
  const classes = useStyles();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  const handleConfirm = (...args) => {
    onConfirm(...args);
    setConfirmationDialogOpen(false);
  };

  const tooltipTitle = React.useMemo(() => {
    if (otherProps.disabled) {
      return (
        <FormattedMessage
          id="components.CampaignInfluencerSelectionButton.disabledTooltip"
          defaultMessage="Before you confirm the final selection, Accept or Reject all the Influencers who applied to this campaign."
        />
      );
    }

    // Empty string tooltip title means tooltip won't show
    return '';
  }, [otherProps.disabled]);

  return (
    <>
      <ConfirmationDialog
        platform={platform}
        open={confirmationDialogOpen}
        selectionSummary={selectionSummary}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={handleConfirm}
      />
      <Tooltip placement="top" title={tooltipTitle}>
        {/* div here because disabled controls don't send events, and the button is disabled */}
        <div>
          <Button
            variant="contained"
            className={clsx(classes.root, className)}
            onClick={() => setConfirmationDialogOpen(true)}
            {...otherProps}
          >
            <FormattedMessage
              id="components.CampaignInfluencerSelectionButton.label"
              defaultMessage="Confirm selection…"
            />
          </Button>
        </div>
      </Tooltip>
    </>
  );
};

CampaignInfluencerSelectionButton.propTypes = {
  platform: PlatformPropType.isRequired,
  selectionSummary: CampaignInfluencerSelectionSummaryPropType.isRequired,
  onConfirm: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CampaignInfluencerSelectionButton.defaultProps = {
  className: '',
};

export default CampaignInfluencerSelectionButton;

import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useFormStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    paddingBottom: theme.spacing(1),
  },
  boldField: {
    '& input, textarea': {
      fontWeight: 'bold',
    },
  },
}));

const UserForm = ({ onSubmit, onCancel }) => {
  const classes = useFormStyles();
  const intl = useIntl();
  const [submitEnabled, setSubmitEnabled] = React.useState(false);

  const {
    formState,
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {},
  });

  const { name, email } = watch();

  const submitForm = (data) => {
    onSubmit(data);
    setSubmitEnabled(false);
  };

  React.useEffect(() => {
    setSubmitEnabled(name && email && formState.isDirty);
  }, [name, email, formState.isDirty]);

  return (
    <form className={classes.root} onSubmit={handleSubmit(submitForm)}>
      <TextField
        {...register('name', { required: true })}
        className={classes.boldField}
        error={Boolean(errors.name)}
        label={intl.formatMessage({
          id: 'scenes.clients.details.newUser.form.name.label',
          defaultMessage: "The new user's name",
        })}
        variant="outlined"
        fullWidth
      />
      <TextField
        {...register('email', { required: true })}
        className={classes.boldField}
        error={Boolean(errors.email)}
        label={intl.formatMessage({
          id: 'scenes.clients.details.newUser.form.email.label',
          defaultMessage: "The new user's email",
        })}
        variant="outlined"
        fullWidth
      />
      <Box className={classes.actions}>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          {intl.formatMessage({
            id: 'scenes.clients.details.newUser.form.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          type="submit"
        >
          {intl.formatMessage({
            id: 'scenes.clients.details.newUser.form.save',
            defaultMessage: 'Save',
          })}
        </Button>
      </Box>
    </form>
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

UserForm.defaultProps = {};

const useStyles = makeStyles({
  content: {
    width: 320,
  },
});

const NewClientUserDialog = ({ onSave, onClose, ...otherProps }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Dialog onClose={onClose} {...otherProps}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'scenes.clients.details.newUser.dialog.title',
          defaultMessage: 'Add User',
        })}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box mb={2}>
          <Typography>
            {intl.formatMessage({
              id: 'scenes.clients.details.newUser.dialog.passwordMessage',
              defaultMessage: 'The invitation email is not sent automatically',
            })}
          </Typography>
        </Box>

        <UserForm onSubmit={onSave} onCancel={onClose} />
      </DialogContent>
    </Dialog>
  );
};

NewClientUserDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

NewClientUserDialog.defaultProps = {};

export default NewClientUserDialog;

import React from 'react';
import { useIntl } from 'react-intl';
import take from 'lodash/take';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { useTheme } from '@material-ui/core/styles';

import CountryLabel from 'components/CountryLabel';

import { formatDate } from 'utils/formatting';
import { ageGroups, getChannelMetrics } from 'utils/kpi';
import { ChannelPropType } from 'utils/channel';
import { getCountryCodeByName } from 'utils/countries';

import ChannelCardSection from './ChannelCardSection';
import ChannelCardKPI from './ChannelCardKPI';
import ChannelCardKPIRow from './ChannelCardKPIRow';

const InstagramKPI = ({ channel }) => {
  const theme = useTheme();
  const intl = useIntl();
  const metrics = getChannelMetrics(channel);

  const topCountriesKPIs = React.useMemo(() => {
    const topCountries = map(
      metrics.countryStatistics,
      (value, countryCode) => ({
        countryCode,
        value,
      }),
    );

    return take(
      sortBy(topCountries, (country) => -country.value),
      3,
    );
  }, [metrics.countryStatistics]);

  return (
    <>
      <ChannelCardKPIRow
        title={intl.formatMessage({
          id: 'components.ChannelCard.instagram.gender',
          defaultMessage: 'Gender',
        })}
      >
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.instagram.gender.f',
            defaultMessage: 'Women',
          })}
          value={metrics.femaleAudience}
          format="percent"
          color={theme.palette.primary.main}
          bold
        />
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.instagram.gender.m',
            defaultMessage: 'Men',
          })}
          value={metrics.maleAudience}
          format="percent"
          color={theme.palette.primary.main}
          bold
        />
      </ChannelCardKPIRow>
      <ChannelCardKPIRow
        title={intl.formatMessage({
          id: 'components.ChannelCard.instagram.ageRange',
          defaultMessage: 'Age Range',
        })}
      >
        {ageGroups.map((ageGroup) => (
          <ChannelCardKPI
            key={ageGroup}
            label={ageGroup}
            value={metrics.ageGroups[ageGroup]}
            format="percent"
            xs={3}
            bold
          />
        ))}
      </ChannelCardKPIRow>
      {!isEmpty(topCountriesKPIs) && (
        <ChannelCardKPIRow
          title={intl.formatMessage({
            id: 'components.ChannelCard.topCountries',
            defaultMessage: 'Top Countries',
          })}
        >
          {topCountriesKPIs.map(({ countryCode, value }) => (
            <ChannelCardKPI
              key={countryCode}
              label={<CountryLabel countryCode={countryCode} short />}
              value={value}
              format="percent"
              color={theme.palette.primary.main}
              bold
              withUnderline={false}
            />
          ))}
        </ChannelCardKPIRow>
      )}
    </>
  );
};

InstagramKPI.propTypes = {
  channel: ChannelPropType.isRequired,
};

const BlogKPI = ({ channel }) => {
  const theme = useTheme();
  const intl = useIntl();
  const kpi = getChannelMetrics(channel);

  const topCountriesKPIs = React.useMemo(
    () =>
      sortBy(kpi.countries, (item) => -item.percentage)
        .map((countryObj) => ({
          ...countryObj,
          value: countryObj.percentage / 100,
          countryCode: getCountryCodeByName(countryObj.country),
        }))
        .filter((countryObj) => !!countryObj.countryCode)
        .slice(0, 3),
    [kpi.countries],
  );

  if (isEmpty(topCountriesKPIs)) {
    return null;
  }

  return (
    <ChannelCardKPIRow
      title={intl.formatMessage({
        id: 'components.ChannelCard.topCountries',
        defaultMessage: 'Top Countries',
      })}
    >
      {topCountriesKPIs.map(({ countryCode, value }) => (
        <ChannelCardKPI
          key={countryCode}
          label={<CountryLabel countryCode={countryCode} short />}
          value={value}
          format="percent"
          color={theme.palette.primary.main}
          bold
          withUnderline={false}
        />
      ))}
    </ChannelCardKPIRow>
  );
};

BlogKPI.propTypes = {
  channel: ChannelPropType.isRequired,
};

const TikTokKPI = ({ channel }) => {
  const theme = useTheme();
  const intl = useIntl();
  const metrics = getChannelMetrics(channel);

  const topCountriesKPIs = React.useMemo(() => {
    const topCountries = map(
      metrics.countryStatistics,
      (value, countryCode) => ({
        countryCode,
        value,
      }),
    );

    return take(
      sortBy(topCountries, (country) => -country.value),
      3,
    );
  }, [metrics.countryStatistics]);

  return (
    <>
      <ChannelCardKPIRow
        title={intl.formatMessage({
          id: 'components.ChannelCard.tiktok.gender',
          defaultMessage: 'Gender',
        })}
      >
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.tiktok.gender.f',
            defaultMessage: 'Women',
          })}
          value={metrics.femaleAudience}
          format="percent"
          color={theme.palette.primary.main}
          bold
        />
        <ChannelCardKPI
          label={intl.formatMessage({
            id: 'components.ChannelCard.tiktok.gender.m',
            defaultMessage: 'Men',
          })}
          value={metrics.maleAudience}
          format="percent"
          color={theme.palette.primary.main}
          bold
        />
      </ChannelCardKPIRow>
      {!isEmpty(topCountriesKPIs) && (
        <ChannelCardKPIRow
          title={intl.formatMessage({
            id: 'components.ChannelCard.topCountries',
            defaultMessage: 'Top Countries',
          })}
        >
          {topCountriesKPIs.map(({ countryCode, value }) => (
            <ChannelCardKPI
              key={countryCode}
              label={<CountryLabel countryCode={countryCode} short />}
              value={value}
              format="percent"
              color={theme.palette.primary.main}
              bold
              withUnderline={false}
            />
          ))}
        </ChannelCardKPIRow>
      )}
    </>
  );
};

TikTokKPI.propTypes = {
  channel: ChannelPropType.isRequired,
};

const ChannelCardAudienceKPISection = ({ channel }) => {
  if (!['instagram', 'tiktok', 'website', 'ga'].includes(channel.platform)) {
    return null;
  }

  const intl = useIntl();
  const { updatedByAt } = getChannelMetrics(channel);

  const lastUpdatedDate = updatedByAt
    ? formatDate({
        isoDate: updatedByAt,
        formatter: intl.formatDate,
      })
    : '—';

  const lastUpdatedLabel = intl.formatMessage(
    { id: 'general.lastUpdated', defaultMessage: 'Last updated: {date}' },
    { date: lastUpdatedDate },
  );

  let component = null;
  if (channel.platform === 'instagram') {
    component = <InstagramKPI channel={channel} />;
  }

  if (channel.platform === 'tiktok') {
    component = <TikTokKPI channel={channel} />;
  }

  if (['ga', 'website'].includes(channel.platform)) {
    component = <BlogKPI channel={channel} />;
  }

  return (
    <ChannelCardSection
      title={intl.formatMessage({
        id: 'components.ChannelCard.audienceKPISection.title',
        defaultMessage: 'Audience KPIs',
      })}
      subtitle={lastUpdatedLabel}
    >
      {component}
    </ChannelCardSection>
  );
};

ChannelCardAudienceKPISection.propTypes = {
  channel: ChannelPropType.isRequired,
};

ChannelCardAudienceKPISection.defaultProps = {};

export default ChannelCardAudienceKPISection;

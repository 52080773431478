export default {
  possibleTypes: {
    ApplicationContentPreview: [
      'BlogContentPreview',
      'EmptyContentPreview',
      'InstagramContentPreview',
    ],
    ChannelInsights: [
      'BlogInsights',
      'EmptyInsights',
      'InstagramInsights',
      'TiktokInsights',
      'YoutubeInsights',
    ],
    ChannelKPI: ['EmptyKPI', 'InstagramKPI', 'TikTokKPI'],
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessage } from 'react-intl';
import set from 'lodash/set';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import EditableTextField from 'components/EditableTextField';
import MarkdownNote from 'components/MarkdownNote';
import { formatDateTime } from 'utils/formatting';
import { validateEmail } from 'utils/validations';

import { useUpdateClientUser } from '../../data/clientHooks';

const ClientTextField = ({
  value,
  onChange,
  label,
  inputLabel,
  required,
  validate,
  ...otherProps
}) => (
  <Box display="flex" flexDirection="column" {...otherProps}>
    <Box mb={1}>
      <Typography variant="overline" color="textSecondary">
        {label}
      </Typography>
    </Box>
    <EditableTextField
      label={inputLabel}
      value={value}
      onChange={onChange}
      validate={validate}
      required={required}
    />
  </Box>
);

ClientTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  validate: PropTypes.func,
  required: PropTypes.bool,
};
ClientTextField.defaultProps = {
  value: '',
  validate: () => true,
  required: false,
};

const fieldConfigs = {
  name: {
    label: defineMessage({
      id: 'scenes.clients.details.userInspector.editNameLabel',
      defaultMessage: 'Name',
    }),
    inputLabel: defineMessage({
      id: 'scenes.clients.details.userInspector.editNameLabel.input',
      defaultMessage: 'Edit Name (required)',
    }),
  },

  email: {
    label: defineMessage({
      id: 'scenes.clients.details.userInspector.editEmailLabel',
      defaultMessage: 'Email',
    }),
    inputLabel: defineMessage({
      id: 'scenes.clients.details.userInspector.editEmailLabel.input',
      defaultMessage: 'Edit Email (required)',
    }),
  },

  salesforceId: {
    label: defineMessage({
      id: 'scenes.clients.details.userInspector.editSalesforceIdlLabel',
      defaultMessage: 'Salesforce Contact',
    }),
    inputLabel: defineMessage({
      id: 'scenes.clients.details.userInspector.editSalesforceIdlLabel.input',
      defaultMessage: 'Edit Salesforce Contact ID',
    }),
  },

  kamNote: {
    label: defineMessage({
      id: 'scenes.clients.details.userInspector.editKamNotelLabel',
      defaultMessage: 'Internal Kam Note',
    }),
  },
};

const ClientUserInspectorBody = ({ clientId, user, ...otherProps }) => {
  const intl = useIntl();

  const { updateClientUser } = useUpdateClientUser();

  const createChangeHandler = (fieldName) => (newValue) => {
    const payload = {};
    set(payload, fieldName, newValue);

    updateClientUser({
      clientId,
      userId: user.id,
      payload,
    });
  };

  return (
    <Box p={2} display="flex" flexDirection="column" {...otherProps}>
      <ClientTextField
        mb={3}
        value={user.name}
        onChange={createChangeHandler('name')}
        label={intl.formatMessage(fieldConfigs.name.label)}
        inputLabel={intl.formatMessage(fieldConfigs.name.inputLabel)}
        required
      />
      <ClientTextField
        mb={3}
        value={user.email}
        onChange={createChangeHandler('email')}
        label={intl.formatMessage(fieldConfigs.email.label)}
        inputLabel={intl.formatMessage(fieldConfigs.email.inputLabel)}
        validate={validateEmail}
        required
      />
      <ClientTextField
        mb={3}
        value={user.salesforceId}
        onChange={createChangeHandler('salesforceId')}
        label={intl.formatMessage(fieldConfigs.salesforceId.label)}
        inputLabel={intl.formatMessage(fieldConfigs.salesforceId.inputLabel)}
      />
      <MarkdownNote
        editable
        onEdit={createChangeHandler('kamNote.note')}
        label={intl.formatMessage(fieldConfigs.kamNote.label)}
        editLabel={intl.formatMessage({
          id: 'scenes.clients.details.userInspector.kamNote.editLabel',
          defaultMessage: 'Only shown to the Account Manager',
        })}
        note={user?.kamNote?.note}
      />
      <Box display="flex" flexDirection="column">
        <Box mb={1}>
          <Typography variant="overline" color="textSecondary">
            {intl.formatMessage({
              id: 'scenes.clients.details.userInspector.updatedAt',
              defaultMessage: 'Last Updated',
            })}
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          {user.updatedAt
            ? formatDateTime({
                isoDate: user.updatedAt,
                formatter: intl.formatDate,
              })
            : '—'}
        </Typography>
      </Box>
    </Box>
  );
};

ClientUserInspectorBody.propTypes = {
  clientId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    salesforceId: PropTypes.string,
    kamNote: PropTypes.shape({
      note: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
  }).isRequired,
};
ClientUserInspectorBody.defaultProps = {};

export default ClientUserInspectorBody;

import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { useIntl, FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import omit from 'lodash/omit';

import TableCell from '@material-ui/core/TableCell';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import GenderIcon from 'icons/Gender';

import { LocationLabel } from 'utils/user';
import { formatDate } from 'utils/formatting';
import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellClientReviewStatus from 'components/table/TableCellClientReviewStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeftWidth: '20px',
    borderLeftColor: theme.palette.primary.main,
    borderLeftStyle: 'solid',
  },
  expanded: {
    borderLeftWidth: '10px',
  },
  selected: {
    borderLeftColor: theme.palette.secondary.main,
  },
  channelExpandIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const PrivateNetworkClientUserTableRow = React.memo(
  ({
    user,
    expanded,
    onExpand,
    numberOfChannels,
    createdAt,
    clientReview,
    selected,
    hasSelectedChannel,
    ...otherProps
  }) => {
    const theme = useTheme();
    const intl = useIntl();
    const classes = useStyles();

    const handleExpand = (event) => {
      event.stopPropagation();
      onExpand();
    };

    return (
      <TableRow
        className={clsx(classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected && !hasSelectedChannel,
        })}
        color={theme.palette.primary.light}
        selectable
        selected={selected}
        {...omit(otherProps, ['isHidden', 'kamReview'])}
      >
        <TableCellClientReviewStatus
          clientReview={clientReview}
          sortDirection={false}
        />
        <TableCellText bold>{user.firstname}</TableCellText>
        <TableCell align="center" sortDirection={false}>
          <GenderIcon gender={user.salutation} />
        </TableCell>
        <TableCellText bold>
          <LocationLabel user={user} />
        </TableCellText>
        <TableCellText bold>
          {formatDate({
            isoDate: createdAt,
            formatter: intl.formatDate,
          })}
        </TableCellText>
        <TableCellText colSpan={2} bold>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="9rem"
          >
            <Box marginRight={2}>
              <FormattedMessage
                id="PrivateNetworkTable.numberOfChannelsCell"
                defaultMessage="{numberOfChannels, plural, one {1 channel} other {# channels}}"
                values={{
                  numberOfChannels,
                }}
              />
            </Box>
            <IconButton
              className={clsx({
                [classes.channelExpandIcon]: !expanded,
              })}
              onClick={handleExpand}
            >
              {expanded ? (
                <Icons.ChevronUp size={15} color={theme.palette.primary.main} />
              ) : (
                <Icons.ChevronDown size={15} color="#FFF" />
              )}
            </IconButton>
          </Box>
        </TableCellText>
      </TableRow>
    );
  },
  (prevProps, nextProps) =>
    prevProps.user.id === nextProps.user.id &&
    prevProps.expanded === nextProps.expanded &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.status === nextProps.status,
);

PrivateNetworkClientUserTableRow.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  hasSelectedChannel: PropTypes.bool,
  user: PropTypes.object,
  numberOfChannels: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
  clientReview: PropTypes.shape({
    note: PropTypes.string,
    status: PropTypes.oneOf(['pending', 'approved', 'rejected', 'undecided'])
      .isRequired,
  }),
};

PrivateNetworkClientUserTableRow.defaultProps = {
  selected: false,
  hasSelectedChannel: false,
  user: {},
  numberOfChannels: 0,
  clientReview: {
    note: '',
    status: 'pending',
  },
};

export default PrivateNetworkClientUserTableRow;

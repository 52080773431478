import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';

import { AlignPropType } from 'utils/table';
import Tags from 'components/Tags';

const TableCellTags = ({
  tags,
  getTagValue,
  getTagLabel,
  align,
  sortBy,
  variant,
  ...otherProps
}) => (
  <TableCell align={align} {...otherProps}>
    <Tags
      tags={tags}
      getTagValue={getTagValue}
      getTagLabel={getTagLabel}
      sortBy={sortBy}
      variant={variant}
    />
  </TableCell>
);

TableCellTags.propTypes = {
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  sortBy: PropTypes.string,
  align: AlignPropType,
  getTagLabel: PropTypes.func,
  getTagValue: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'outlined']),
};

TableCellTags.defaultProps = {
  tags: [],
  sortBy: '',
  align: 'left',
  getTagLabel: (tag) => tag,
  getTagValue: (tag) => tag,
};

export default TableCellTags;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';

import { SortObjectPropType } from 'utils/sorting';

const PrivateNetworkKamTableHead = ({
  currentSort,
  onSort,
  clientName,
  ...otherProps
}) => (
  <TableHead {...otherProps}>
    <TableRow>
      <TableCellHead
        align="center"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="kamReviewStatus"
        colSpan={2}
      >
        <FormattedMessage
          id="PrivateNetworkTable.status"
          defaultMessage="Status"
        />
      </TableCellHead>
      <TableCellHead colSpan={2} noWrap>
        <FormattedMessage
          id="PrivateNetworkTable.userAndChannel"
          defaultMessage="Influencer / Channel"
        />
      </TableCellHead>
      <TableCellHead
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="topCountry"
        noWrap
        tooltip={
          <FormattedMessage
            id="PrivateNetworkTable.topCountry.tooltip"
            defaultMessage="Location of the Influencers Follower"
          />
        }
      >
        <FormattedMessage
          id="PrivateNetworkTable.topCountry"
          defaultMessage="Top Country"
        />
      </TableCellHead>
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="reach"
        tooltip={
          <FormattedMessage
            id="PrivateNetworkTable.reach.tooltip"
            defaultMessage="Automatically tracked by the eqolot platform for registered Influencers"
          />
        }
      >
        <FormattedMessage
          id="PrivateNetworkTable.reach"
          defaultMessage="Reach"
        />
      </TableCellHead>
      <TableCellHead
        align="left"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="rating"
        tooltip={
          <FormattedMessage
            id="PrivateNetworkTable.rating.tooltip"
            defaultMessage="This channel's average rating on the eqolot platform"
          />
        }
      >
        <FormattedMessage
          id="PrivateNetworkTable.rating"
          defaultMessage="Rating"
        />
      </TableCellHead>
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="PrivateNetworkTable.clientTags.tooltip"
            defaultMessage="The client's tags"
          />
        }
      >
        <FormattedMessage
          id="PrivateNetworkTable.clientTags"
          defaultMessage="{clientName} Tags"
          values={{
            clientName,
          }}
        />
      </TableCellHead>
    </TableRow>
  </TableHead>
);

PrivateNetworkKamTableHead.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  clientName: PropTypes.string,
};

PrivateNetworkKamTableHead.defaultProps = {};

export default PrivateNetworkKamTableHead;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CardMedia from 'components/CardMedia';
import Tags from 'components/Tags';
import { Feature } from 'utils/features';
import { DateLabel, PriceLabel } from 'utils/formatting';
import { NonPropagatingLink } from 'utils/routing';

import ContentCardWrapper from './InfluencerAssetCardWrapper';
import MediaViewerModal from '../MediaViewerModal';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
  },
  media: {
    position: 'absolute',
    objectFit: 'contain',
    backgroundColor: '#000',
  },
  content: {
    paddingBottom: 0,
  },
  actions: {
    paddingTop: theme.spacing(1),
  },
}));

const ContentCardFullScreen = ({
  id,
  mediaType,
  mediaSrc,
  tags,
  getTagLabel,
  onInspect,
  onDownload,
  onClose,
  createdAt,
  buyOutPrice,
  autoPlay,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <MediaViewerModal onClose={onClose} open>
      <ContentCardWrapper
        selectable={false}
        className={classes.root}
        {...otherProps}
      >
        <CardMedia
          type={mediaType}
          src={mediaSrc}
          playable
          autoPlay={autoPlay}
          clickable={false}
          height="100%"
          className={classes.media}
        />
        <CardContent className={classes.content}>
          <Tags tags={tags} getTagLabel={getTagLabel} />
        </CardContent>
        <CardActions className={classes.actions} disableSpacing>
          <Box display="flex" flexGrow="1">
            <DateLabel
              date={createdAt}
              ml={1}
              label={
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'components.ContentCard.labels.creationDate',
                    defaultMessage: 'Creation Date',
                  })}
                </Typography>
              }
            />
            <Feature feature="influencerAssetsBuyOutPrice">
              <PriceLabel
                value={buyOutPrice}
                ml={1}
                label={
                  <Typography variant="body2" color="textSecondary">
                    {intl.formatMessage({
                      id: 'components.ContentCard.labels.buyOutPrice',
                      defaultMessage: 'Buyout Price',
                    })}
                  </Typography>
                }
              />
            </Feature>
          </Box>
          <Button
            startIcon={<Icons.Download />}
            color="primary"
            onClick={onDownload}
          >
            {intl.formatMessage({
              id: 'components.ContentCard.action.download',
              defaultMessage: 'Download',
            })}
          </Button>
          <Button
            startIcon={<Icons.Info />}
            color="primary"
            onClick={onInspect}
          >
            {intl.formatMessage({
              id: 'components.ContentCard.action.inspect',
              defaultMessage: 'Details',
            })}
          </Button>
          <Button
            component={NonPropagatingLink}
            to="/influencer-assets"
            startIcon={<Icons.ZoomOut />}
            color="secondary"
          >
            {intl.formatMessage({
              id: 'components.ContentCard.action.zoomOut',
              defaultMessage: 'Zoom out',
            })}
          </Button>
        </CardActions>
      </ContentCardWrapper>
    </MediaViewerModal>
  );
};

ContentCardFullScreen.propTypes = {
  id: PropTypes.string.isRequired,
  mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
  mediaSrc: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  getTagLabel: PropTypes.func.isRequired,
  buyOutPrice: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  onInspect: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ContentCardFullScreen.defaultProps = {
  tags: [],
  autoPlay: true,
};

export default ContentCardFullScreen;

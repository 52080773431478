import React from 'react';
import * as Icons from 'react-feather';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { loadingString } from 'components/LoadingState';
import Toggle from 'components/Toggle';
import config from 'config';

import {
  useUpdateClientUser,
  useResetClientUserPassword,
} from '../../data/clientHooks';

const ClientUserInspectorHeader = ({ clientId, user, ...otherProps }) => {
  const theme = useTheme();
  const intl = useIntl();

  const { updateClientUser } = useUpdateClientUser();
  const { resetClientUserPassword, loading: loadingPasswordReset } =
    useResetClientUserPassword();

  const toggleBlocked = (newStatus) => {
    updateClientUser({
      clientId,
      userId: user.id,
      payload: {
        blocked: newStatus,
      },
    });
  };

  const handleUserPasswordReset = () => {
    resetClientUserPassword({
      clientId,
      userId: user.id,
    });
  };

  const passwordResetButtonLabel = React.useMemo(() => {
    if (loadingPasswordReset) {
      return intl.formatMessage(loadingString);
    }

    return intl.formatMessage({
      id: 'scenes.clients.details.userInspector.resetPassword',
      defaultMessage: 'Reset Password',
    });
  }, [intl, loadingPasswordReset]);

  return (
    <Box
      bgcolor={user.blocked ? 'primary.light' : 'secondary.light'}
      pt={8}
      pb={2}
      px={2}
      {...otherProps}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        underline="none"
        component={Link}
        target="_blank"
        rel="noreferrer"
        href={`https://manage.auth0.com/dashboard/eu/${config.auth.usersLinkDomain}/users/${user.id}`}
        mb={2}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" color="textPrimary">
            {intl.formatMessage({
              id: 'scenes.clients.details.userInspector.auth0Link',
              defaultMessage: 'Show in Auth0',
            })}
          </Typography>
          <Typography variant="caption" color="textPrimary">
            {user.id}
          </Typography>
        </Box>
        <Icons.ExternalLink color={theme.palette.primary.main} />
      </Box>
      <Toggle
        label={
          user.blocked
            ? intl.formatMessage({
                id: 'scenes.clients.details.userInspector.blocked',
                defaultMessage: 'Locked (click to unlock)',
              })
            : intl.formatMessage({
                id: 'scenes.clients.details.userInspector.notBlocked',
                defaultMessage: 'Not Locked (click to lock)',
              })
        }
        value={user.blocked}
        onChange={toggleBlocked}
      />
      <Box mt={2}>
        <Button
          onClick={handleUserPasswordReset}
          variant="contained"
          color="primary"
          disabled={loadingPasswordReset}
        >
          {passwordResetButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};

ClientUserInspectorHeader.propTypes = {
  clientId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    salesforceId: PropTypes.string,
    kamNote: PropTypes.shape({
      note: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
    blocked: PropTypes.bool,
  }).isRequired,
};
ClientUserInspectorHeader.defaultProps = {};

export default ClientUserInspectorHeader;

import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import clsx from 'clsx';

import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

const useWrapperStyles = makeStyles((theme) => ({
  selectedOverlay: {
    position: 'absolute',
    right: 0,
  },
  selectedIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
  },
  selectedActionArea: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
}));

const ContentCardWrapper = ({
  children,
  selectable,
  selected,
  onSelect,
  ...otherProps
}) => {
  const classes = useWrapperStyles();
  const theme = useTheme();

  if (selectable) {
    return (
      <Card onClick={onSelect} {...otherProps}>
        <CardActionArea
          className={clsx({
            [classes.selectedActionArea]: selected,
          })}
          component="div"
        >
          {selected && (
            <Box className={classes.selectedOverlay}>
              <Box className={classes.selectedIconContainer}>
                <Icons.CheckCircle color={theme.palette.secondary.dark} />
              </Box>
            </Box>
          )}
          {children}
        </CardActionArea>
      </Card>
    );
  }

  return <Card {...otherProps}>{children}</Card>;
};

ContentCardWrapper.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
};

ContentCardWrapper.defaultProps = {
  selected: false,
  selectable: false,
  onSelect: null,
};

export default ContentCardWrapper;

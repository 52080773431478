import React from 'react';

import SvgIconWrapper from './SvgIconWrapper';

export default (props) => (
  <SvgIconWrapper {...props}>
    <path
      d="M20.6603 8.83013L18.8301 2M18.8301 2L12 3.83013M18.8301 2L13.8301 10.6603M16.5 16.5C16.5 19.8137 13.8137 22.5 10.5 22.5C7.18629 22.5 4.5 19.8137 4.5 16.5C4.5 13.1863 7.18629 10.5 10.5 10.5C13.8137 10.5 16.5 13.1863 16.5 16.5Z"
      stroke="black"
      strokeOpacity="0.54"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconWrapper>
);

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import memoize from 'lodash/memoize';

import { makeStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { getInitials } from 'utils/user';

export const doesGravatarExist = memoize(async (url, options) => {
  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error('Gravatar not found!');
  }

  return response.ok;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => theme.typography.pxToRem(props.size),
    height: (props) => theme.typography.pxToRem(props.size),
    backgroundColor: (props) =>
      props.backgroundColor || theme.palette.primary.light,
  },
}));

const Avatar = (props) => {
  const {
    url,
    email,
    fallbackName,
    size,
    className,
    backgroundColor,
    textVariant,
    ...otherProps
  } = props;
  const classes = useStyles(props);

  const combinedClassName = clsx(classes.root, className);
  const initials = getInitials(fallbackName);

  if (!url) {
    return (
      <MuiAvatar
        className={combinedClassName}
        alt={fallbackName}
        {...otherProps}
      >
        {textVariant ? (
          <Typography variant={textVariant}>{initials}</Typography>
        ) : (
          initials
        )}
      </MuiAvatar>
    );
  }

  return (
    <MuiAvatar
      className={combinedClassName}
      src={url}
      alt={fallbackName}
      {...otherProps}
    />
  );
};

Avatar.propTypes = {
  url: PropTypes.string,
  email: PropTypes.string,
  fallbackName: PropTypes.string.isRequired,
  size: PropTypes.number,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  textVariant: PropTypes.string,
};

Avatar.defaultProps = {
  size: 64,
  className: '',
  backgroundColor: undefined,
  textVariant: undefined,
};

export default Avatar;

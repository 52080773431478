import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { useGridColumns } from 'utils/mediaHooks';

export const ContentGrid = ({
  size,
  children,
  columnWidths,
  ...otherProps
}) => {
  const columns = useGridColumns(columnWidths);

  const cols = size === 'reduced' ? columns - 1 : columns;

  return (
    <ImageList
      component="div"
      rowHeight="auto"
      gap={24}
      {...otherProps}
      cols={cols}
    >
      {children}
    </ImageList>
  );
};

ContentGrid.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['full', 'reduced']),
  columnWidths: PropTypes.arrayOf(PropTypes.number),
};

ContentGrid.defaultProps = {
  size: 'full',
  columnWidths: [768, 1024, 1280, 1536, 1900, 2048],
};

const useStyles = makeStyles({
  item: {
    overflow: 'inherit',
  },
});

export const ContentGridItem = ({ children, ...otherProps }) => {
  const classes = useStyles();

  return (
    <ImageListItem
      component="div"
      classes={{
        item: classes.item,
      }}
      {...otherProps}
    >
      {children}
    </ImageListItem>
  );
};

ContentGridItem.propTypes = {
  children: PropTypes.node.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Icons from 'react-feather';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import useAuth from 'auth/useAuth';
import { useClient } from 'utils/client';
import { useFeatures } from 'utils/features';

import EqolotIcon from 'icons/Eqolot';
import Avatar from 'components/avatars/Avatar';
import { useWhatsNew } from 'utils/whatsNew';

import SidebarLink from './SidebarLink';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.sidebar.width,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.sidebar.width,
    background: theme.sidebar.color,
  },
  brandLogo: {
    objectFit: 'cover',
    // -1 because of side-scroll
    width: theme.typography.pxToRem(theme.logo.width - 1),
  },
  staticLinksList: {
    marginTop: 'auto',
  },
  avatar: {
    fontSize: theme.typography.pxToRem(10),
    backgroundColor: theme.palette.secondary.main,
  },
  userName: {
    color: theme.palette.secondary.contrastText,
  },
  hasUnseenUpdatesBadge: {
    '&:after': {
      position: 'absolute',
      top: 7,
      right: 0,
      bottom: 0,
      left: 30,
      width: 12,
      height: 12,
      borderRadius: '50%',
      border: '2px solid white',
      backgroundColor: 'red',
      content: '""',
    },
  },
}));

const Sidebar = ({ className, routes, ...otherProps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { isFeatureAvailable } = useFeatures();
  const { client } = useClient();
  const { hasUnseenUpdates } = useWhatsNew();

  const { isAuthenticated, logout: authClientLogout, user } = useAuth();

  const logout = async () => {
    await authClientLogout();
  };

  const companyLogo = React.useMemo(() => {
    if (client?.theme?.brandLogoUrl) {
      return (
        <img
          src={client.theme.brandLogoUrl}
          width="40%"
          height="auto"
          alt={client.name}
          title={client.name}
        />
      );
    }

    return <EqolotIcon width={100} height={45} />;
  }, [client?.theme?.brandLogoUrl]);

  const navLinks = routes
    .filter((route) => !route.feature || isFeatureAvailable(route.feature))
    .filter(
      (route) => isEmpty(route.roles) || route.roles.includes(client?.role),
    )
    .map((route) => (
      <SidebarLink key={route.url} hidden={!isAuthenticated} {...route} />
    ));

  return (
    <Drawer
      className={clsx(classes.drawer, className)}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      PaperProps={{
        elevation: 9,
      }}
      {...otherProps}
    >
      <img
        className={classes.brandLogo}
        src={theme.logo.src}
        alt="Brand Logo"
      />
      <List disablePadding>{navLinks}</List>
      <List disablePadding className={classes.staticLinksList}>
        <SidebarLink
          label={intl.formatMessage({
            id: 'sidebar.links.whatsNew',
            defaultMessage: "What's new",
          })}
          url="/insider/whats-new"
          hidden={!isAuthenticated}
          icon={
            <>
              <Icons.Bell />
              {hasUnseenUpdates && (
                <span className={classes.hasUnseenUpdatesBadge} />
              )}
            </>
          }
        />
        <ListItem>
          <ListItemIcon>
            <Avatar
              className={classes.avatar}
              size={24}
              email={user.email}
              fallbackName={user.name}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography noWrap className={classes.userName}>
              {user.name}
            </Typography>
          </ListItemText>
        </ListItem>
        <SidebarLink
          label={intl.formatMessage({
            id: 'sidebar.links.logout',
            defaultMessage: 'Sign-out',
          })}
          onClick={logout}
          hidden={!isAuthenticated}
          icon={<Icons.LogOut />}
        />
      </List>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        mt={2}
        mb={2}
      >
        {companyLogo}
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

Sidebar.defaultProps = {
  routes: [],
  className: '',
};

export default Sidebar;

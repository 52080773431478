import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ListItemText from '@material-ui/core/ListItemText';

import TagsInput from 'components/TagsInput';

const TagsFilter = ({
  options,
  label,
  value,
  onChange,
  getFilterLabel,
  ...otherProps
}) => {
  const intl = useIntl();

  const handleChange = (event, newValues) => {
    onChange(newValues);
  };

  const filterOptions = React.useCallback(
    (options, { inputValue }) =>
      options.filter((filterId) => {
        const label = getFilterLabel(filterId);

        const lowercaseInputValue = inputValue.toLowerCase();
        return intl
          .formatMessage(label)
          .toLowerCase()
          .includes(lowercaseInputValue);
      }),
    [intl, getFilterLabel],
  );

  const renderOption = React.useCallback(
    (filterId) => {
      const label = getFilterLabel(filterId);

      return <ListItemText primary={intl.formatMessage(label)} />;
    },
    [intl, getFilterLabel],
  );

  const getTagLabel = React.useCallback(
    (filterId) => {
      const label = getFilterLabel(filterId);
      return intl.formatMessage(label);
    },
    [intl, getFilterLabel],
  );

  return (
    <TagsInput
      value={value}
      options={options}
      renderOption={renderOption}
      getTagLabel={getTagLabel}
      onChange={handleChange}
      label={label}
      filterOptions={filterOptions}
      {...otherProps}
    />
  );
};

TagsFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  getFilterLabel: PropTypes.func.isRequired,
};
TagsFilter.defaultProps = {
  value: [],
};

export default TagsFilter;

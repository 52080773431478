import React from 'react';
import { useIntl } from 'react-intl';
import countries from 'i18n-iso-countries';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { DeliveryAddressPropType } from 'utils/application';

const Address = ({ address, ...otherProps }) => {
  const intl = useIntl();

  if (address) {
    const {
      city,
      firstname,
      lastname,
      postalCode,
      street,
      streetNumber,
      country,
    } = address;

    return (
      <Box display="flex" flexDirection="column">
        <Typography {...otherProps}>
          {firstname} {lastname}
        </Typography>
        <Typography {...otherProps}>
          {street} {streetNumber}
        </Typography>
        <Typography {...otherProps}>
          {postalCode} {city}
        </Typography>
        <Typography {...otherProps}>
          {countries.getName(country, intl.locale)}
        </Typography>
      </Box>
    );
  }

  return <Typography {...otherProps}>—</Typography>;
};

Address.propTypes = {
  address: DeliveryAddressPropType,
};

Address.defaultProps = {};

export default Address;

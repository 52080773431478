import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import PersistentDrawer from 'components/PersistentDrawer';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: (props) => theme.typography.pxToRem(props.width),
  },
  drawerPaper: {
    border: 'none',
    backgroundColor: 'transparent',
    width: (props) => theme.typography.pxToRem(props.width),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
}));

const InspectorDrawer = (props) => {
  const { className, children, width, ...otherProps } = props;

  const classes = useStyles(props);

  return (
    <PersistentDrawer
      className={clsx(className, classes.drawer)}
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{
        elevation: 1,
      }}
      {...otherProps}
    >
      {children}
    </PersistentDrawer>
  );
};

InspectorDrawer.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};
InspectorDrawer.defaultProps = {
  className: '',
};

export default InspectorDrawer;

import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { useIntl, defineMessage } from 'react-intl';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { formatDate } from 'utils/formatting';
import { InfluencerAssetPublicationPropType } from 'utils/influencerAssets';
import Url from 'components/Url';
import StickyPostIcon from 'icons/StickyPost';

import Subheader from './Subheader';
import StaticListItem from './StaticListItem';

const usePublicationItemStyles = makeStyles((theme) => ({
  root: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1.5),
    },
  },
}));

const PublicationItem = ({ publication, ...otherProps }) => {
  const intl = useIntl();
  const classes = usePublicationItemStyles();
  const { publishedAt, medium, comment } = publication;

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="row"
      alignItems="center"
      {...otherProps}
    >
      <Typography>
        {formatDate({
          isoDate: publishedAt,
          formatter: intl.formatDate,
        })}
      </Typography>
      <Chip label={medium || '—'} variant="outlined" />
      {comment && (
        <Tooltip title={comment} placement="top">
          <Box display="flex">
            <StickyPostIcon />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

PublicationItem.propTypes = {
  publication: InfluencerAssetPublicationPropType.isRequired,
};

const useStyles = makeStyles({
  publicationActions: {
    visibility: 'hidden',
  },
  listItemBox: {
    '&:hover $publicationActions': {
      visibility: 'visible',
    },
  },
  plusButton: {
    minWidth: 47,
  },
});

const headlineString = defineMessage({
  id: 'components.InfluencerAssetInspector.headline.publicationHistory',
  defaultMessage: 'Publication history',
});

const InfluencerAssetInspectorPublications = ({
  publications,
  onAddPublicationClick,
  onDeletePublicationClick,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  return (
    <List
      subheader={
        <Subheader label={intl.formatMessage(headlineString)}>
          <ListItemSecondaryAction>
            <Button
              className={classes.plusButton}
              onClick={onAddPublicationClick}
              size="small"
              variant="contained"
              color="primary"
            >
              +
            </Button>
          </ListItemSecondaryAction>
        </Subheader>
      }
    >
      {publications.map((publication) => (
        <Box key={publication.id} className={classes.listItemBox}>
          <StaticListItem
            button
            disableRipple
            label={<Url url={publication.url} />}
            value={<PublicationItem publication={publication} />}
          >
            <ListItemSecondaryAction className={classes.publicationActions}>
              <IconButton
                edge="end"
                onClick={() => onDeletePublicationClick(publication.id)}
              >
                <Icons.Trash2 color={theme.palette.primary.main} />
              </IconButton>
            </ListItemSecondaryAction>
          </StaticListItem>
          <Divider variant="middle" component="li" />
        </Box>
      ))}
    </List>
  );
};

InfluencerAssetInspectorPublications.propTypes = {
  publications: PropTypes.arrayOf(InfluencerAssetPublicationPropType),
  onAddPublicationClick: PropTypes.func.isRequired,
  onDeletePublicationClick: PropTypes.func.isRequired,
};

InfluencerAssetInspectorPublications.defaultProps = {
  publications: [],
};

export default InfluencerAssetInspectorPublications;

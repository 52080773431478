import React from 'react';
import PropTypes from 'prop-types';

import SearchField from 'components/SearchField';

const SearchFilter = ({ value: valueProp, onChange, ...otherProps }) => {
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = (value) => {
    setValue(value);
  };

  const handleClear = () => {
    if (value) {
      setValue('');
      onChange(null);
    }
  };

  return (
    <SearchField
      value={value}
      onChange={handleChange}
      onClear={handleClear}
      onEnter={onChange}
      variant="outlined"
      {...otherProps}
    />
  );
};

SearchFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
SearchFilter.defaultProps = {
  value: '',
};

export default SearchFilter;

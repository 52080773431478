import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { useQueryParams, StringParam } from 'utils/routing';
import { views as campaignViews } from 'utils/campaign';
import { ContentGrid, ContentGridItem } from 'layout/ContentGrid';
import LoadingState from 'components/LoadingState';
import ApplicationContentPreviewCard from 'components/applications/ApplicationContentPreviewCard';

import CampaignContentReviewInspector, {
  drawerWidth as inspectorWidth,
} from './CampaignContentReviewInspector';
import GridStatusMessage from './GridStatusMessage';
import CampaignDetailsPageContainer from '../CampaignDetailsPageContainer';
import { useOne as useCampaign } from '../../data/campaignHooks';

const useStyles = makeStyles((theme) => ({
  contentGrid: {
    overflow: 'visible',
  },
  controls: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
  controlsRow: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(4),
    },
  },
}));

const CampaignContentReviewPage = ({ campaignId }) => {
  const classes = useStyles();

  const { data, loading, error } = useCampaign({
    id: campaignId,
    applicationsFilter: 'byClientContentPreview',
  });

  if (error) {
    throw new Error(`Error fetching campaign: ${error}`);
  }

  const campaign = data?.campaign || { applications: [] };

  const [query, setQuery] = useQueryParams({
    inspect: StringParam,
  });

  const [selectedApplicationId, setSelectedApplicationId] = React.useState(
    query.inspect,
  );

  const selectedApplication = React.useMemo(() => {
    if (!selectedApplicationId) {
      return null;
    }

    return find(campaign.applications, { id: selectedApplicationId });
  }, [selectedApplicationId, campaign.applications]);

  // Sync query param with state (when back button is used, for example)
  React.useEffect(() => {
    if (query.inspect !== selectedApplicationId) {
      setSelectedApplicationId(query.inspect);
    }
  }, [query.inspect, selectedApplicationId]);

  const inspectApplication = (id) => {
    setSelectedApplicationId(id);
    setQuery({ inspect: id });
  };

  const uninspectApplication = () => {
    setSelectedApplicationId(null);
    setQuery({ inspect: undefined });
  };

  const sortedApplications = React.useMemo(
    () => sortBy(campaign.applications, 'contentPreview.updatedAt').reverse(),
    [campaign.applications, sortBy],
  );

  return (
    <CampaignDetailsPageContainer
      campaign={campaign}
      drawerOpen={!!selectedApplicationId}
      drawerWidth={inspectorWidth}
      loading={loading}
      view={campaignViews.CONTENT_REVIEW}
      renderDrawer={() => (
        <CampaignContentReviewInspector
          platform={campaign.platform}
          application={selectedApplication}
          onClose={uninspectApplication}
        />
      )}
    >
      <Box className={classes.controls}>
        <Box className={classes.controlsRow}>
          <GridStatusMessage
            applications={campaign.applications}
            loading={loading}
          />
        </Box>
      </Box>
      {loading && <LoadingState />}
      {!loading && (
        <ContentGrid
          size={selectedApplicationId ? 'reduced' : 'full'}
          columnWidths={[1200, 1600, 1900]}
          className={classes.contentGrid}
        >
          {sortedApplications.map((application) => (
            <ContentGridItem key={application.id}>
              <ApplicationContentPreviewCard
                application={application}
                onSelect={inspectApplication}
                selected={selectedApplicationId === application.id}
              />
            </ContentGridItem>
          ))}
        </ContentGrid>
      )}
    </CampaignDetailsPageContainer>
  );
};

CampaignContentReviewPage.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
CampaignContentReviewPage.defaultProps = {};

export default CampaignContentReviewPage;

import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

const KAM_APPLICATION_FIELDS = gql`
  fragment kamApplicationFields on PrivateNetworkApplication {
    hiddenFromClient
    kamReview {
      status
      note
      updatedAt
      updatedBy
    }
  }
`;

const COMMON_APPLICATION_FIELDS = gql`
  fragment commonApplicationFields on PrivateNetworkApplication {
    id
    createdAt
    user {
      id
      firstname
      salutation
      city
      country
    }
    userPitch {
      pitch
    }
    clientReview {
      status
      note
      updatedBy
      updatedAt
    }
    kamNote {
      note
      updatedBy
      updatedAt
    }
    channels {
      id
      tags
      platform
      name
      reach
      avatarUrl
      updatedAt
      data {
        url
      }
      applications {
        applicationId
        campaignId
        campaignName
        campaignStatus
        campaignSummaryStatus
        applicationStatus
        clientApplicationReview {
          status
          note
          updatedBy
          updatedAt
        }
        createdAt
      }
      notes {
        note
        createdBy
        createdAt
        creatorName
      }
      collaborationRatings {
        visualCreativity
        copyWriting
        punctuality
        professionalism
      }
      insights {
        ... on InstagramInsights {
          followedBy
          follows
          media
          date
        }

        ... on YoutubeInsights {
          viewCount
          commentCount
          subscriberCount
          hiddenSubscriberCount
          videoCount
          date
        }
      }
      keyMetrics {
        ... on InstagramKeyMetrics {
          metricsValidAt
          source
          followers
          engagementRate
          followersHistory {
            month
            followers
          }
          audience {
            countries
            gender
            ageRange
          }
          topCountry
          storiesImpressions
          postsImpressions
          storyImpressionMedian
          postImpressionMedian
          storyImpressionRatio
          postImpressionRatio
          updatedByAt
        }
        ... on PinterestKeyMetrics {
          followers
          impressionMedian
          engagementMedian
          engagementRatio
          pinClickMedian
          pinClickRatio
          saveMedian
          saveRatio
          updatedAt
        }
      }
      kpi {
        ... on TikTokKPI {
          audienceStatistic {
            genders
            ageGroups
          }
          geoStatistic {
            countries
          }
          followers
          topCountry
          viewsPerVideo
          viewsPerVideoMedian
          viewsTotal
          updatedByAt
        }
      }
    }
  }
`;

const ONE_CLIENT = gql`
  query PrivateNetwork($applicationsQuery: PrivateNetworkApplicationsQuery) {
    privateNetwork {
      id
      name
      clientId
      applications(query: $applicationsQuery) {
        ...commonApplicationFields
      }
    }
  }
  ${COMMON_APPLICATION_FIELDS}
`;

const ONE_KAM = gql`
  query PrivateNetwork(
    $applicationsQuery: PrivateNetworkApplicationsQuery
    $id: ID
  ) {
    privateNetwork(id: $id) {
      id
      name
      client {
        id
        name
      }
      applications(query: $applicationsQuery) {
        ...commonApplicationFields
        ...kamApplicationFields
      }
    }
  }
  ${COMMON_APPLICATION_FIELDS}
  ${KAM_APPLICATION_FIELDS}
`;

const MANY = gql`
  query PrivateNetworks {
    privateNetworks {
      id
      name
      client {
        name
        id
      }
    }
  }
`;

const UPDATE_CHANNEL_TAGS = gql`
  mutation UpdateChannelTags($id: ID!, $payload: [String]) {
    updateChannelTags(id: $id, input: $payload)
  }
`;

const UPDATE_CHANNEL_NOTES = gql`
  mutation UpdateChannelNotes($id: ID!, $payload: [ChannelNoteInput]!) {
    updateChannelNotes(id: $id, input: $payload)
  }
`;

const UPDATE_APPLICATION_CLIENT_REVIEW = gql`
  mutation UpdateApplicationClientReview(
    $id: ID!
    $payload: PrivateNetworkApplicationClientReviewUpdateInput!
  ) {
    updatePrivateNetworkApplicationClientReview(id: $id, input: $payload) {
      id
      clientReview {
        status
        note
        updatedBy
        updatedAt
      }
    }
  }
`;

const UPDATE_APPLICATION_KAM_REVIEW = gql`
  mutation UpdateApplicationKamReview(
    $id: ID!
    $payload: PrivateNetworkApplicationKamReviewUpdateInput!
  ) {
    updatePrivateNetworkApplicationKamReview(id: $id, input: $payload) {
      id
      kamReview {
        status
        note
        updatedBy
        updatedAt
      }
    }
  }
`;

const UPDATE_APPLICATION_HIDDEN_FROM_CLIENT = gql`
  mutation UpdateApplicationHiddenFromClient(
    $id: ID!
    $payload: PrivateNetworkApplicationHiddenFromClientUpdateInput!
  ) {
    updatePrivateNetworkApplicationHiddenFromClient(id: $id, input: $payload) {
      id
      hiddenFromClient
    }
  }
`;

const UPDATE_APPLICATION_KAM_NOTE = gql`
  mutation UpdateApplicationKamNote(
    $id: ID!
    $payload: PrivateNetworkApplicationKamNoteUpdateInput!
  ) {
    updatePrivateNetworkApplicationKamNote(id: $id, input: $payload) {
      id
      kamNote {
        note
        updatedAt
        updatedBy
      }
    }
  }
`;

const UPDATE_APPLICATION_USER_PITCH = gql`
  mutation UpdateApplicationUserPitch(
    $id: ID!
    $payload: PrivateNetworkApplicationUserPitchUpdateInput!
  ) {
    updatePrivateNetworkApplicationUserPitch(id: $id, input: $payload) {
      id
      userPitch {
        originalPitch
        pitch
        updatedAt
        updatedBy
      }
    }
  }
`;

export const useUpdateChannelTags = () => {
  const [updateChannelTags, { loading, error }] = useMutation(
    UPDATE_CHANNEL_TAGS,
    {
      refetchQueries: ['ChannelTags', 'PrivateNetwork'],
    },
  );

  if (error) {
    throw new Error(`Failed to mutate tags: ${JSON.stringify(error)}`);
  }

  return {
    updateChannelTags: React.useCallback(
      ({ id, payload }) => updateChannelTags({ variables: { id, payload } }),
      [updateChannelTags],
    ),
    loading,
  };
};

export const useUpdateChannelNotes = () => {
  const [updateChannelNotes, { loading, error }] = useMutation(
    UPDATE_CHANNEL_NOTES,
    {
      refetchQueries: ['PrivateNetwork'],
    },
  );

  if (error) {
    throw new Error(`Failed to mutate notes: ${JSON.stringify(error)}`);
  }

  return {
    updateChannelNotes: React.useCallback(
      ({ id, payload }) => updateChannelNotes({ variables: { id, payload } }),
      [updateChannelNotes],
    ),
    loading,
  };
};

export const useUpdateApplicationClientReview = () => {
  const [updateApplicationClientReview, { loading, error, data }] = useMutation(
    UPDATE_APPLICATION_CLIENT_REVIEW,
  );

  if (error) {
    throw new Error(
      `Failed to update private network application: ${JSON.stringify(error)}`,
    );
  }

  return {
    updateApplicationClientReview: React.useCallback(
      ({ id, payload }) =>
        updateApplicationClientReview({ variables: { id, payload } }),
      [updateApplicationClientReview],
    ),
    loading,
    data: data ? data.updateApplicationClientReview : undefined,
  };
};

export const useUpdateApplicationKamReview = () => {
  const [updateApplicationKamReview, { loading, error, data }] = useMutation(
    UPDATE_APPLICATION_KAM_REVIEW,
  );

  if (error) {
    throw new Error(
      `Failed to update private network application: ${JSON.stringify(error)}`,
    );
  }

  return {
    updateApplicationKamReview: React.useCallback(
      ({ id, payload }) =>
        updateApplicationKamReview({ variables: { id, payload } }),
      [updateApplicationKamReview],
    ),
    loading,
    data: data ? data.updateApplicationKamReview : undefined,
  };
};

export const useUpdateApplicationHiddenFromClient = () => {
  const [updateApplicationHiddenFromClient, { loading, error, data }] =
    useMutation(UPDATE_APPLICATION_HIDDEN_FROM_CLIENT);

  if (error) {
    throw new Error(
      `Failed to update private network application: ${JSON.stringify(error)}`,
    );
  }

  return {
    updateApplicationHiddenFromClient: React.useCallback(
      ({ id, payload }) =>
        updateApplicationHiddenFromClient({ variables: { id, payload } }),
      [updateApplicationHiddenFromClient],
    ),
    loading,
    data: data ? data.updateApplicationHiddenFromClient : undefined,
  };
};

export const useUpdateApplicationKamNote = () => {
  const [updateApplicationKamNote, { loading, error, data }] = useMutation(
    UPDATE_APPLICATION_KAM_NOTE,
  );

  if (error) {
    throw new Error(
      `Failed to update private network application: ${JSON.stringify(error)}`,
    );
  }

  return {
    updateApplicationKamNote: React.useCallback(
      ({ id, payload }) =>
        updateApplicationKamNote({ variables: { id, payload } }),
      [updateApplicationKamNote],
    ),
    loading,
    data: data ? data.updateApplicationKamNote : undefined,
  };
};

export const useUpdateApplicationUserPitch = () => {
  const [updateApplicationUserPitch, { loading, error, data }] = useMutation(
    UPDATE_APPLICATION_USER_PITCH,
  );

  if (error) {
    throw new Error(
      `Failed to update private network application: ${JSON.stringify(error)}`,
    );
  }

  return {
    updateApplicationUserPitch: React.useCallback(
      ({ id, payload }) =>
        updateApplicationUserPitch({ variables: { id, payload } }),
      [updateApplicationUserPitch],
    ),
    loading,
    data: data ? data.updateApplicationUserPitch : undefined,
  };
};

export const useOneClient = (opts) => useQuery(ONE_CLIENT, opts);
export const useOneKam = (opts) => useQuery(ONE_KAM, opts);
export const useMany = (opts) => useQuery(MANY, opts);

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import isFunction from 'lodash/isFunction';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import PageWrapper from 'layout/PageWrapper';
import PageTitle from 'layout/PageTitle';
import { formatDate } from 'utils/formatting';

import InspectorDrawerContent from 'components/inspector/InspectorDrawerContent';

import ClientDetailsViewStepper from './ClientDetailsViewStepper';

const ClientDates = ({ createdAt, updatedAt }) => {
  const intl = useIntl();

  if (!createdAt || !updatedAt) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Box display="flex">
        <Typography noWrap>
          {intl.formatMessage({
            id: 'scenes.clients.details.createdAtLabel',
            defaultMessage: 'Created:',
          })}
          &nbsp;
        </Typography>
        <Typography variant="subtitle1">
          {formatDate({
            isoDate: createdAt,
            formatter: intl.formatDate,
          })}
        </Typography>
      </Box>
      <Box display="flex">
        <Typography noWrap>
          {intl.formatMessage({
            id: 'scenes.clients.details.updatedAtLabel',
            defaultMessage: 'Last Update:',
          })}
          &nbsp;
        </Typography>
        <Typography variant="subtitle1">
          {formatDate({
            isoDate: updatedAt,
            formatter: intl.formatDate,
          })}
        </Typography>
      </Box>
    </Box>
  );
};

ClientDates.propTypes = {
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

ClientDates.defaultProps = {
  createdAt: null,
  updatedAt: null,
};

const useStyles = makeStyles({
  backLink: {
    textDecoration: 'none',
  },
});

const ClientDetailsPageContainer = ({
  client,
  isNew,
  view,
  loading,
  drawerOpen,
  drawerWidth,
  renderDrawer,
  children,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <PageWrapper
      pageTitle={intl.formatMessage(view.pageTitle(isNew), {
        clientName: client.name,
      })}
      loading={loading}
    >
      <Box width="100%" display="flex">
        <InspectorDrawerContent
          drawerOpen={drawerOpen}
          drawerWidth={drawerWidth}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" flexDirection="column">
              <Link className={classes.backLink} to="/clients" color="primary">
                <Typography variant="overline" color="primary">
                  {intl.formatMessage({
                    id: 'scenes.clients.details.backLink',
                    defaultMessage: '← Back to all',
                  })}
                </Typography>
              </Link>
              <PageTitle>{client.name}</PageTitle>
            </Box>
            <ClientDates
              createdAt={client.createdAt}
              updatedAt={client.updatedAt}
            />
          </Box>
          <Box>
            <ClientDetailsViewStepper
              selectedView={view.id}
              disableUsers={isNew}
            />
            {children}
          </Box>
        </InspectorDrawerContent>
        {isFunction(renderDrawer) && renderDrawer({ drawerOpen, drawerWidth })}
      </Box>
    </PageWrapper>
  );
};

ClientDetailsPageContainer.propTypes = {
  loading: PropTypes.bool,
  client: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  view: PropTypes.shape({
    id: PropTypes.string,
    pageTitle: PropTypes.func,
  }),
  drawerOpen: PropTypes.bool,
  drawerWidth: PropTypes.number,
  renderDrawer: PropTypes.func,
  children: PropTypes.node.isRequired,
};
ClientDetailsPageContainer.defaultProps = {
  loading: false,
  isNew: false,
  drawerOpen: false,
  drawerWidth: 0,
};

export default ClientDetailsPageContainer;

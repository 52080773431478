import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/browser';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ErrorPageGenericImage from 'images/errorPageGeneric.jpg';
import config from 'config';

import ErrorPage from './ErrorPage';

const useStyles = makeStyles((theme) => ({
  reportButton: {
    marginTop: theme.spacing(2),
  },
  message: {
    display: 'flex',
    flexWrap: 'wrap',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  link: {
    marginLeft: theme.spacing(0.5),
  },
}));

const Generic = ({ sentryEventId }) => {
  const classes = useStyles();
  const intl = useIntl();

  const openReportDialog = React.useCallback(() => {
    Sentry.showReportDialog({
      eventId: sentryEventId,
      lang: intl.locale,
    });
  }, [sentryEventId, intl.locale]);

  return (
    <ErrorPage
      title={intl.formatMessage({
        id: 'errorPages.generic.title',
        defaultMessage: 'Error',
      })}
      image={ErrorPageGenericImage}
    >
      <Box pt={6} pb={6}>
        <FormattedMessage
          id="errorPages.generic.message"
          defaultMessage="<typo>Something went wrong, please reload the page.</typo><typo>If the error persists, please contact {supportLink}</typo>"
          values={{
            typo: (...chunks) => (
              <Typography className={classes.message} variant="subtitle1">
                {chunks}
              </Typography>
            ),
            supportLink: (
              <Link
                underline="always"
                href="mailto:support@eqolot.com"
                color="textPrimary"
                className={classes.link}
              >
                <Typography variant="subtitle1">support@eqolot.com</Typography>
              </Link>
            ),
          }}
        />
        {!config.sentry.disabled && (
          <Button
            className={classes.reportButton}
            color="primary"
            variant="contained"
            onClick={openReportDialog}
          >
            {intl.formatMessage({
              id: 'errorPages.generic.reportErrorButtonLabel',
              defaultMessage: 'Report Error',
            })}
          </Button>
        )}
      </Box>
    </ErrorPage>
  );
};

Generic.propTypes = {
  sentryEventId: PropTypes.string,
};

Generic.defaultProps = {
  sentryEventId: null,
};

export default Generic;

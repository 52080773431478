import React from 'react';

import { ApplicationPropType } from 'utils/application';
import { PlatformPropType } from 'utils/channel';

import Instagram from './instagram/TableRow';
import Pinterest from './pinterest/TableRow';
import Tiktok from './tiktok/TableRow';
import Blog from './blog/TableRow';

const componentsByPlatform = {
  instagram: Instagram,
  pinterest: Pinterest,
  tiktok: Tiktok,
  website: Blog,
  ga: Blog,
};

const applicationPropsAreEqual = (prevProps, nextProps) => {
  const areApplicationIdsEqual =
    prevProps.application.id === nextProps.application.id;

  return areApplicationIdsEqual;
};

const PublishedApplicationsTableRow = React.memo(
  ({ application, platform, ...otherProps }) => {
    const Component = componentsByPlatform[platform];
    return <Component application={application} {...otherProps} />;
  },
  applicationPropsAreEqual,
);

PublishedApplicationsTableRow.propTypes = {
  platform: PlatformPropType.isRequired,
  application: ApplicationPropType.isRequired,
};

PublishedApplicationsTableRow.defaultProps = {};

export default PublishedApplicationsTableRow;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
  },
}));

const ChannelCardKPIRow = (props) => {
  const { title, children, className, ...otherProps } = props;
  const classes = useStyles(props);

  const kpiRow = (
    <Grid
      container
      spacing={3}
      className={clsx({ [classes.root]: !title })}
      {...otherProps}
    >
      {children}
    </Grid>
  );

  if (title) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        className={clsx(classes.root, className)}
      >
        <Typography
          className={classes.title}
          variant="overline"
          color="textSecondary"
        >
          {title}
        </Typography>
        {kpiRow}
      </Box>
    );
  }

  return kpiRow;
};

ChannelCardKPIRow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ChannelCardKPIRow.defaultProps = {
  title: '',
  className: '',
};

export default ChannelCardKPIRow;

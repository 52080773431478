import React from 'react';
import { Route as ReactRouterRoute } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from 'auth/useAuth';

const Route = ({ guarded, ...otherProps }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth();

  if (guarded && !isAuthenticated) {
    loginWithRedirect();
  }

  return <ReactRouterRoute {...otherProps} />;
};

Route.propTypes = {
  guarded: PropTypes.bool,
};

Route.defaultProps = {
  guarded: true,
};

export default Route;

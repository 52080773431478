import React from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import {
  useQueryParams,
  StringParam,
  SortParam,
  withDefault,
} from 'utils/routing';

const defaultSort = {
  by: 'createdAt',
  direction: 'desc',
};

const dateSorter = (by, direction) => (array) =>
  sortBy(array, (el) => {
    const value = new Date(el[by]);

    if (direction === 'asc') {
      return value;
    }

    return -value;
  });

const alphabeticalSorter = (by, direction) => (array) => {
  const sorted = sortBy(array, (el) => el[by].toLowerCase());

  if (direction === 'desc') {
    return sorted.reverse();
  }

  return sorted;
};

export const sorters = {
  lastLoginAt: {
    desc: dateSorter('lastLoginAt', 'desc'),
    asc: dateSorter('lastLoginAt', 'asc'),
  },
  createdAt: {
    desc: dateSorter('createdAt', 'desc'),
    asc: dateSorter('createdAt', 'asc'),
  },
  name: {
    desc: alphabeticalSorter('name', 'desc'),
    asc: alphabeticalSorter('name', 'asc'),
  },
  email: {
    desc: alphabeticalSorter('email', 'desc'),
    asc: alphabeticalSorter('email', 'asc'),
  },
};

export const useTableSort = () => {
  const [query, setQuery] = useQueryParams({
    application: StringParam,
    sort: withDefault(SortParam, defaultSort),
  });

  const [currentSort, setSort] = React.useState(query.sort || defaultSort);

  const sortBy = React.useCallback((newSort) => {
    setSort((prevSort) => {
      if (!isEqual(prevSort, newSort)) {
        return newSort;
      }
      return prevSort;
    });
  }, []);

  React.useEffect(() => {
    setQuery({ sort: currentSort });
  }, [currentSort.by, currentSort.direction]);

  return {
    currentSort,
    sortBy,
  };
};

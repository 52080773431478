import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import noop from 'lodash/noop';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import EditableMarkdown from 'components/EditableMarkdown';

const useStyles = makeStyles((theme) => ({
  taskBody: {
    color: theme.palette.eqolot.infoBlue,
    '& textarea': {
      color: theme.palette.eqolot.infoBlue,
      fontWeight: 'bold',
    },
  },
  newNote: {
    fontStyle: 'italic',
  },
}));

const ApplicationCardTask = ({
  onEdit,
  label,
  inputLabel,
  value,
  className,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" {...otherProps}>
      <Box mb={1}>
        <Typography variant="overline" color="textSecondary">
          {label}
        </Typography>
      </Box>
      <EditableMarkdown
        classes={{
          markdown: classes.taskBody,
          textArea: classes.taskBody,
        }}
        label={inputLabel}
        value={value}
        onChange={onEdit}
        content={
          !value && (
            <Box mb={2.5}>
              <Typography
                className={classes.newNote}
                variant="body1"
                color="textSecondary"
              >
                <FormattedMessage
                  id="components.ApplicationCardNotes.editable.clickToEdit"
                  defaultMessage="Click to edit…"
                />
              </Typography>
            </Box>
          )
        }
      />
    </Box>
  );
};

ApplicationCardTask.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  inputLabel: PropTypes.node,
  value: PropTypes.string,
  onEdit: PropTypes.func,
};
ApplicationCardTask.defaultProps = {
  className: '',
  value: '',
  onEdit: noop,
};

export default ApplicationCardTask;

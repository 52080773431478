import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import memoize from 'lodash/memoize';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';

export const ageGroups = [
  '13-17',
  '18-24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  '65+',
];

export const reachFilterSteps = [
  {
    value: 1000,
    label: '1k',
  },
  {
    value: 5000,
    label: '5k',
  },
  {
    value: 10000,
    label: '10k',
  },
  {
    value: 25000,
    label: '25k',
  },
  {
    value: 50000,
    label: '50k',
  },
  {
    value: 75000,
    label: '75k',
  },
  {
    value: 100000,
    label: '100k',
  },
  {
    value: 200000,
    label: '200k',
  },
  {
    value: 300000,
    label: '300k',
  },
  {
    value: 400000,
    label: '400k',
  },
  {
    value: 500000,
    label: '500k',
  },
  {
    value: 750000,
    label: '750k',
  },
  {
    value: 1000000,
    label: '1m',
  },
  {
    value: null,
    label: '1m+',
  },
];

const audienceGendersFiltersStrings = defineMessages({
  femalePrevalenceTitle: {
    id: 'components.AudienceGendersFilter.options.femalePrevalence.title',
    defaultMessage: 'Female Prevalence',
  },
  femaleMajorityTitle: {
    id: 'components.AudienceGendersFilter.options.femaleMajority.title',
    defaultMessage: 'Female Majority',
  },
  balancedTitle: {
    id: 'components.AudienceGendersFilter.options.balanced.title',
    defaultMessage: 'Balanced Audience',
  },
  maleMajorityTitle: {
    id: 'components.AudienceGendersFilter.options.maleMajority.title',
    defaultMessage: 'Male Majority',
  },
  malePrevalenceTitle: {
    id: 'components.AudienceGendersFilter.options.malePrevalence.title',
    defaultMessage: 'Male Prevalence',
  },
  femalePrevalenceSubtitle: {
    id: 'components.AudienceGendersFilter.options.femalePrevalence.subtitle',
    defaultMessage: 'Female viewers make up more than 70% of the audience',
  },
  femaleMajoritySubtitle: {
    id: 'components.AudienceGendersFilter.options.femaleMajority.subtitle',
    defaultMessage: 'Female viewers make up from 50% to 80% of the audience',
  },
  balancedSubtitle: {
    id: 'components.AudienceGendersFilter.options.balanced.subtitle',
    defaultMessage: 'Female to Male ratio is between 40% and 60%',
  },
  maleMajoritySubtitle: {
    id: 'components.AudienceGendersFilter.options.maleMajority.subtitle',
    defaultMessage: 'Male viewers make up from 50% to 80% of the audience',
  },
  malePrevalenceSubtitle: {
    id: 'components.AudienceGendersFilter.options.malePrevalence.subtitle',
    defaultMessage: 'Male viewers make up more than 70% of the audience',
  },
});

const audienceGendersFiltersMap = {
  femalePrevalence: {
    title: audienceGendersFiltersStrings.femalePrevalenceTitle,
    subtitle: audienceGendersFiltersStrings.femalePrevalenceSubtitle,
    value: { female: { min: 0.7 } },
  },
  femaleMajority: {
    title: audienceGendersFiltersStrings.femaleMajorityTitle,
    subtitle: audienceGendersFiltersStrings.femaleMajoritySubtitle,
    value: { female: { min: 0.5, max: 0.8 } },
  },
  balanced: {
    title: audienceGendersFiltersStrings.balancedTitle,
    subtitle: audienceGendersFiltersStrings.balancedSubtitle,
    value: { female: { min: 0.4, max: 0.6 } },
  },
  maleMajority: {
    title: audienceGendersFiltersStrings.maleMajorityTitle,
    subtitle: audienceGendersFiltersStrings.maleMajoritySubtitle,
    value: { male: { min: 0.5, max: 0.8 } },
  },
  malePrevalence: {
    title: audienceGendersFiltersStrings.malePrevalenceTitle,
    subtitle: audienceGendersFiltersStrings.malePrevalenceSubtitle,
    value: { male: { min: 0.7 } },
  },
};

export const getAudienceGenderFilter = memoize(
  (filterId) => audienceGendersFiltersMap[filterId],
);

export const getTopCountry = (countriesObj = {}) => {
  const countryCodes = keys(countriesObj);

  if (isEmpty(countryCodes)) {
    return null;
  }

  const topCountryCode = maxBy(
    countryCodes,
    (countryCode) => countriesObj[countryCode],
  );

  return {
    [topCountryCode]: countriesObj[topCountryCode],
  };
};

export const getChannelTopCountry = (channel) => {
  if (channel.platform === 'instagram') {
    return (
      get(channel, 'keyMetrics.topCountry') ||
      getTopCountry(get(channel, 'keyMetrics.audience.countries', {})) ||
      get(channel, 'kpi.topCountry')
    );
  }

  if (channel.platform === 'tiktok') {
    return (
      get(channel, 'kpi.topCountry') ||
      getTopCountry(get(channel, 'kpi.geoStatistic.countries', {}))
    );
  }

  if (['ga', 'website'].includes(channel.platform)) {
    const countries = get(channel, 'insights.countries', []);
    return getTopCountry(countries);
  }

  return null;
};

export const getChannelMetrics = (channel) => {
  switch (channel.platform) {
    case 'instagram':
      return {
        reach: get(channel, 'reach'),
        engagementRate:
          get(channel, 'keyMetrics.engagementRate') ||
          get(channel, 'kpi.engagementRate'),
        storyImpressionMedian:
          get(channel, 'keyMetrics.storyImpressionMedian') ||
          get(channel, 'kpi.storyImpressionMedian'),
        postImpressionMedian:
          get(channel, 'keyMetrics.postImpressionMedian') ||
          get(channel, 'kpi.postImpressionMedian'),
        storyImpressionRatio:
          get(channel, 'keyMetrics.storyImpressionRatio') ||
          get(channel, 'kpi.storyImpressionRatio'),
        postImpressionRatio:
          get(channel, 'keyMetrics.postImpressionRatio') ||
          get(channel, 'kpi.postImpressionRatio'),
        femaleAudience:
          get(channel, 'keyMetrics.audience.gender.female') ||
          get(channel, 'kpi.audienceStatistic.genders.female'),
        maleAudience:
          get(channel, 'keyMetrics.audience.gender.male') ||
          get(channel, 'kpi.audienceStatistic.genders.male'),
        // Lodash `get` will only return the default value if the
        // value is `undefined`, but since ageGroups can be null
        // we need to do it via logical OR
        ageGroups:
          get(channel, 'keyMetrics.audience.ageRange') ||
          get(channel, 'kpi.audienceStatistic.ageGroups') ||
          {},
        countryStatistics:
          get(channel, 'keyMetrics.audience.countries') ||
          get(channel, 'kpi.geoStatistic.countries') ||
          {},
        topCountry: getChannelTopCountry(channel),
        updatedByAt:
          get(channel, 'keyMetrics.metricsValidAt') ||
          get(channel, 'keyMetrics.updatedByAt') ||
          get(channel, 'kpi.updatedByAt'),
      };
    case 'tiktok':
      return {
        reach:
          get(channel, 'keyMetrics.followers') ||
          get(channel, 'kpi.followers') ||
          get(channel, 'reach'),
        viewsTotal: get(channel, 'kpi.viewsTotal'),
        viewsPerVideo: get(channel, 'kpi.viewsPerVideo') || [],
        viewsPerVideoMedian: get(channel, 'kpi.viewsPerVideoMedian'),
        countryStatistics: get(channel, 'kpi.geoStatistic.countries') || {},
        topCountry: getChannelTopCountry(channel),
        femaleAudience: get(channel, 'kpi.audienceStatistic.genders.female'),
        maleAudience: get(channel, 'kpi.audienceStatistic.genders.male'),
        updatedByAt:
          get(channel, 'keyMetrics.updatedByAt') ||
          get(channel, 'kpi.updatedByAt'),
      };
    case 'pinterest':
      return {
        reach:
          get(channel, 'keyMetrics.impressionMedian') || get(channel, 'reach'),
        followers: get(channel, 'keyMetrics.followers'),
        engagementMedian: get(channel, 'keyMetrics.engagementMedian'),
        engagementRatio: get(channel, 'keyMetrics.engagementRatio'),
        pinClickMedian: get(channel, 'keyMetrics.pinClickMedian'),
        pinClickRatio: get(channel, 'keyMetrics.pinClickRatio'),
        saveMedian: get(channel, 'keyMetrics.saveMedian'),
        saveRatio: get(channel, 'keyMetrics.saveRatio'),
        updatedByAt: get(channel, 'keyMetrics.updatedAt'),
      };
    case 'youtube':
      return {
        reach: get(channel, 'reach'),
        views: get(channel, 'insights.viewCount'),
        updatedByAt: get(channel, 'insights.date'),
        topCountry: getChannelTopCountry(channel),
      };
    case 'ga':
      return {
        reach: get(channel, 'reach'),
        views: get(channel, 'insights.pageviews'),
        countries: get(channel, 'insights.countries', []),
        topCountry: getChannelTopCountry(channel),
      };
    case 'website':
      return {
        reach: get(channel, 'reach'),
        views: get(channel, 'insights.pageviews'),
        countries: get(channel, 'insights.countries', []),
        topCountry: getChannelTopCountry(channel),
      };
    default:
      return {};
  }
};

const reachLabels = defineMessages({
  instagram: {
    id: 'reach.label.instagram',
    defaultMessage: 'Followers',
  },
  pinterest: {
    id: 'reach.label.pinterest',
    defaultMessage: 'Impressions',
  },
  tiktok: {
    id: 'reach.label.tiktok',
    defaultMessage: 'Followers',
  },
  youtube: {
    id: 'reach.label.youtube',
    defaultMessage: 'Subscribers',
  },
  blog: {
    id: 'reach.label.blog',
    defaultMessage: 'Pageviews',
  },
});

export const getPlatformReachLabel = (platform) => {
  if (['ga', 'website'].includes(platform)) {
    return reachLabels.blog;
  }

  return reachLabels[platform];
};

export const InstagramKPIPropType = PropTypes.shape({
  reach: PropTypes.number,
  engagementRate: PropTypes.number,
  storyImpressionMedian: PropTypes.number,
  postImpressionMedian: PropTypes.number,
  femaleAudience: PropTypes.number,
  maleAudience: PropTypes.number,
  // Lodash `get` will only return the default value if the
  // value is `undefined`, but since ageGroups can be null
  // we need to do it via logical OR
  ageGroups: PropTypes.object,
  countryStatistics: PropTypes.object,
  topCountry: PropTypes.object,
  updatedByAt: PropTypes.string,
});

export const PinterestKPIPropType = PropTypes.shape({
  reach: PropTypes.number,
  followers: PropTypes.number,
  engagementMedian: PropTypes.number,
  engagementRatio: PropTypes.number,
  pinClickMedian: PropTypes.number,
  pinClickRatio: PropTypes.number,
  saveMedian: PropTypes.number,
  saveRatio: PropTypes.number,
  updatedByAt: PropTypes.string,
});

export const TikTokKPIPropType = PropTypes.shape({
  reach: PropTypes.number,
  viewsTotal: PropTypes.number,
  viewsPerVideo: PropTypes.arrayOf(PropTypes.number),
  viewsPerVideoMedian: PropTypes.number,
  countryStatistics: PropTypes.object,
  topCountry: PropTypes.object,
  femaleAudience: PropTypes.number,
  maleAudience: PropTypes.number,
  updatedByAt: PropTypes.string,
});

export const YoutubeKPIPropType = PropTypes.shape({
  reach: PropTypes.number,
  views: PropTypes.number,
});

export const BlogKPIPropType = PropTypes.shape({
  reach: PropTypes.number,
  views: PropTypes.number,
});

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import ErrorPage404 from 'scenes/error/404';

import InsiderArticleCardDetail from '../InsiderArticleCardDetail';
import InsiderPage from '../InsiderPage';

const QUERY = gql`
  query HelpArticle($slug: String!) {
    insiderHelpArticle(slug: $slug) {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      publishedAt
      publishedBy
      title
      body
      slug
    }
  }
`;

const HelpPage = () => {
  const { slug } = useParams();
  const history = useHistory();

  const {
    data: selectedArticleData,
    loading: loadingSelectedArticle,
    error,
  } = useQuery(QUERY, {
    variables: { slug },
  });

  const selectedArticle = selectedArticleData?.insiderHelpArticle;

  if (error) {
    throw new Error(error);
  }

  const handleClose = () => {
    history.goBack();
  };

  if (slug && !loadingSelectedArticle && !selectedArticle) {
    return <ErrorPage404 />;
  }

  return (
    <InsiderPage pageTitle={selectedArticle?.title}>
      <InsiderArticleCardDetail
        loading={loadingSelectedArticle}
        open={Boolean(slug || selectedArticle)}
        article={selectedArticle}
        onClose={handleClose}
      />
    </InsiderPage>
  );
};

export default HelpPage;

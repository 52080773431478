import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import cheerio from 'cheerio';

import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

import CardMedia from 'components/CardMedia';
import PlatformLabel from 'components/PlatformLabel';
import ChannelAvatar from 'components/avatars/ChannelAvatar';
import ApplicationClientReviewStatus from 'components/applications/ApplicationClientReviewStatus';

import { ApplicationPropType } from 'utils/application';
import { formatDateTime } from 'utils/formatting';

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: theme.typography.pxToRem(360),
    width: '100%',
  },
  avatar: {
    width: 48,
    height: 48,
  },
  headerAction: {
    alignSelf: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)} !important`,
  },
  numberOfItems: {
    color: theme.palette.primary.main,
  },
  selectedActionArea: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
  readonly: {
    backgroundColor: theme.palette.primary.light,
  },
  updatedLabel: {
    color: theme.palette.eqolot.brightOrange,
    marginRight: theme.spacing(0.5),
  },
}));

const ApplicationContentPreviewCard = ({
  application,
  selected,
  onSelect,
  className,
  ...otherProps
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  const { channel, contentPreview, user, clientContentReview } = application;

  const coverMedia = React.useMemo(() => {
    const { media = [], articleSanitized = '' } = contentPreview;

    if (['instagram', 'tiktok', 'pinterest'].includes(channel.platform)) {
      const mediaItem = media[0];

      if (mediaItem) {
        return {
          url: mediaItem.url,
          type: mediaItem.type,
          assetId: mediaItem.assetId,
        };
      }
    }

    if (['ga', 'website'].includes(channel.platform)) {
      const $ = cheerio.load(articleSanitized);
      const img = $('img');

      if (img.length > 0) {
        return {
          url: img.attr('src'),
          type: 'image',
        };
      }
    }

    return {};
  }, ['contentPreview']);

  const creationDate =
    contentPreview.updatedAt &&
    formatDateTime({
      isoDate: contentPreview.updatedAt,
      formatter: intl.formatDate,
    });

  const isReadOnly = contentPreview?.status === 'readonly';

  const gravatarBackgroundColor = isReadOnly
    ? theme.palette.primary.main
    : undefined;

  return (
    <Card
      className={clsx(classes.root, className, {
        [classes.readonly]: isReadOnly,
      })}
      onClick={() => onSelect(application.id)}
      {...otherProps}
    >
      <CardActionArea
        className={clsx({
          [classes.selectedActionArea]: selected,
        })}
        component="div"
      >
        <CardHeader
          disableTypography
          classes={{
            action: classes.headerAction,
          }}
          avatar={
            <ChannelAvatar
              className={classes.avatar}
              channel={channel}
              user={user}
              gravatarBackgroundColor={gravatarBackgroundColor}
            />
          }
          action={
            <ApplicationClientReviewStatus clientReview={clientContentReview} />
          }
          title={
            <PlatformLabel
              platform={channel.platform}
              channelName={channel.name}
              variant="h6"
              color={theme.palette.primary.main}
            />
          }
        />
        <CardMedia
          className={classes.media}
          src={coverMedia.url}
          type={coverMedia.type}
          assetId={coverMedia.assetId}
          playable={false}
          autoPlay={false}
          clickable
        />
        <CardContent className={classes.content}>
          <Box display="flex">
            {contentPreview.status === 'updated' && (
              <Typography className={classes.updatedLabel} variant="subtitle1">
                {intl.formatMessage({
                  id: 'components.ApplicationContentPreviewCard.updatedPreviewLabel',
                  defaultMessage: 'Updated',
                })}
              </Typography>
            )}
            <Typography variant="body1" color="textSecondary">
              {creationDate}
            </Typography>
          </Box>
          {['instagram', 'pinterest'].includes(channel.platform) && (
            <Typography variant="body1" className={classes.numberOfItems}>
              <FormattedMessage
                id="components.ApplicationContentPreviewCard.numberOfItems"
                defaultMessage="{numberOfItems, plural, =0 {No items} one {# item} other {# items}}"
                values={{ numberOfItems: contentPreview.media.length }}
              />
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ApplicationContentPreviewCard.propTypes = {
  application: ApplicationPropType.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ApplicationContentPreviewCard.defaultProps = {
  selected: false,
  className: '',
};

export default ApplicationContentPreviewCard;

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useIntl, FormattedMessage } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { dateLocaleFormatMap } from 'utils/formatting';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 320,
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
  },
  autoResize: {
    '& textarea': {
      resize: 'vertical',
      minHeight: theme.typography.pxToRem(32),
    },
  },
}));

const CreatePublicationDialog = ({ onCancel, onSubmit, ...otherProps }) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { publishedAt: new Date() } });

  const submitForm = (data) => {
    if (isEmpty(errors)) {
      onSubmit(data);
      reset();
    }
  };

  return (
    <Dialog aria-labelledby="add-publication-dialog-title" {...otherProps}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Card className={classes.root}>
          <CardHeader
            className={classes.header}
            titleTypographyProps={{ variant: 'h6' }}
            title={intl.formatMessage({
              id: 'components.InfluencerAssetInspector.publicationForm.title',
              defaultMessage: 'Add Publication History',
            })}
          />
          <CardContent className={classes.content}>
            <Controller
              name="publishedAt"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <KeyboardDatePicker
                  error={Boolean(errors.publishedAt)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  invalidDateMessage={intl.formatMessage({
                    id: 'components.InfluencerAssetInspector.publicationForm.labels.invalidDate',
                    defaultMessage: 'Invalid Date Format',
                  })}
                  onError={(error) => {
                    if (error) {
                      errors.publishedAt = error;
                    }
                  }}
                  label={intl.formatMessage({
                    id: 'components.InfluencerAssetInspector.publicationForm.labels.date',
                    defaultMessage: 'Publication Date',
                  })}
                  format={dateLocaleFormatMap[intl.locale]}
                  InputProps={{ readOnly: true }}
                  margin="normal"
                  variant="inline"
                  inputVariant="outlined"
                  autoOk
                  disableToolbar
                  fullWidth
                />
              )}
              rules={{ required: true }}
            />
            <TextField
              {...register('url')}
              label={intl.formatMessage({
                id: 'components.InfluencerAssetInspector.publicationForm.labels.url',
                defaultMessage: 'URL of Publication',
              })}
              placeholder="https://"
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <TextField
              {...register('medium')}
              label={intl.formatMessage({
                id: 'components.InfluencerAssetInspector.publicationForm.labels.medium',
                defaultMessage: 'UTM Medium',
              })}
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <TextField
              {...register('comment')}
              className={classes.autoResize}
              label={intl.formatMessage({
                id: 'components.InfluencerAssetInspector.publicationForm.labels.notes',
                defaultMessage: 'Notes',
              })}
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              fullWidth
            />
          </CardContent>
          <CardActions className={classes.actions}>
            <Button size="small" color="primary" onClick={onCancel}>
              <FormattedMessage
                id="components.InfluencerAssetInspector.publicationForm.labels.cancelButton"
                defaultMessage="Cancel"
              />
            </Button>
            <Button size="small" color="primary" type="submit">
              <FormattedMessage
                id="components.InfluencerAssetInspector.publicationForm.labels.saveButton"
                defaultMessage="Save"
              />
            </Button>
          </CardActions>
        </Card>
      </form>
    </Dialog>
  );
};

CreatePublicationDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreatePublicationDialog;

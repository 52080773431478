import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import {
  formatNumber as numberFormatter,
  NumberFormatsPropType,
  formatRounding,
} from 'utils/formatting';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    cursor: 'default',
  },
  title: {
    fontWeight: (props) => (props.bold ? 'bold' : 'inherit'),
    color: (props) => props.color,
  },
  tooltipPopper: {
    top: '15px !important',
  },
});

const ChannelCardKPI = (props) => {
  const {
    label: labelProp,
    value: valueProp,
    format,
    bold,
    color,
    withUnderline,
    tooltip,
    className,
    roundingDigits,
    ...otherProps
  } = props;
  const classes = useStyles(props);
  const intl = useIntl();

  const value = React.useMemo(() => {
    const typoProps = { variant: 'h6', className: classes.title };

    if (!valueProp || isNil(valueProp) || !isNumber(valueProp)) {
      return <Typography {...typoProps}>—</Typography>;
    }
    const number = roundingDigits
      ? formatRounding(valueProp, roundingDigits)
      : valueProp;

    return (
      <Typography {...typoProps}>
        {numberFormatter({
          formatter: intl.formatNumber,
          number,
          format,
        })}
      </Typography>
    );
  }, [valueProp, format, classes, intl]);

  let label = labelProp;

  if (isString(labelProp)) {
    label = (
      <Typography color="textSecondary" variant="caption">
        {labelProp}
      </Typography>
    );
  }

  const rendered = (
    <Grid item xs className={clsx(classes.root, className)} {...otherProps}>
      {value}
      {label}
      {withUnderline && <Divider />}
    </Grid>
  );

  if (tooltip) {
    return (
      <Tooltip
        title={tooltip}
        placement="top"
        classes={{ popper: classes.tooltipPopper }}
      >
        {rendered}
      </Tooltip>
    );
  }

  return rendered;
};

ChannelCardKPI.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.number,
  bold: PropTypes.bool,
  color: PropTypes.string,
  format: NumberFormatsPropType,
  withUnderline: PropTypes.bool,
  className: PropTypes.string,
  roundingDigits: PropTypes.number,
  tooltip: PropTypes.node,
};

ChannelCardKPI.defaultProps = {
  className: '',
  color: 'inherit',
  bold: false,
  format: undefined,
  withUnderline: true,
  roundingDigits: undefined,
  tooltip: '',
};

export default ChannelCardKPI;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import PlatformIcon from 'icons/Platform';
import { PlatformPropType } from 'utils/channel';
import { TrackedLink } from 'utils/gtm';

import PlatformAvatar from './PlatformAvatar';

const useStyles = makeStyles({
  tooltipPopper: {
    top: '10px !important',
  },
});

const PlatformLinkIcon = ({ platform, url, selected }) => {
  const classes = useStyles();
  const theme = useTheme();

  const linkProps = {
    href: url,
    eventLabel: `${platform} link clicked: ${url}`,
    target: '_blank',
    rel: 'noreferrer',
  };

  let icon;

  if (selected) {
    icon = (
      <Box>
        <PlatformAvatar
          component={TrackedLink}
          platform={platform}
          color={theme.palette.secondary.main}
          {...linkProps}
        />
      </Box>
    );
  } else {
    icon = (
      <TrackedLink {...linkProps}>
        <IconButton>
          <PlatformIcon
            platform={platform}
            color={theme.palette.secondary.main}
          />
        </IconButton>
      </TrackedLink>
    );
  }

  return (
    <Tooltip
      placement="top"
      title={url || ''}
      classes={{
        popper: classes.tooltipPopper,
      }}
    >
      {icon}
    </Tooltip>
  );
};

PlatformLinkIcon.propTypes = {
  platform: PlatformPropType.isRequired,
  url: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

PlatformLinkIcon.defaultProps = {
  selected: false,
};

export default PlatformLinkIcon;

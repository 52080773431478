import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useQueryParam, StringParam, withDefault } from 'utils/routing';
import { views as clientViews } from 'utils/client';

import { loadingString } from 'components/LoadingState';
import TableBody from 'components/table/TableBody';

import { useOne as useClient, useAddClientUser } from './data/clientHooks';
import { useTableSort, sorters } from './data/clientUsersTable';
import ClientDetailsPageContainer from './components/ClientDetailsPageContainer';
import ClientUsersTable from './components/ClientUsersTable';
import ClientUsersTableHead from './components/ClientUsersTableHead';
import ClientUsersTableRow from './components/ClientUsersTableRow';
import ClientUserInspector, {
  drawerWidth as inspectorDrawerWidth,
} from './components/inspector/ClientUserInspector';
import NewClientUserDialog from './components/NewClientUserDialog';

const ClientUsersPage = ({ clientId }) => {
  const [querySelectedUserId, setQuerySelectedUserId] = useQueryParam(
    'user',
    withDefault(StringParam, undefined),
  );
  const { currentSort, sortBy } = useTableSort();
  const [selectedUserId, setSelectedUserId] =
    React.useState(querySelectedUserId);
  const history = useHistory();
  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);

  const intl = useIntl();
  const {
    data: clientData,
    loading: loadingClient,
    error: clientError,
  } = useClient({ id: clientId });
  const { addClientUser } = useAddClientUser();

  React.useEffect(() => {
    setQuerySelectedUserId(selectedUserId);
  }, [selectedUserId]);

  if (clientId === 'new') {
    history.push(`/clients/new/settings`);
  }

  if (clientError) {
    throw new Error(`Error fetching client: ${clientError}`);
  }

  const client = clientData?.client || {};
  const clientUsers = React.useMemo(() => {
    const users = client?.users || [];

    const sortBy = sorters[currentSort.by][currentSort.direction];

    if (!sortBy) {
      return users;
    }

    return sortBy(users);
  }, [client?.users, currentSort, sorters]);

  const selectedUser = React.useMemo(
    () => clientUsers.find((user) => user.id === selectedUserId),
    [clientUsers, selectedUserId],
  );

  const metaLabel = intl.formatMessage(
    {
      id: 'scenes.clients.details.usersTable.metaLabel',
      defaultMessage:
        '{numberOfUsers, plural, =0 {No users} one {# user} other {# users}}',
    },
    { numberOfUsers: clientUsers.length },
  );

  const handleAddNewUser = async (payload) => {
    const { data } = await addClientUser({
      clientId: client.id,
      payload,
    });

    const newClient = data?.addClientUser;
    setAddUserDialogOpen(false);

    if (newClient) {
      const newUser = newClient.users.find(
        (user) => user.email === payload.email,
      );
      if (newUser) {
        setSelectedUserId(newUser.id);
      }
    }
  };

  return (
    <ClientDetailsPageContainer
      client={client}
      view={clientViews.USERS}
      loading={loadingClient}
      drawerOpen={!!selectedUserId}
      drawerWidth={inspectorDrawerWidth}
      renderDrawer={() => (
        <ClientUserInspector
          clientId={client.id}
          user={selectedUser}
          onClose={() => setSelectedUserId(undefined)}
          loading={loadingClient}
        />
      )}
    >
      <NewClientUserDialog
        open={addUserDialogOpen}
        onSave={handleAddNewUser}
        onClose={() => setAddUserDialogOpen(false)}
      />
      <ClientUsersTable
        metaLabel={
          loadingClient ? intl.formatMessage(loadingString) : metaLabel
        }
        onAddUser={() => setAddUserDialogOpen(true)}
      >
        <ClientUsersTableHead currentSort={currentSort} onSort={sortBy} />
        <TableBody loading={loadingClient}>
          {clientUsers.map((user) => (
            <ClientUsersTableRow
              key={user.id}
              user={user}
              selected={user.id === selectedUserId}
              onClick={() => setSelectedUserId(user.id)}
            />
          ))}
        </TableBody>
      </ClientUsersTable>
    </ClientDetailsPageContainer>
  );
};

ClientUsersPage.propTypes = {
  clientId: PropTypes.string.isRequired,
};
ClientUsersPage.defaultProps = {};

export default ClientUsersPage;

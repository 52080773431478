import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { useCategories, noCategoryLabel } from 'utils/categories';

import TagsInput from 'components/TagsInput';

const CategoriesFilter = ({ value, onChange, ...otherProps }) => {
  const intl = useIntl();
  const { allCategories, getCategory } = useCategories();

  const handleChange = (event, newValues) => {
    onChange(newValues);
  };

  const allCodes = React.useMemo(
    () => allCategories.map((category) => category.code),
    [allCategories],
  );

  const getGroup = React.useCallback((tag) => {
    const category = getCategory(tag);

    if (category.parent) {
      return getCategory(category.parent).label;
    }

    return intl.formatMessage(noCategoryLabel);
  }, getCategory);

  const filterOptions = React.useCallback(
    (options, { inputValue }) => {
      const filterFn = (categoryCode) => {
        const { label: fullCategoryLabel } = getCategory(categoryCode);
        return (
          fullCategoryLabel &&
          fullCategoryLabel.toLowerCase().includes(inputValue.toLowerCase())
        );
      };
      return options.filter(filterFn);
    },
    [getCategory],
  );

  const getCategoryLabel = React.useCallback(
    (categoryCode) => getCategory(categoryCode).label,
    [getCategory],
  );

  return (
    <TagsInput
      value={value}
      options={allCodes}
      getTagLabel={getCategoryLabel}
      renderOption={getCategoryLabel}
      groupBy={getGroup}
      onChange={handleChange}
      label={intl.formatMessage({
        id: 'components.CategoriesFilter.label',
        defaultMessage: 'IAB Categories',
      })}
      maxTags={5}
      maxTagsMessage={intl.formatMessage({
        id: 'components.CategoriesFilter.maxTagsMessage',
        defaultMessage:
          'Select up to 5 categories. Remove a category to choose another.',
      })}
      disableCloseOnSelect
      filterOptions={filterOptions}
      {...otherProps}
    />
  );
};

CategoriesFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};
CategoriesFilter.defaultProps = {
  value: [],
};

export default CategoriesFilter;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import countries from 'i18n-iso-countries';
import ReactCountryFlag from 'react-country-flag';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const CountryLabel = ({
  countryCode,
  withFlag,
  short,
  children,
  className,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  if (!countryCode) {
    throw new Error('Cannot use CountryLabel without a valid country code!');
  }

  const countryName = short
    ? countryCode.toUpperCase()
    : countries.getName(countryCode, intl.locale);

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      {withFlag && <ReactCountryFlag countryCode={countryCode} />}
      <Typography noWrap>{countryName}</Typography>
      {children}
    </Box>
  );
};

CountryLabel.propTypes = {
  countryCode: PropTypes.string.isRequired,
  withFlag: PropTypes.bool,
  short: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

CountryLabel.defaultProps = {
  withFlag: true,
  short: false,
  className: '',
  children: null,
};

export default CountryLabel;

import React from 'react';
import { Helmet } from 'react-helmet';

import config from 'config';

export const CloudinaryUploadWidgetMarkup = () => (
  <Helmet>
    <script
      src="https://upload-widget.cloudinary.com/global/all.js"
      type="text/javascript"
    />
  </Helmet>
);

export const useUploadWidget = ({ onSuccess, onError }) =>
  React.useMemo(() => {
    if (!window?.cloudinary) {
      return {};
    }

    return window.cloudinary.createUploadWidget(
      {
        cloudName: config.cloudinary.cloudName,
        ...config.cloudinary.clientAssets,
      },
      (error, result) => {
        if (error) {
          onError(error);
          return;
        }

        if (result && result.event === 'success') {
          onSuccess({
            id: result.info.public_id,
            url: result.info.secure_url,
          });
        }
      },
    );
  }, [window?.cloudinary, onSuccess, onError]);

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import * as Icons from 'react-feather';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  endAdornment: {
    // We use a feather icon instead of the built-in, which doesn't sit perfectly
    // aligned here, so we adjust it a little, the MUI style is `calc(50% - 14px)`
    top: 'calc(50% - 16px)',
  },
  inputLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '79%',
  },
  inputLabelShrink: {
    width: '100%',
  },
});

const defaultFilter = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option,
});

const getTagValue = (tag) => get(tag, 'value', tag);
const getTagOption = (tag) => get(tag, 'option', tag);

const strings = defineMessages({
  createNewTag: {
    id: 'components.TagsInput.createTag',
    defaultMessage: 'Create tag "{tag}"',
  },
  noOptions: {
    id: 'components.TagsInput.noOptions',
    defaultMessage: 'No options',
  },
});

const TagsInput = (props) => {
  const {
    value,
    options,
    label,
    placeholder,
    tagClasses,
    getTagLabel,
    renderOption: renderOptionProp,
    noOptionsText: noOptionsTextProp,
    filterOptions: filterOptionsProp,
    minTags,
    maxTags,
    maxTagsMessage,
    allowNew,
    showTooltip,
    onChange: onChangeProp,
    inputLabelShrinkProps,
    ...otherProps
  } = props;
  const {
    inputLabel: inputLabelClass,
    inputLabelShrink: inputLabelShrinkClass,
    ...autocompleteClasses
  } = useStyles();
  const intl = useIntl();

  const renderTags = React.useCallback(
    (tags, getTagProps) =>
      tags.map((tag, index) => {
        const tagProps = getTagProps({ index });

        if (minTags && minTags >= value.length) {
          tagProps.onDelete = undefined;
        }

        return (
          <Tooltip key={tag} title={showTooltip ? getTagLabel(tag) : ''}>
            <Chip classes={tagClasses} label={getTagLabel(tag)} {...tagProps} />
          </Tooltip>
        );
      }),
    [getTagLabel, value, minTags, tagClasses],
  );

  const renderInput = React.useCallback(
    (params) => (
      <TextField
        {...params}
        InputLabelProps={{
          classes: {
            root: inputLabelClass,
            shrink: inputLabelShrinkClass,
          },
          ...inputLabelShrinkProps,
        }}
        label={label}
        variant="outlined"
        placeholder={isEmpty(value) ? placeholder : null}
      />
    ),
    [value, label, placeholder, inputLabelClass, inputLabelShrinkClass],
  );

  const hasReachedMaxTags = () => {
    if (maxTags === -1) {
      return false;
    }

    return value.length >= maxTags;
  };

  const filterOptions = (options, params) => {
    if (maxTags === -1) {
      const { inputValue } = params;
      const filteredOptions = filterOptionsProp(options, params);

      if (filteredOptions.length === 0 && allowNew && inputValue.length > 0) {
        const option = intl.formatMessage(strings.createNewTag, {
          tag: inputValue,
        });

        return [{ value: inputValue, option }];
      }

      return filteredOptions;
    }

    if (hasReachedMaxTags()) {
      return [];
    }

    if (filterOptionsProp) {
      return filterOptionsProp(options, params);
    }

    return options;
  };

  const onChange = (event, newTags) => {
    if (allowNew) {
      const flatTags = newTags.map(getTagValue);
      return onChangeProp(event, flatTags);
    }
    return onChangeProp(event, newTags);
  };

  const renderOption = (tag) => {
    if (allowNew) {
      const newTagOption = getTagOption(tag);
      return renderOptionProp(newTagOption);
    }

    return renderOptionProp(tag);
  };

  const noOptionsText =
    noOptionsTextProp || intl.formatMessage(strings.noOptions);

  return (
    <Autocomplete
      value={value}
      multiple
      freeSolo={allowNew}
      filterSelectedOptions
      options={options}
      onChange={onChange}
      renderTags={renderTags}
      renderInput={renderInput}
      renderOption={renderOption}
      noOptionsText={hasReachedMaxTags() ? maxTagsMessage : noOptionsText}
      filterOptions={filterOptions}
      size="small"
      classes={autocompleteClasses}
      closeIcon={<Icons.XCircle />}
      {...otherProps}
    />
  );
};

TagsInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  options: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getTagLabel: PropTypes.func,
  renderOption: PropTypes.func,
  tagClasses: PropTypes.object,
  minTags: PropTypes.number,
  maxTags: PropTypes.number,
  maxTagsMessage: PropTypes.string,
  filterOptions: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  noOptionsText: PropTypes.string,
  allowNew: PropTypes.bool,
  showTooltip: PropTypes.bool,
  inputLabelShrinkProps: PropTypes.object,
};

TagsInput.defaultProps = {
  options: [],
  label: '',
  placeholder: '',
  getTagLabel: (tag) => tag,
  renderOption: (tag) => tag,
  filterOptions: defaultFilter,
  tagClasses: {},
  maxTags: -1,
  noOptionsText: undefined,
  allowNew: false,
  showTooltip: false,
  inputLabelShrinkProps: {},
};

export default TagsInput;

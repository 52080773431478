import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Icons from 'react-feather';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import EqolotLogo from 'images/eqolotLogo.png';
import ContactIcon from 'icons/Contact';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: 8,
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  personalInfoLine: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
    '& > svg:first-child': {
      marginRight: theme.spacing(1.5),
    },
  },
  contactPersonImage: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

const defaultManager = {
  avatarUrl: EqolotLogo,
  name: 'Key Account Management',
  email: 'kam@eqolot.com',
  phone: '+49 30 60 98 12 45 03',
};

const ContactCard = ({
  title,
  manager: managerProp,
  className,
  ...otherProps
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const manager = managerProp || defaultManager;

  const { avatarUrl = EqolotLogo, name, email, phone } = manager;

  return (
    <Box
      component={Paper}
      className={clsx(classes.root, className)}
      {...otherProps}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <Avatar
              alt="Contact Logo"
              src={avatarUrl}
              className={classes.contactPersonImage}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box className={classes.personalInfoLine}>
              <ContactIcon color={theme.palette.primary.main} />
              <Typography variant="body1">{name}</Typography>
            </Box>
            <Box className={classes.personalInfoLine}>
              <Icons.Mail color={theme.palette.primary.main} />
              <Typography variant="body1">{email}</Typography>
            </Box>
            <Box className={classes.personalInfoLine}>
              <Icons.Phone color={theme.palette.primary.main} />
              <Typography variant="body1">{phone}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ContactCard.propTypes = {
  title: PropTypes.string.isRequired,
  manager: PropTypes.shape({
    avatarUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
};
ContactCard.defaultProps = {
  manager: null,
  className: '',
};

export default ContactCard;

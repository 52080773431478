import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Tags from 'components/Tags';

import { useCategories } from 'utils/categories';
import { ChannelPropType } from 'utils/channel';

const ChannelCardCategories = ({ channel, ...otherProps }) => {
  const { getFullCategories } = useCategories();
  const intl = useIntl();

  const fullCategories = React.useMemo(
    () => getFullCategories(channel.categories),
    [channel.categories, getFullCategories],
  );

  return (
    <Box mb={2} {...otherProps}>
      <Typography variant="overline" color="textSecondary">
        {intl.formatMessage({
          id: 'components.ChannelCard.categories.title',
          defaultMessage: 'Channel categories',
        })}
      </Typography>
      {isEmpty(fullCategories) && (
        <Box mb={-3}>
          <Typography>
            <FormattedMessage
              id="components.ChannelCard.categories.emptyLabel"
              defaultMessage="No Categories"
            />
          </Typography>
        </Box>
      )}
      <Tags
        tags={fullCategories}
        getTagLabel={(tag) => tag.label}
        getTagValue={(tag) => tag.code}
        sortBy="weight"
        mt={1}
        wrap
      />
    </Box>
  );
};

ChannelCardCategories.propTypes = {
  channel: ChannelPropType.isRequired,
};

export default ChannelCardCategories;

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessage } from 'react-intl';
import take from 'lodash/take';
import takeRight from 'lodash/takeRight';
import { useLazyQuery, gql } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import config from 'config';
import useAuth from 'auth/useAuth';

export const views = {
  SETTINGS: {
    id: 'settings',
    title: defineMessage({
      id: 'scenes.clients.details.settings.stepperTitle',
      defaultMessage: 'Settings',
    }),
    subtitle: defineMessage({
      id: 'scenes.clients.details.settings.stepperSubtitle',
      defaultMessage: 'Client Settings',
    }),
    pageTitle: (isNew) => {
      if (isNew) {
        return defineMessage({
          id: 'scenes.clients.details.settings.newClient.title',
          defaultMessage: 'New Client',
        });
      }
      return defineMessage({
        id: 'scenes.clients.details.settings.title',
        defaultMessage: 'Settings - {clientName}',
      });
    },
  },
  USERS: {
    id: 'users',
    title: defineMessage({
      id: 'scenes.clients.details.users.stepperTitle',
      defaultMessage: 'Users',
    }),
    subtitle: defineMessage({
      id: 'scenes.clients.details.users.stepperSubtitle',
      defaultMessage: 'Accounts',
    }),
    pageTitle: () =>
      defineMessage({
        id: 'scenes.clients.details.users.title',
        defaultMessage: 'Users - {clientName}',
      }),
  },
};

const CLIENT = gql`
  query Client($id: ID!) {
    client(id: $id) {
      id
      name
      theme {
        logoUrl
        brandLogoUrl
        sidebarColor
      }
      manager {
        avatarUrl
        name
        email
        phone
      }
      features
      tags {
        influencerAssets
      }
      role
    }
  }
`;

const CLIENT_CHANNEL_TAGS = gql`
  query ChannelTags($clientId: ID!) {
    channelTags(clientId: $clientId)
  }
`;

const obfuscateClientEmail = (email) => {
  if (!email) {
    return null;
  }

  const [name, domain] = email.split('@');

  const first3Letters = take(name, 3).join('');
  const last3Letters = takeRight(name, 3).join('');

  return `${first3Letters}***${last3Letters}@${domain}`;
};

export const Context = React.createContext();

export const ClientProvider = ({ children }) => {
  const { user = {} } = useAuth() || {};
  const [fetchClient, { data: clientData = {}, loading }] =
    useLazyQuery(CLIENT);
  const [fetchClientTags, { data: tagsData = {} }] =
    useLazyQuery(CLIENT_CHANNEL_TAGS);

  const clientId = user[`${config.auth.metaDataNamespace}/clientId`];
  const clientEmail = user.email;

  if (clientId && clientEmail) {
    Sentry.setUser({ id: clientId, email: obfuscateClientEmail(clientEmail) });
  }

  React.useEffect(() => {
    if (clientId && !loading) {
      fetchClient({
        variables: { id: clientId },
      });
    }
  }, [clientId]);

  React.useEffect(() => {
    if (clientId && !loading) {
      fetchClientTags({ variables: { clientId } });
    }
  }, [clientId]);

  const sortedClientTags = React.useMemo(() => {
    const clientTags = tagsData?.channelTags || [];

    return [...clientTags].sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: 'base' }),
    );
  }, [tagsData]);

  return (
    <Context.Provider
      value={{
        client: clientData?.client,
        tags: sortedClientTags,
        loading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

ClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useClient = () => React.useContext(Context);

import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';

import ChannelAvatar from 'components/avatars/ChannelAvatar';
import { ChannelPropType } from 'utils/channel';
import { AlignPropType } from 'utils/table';
import { UserPropType } from 'utils/user';

const TableCellChannelAvatar = ({
  channel,
  user,
  size,
  align,
  gravatarBackgroundColor,
  ...otherProps
}) => (
  <TableCell align={align} {...otherProps}>
    <ChannelAvatar
      channel={channel}
      user={user}
      size={size}
      gravatarBackgroundColor={gravatarBackgroundColor}
    />
  </TableCell>
);

TableCellChannelAvatar.propTypes = {
  align: AlignPropType,
  channel: ChannelPropType.isRequired,
  user: UserPropType.isRequired,
  size: PropTypes.number,
  gravatarBackgroundColor: PropTypes.string,
};

TableCellChannelAvatar.defaultProps = {
  align: 'left',
  size: 50,
  gravatarBackgroundColor: undefined,
};

export default TableCellChannelAvatar;

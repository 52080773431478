import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';

import TableContainer from 'components/table/TableContainer';

const useStyles = makeStyles({
  table: {
    position: 'relative',
  },
});

const ClientUsersTable = ({
  onAddUser,
  metaLabel,
  children,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <TableContainer
      label={metaLabel}
      actions={
        <Button onClick={onAddUser} variant="contained" color="primary">
          {intl.formatMessage({
            id: 'scenes.clients.details.usersTable.newUserButton.label',
            defaultMessage: 'Add User…',
          })}
        </Button>
      }
    >
      <Table
        className={classes.table}
        aria-label="Client Users Table"
        size="medium"
        {...otherProps}
      >
        {children}
      </Table>
    </TableContainer>
  );
};

ClientUsersTable.propTypes = {
  onAddUser: PropTypes.func.isRequired,
  metaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ClientUsersTable.defaultProps = {};

export default ClientUsersTable;

import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';

import ChannelAvatar from 'components/avatars/ChannelAvatar';
import PlatformLabel from 'components/PlatformLabel';
import ChannelLink from 'components/ChannelLink';
import { ChannelPropType } from 'utils/channel';
import { AlignPropType } from 'utils/table';

const TableCellChannel = ({
  channel,
  link,
  align,
  withAvatar,
  bold,
  ...otherProps
}) => {
  const theme = useTheme();

  return (
    <TableCell align={align} {...otherProps}>
      <Box display="flex" alignItems="center">
        <Box marginRight={1}>
          {withAvatar && (
            <ChannelAvatar withBadge={false} channel={channel} size={30} />
          )}
        </Box>
        {link ? (
          <ChannelLink
            channel={channel}
            compact
            color={theme.palette.secondary.main}
            variant="body2"
          />
        ) : (
          <PlatformLabel
            platform={channel.platform}
            channelName={channel.name}
            variant="subtitle2"
            color={theme.palette.secondary.main}
          />
        )}
      </Box>
    </TableCell>
  );
};

TableCellChannel.propTypes = {
  align: AlignPropType,
  channel: ChannelPropType.isRequired,
  link: PropTypes.bool,
  bold: PropTypes.bool,
  withAvatar: PropTypes.bool,
};

TableCellChannel.defaultProps = {
  align: 'left',
  link: false,
  withAvatar: false,
  bold: false,
};

export default TableCellChannel;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as Icons from 'react-feather';
import clsx from 'clsx';
import noop from 'lodash/noop';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

import { ArticlePropType, getInfoLine } from 'utils/insider';
import LoadingState from 'components/LoadingState';

import Markdown from './InsiderMarkdown';

const useStyles = makeStyles((theme) => {
  const sidebarWidth = `${theme.sidebar.width}px`;

  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      overflowY: 'scroll',
    },
    title: {
      fontSize: 34,
      lineHeight: '130%',
    },
    container: {
      position: 'relative',
      marginTop: theme.spacing(15),
      marginLeft: sidebarWidth,
      marginBottom: theme.spacing(4),
      width: `calc(100vw - ${theme.spacing(8)} - ${sidebarWidth} + 1px)`,
      '& $title': {
        marginBottom: theme.spacing(4),
      },
    },
    clickable: {
      cursor: 'pointer',
    },
  };
});

const InsiderArticleCardDetail = ({
  className,
  article,
  loading,
  onOpen,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  React.useEffect(() => {
    if (otherProps.open && article) {
      onOpen();
    }
  }, [otherProps.open, article]);

  return (
    <Modal
      className={clsx(classes.root, className)}
      disableAutoFocus
      disableEnforceFocus
      {...otherProps}
    >
      <>
        <Box
          className={classes.container}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          borderRadius={12}
          borderColor="primary.light"
          border={1}
          bgcolor="#FFF"
          maxWidth="64rem"
          px="10%"
          py={4}
        >
          <Box
            position="absolute"
            top={theme.spacing(2)}
            right={theme.spacing(2)}
            className={classes.clickable}
          >
            <Icons.XCircle
              color={theme.palette.primary.main}
              onClick={otherProps.onClose}
            />
          </Box>
          {loading && <LoadingState width="100%" />}
          {!loading && (
            <>
              <Typography className={classes.title} variant="h1">
                {article.title}
              </Typography>
              <Markdown source={article.body} />
              <Box mt="auto">
                <Typography noWrap variant="body1" color="primary">
                  {getInfoLine({ intl, article })}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </>
    </Modal>
  );
};

InsiderArticleCardDetail.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  article: ArticlePropType,
  onOpen: PropTypes.func,
};
InsiderArticleCardDetail.defaultProps = {
  className: '',
  loading: true,
  article: {},
  onOpen: noop,
};

export default InsiderArticleCardDetail;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { CampaignPropType } from 'utils/campaign';
import { formatNumber as numberFormatter } from 'utils/formatting';

const CampaignsTableMetaLabel = ({ campaigns }) => {
  const intl = useIntl();
  const metaLabel = React.useMemo(() => {
    const campaignsNumber = campaigns.length;

    const completedCampaignsLabel = intl.formatMessage(
      {
        id: 'components.CampaignsTable.metaLabel.completedCampaigns',
        defaultMessage:
          '{campaignsNumber, plural, one {completed campaign} other {completed campaigns}}',
      },
      {
        campaignsNumber,
      },
    );

    const formatNumber = (number) =>
      numberFormatter({ formatter: intl.formatNumber, number });

    return [formatNumber(campaignsNumber), completedCampaignsLabel].join(' ');
  }, [campaigns, intl]);

  return metaLabel;
};

CampaignsTableMetaLabel.propTypes = {
  campaigns: PropTypes.arrayOf(CampaignPropType),
};

CampaignsTableMetaLabel.defaultProps = {
  campaigns: [],
};

export default CampaignsTableMetaLabel;

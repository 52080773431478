import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import TableBody from 'components/table/TableBody';

import { ChannelPropType } from 'utils/channel';
import { ROLES } from 'utils/roles';
import { PrivateNetworkApplicationPropType } from 'utils/privateNetwork';

import PrivateNetworkChannelTableRow from './PrivateNetworkChannelTableRow';
import ClientUserRow from '../client/PrivateNetworkClientUserTableRow';
import KamUserRow from '../kam/PrivateNetworkKamUserTableRow';

const userTableRowByRole = {
  [ROLES.CLIENT]: ClientUserRow,
  [ROLES.KAM]: KamUserRow,
};

const useStyles = makeStyles({
  table: {
    position: 'relative',
  },
  channelsRow: {
    visibility: 'collapse',
    transition: 'visibility 0.1s ease-in, opacity 0.1s ease-in',
    opacity: 0,
  },
  channelsRowExpanded: {
    visibility: 'visible',
    opacity: 1,
  },
});

const PrivateNetworkApplicationTableRow = React.memo(
  ({
    role,
    onClick,
    onExpand,
    onClickChannel,
    selected,
    selectedChannel,
    expanded,
    application,
  }) => {
    const classes = useStyles();

    const UserRowComponent = userTableRowByRole[role];

    return (
      <React.Fragment key={application.id}>
        <TableBody>
          <UserRowComponent
            user={application.user}
            clientReview={application.clientReview}
            kamReview={application.kamReview}
            isHidden={application?.hiddenFromClient}
            numberOfChannels={application.channels.length}
            createdAt={application.createdAt}
            onClick={onClick}
            expanded={expanded}
            onExpand={onExpand}
            selected={selected}
            hasSelectedChannel={!!selectedChannel?.id}
            selectable
            hover
          />
        </TableBody>
        <TableBody
          className={clsx(classes.channelsRow, {
            [classes.channelsRowExpanded]: expanded,
          })}
        >
          {expanded &&
            application.channels.map((channel) => (
              <PrivateNetworkChannelTableRow
                key={channel.id}
                channel={channel}
                onClick={() => onClickChannel(channel.id)}
                selected={selectedChannel?.id === channel.id}
                multiStatus={role === ROLES.KAM}
                selectable
                hover
              />
            ))}
        </TableBody>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) =>
    prevProps.application.id === nextProps.application.id &&
    prevProps?.selectedChannel?.id === nextProps?.selectedChannel?.id &&
    prevProps.selected === nextProps.selected &&
    prevProps.expanded === nextProps.expanded,
);

PrivateNetworkApplicationTableRow.propTypes = {
  role: PropTypes.oneOf([ROLES.KAM, ROLES.CLIENT]),
  application: PrivateNetworkApplicationPropType,
  onClick: PropTypes.func.isRequired,
  onClickChannel: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  selectedChannel: ChannelPropType,
  expanded: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
};

PrivateNetworkApplicationTableRow.defaultProps = {
  role: ROLES.CLIENT,
  selected: false,
  expanded: true,
};

export default PrivateNetworkApplicationTableRow;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ChannelSelect from 'components/ChannelSelect';
import ChannelAvatar from 'components/avatars/ChannelAvatar';
import { LocationLabel, GenderLabel, UserPropType } from 'utils/user';
import { ChannelPropType } from 'utils/channel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: theme.palette.secondary.light,
  },
  info: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginLeft: theme.spacing(2),
  },
  personal: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: theme.spacing(2),
    width: theme.typography.pxToRem(170),
  },
}));

const ChannelCardHeader = ({
  selectedChannel,
  channels,
  onSelectChannel,
  loading,
  user,
  className,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Box p={2} className={clsx(classes.root, className)} {...otherProps}>
      <Box display="flex">
        <ChannelAvatar channel={selectedChannel} user={user} />
        <Box className={classes.info}>
          <Box className={classes.personal}>
            <Typography>{selectedChannel.name}</Typography>
            <Typography color="textSecondary" variant="body2">
              <GenderLabel user={user} />
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <LocationLabel user={user} />
            </Typography>
          </Box>
          <Divider />
        </Box>
      </Box>
      {loading && <Box height="48px" mt={2} />}
      {!loading && (
        <ChannelSelect
          channels={channels}
          selectedChannel={selectedChannel || channels[0]}
          onSelectChannel={onSelectChannel}
        />
      )}
    </Box>
  );
};

ChannelCardHeader.propTypes = {
  selectedChannel: ChannelPropType.isRequired,
  channels: PropTypes.arrayOf(ChannelPropType),
  onSelectChannel: PropTypes.func,
  user: UserPropType,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

ChannelCardHeader.defaultProps = {
  className: '',
  user: {},
  loading: false,
  onSelectChannel: null,
  channels: [],
};

export default ChannelCardHeader;

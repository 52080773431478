import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

import { PlatformPropType } from 'utils/channel';
import { ApplicationPropType } from 'utils/application';
import { SortObjectPropType } from 'utils/sorting';

import TableContainer from 'components/table/TableContainer';
import TableBody from 'components/table/TableBody';

import PublishedApplicationsTableHead from './PublishedApplicationsTableHead';
import PublishedApplicationsTableRow from './PublishedApplicationsTableRow';

const useStyles = makeStyles({
  table: {
    position: 'relative',
  },
});

const PublishedApplicationsTable = ({
  loading,
  platform,
  applications,
  currentSort,
  onSort,
  headerLabel,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <TableContainer label={headerLabel}>
      <Table
        className={classes.table}
        aria-label="Published Applications Table"
        size="small"
        {...otherProps}
      >
        <PublishedApplicationsTableHead
          platform={platform}
          currentSort={currentSort}
          onSort={onSort}
        />
        <TableBody loading={loading}>
          {applications.map((application) => (
            <PublishedApplicationsTableRow
              key={application.id}
              platform={platform}
              application={application}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PublishedApplicationsTable.propTypes = {
  platform: PlatformPropType,
  loading: PropTypes.bool,
  applications: PropTypes.arrayOf(ApplicationPropType),
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  headerLabel: PropTypes.node,
};

PublishedApplicationsTable.defaultProps = {
  loading: false,
  applications: [],
  headerLabel: null,
};

export default PublishedApplicationsTable;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.light}`,
    backgroundColor: ({ value }) =>
      value ? theme.palette.primary.light : 'transparent',
    borderRadius: 16,
    '& > *:first-child': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const CampaignCardKPI = ({ value, label, className, ...otherProps }) => {
  const classes = useStyles({ value });

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <Typography variant="h6" color="textPrimary">
        {value || '—'}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
    </Box>
  );
};

CampaignCardKPI.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CampaignCardKPI.defaultProps = {
  value: 0,
  className: '',
};

export default CampaignCardKPI;

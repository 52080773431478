import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

import { makeStyles, alpha } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    '&$selected:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.125),
    },
    '&:after': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: 4,
      content: '""',
    },
  },
  selected: {
    '&$button': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.125),

      '& $icon > svg': {
        stroke: theme.palette.primary.contrastText,
      },
    },
    '&:after': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  button: {
    color: theme.palette.primary.contrastText,
    '& $icon > svg': {
      stroke: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: alpha('#FFF', 0.08),
    },
  },
  icon: {},
}));

const SidebarLink = ({
  label,
  url,
  icon,
  children,
  onClick,
  hidden,
  ...otherProps
}) => {
  const {
    icon: iconClass,
    selected: selectedClass,
    ...listItemClasses
  } = useStyles();
  const location = useLocation();
  const intl = useIntl();

  const content = React.useMemo(() => {
    if (isString(label)) {
      return label;
    }

    if (isObject(label)) {
      return intl.formatMessage(label);
    }

    return children;
  }, [label, children]);

  if (hidden) {
    return null;
  }

  if (onClick) {
    return (
      <ListItem
        button
        classes={listItemClasses}
        onClick={onClick}
        {...otherProps}
      >
        {icon && <ListItemIcon className={iconClass}>{icon}</ListItemIcon>}
        <ListItemText>{content}</ListItemText>
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      selected={location.pathname === url}
      classes={listItemClasses}
      activeClassName={selectedClass}
      // eslint-disable-next-line react/prop-types
      component={React.forwardRef(({ children, ...props }, ref) => (
        <NavLink to={url} {...props} ref={ref}>
          {children}
        </NavLink>
      ))}
      {...otherProps}
    >
      {icon && <ListItemIcon className={iconClass}>{icon}</ListItemIcon>}
      <ListItemText>{content}</ListItemText>
    </ListItem>
  );
};

SidebarLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  url: PropTypes.string,
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node,
};

SidebarLink.defaultProps = {
  label: '',
  url: '',
  onClick: null,
  hidden: false,
  icon: null,
  children: null,
};

export default SidebarLink;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import { FormattedNumber } from 'react-intl';
import numeral from 'numeral';

import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import { AlignPropType } from 'utils/table';
import {
  intlUnitFormats,
  NumberFormatsPropType,
  formatRounding,
} from 'utils/formatting';

const TableCellNumber = ({
  value: valueProp,
  align,
  format,
  roundingDigits,
  nFormat,
  label,
  ...otherProps
}) => {
  const value = React.useMemo(() => {
    if (!valueProp || isNil(valueProp) || !isNumber(valueProp)) {
      return (
        <Typography noWrap variant="body2">
          —
        </Typography>
      );
    }

    let val = valueProp;

    if (roundingDigits) {
      val = formatRounding(val, roundingDigits);
    }

    if (nFormat) {
      return (
        <Typography noWrap variant="body2">
          {numeral(val).format('0a')}
        </Typography>
      );
    }

    const unitFormat = get(intlUnitFormats, format, {});

    return (
      <Typography noWrap variant="body2">
        <FormattedNumber value={val} {...unitFormat} />
      </Typography>
    );
  }, [valueProp, format, nFormat, intlUnitFormats]);

  return (
    <TableCell align={align} {...otherProps}>
      {value}
      {label && (
        <Typography variant="caption" color="primary">
          {label}
        </Typography>
      )}
    </TableCell>
  );
};

TableCellNumber.propTypes = {
  value: PropTypes.number,
  align: AlignPropType,
  format: NumberFormatsPropType,
  roundingDigits: PropTypes.number,
  nFormat: PropTypes.bool,
  label: PropTypes.string,
};

TableCellNumber.defaultProps = {
  align: 'right',
  format: undefined,
  roundingDigits: undefined,
  nFormat: false,
  label: '',
};

export default TableCellNumber;

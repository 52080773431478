import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

import { PlatformPropType } from 'utils/channel';
import { ApplicationPropType } from 'utils/application';
import { CampaignPropType } from 'utils/campaign';
import { SortObjectPropType } from 'utils/sorting';

import TableContainer from 'components/table/TableContainer';
import TableBody from 'components/table/TableBody';

import ApplicationsTableHead from './ApplicationsTableHead';
import ApplicationsTableRow from './ApplicationsTableRow';

const useStyles = makeStyles({
  table: {
    position: 'relative',
  },
});

const ApplicationsTable = ({
  platform,
  loading,
  campaign,
  applications,
  currentSort,
  onSort,
  selectedApplication,
  onSelectApplication,
  headerContent,
  productShipmentEnabled,
  headerLabel,
  hideKamNotesColumn,
  ...otherProps
}) => {
  const classes = useStyles();

  const isSelectedRow = (application) =>
    !!selectedApplication && application.id === selectedApplication.id;

  const selector = (id) => () => onSelectApplication(id);

  return (
    <TableContainer label={headerLabel} headerContent={headerContent}>
      <Table
        className={classes.table}
        aria-label="Applications Table"
        size="small"
        {...otherProps}
      >
        <ApplicationsTableHead
          platform={platform}
          currentSort={currentSort}
          onSort={onSort}
          productShipmentEnabled={productShipmentEnabled}
          hideKamNotesColumn={hideKamNotesColumn}
          tasks={campaign.tasks}
        />
        <TableBody loading={loading}>
          {applications.map((application) => (
            <ApplicationsTableRow
              key={application.id}
              platform={platform}
              application={application}
              onClick={selector(application.id)}
              selected={isSelectedRow(application)}
              productShipmentEnabled={productShipmentEnabled}
              hideKamNotesColumn={hideKamNotesColumn}
              hover
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ApplicationsTable.propTypes = {
  platform: PlatformPropType,
  loading: PropTypes.bool,
  applications: PropTypes.arrayOf(ApplicationPropType),
  campaign: PropTypes.oneOfType([CampaignPropType, PropTypes.object]),
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  selectedApplication: ApplicationPropType,
  onSelectApplication: PropTypes.func.isRequired,
  headerContent: PropTypes.node,
  productShipmentEnabled: PropTypes.bool,
  hideKamNotesColumn: PropTypes.bool,
  headerLabel: PropTypes.string,
};

ApplicationsTable.defaultProps = {
  campaign: undefined,
  loading: false,
  applications: [],
  headerContent: null,
  productShipmentEnabled: false,
  headerLabel: '',
  hideKamNotesColumn: false,
};

export default ApplicationsTable;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import NameChip from 'components/NameChip';

import { formatTime, formatDate } from 'utils/formatting';

const NoteListItem = ({ note, createdAt, createdBy, creatorName }) => {
  const intl = useIntl();

  const nameInfo = React.useMemo(() => {
    const isEqolotNote =
      createdBy.includes('eqolot.com') || createdBy.includes('blogfoster.com');

    if (isEqolotNote) {
      return {
        name: 'eqolot',
        tooltip: '',
      };
    }

    return {
      name: creatorName || createdBy,
      tooltip: creatorName ? createdBy : '',
    };
  }, [creatorName, createdBy]);

  return (
    <ListItem disableGutters>
      <ListItemText
        primary={
          <Box display="flex">
            <Typography variant="body1" color="textSecondary">
              {formatDate({ isoDate: createdAt, formatter: intl.formatDate })}
              &nbsp;
              <FormattedMessage
                id="note.dateTimeSeparator"
                defaultMessage="at"
              />
              &nbsp;
              {formatTime({ isoDate: createdAt, formatter: intl.formatTime })}
            </Typography>
            <Box overflow="hidden" ml={1}>
              <NameChip name={nameInfo.name} tooltip={nameInfo.tooltip} />
            </Box>
          </Box>
        }
        secondary={
          <Typography color="textSecondary" variant="body2">
            {note}
          </Typography>
        }
      />
    </ListItem>
  );
};

NoteListItem.propTypes = {
  note: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  creatorName: PropTypes.string,
};

NoteListItem.defaultProps = {
  creatorName: '',
};

const ChannelCardNotesList = ({ notes }) => {
  const sortedNotes = React.useMemo(
    () => sortBy(notes, ({ createdAt }) => -new Date(createdAt)),
    [notes],
  );

  return (
    <List>
      {sortedNotes.map((note) => (
        <React.Fragment
          key={`${Number(new Date(note.createdAt))}${note.createdBy}`}
        >
          <NoteListItem {...note} />
          <Divider />
        </React.Fragment>
      ))}
      {isEmpty(sortedNotes) && <Typography>No notes</Typography>}
    </List>
  );
};

ChannelCardNotesList.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object),
};

ChannelCardNotesList.defaultProps = {
  notes: [],
};

export default ChannelCardNotesList;

import React from 'react';
import * as Icons from 'react-feather';

import { PlatformPropType } from 'utils/channel';

import TikTokIcon from './TikTok';
import BlogGAIcon from './BlogGA';
import PinterestIcon from './Pinterest';

const iconsByPlatform = {
  instagram: Icons.Instagram,
  tiktok: TikTokIcon,
  youtube: Icons.Youtube,
  twitter: Icons.Twitter,
  ga: BlogGAIcon,
  website: BlogGAIcon,
  pinterest: PinterestIcon,
  facebook: Icons.Facebook,
};

const Platform = ({ platform, ...otherProps }) => {
  const IconComponent = iconsByPlatform[platform];
  return <IconComponent {...otherProps} />;
};

Platform.propTypes = {
  platform: PlatformPropType.isRequired,
};

export default Platform;

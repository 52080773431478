import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    maxWidth: (props) => props.maxWidth,
  },
});

const NameChip = ({ className, maxWidth, name, tooltip }) => {
  const classes = useStyles({ maxWidth });

  return (
    <Tooltip title={tooltip}>
      <Chip
        className={clsx(classes.root, className)}
        label={name}
        variant="outlined"
      />
    </Tooltip>
  );
};

NameChip.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  maxWidth: PropTypes.string,
};

NameChip.defaultProps = {
  className: '',
  tooltip: '',
  maxWidth: '100%',
};

export default NameChip;

import React from 'react';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';

const CHANNEL_SEARCH_FIELDS = gql`
  fragment channelSearchFields on ChannelSearchItem {
    id
    name
    reach
    platform
    external
    tags
    avatarUrl
    data {
      url
    }
    user {
      id
      salutation
      city
      country
      gravatar(size: $gravatarSize)
      channels {
        id
        name
        platform
        data {
          url
        }
      }
    }
    categories {
      code
      weight
    }
    insights {
      ... on InstagramInsights {
        followedBy
        follows
        media
        date
      }

      ... on YoutubeInsights {
        viewCount
        commentCount
        subscriberCount
        hiddenSubscriberCount
        videoCount
        date
      }
    }
    kpi {
      ... on TikTokKPI {
        audienceStatistic {
          genders
        }
        geoStatistic {
          countries
        }
        followers
        topCountry
        viewsPerVideo
        viewsPerVideoMedian
        viewsTotal
        updatedByAt
      }
    }
    keyMetrics {
      ... on InstagramKeyMetrics {
        metricsValidAt
        source
        followers
        engagementRate
        followersHistory {
          month
          followers
        }
        audience {
          countries
          gender
          ageRange
        }
        topCountry
        storiesImpressions
        postsImpressions
        storyImpressionMedian
        postImpressionMedian
        storyImpressionRatio
        postImpressionRatio
        updatedByAt
      }
      ... on PinterestKeyMetrics {
        followers
        impressionMedian
        engagementMedian
        engagementRatio
        pinClickMedian
        pinClickRatio
        saveMedian
        saveRatio
        updatedAt
      }
    }
    collaborationRatings {
      visualCreativity
      copyWriting
      punctuality
      professionalism
    }
    updatedAt
    applications {
      applicationId
      campaignId
      campaignName
      campaignStatus
      campaignSummaryStatus
      applicationStatus
      clientApplicationReview {
        status
        note
        updatedBy
        updatedAt
      }
      createdAt
    }
    notes {
      note
      createdBy
      createdAt
      creatorName
    }
  }
`;

const MANY = gql`
  query ChannelSearch(
    $page: Int = 1
    $query: ChannelSearchQuery
    $sort: ChannelSearchSort
    $gravatarSize: Int = 64
  ) {
    channelSearch(page: $page, query: $query, sort: $sort) {
      data {
        ...channelSearchFields
      }
      meta {
        counters {
          reach {
            filtered
            total
          }
          channels {
            filtered
            total
          }
        }
      }
    }
  }
  ${CHANNEL_SEARCH_FIELDS}
`;

const UPDATE_CHANNEL_TAGS = gql`
  mutation UpdateChannelTags($id: ID!, $payload: [String]) {
    updateChannelTags(id: $id, input: $payload)
  }
`;

const UPDATE_CHANNEL_NOTES = gql`
  mutation UpdateChannelNotes($id: ID!, $payload: [ChannelNoteInput]!) {
    updateChannelNotes(id: $id, input: $payload)
  }
`;

const CLIENT_CAMPAIGNS = gql`
  query Campaigns(
    $page: Int
    $sort: CampaignsSort
    $campaignsFilter: CampaignsFilter
    $applicationsFilter: ApplicationsFilter
  ) {
    campaigns(
      page: $page
      sort: $sort
      campaignsFilter: $campaignsFilter
      applicationsFilter: $applicationsFilter
    ) {
      id
      name
      details {
        headline
      }
    }
  }
`;

export const useMany = (opts) => useQuery(MANY, opts);

export const useUpdateChannelTags = () => {
  const [updateChannelTags, { loading, error }] = useMutation(
    UPDATE_CHANNEL_TAGS,
    {
      refetchQueries: ['ChannelTags', 'ChannelSearch'],
    },
  );

  if (error) {
    throw new Error(`Failed to mutate tags: ${JSON.stringify(error)}`);
  }

  return {
    updateChannelTags: React.useCallback(
      ({ id, payload }) => updateChannelTags({ variables: { id, payload } }),
      [updateChannelTags],
    ),
    loading,
  };
};

export const useUpdateChannelNotes = () => {
  const [updateChannelNotes, { loading, error }] = useMutation(
    UPDATE_CHANNEL_NOTES,
    {
      refetchQueries: ['ChannelSearch'],
    },
  );

  if (error) {
    throw new Error(`Failed to mutate notes: ${JSON.stringify(error)}`);
  }

  return {
    updateChannelNotes: React.useCallback(
      ({ id, payload }) => updateChannelNotes({ variables: { id, payload } }),
      [updateChannelNotes],
    ),
    loading,
  };
};

export const useClientCampaigns = (opts) => {
  const [fetchClientCampaigns, { data }] = useLazyQuery(CLIENT_CAMPAIGNS);

  React.useEffect(() => {
    fetchClientCampaigns(opts);
  }, []);
  return data?.campaigns || [];
};

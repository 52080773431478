import React from 'react';
import PropTypes from 'prop-types';
import stripMarkdown from 'strip-markdown';
import { FormattedMessage } from 'react-intl';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ContactIcon from 'icons/Contact';
import Markdown from 'components/Markdown';
import CollapsiblePanel from 'components/CollapsiblePanel';

import { CampaignPropType } from 'utils/campaign';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(1.5),
  },
  content: {
    marginTop: '2px',
  },
  ellipsis: theme.typography.ellipsis,
}));

const CampaignTargetInfluencerProfile = ({
  campaign,
  className,
  ...otherProps
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [collapsed, setCollapsed] = React.useState(true);

  const formattedText = React.useMemo(() => {
    if (!campaign.targetInfluencerProfile) {
      return null;
    }

    return <Markdown source={campaign.targetInfluencerProfile} />;
  }, [campaign.targetInfluencerProfile]);

  const strippedText = React.useMemo(() => {
    if (!campaign.targetInfluencerProfile) {
      return null;
    }

    return (
      <Typography color="textSecondary" variant="body1" noWrap component="div">
        <Markdown
          source={campaign.targetInfluencerProfile}
          plugins={[stripMarkdown]}
          className={classes.ellipsis}
          renderers={{
            // eslint-disable-next-line react/prop-types
            paragraph: ({ children }) => (
              <Box mr={0.5} component="span">
                {children}
              </Box>
            ),
          }}
        />
      </Typography>
    );
  }, [campaign.targetInfluencerProfile, theme]);

  if (!campaign.targetInfluencerProfile) {
    return null;
  }

  const toggleCollapsed = () => setCollapsed(!collapsed);

  return (
    <CollapsiblePanel
      collapsed={collapsed}
      onToggleClick={toggleCollapsed}
      labelContent={
        <Box display="flex" alignItems="center" height="100%">
          <ContactIcon color={theme.palette.text.secondary} />
          <Typography
            color="textSecondary"
            variant="subtitle1"
            className={classes.title}
            noWrap
          >
            <FormattedMessage
              id="scenes.campaigns.details.targetInfluencerProfile"
              defaultMessage="Target Influencer profile"
            />
          </Typography>
        </Box>
      }
      {...otherProps}
    >
      <Box className={classes.content}>
        {collapsed ? strippedText : formattedText}
      </Box>
    </CollapsiblePanel>
  );
};

CampaignTargetInfluencerProfile.propTypes = {
  campaign: CampaignPropType.isRequired,
  className: PropTypes.string,
};
CampaignTargetInfluencerProfile.defaultProps = {
  className: '',
};

export default CampaignTargetInfluencerProfile;

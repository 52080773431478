import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useGridColumns = (columnWidths) => {
  const matches = columnWidths.map((col) =>
    useMediaQuery(`(min-width:${col}px)`),
  );

  // first value required to have at least 1 column
  // last value required to limit maximum number of columns
  const columns = [true, ...matches, false].indexOf(false);

  return columns;
};

export const useInfluencersSearchScreenSize = () => {
  const matchWideScreen = useMediaQuery('(min-width:1725px)');

  return { isWideScreen: matchWideScreen };
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflowX: 'hidden',
    flexWrap: (props) => (props.wrap ? 'wrap' : 'nowrap'),
  },
  chip: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    marginBottom: (props) => (props.wrap ? theme.spacing(1) : 0),
  },
  tagEmpty: {
    visibility: 'hidden',
  },
}));

const Tags = (props) => {
  const {
    tags: tagsProp,
    getTagLabel,
    getTagValue,
    sortBy: sortByProperty,
    className,
    wrap,
    variant,
    classes: classesOverride,
    ...otherProps
  } = props;
  const classes = useStyles(props);

  const renderedTags = React.useMemo(() => {
    let tags = tagsProp;

    if (isEmpty(tags)) {
      return <Chip className={classes.tagEmpty} />;
    }

    if (sortByProperty) {
      tags = sortBy(tags, sortByProperty);
    }

    return tags.map((tag) => (
      <Chip
        key={getTagValue(tag)}
        label={getTagLabel(tag)}
        className={classes.chip}
        variant={variant}
      />
    ));
  }, [
    sortByProperty,
    tagsProp,
    getTagValue,
    getTagLabel,
    variant,
    classes.chip,
  ]);

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      {renderedTags}
    </Box>
  );
};

Tags.propTypes = {
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  getTagLabel: PropTypes.func,
  getTagValue: PropTypes.func,
  sortBy: PropTypes.string,
  wrap: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'outlined']),
  classes: PropTypes.object,
  className: PropTypes.string,
};

Tags.defaultProps = {
  tags: [],
  getTagLabel: (tag) => tag,
  getTagValue: (tag) => tag,
  sortBy: '',
  wrap: false,
  variant: 'outlined',
  classes: {},
  className: '',
};

export default Tags;

import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { PlatformPropType } from 'utils/channel';
import PlatformIcon from 'icons/Platform';

const useStyles = makeStyles((theme) => ({
  secondaryBackground: {
    backgroundColor: theme.palette.secondary.light,
    '&:focus': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  label: {
    transform: 'translate(16px, -6px) scale(0.75)',
    padding: '2px 8px 0',
    margin: '-2px -8px 0',
    borderRadius: 4,
  },
}));

const PlatformSelect = ({ platforms, value, onChange, ...otherProps }) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  const label = intl.formatMessage({
    id: 'scenes.influencers.platformSelect.label',
    defaultMessage: 'Channels and KPIs for',
  });

  return (
    <FormControl variant="outlined" {...otherProps}>
      <InputLabel
        id="platform-select-label"
        className={clsx(classes.secondaryBackground, classes.label)}
      >
        {label}
      </InputLabel>
      <Select
        className={classes.secondaryBackground}
        classes={{ root: classes.secondaryBackground }}
        id="platform-select"
        labelId="platform-select-label"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        label={label}
        margin="dense"
        startAdornment={
          <InputAdornment position="start">
            <PlatformIcon
              platform={value}
              color={theme.palette.secondary.main}
            />
          </InputAdornment>
        }
      >
        {platforms.map((platform) => (
          <MenuItem key={platform.value} value={platform.value}>
            {platform.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

PlatformSelect.propTypes = {
  platforms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PlatformPropType.isRequired,
  onChange: PropTypes.func.isRequired,
};
PlatformSelect.defaultProps = {};

export default PlatformSelect;

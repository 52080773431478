import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import { ClientReviewStatusPropType } from 'utils/clientReviewStatuses';
import NoteIcon from 'icons/Note';
import ApplicationClientReviewIcon from './ApplicationClientReviewIcon';

const ApplicationClientReviewStatus = ({ clientReview, ...otherProps }) => {
  const theme = useTheme();

  // handle applications without client field
  const status = clientReview?.status || 'pending';
  const note = clientReview?.note;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      mr={1}
      {...otherProps}
    >
      <ApplicationClientReviewIcon status={status} />
      {note && (
        <Tooltip title={note}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            ml={1}
          >
            <NoteIcon stroke={theme.palette.text.hint} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

ApplicationClientReviewStatus.propTypes = {
  clientReview: PropTypes.shape({
    status: ClientReviewStatusPropType,
    note: PropTypes.string,
  }).isRequired,
};

ApplicationClientReviewStatus.defaultProps = {};

export default ApplicationClientReviewStatus;

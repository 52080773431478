import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import * as Icons from 'react-feather';
import without from 'lodash/without';

import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {
  allStatuses,
  getStatusLabel,
  useKamReviewStatusButton,
} from 'utils/kamReviewStatuses';

const useDotStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '50%',
    border: `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
    backgroundColor: (props) => props.color,
  },
}));

const StatusDot = ({ status, ...otherProps }) => {
  const statusButton = useKamReviewStatusButton(status);
  const classes = useDotStyles({ color: statusButton.color });

  return (
    <Box className={classes.root} width="24px" height="24px" {...otherProps} />
  );
};

StatusDot.propTypes = {
  status: PropTypes.oneOf(allStatuses).isRequired,
};

const useStyles = makeStyles((theme) => ({
  triggerButton: {
    cursor: 'pointer',
    width: '100%',
    color: (props) => props.selectedColor,
  },
  menuItem: {
    width: '290px',
  },
  menuItemSelected: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.12),
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    },
  },
  darkText: {
    color: theme.palette.text.primary,
  },
}));

const KamStatusSelect = ({ value, onChange, ...otherProps }) => {
  const { color: selectedColor } = useKamReviewStatusButton(value);
  const classes = useStyles({
    selectedColor,
  });
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onSelectItem = (newStatus) => {
    handleCloseMenu();

    if (newStatus !== value) {
      onChange(newStatus);
    }
  };

  return (
    <Box {...otherProps}>
      <Box
        className={classes.triggerButton}
        aria-controls="kam-status-select-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        py={1}
      >
        <Box display="flex" alignItems="center">
          <StatusDot status={value} mr={1} />
          <Box>
            <Typography
              className={clsx({ [classes.darkText]: !value })}
              variant="subtitle1"
            >
              <FormattedMessage {...getStatusLabel(value)} />
            </Typography>
            <Typography
              className={clsx({ [classes.darkText]: !value })}
              variant="caption"
            >
              <FormattedMessage
                id="scenes.privateNetwork.kam.statusSelect.button.label"
                defaultMessage="Click to change"
              />
            </Typography>
          </Box>
        </Box>
        {anchorEl ? (
          <Icons.ChevronUp color={theme.palette.primary.main} />
        ) : (
          <Icons.ChevronDown color={theme.palette.primary.main} />
        )}
      </Box>
      <Menu
        id="kam-status-select-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        keepMounted
        onClose={handleCloseMenu}
      >
        {without(allStatuses, 'all').map((kamStatus) => (
          <Box key={kamStatus}>
            <MenuItem
              className={clsx(classes.menuItem, {
                [classes.menuItemSelected]: kamStatus === value,
              })}
              onClick={() => onSelectItem(kamStatus)}
            >
              <Box py={0.5} display="flex" alignItems="center" width="100%">
                <StatusDot status={kamStatus} mr={1} />
                <Typography variant="subtitle1">
                  <FormattedMessage {...getStatusLabel(kamStatus)} />
                </Typography>
                {kamStatus === value && (
                  <Box display="flex" ml="auto">
                    <Icons.Check color={theme.palette.primary.main} />
                  </Box>
                )}
              </Box>
            </MenuItem>
            <Divider />
          </Box>
        ))}
      </Menu>
    </Box>
  );
};

KamStatusSelect.propTypes = {
  value: PropTypes.oneOf(allStatuses),
  onChange: PropTypes.func.isRequired,
};
KamStatusSelect.defaultProps = {};

export default KamStatusSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import NoteIcon from 'icons/Note';
import Markdown from 'components/Markdown';
import { getStatusLabel as getClienReviewStatusLabel } from 'utils/clientReviewStatuses';

import ApplicationClientReviewIcon from './ApplicationClientReviewIcon';

const useDialogStyles = makeStyles((theme) => ({
  undecidedButton: {
    color: theme.palette.info.main,
  },
  rejectButton: {
    color: theme.palette.error.main,
  },
  acceptButton: {
    color: theme.palette.success.main,
  },
}));

export const UpdateStatusDialog = ({
  title,
  open,
  note,
  description,
  onUpdate,
  onCancel,
  ...otherProps
}) => {
  const classes = useDialogStyles();
  const intl = useIntl();

  const [message, setMessage] = React.useState(note || '');

  React.useEffect(() => {
    if (open) {
      setMessage(note || '');
    }
  }, [open]);

  const statusUpdater = (newStatus) => () => {
    onUpdate({
      status: newStatus,
      note: message,
    });
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="application-client-review-dialog-title"
      {...otherProps}
    >
      <DialogTitle id="application-client-review-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        {description && (
          <Box mb={3}>
            <Markdown source={description} />
          </Box>
        )}
        <TextField
          id="message"
          variant="outlined"
          autoFocus
          label={intl.formatMessage({
            id: 'components.ApplicationReviewButton.dialog.message.label',
            defaultMessage: 'Optional message for the account manager',
          })}
          helperText={intl.formatMessage({
            id: 'components.ApplicationReviewButton.dialog.message.helperText',
            defaultMessage:
              'This message is only shown to the Account Manager, it will not be shared with the Influencer.',
          })}
          fullWidth
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          multiline
          minRows={2}
          maxRows={25}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          {intl.formatMessage({
            id: 'components.ApplicationReviewButton.dialog.action.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          className={classes.undecidedButton}
          onClick={statusUpdater('undecided')}
        >
          {intl.formatMessage({
            id: 'components.ApplicationReviewButton.dialog.action.undecided',
            defaultMessage: 'Undecided',
          })}
        </Button>
        <Button
          className={classes.rejectButton}
          onClick={statusUpdater('rejected')}
        >
          {intl.formatMessage({
            id: 'components.ApplicationReviewButton.dialog.action.reject',
            defaultMessage: 'Reject',
          })}
        </Button>
        <Button
          className={classes.acceptButton}
          onClick={statusUpdater('accepted')}
        >
          {intl.formatMessage({
            id: 'components.ApplicationReviewButton.dialog.action.accept',
            defaultMessage: 'Accept',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpdateStatusDialog.propTypes = {
  open: PropTypes.bool,
  note: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

UpdateStatusDialog.defaultProps = {
  open: false,
  note: '',
  description: '',
};

const useStyles = makeStyles((theme) => {
  const getBackgroundColor = (status) => {
    if (status === 'pending') {
      return theme.palette.primary.main;
    }

    if (status === 'undecided') {
      return theme.palette.info.light;
    }

    if (status === 'rejected') {
      return theme.palette.error.light;
    }

    return theme.palette.success.light;
  };

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 100,
      width: 275,
      zIndex: 1,
      padding: theme.spacing(3),
      backgroundColor: (props) => getBackgroundColor(props.status),
      '&:hover': {
        backgroundColor: (props) => getBackgroundColor(props.status),
      },
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    updateButton: {
      color: '#FFF',
      border: '1px solid #FFF',
      '&:disabled': {
        border: '1px solid #FFF',
        backgroundColor: '#FFF',
      },
    },
    label: {
      color: '#fff',
    },
  };
});

const ApplicationReviewButton = (props) => {
  const {
    className,
    status,
    note,
    title,
    description,
    onUpdate,
    disabled,
    disabledTooltip,
    ...otherProps
  } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const intl = useIntl();

  const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);

  const handleUpdate = (newReviewStatus) => {
    onUpdate(newReviewStatus);
    setUpdateDialogOpen(false);
  };

  const iconColor =
    status === 'pending' ? theme.palette.secondary.main : '#FFF';

  return (
    <Box
      className={clsx(classes.root, className)}
      component={Paper}
      elevation={12}
      {...otherProps}
    >
      <UpdateStatusDialog
        open={updateDialogOpen}
        onCancel={() => setUpdateDialogOpen(false)}
        onUpdate={handleUpdate}
        note={note}
        description={description}
        title={title}
      />
      <Box className={classes.statusContainer}>
        <Box width={24} display="flex" justifyContent="center">
          <ApplicationClientReviewIcon status={status} color={iconColor} />
        </Box>
        <Typography className={classes.label} variant="subtitle1">
          {intl.formatMessage(getClienReviewStatusLabel(status))}
        </Typography>
        {note && (
          <Tooltip title={note}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <NoteIcon stroke="#FFF" />
            </Box>
          </Tooltip>
        )}
      </Box>
      <Tooltip title={disabled ? disabledTooltip : ''} placement="top">
        <span>
          <Button
            className={classes.updateButton}
            variant="outlined"
            onClick={() => setUpdateDialogOpen(true)}
            disabled={disabled}
          >
            {intl.formatMessage({
              id: 'components.ApplicationReviewButton.update',
              defaultMessage: 'Update',
            })}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

ApplicationReviewButton.propTypes = {
  status: PropTypes.string.isRequired,
  note: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

ApplicationReviewButton.defaultProps = {
  note: '',
  disabled: false,
  disabledTooltip: '',
  className: '',
  description: '',
};

export default ApplicationReviewButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { headTitle } from 'utils/pages';
import LoadingState from 'components/LoadingState';
import ErrorBoundary from 'utils/ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 3),
    marginLeft: theme.sidebar.width,
    height: '100%',
    minWidth: `calc(100% - ${theme.sidebar.width}px)`,
    display: (props) => (props.fullWidth ? 'inline-block' : 'block'),
  },
}));

const PageWrapper = ({
  title,
  pageTitle,
  className,
  children,
  loading,
  fullWidth,
  ...otherProps
}) => {
  const classes = useStyles({ fullWidth });

  return (
    <ErrorBoundary>
      <Box className={clsx(classes.root, className)} {...otherProps}>
        <Helmet>
          <title>{pageTitle || headTitle(title)}</title>
        </Helmet>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="40%"
          >
            <LoadingState />
          </Box>
        ) : (
          children
        )}
      </Box>
    </ErrorBoundary>
  );
};

PageWrapper.propTypes = {
  title: PropTypes.string,
  pageTitle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

PageWrapper.defaultProps = {
  title: '',
  pageTitle: '',
  className: '',
  loading: false,
  fullWidth: false,
  children: null,
};

export default PageWrapper;

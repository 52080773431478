import React from 'react';

import { PlatformPropType } from 'utils/channel';

import Instagram from './instagram/TableHead';
import Pinterest from './pinterest/TableHead';
import Tiktok from './tiktok/TableHead';
import Blog from './blog/TableHead';

const componentsByPlatform = {
  instagram: Instagram,
  pinterest: Pinterest,
  tiktok: Tiktok,
  website: Blog,
  ga: Blog,
};

const ApplicationsTableHead = React.memo(({ platform, ...otherProps }) => {
  const Component = componentsByPlatform[platform];

  return <Component {...otherProps} />;
});

ApplicationsTableHead.propTypes = {
  platform: PlatformPropType.isRequired,
};

ApplicationsTableHead.defaultProps = {};

export default ApplicationsTableHead;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, alpha } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useItemStyles = makeStyles((theme) => {
  const iconBackgroundColor = alpha(theme.palette.primary.main, 0.13);

  return {
    icon: {
      backgroundColor: iconBackgroundColor,
      '&:hover': {
        backgroundColor: iconBackgroundColor,
      },
    },
  };
});

const ButtonSwitchItem = React.memo(
  ({ isSelected, label, icon, value, onClick, disabled, ...otherProps }) => {
    const classes = useItemStyles();

    const handleClick = () => onClick(value);

    if (icon) {
      return (
        <Button
          {...otherProps}
          color="primary"
          variant="outlined"
          disableTouchRipple
          classes={isSelected ? { outlinedPrimary: classes.icon } : {}}
          onClick={handleClick}
          disabled={disabled}
        >
          {icon}
        </Button>
      );
    }

    return (
      <Button
        {...otherProps}
        color="primary"
        variant={isSelected ? 'contained' : 'outlined'}
        disableTouchRipple
        disableElevation
        onClick={handleClick}
        disabled={disabled}
      >
        {label}
      </Button>
    );
  },
);

ButtonSwitchItem.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.node,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ButtonSwitchItem.defaultProps = {
  isSelected: false,
  label: '',
  icon: null,
  disabled: false,
};

const useStyles = makeStyles({
  root: {
    backgroundColor: '#FFF',
  },
});

const ButtonSwitch = ({
  filters,
  value,
  onChange,
  className,
  ...otherProps
}) => {
  const classes = useStyles();

  const isSelected = (filter) =>
    value === filter.value || (!value && filter.value === filters[0].value);

  return (
    <ButtonGroup className={clsx(classes.root, className)} {...otherProps}>
      {filters.map((filter) => (
        <ButtonSwitchItem
          key={filter.value}
          isSelected={isSelected(filter)}
          onClick={onChange}
          {...filter}
        />
      ))}
    </ButtonGroup>
  );
};

ButtonSwitch.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      icon: PropTypes.node,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ButtonSwitch.defaultProps = {
  className: '',
  filters: [],
};

export default ButtonSwitch;

import React from 'react';
import range from 'lodash/range';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { useFeatures } from 'utils/features';
import {
  CampaignStatusPropType,
  statuses as campaignStatuses,
  views as campaignViews,
} from 'utils/campaign';
import Stepper from 'components/Stepper';

const getProgressStepByStatus = (status, steps) =>
  findIndex(steps, (step) => step.status === status);

const useStyles = makeStyles((theme) => ({
  stepper: {
    maxWidth: theme.typography.pxToRem(1024),
  },
}));

const CampaignProgressStepper = ({
  currentStep,
  status,
  hideContentReviewStep,
  ...otherProps
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { isFeatureAvailable } = useFeatures();
  const history = useHistory();

  const steps = React.useMemo(
    () =>
      [
        {
          title: intl.formatMessage({
            id: 'campaign.status.briefing',
            defaultMessage: 'Briefing',
          }),
          subtitle: intl.formatMessage({
            id: 'campaign.status.briefing.detailed',
            defaultMessage: 'Campaign assets and settings',
          }),
          value: campaignViews.BRIEFING,
          status: campaignStatuses.BRIEFING,
        },
        {
          title: intl.formatMessage({
            id: 'campaign.status.published',
            defaultMessage: 'Influencer Selection',
          }),
          subtitle: intl.formatMessage({
            id: 'campaign.status.published.detailed',
            defaultMessage: 'The brief is published and Influencers can apply',
          }),
          value: campaignViews.APPLICATIONS,
          status: campaignStatuses.PUBLISHED,
        },
        {
          title: intl.formatMessage({
            id: 'campaign.status.preparation',
            defaultMessage: 'Preparation',
          }),
          subtitle: intl.formatMessage({
            id: 'campaign.status.preparation.detailed',
            defaultMessage: 'Gather documentation, test product',
          }),
          value: campaignViews.PREPARATION,
          status: campaignStatuses.PREPARATION,
        },
        {
          title: intl.formatMessage({
            id: 'campaign.status.clientContentPreview',
            defaultMessage: 'Content Review',
          }),
          subtitle: intl.formatMessage({
            id: 'campaign.status.clientContentPreview.detailed',
            defaultMessage: 'Influencers started to create content',
          }),
          value: campaignViews.CONTENT_REVIEW,
          status: campaignStatuses.CONTENT_REVIEW,
          hidden: hideContentReviewStep,
        },
        {
          title: intl.formatMessage({
            id: 'campaign.status.live',
            defaultMessage: 'Live',
          }),
          subtitle: intl.formatMessage({
            id: 'campaign.status.live.detailed',
            defaultMessage: 'The content is being published',
          }),
          value: 'live',
          status: campaignStatuses.LIVE,
        },
      ].filter(
        (step) =>
          !step.hidden &&
          (!step.feature || (step.feature && isFeatureAvailable(step.feature))),
      ),
    [campaignStatuses, status, hideContentReviewStep, isFeatureAvailable, intl],
  );

  const disabledSteps = React.useMemo(() => {
    const disabledSteps = difference(
      range(steps.length),
      range(getProgressStepByStatus(status, steps) + 1),
    );

    return disabledSteps;
  }, [steps, status, getProgressStepByStatus]);

  const activeStep = findIndex(steps, (step) => step.value === currentStep);

  const onStepClick = ({ value }) => {
    history.push(value);
  };

  return (
    <Box display="flex" justifyContent="center" mb={3} {...otherProps}>
      <Stepper
        className={classes.stepper}
        steps={steps}
        disabledSteps={disabledSteps}
        activeStep={activeStep}
        withSubtitle
        hideIcons
        onClick={onStepClick}
      />
    </Box>
  );
};

CampaignProgressStepper.propTypes = {
  status: CampaignStatusPropType,
  currentStep: PropTypes.string.isRequired,
  hideContentReviewStep: PropTypes.bool,
};

CampaignProgressStepper.defaultProps = {
  hideContentReviewStep: true,
};

export default CampaignProgressStepper;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import * as Icons from 'react-feather';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import { SortObjectPropType } from 'utils/sorting';

const sortableProperties = ['createdAt', 'lastPublishedAt', 'campaignTitle'];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  selectedValue: {
    paddingLeft: theme.spacing(0.5),
    fontWeight: 'bold',
  },
}));

const sortPropertiesStrings = defineMessages({
  createdAt: {
    id: 'scenes.influencerAssets.sortProperties.createdAt',
    defaultMessage: 'Creation Date',
  },
  lastPublishedAt: {
    id: 'scenes.influencerAssets.sortProperties.lastPublishedAt',
    defaultMessage: 'Last Publication Date',
  },
  campaignTitle: {
    id: 'scenes.influencerAssets.sortProperties.campaignTitle',
    defaultMessage: 'Campaign',
  },
});

const InfluencerAssetsSort = ({
  onSort,
  currentSort,
  className,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleChangeDirection = () => {
    const newDirection = currentSort.direction === 'desc' ? 'asc' : 'desc';

    onSort({
      ...currentSort,
      direction: newDirection,
    });
  };

  const handleChangeSortProperty = (event) => {
    const { value: newSortProperty } = event.target;

    onSort({
      ...currentSort,
      by: newSortProperty,
    });
  };

  const sortablePropertiesOptions = React.useMemo(
    () =>
      sortableProperties.map((property) => (
        <MenuItem key={property} value={property}>
          {intl.formatMessage(sortPropertiesStrings[property])}
        </MenuItem>
      )),
    [sortableProperties, intl],
  );

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <Typography>
        {intl.formatMessage({
          id: 'scenes.influencerAssets.sortLabel',
          defaultMessage: 'Order by',
        })}
      </Typography>
      <IconButton size="small" color="primary" onClick={handleChangeDirection}>
        {currentSort.direction === 'desc' ? (
          <Icons.ArrowDown />
        ) : (
          <Icons.ArrowUp />
        )}
      </IconButton>
      <Select
        value={currentSort.by}
        onChange={handleChangeSortProperty}
        input={<InputBase />}
        renderValue={(value) => (
          <Typography className={classes.selectedValue} color="primary">
            {intl.formatMessage(sortPropertiesStrings[value])}
          </Typography>
        )}
      >
        {sortablePropertiesOptions}
      </Select>
    </Box>
  );
};

InfluencerAssetsSort.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  className: PropTypes.string,
};

InfluencerAssetsSort.defaultProps = {
  className: '',
};

export default InfluencerAssetsSort;

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { TrackedLink } from 'utils/gtm';
import { headTitle } from 'utils/pages';

const useStyles = makeStyles((theme) => {
  const marginLeft = `calc(${theme.sidebar.width}px + ${theme.spacing(15)})`;

  return {
    paper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '70%',
      margin: `${theme.spacing(15)} ${theme.spacing(15)} ${theme.spacing(
        15,
      )} ${marginLeft}`,
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  };
});

const ErrorPage = ({ title, children, image }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Paper elevation={1} className={classes.paper}>
        <Helmet>
          <title>{headTitle(title)}</title>
        </Helmet>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          minWidth="55%"
          height="100%"
          p={5}
        >
          <Typography variant="h1">{title}</Typography>
          {children}
          <TrackedLink
            href="https://eqolot.com"
            target="_blank"
            rel="noreferrer"
            variant="subtitle1"
            eventLabel="Eqolot link clicked on error page"
            color="primary"
          >
            {intl.formatMessage({
              id: 'errorPages.goToEqolot',
              defaultMessage: 'Go to eqolot.com',
            })}
          </TrackedLink>
        </Box>
        <Box height="100%">
          <img className={classes.image} src={image} alt={title} />
        </Box>
      </Paper>
    </Box>
  );
};

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
};

export default ErrorPage;

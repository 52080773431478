import React from 'react';
import pick from 'lodash/pick';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
}));

const CampaignDraftForm = ({ formData, onSubmit, onCancel, onChange }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [submitEnabled, setSubmitEnabled] = React.useState(false);

  const {
    formState,
    watch,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: formData,
  });

  const { headline, mission, goal } = watch();

  const submitForm = (data) => {
    onSubmit(pick(data, ['headline', 'mission', 'goal']));
    setSubmitEnabled(false);
  };

  React.useEffect(() => {
    setSubmitEnabled(headline && goal && mission && formState.isDirty);
    onChange({ headline, mission, goal });
  }, [headline, mission, goal, formState.isDirty]);

  React.useEffect(() => {
    reset(formData);
    setSubmitEnabled(false);
  }, [formData, reset, setSubmitEnabled]);

  return (
    <form className={classes.root} onSubmit={handleSubmit(submitForm)}>
      <TextField
        {...register('headline', { required: true })}
        error={Boolean(errors.headline)}
        label={intl.formatMessage({
          id: 'scenes.campaigns.form.headline.label',
          defaultMessage: 'Campaign Headline',
        })}
        helperText={intl.formatMessage({
          id: 'scenes.campaigns.form.headline.helperText',
          defaultMessage:
            'A clear title to help Influencers find and join this campaign',
        })}
        variant="outlined"
        fullWidth
      />
      <TextField
        {...register('mission', { required: true })}
        error={Boolean(errors.mission)}
        label={intl.formatMessage({
          id: 'scenes.campaigns.form.mission.label',
          defaultMessage:
            'Mission - What should the Influencers do in this campaign?',
        })}
        placeholder={intl.formatMessage({
          id: 'scenes.campaigns.form.mission.placeholder',
          defaultMessage:
            'EXAMPLE: Make your followers aware of the cooperation with our brand…',
        })}
        helperText={intl.formatMessage({
          id: 'scenes.campaigns.form.mission.helperText',
          defaultMessage: 'You can use Markdown for simple text formatting.',
        })}
        variant="outlined"
        multiline
        fullWidth
        minRows={2}
        maxRows={25}
      />
      <TextField
        {...register('goal', { required: true })}
        error={Boolean(errors.goal)}
        label={intl.formatMessage({
          id: 'scenes.campaigns.form.goal.label',
          defaultMessage:
            'Goal - What do you aim to achieve with this campaign?',
        })}
        placeholder={intl.formatMessage({
          id: 'scenes.campaigns.form.goal.placeholder',
          defaultMessage:
            'EXAMPLE: Introduce your followers to our product and highlight its features…',
        })}
        helperText={intl.formatMessage({
          id: 'scenes.campaigns.form.goal.helperText',
          defaultMessage: 'You can use Markdown for simple text formatting.',
        })}
        variant="outlined"
        multiline
        fullWidth
        minRows={2}
        maxRows={25}
      />
      <Box className={classes.actions}>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          {intl.formatMessage({
            id: 'scenes.campaigns.form.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          type="submit"
        >
          {intl.formatMessage({
            id: 'scenes.campaigns.form.save',
            defaultMessage: 'Save',
          })}
        </Button>
      </Box>
    </form>
  );
};

CampaignDraftForm.propTypes = {
  formData: PropTypes.shape({
    headline: PropTypes.string,
    mission: PropTypes.string,
    goal: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

CampaignDraftForm.defaultProps = {
  formData: {
    headline: '',
    mission: '',
    goal: '',
  },
};

export default CampaignDraftForm;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useTitleStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
});

const ChannelCardSectionTitle = ({ title, subtitle, ...otherProps }) => {
  const classes = useTitleStyles();

  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box mb={2} {...otherProps}>
      {title && (
        <Typography className={classes.bold} color="textSecondary" variant="h6">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography color="textSecondary" variant="caption">
          {subtitle}
        </Typography>
      )}
      <Box mt={1} />
      <Divider />
    </Box>
  );
};

ChannelCardSectionTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ChannelCardSectionTitle.defaultProps = {
  title: '',
  subtitle: '',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: theme.spacing(2),
  },
}));

const ChannelCardSection = ({
  title,
  subtitle,
  children,
  className,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <ChannelCardSectionTitle title={title} subtitle={subtitle} />
      {children}
    </Box>
  );
};

ChannelCardSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ChannelCardSection.defaultProps = {
  title: '',
  subtitle: '',
  className: '',
};

export default ChannelCardSection;

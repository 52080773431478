import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import omit from 'lodash/omit';
import { useIntl, FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import MarkdownNote from 'components/MarkdownNote';
import Tabs from 'components/Tabs';
import ChannelCardAudienceKPISection from 'components/channels/card/ChannelCardAudienceKPISection';
import ChannelCardPlatformLink from 'components/channels/card/ChannelCardPlatformLink';
import ChannelCardCategories from 'components/channels/card/ChannelCardCategories';
import ChannelCardBody from 'components/channels/card/ChannelCardBody';
import ChannelCardClientTags from 'components/channels/card/ChannelCardClientTags';
import ChannelCardPerformanceKPISection from 'components/channels/card/ChannelCardPerformanceKPISection';

import ChannelCardCampaignsList from 'components/channels/card/ChannelCardCampaignsList';
import NewChannelNoteDialog from 'components/channels/card/NewChannelNoteDialog';
import ChannelCardNotesList from 'components/channels/card/ChannelCardNotesList';

import { Feature } from 'utils/features';
import { ChannelPropType } from 'utils/channel';
import { formatDateTime } from 'utils/formatting';
import { useClient } from 'utils/client';
import useAuth from 'auth/useAuth';
import { PrivateNetworkApplicationPropType } from 'utils/privateNetwork';

import PrivateNetworkApplicationCardHeader from '../components/PrivateNetworkApplicationCardHeader';
import KamStatusSelect from './KamStatusSelect';
import HiddenStatusButton from './HiddenStatusButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: theme.typography.pxToRem(320),
    backgroundColor: '#fff',
  },
  body: {
    height: '100%',
    // The review button is 85px high
    paddingBottom: `calc(${theme.spacing(3)} + 85px)`,
  },
  internalNotesTitle: {
    marginTop: theme.spacing(1),
  },
}));

const TABS = {
  APPLICATION: 0,
  INSIGHTS: 1,
  CAMPAIGNS: 2,
  NOTES: 3,
};

const PrivateNetworkApplicationKamCard = ({
  selectedChannel,
  channels,
  onSelectChannel,
  application,
  onUpdateChannelTags,
  onUpdateKamReviewStatus,
  onUpdateHiddenFromClient,
  onUpdateKamNote,
  onUpdateUserPitch,
  className,
  loading,
  updateChannelNotes,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { client } = useClient();
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = React.useState(TABS.APPLICATION);

  const [newNoteDialogOpen, setNewNoteDialogOpen] = React.useState(false);

  const displayChannel = React.useMemo(
    () => selectedChannel || channels[0],
    [selectedChannel, channels[0]],
  );

  const handleSelectTab = (newTabIndex) => {
    if (newTabIndex === TABS.APPLICATION) {
      onSelectChannel(null);
      setSelectedTab(newTabIndex);
    } else {
      onSelectChannel(displayChannel.id);
      setSelectedTab(newTabIndex);
    }
  };

  const handleSelectChannel = (newChannelId) => {
    if (!selectedChannel?.id) {
      setSelectedTab(TABS.INSIGHTS);
    }
    onSelectChannel(newChannelId);
  };

  React.useEffect(() => {
    if (!selectedChannel?.id) {
      setSelectedTab(TABS.APPLICATION);
    } else {
      setSelectedTab(
        selectedTab === TABS.APPLICATION ? TABS.INSIGHTS : selectedTab,
      );
    }
  }, [selectedChannel?.id]);

  const submitNewNote = (newNote) => {
    const newNotePayload = {
      createdAt: new Date(),
      createdBy: user.email,
      creatorName: user.name,
      note: newNote,
    };

    const cleanNotes = selectedChannel.notes.map((note) =>
      omit(note, '__typename'),
    );

    updateChannelNotes([...cleanNotes, newNotePayload]);
  };

  const tabLabels = [
    intl.formatMessage({
      id: 'components.PrivateNetworkApplicationCard.tabs.application',
      defaultMessage: 'Request',
    }),
    intl.formatMessage({
      id: 'components.ApplicationCard.tabs.insights',
      defaultMessage: 'Insights',
    }),
    intl.formatMessage(
      {
        id: 'components.PrivateNetworkApplicationCard.tabs.campaigns',
        defaultMessage: 'Camp... ({numberOfApplications})',
      },
      {
        numberOfApplications: displayChannel?.applications?.length,
      },
    ),
    intl.formatMessage(
      {
        id: 'components.PrivateNetworkApplicationCard.tabs.notes',
        defaultMessage: 'Notes ({numberOfNotes})',
      },
      {
        numberOfNotes: displayChannel?.notes?.length,
      },
    ),
  ];

  const isApplicationView =
    selectedTab === TABS.APPLICATION || !selectedChannel;

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <PrivateNetworkApplicationCardHeader
        channels={channels}
        selectedChannel={displayChannel}
        onSelectChannel={handleSelectChannel}
        user={application.user}
        reviewStatus={application?.clientReview?.status}
        hideSelection={isApplicationView}
        hideChannelName={isApplicationView}
        pb={0}
      />
      <Tabs
        selectedTab={selectedTab}
        onSelectTab={handleSelectTab}
        labels={tabLabels}
      >
        <ChannelCardBody className={classes.body} key="application">
          <KamStatusSelect
            value={application?.kamReview?.status}
            onChange={onUpdateKamReviewStatus}
          />
          <Divider />
          <HiddenStatusButton
            hidden={application?.hiddenFromClient}
            onChange={onUpdateHiddenFromClient}
          />
          <Divider />
          <Box display="flex" flexDirection="column" mt={2}>
            <MarkdownNote
              label={
                <FormattedMessage
                  id="scenes.privateNetwork.applicationCard.kam.clientReviewHeadline"
                  defaultMessage="Client Review"
                />
              }
              note={application?.clientReview.note || '—'}
              timestamp={application?.clientReview?.updatedAt}
              updaterEmail={application?.clientReview?.updatedBy}
            />
            <MarkdownNote
              label={
                <FormattedMessage
                  id="scenes.privateNetwork.applicationCard.kam.pitchHeadline"
                  defaultMessage="Request to Join"
                />
              }
              note={application?.userPitch.pitch}
              timestamp={application?.createdAt}
              editable
              onEdit={onUpdateUserPitch}
              updaterName={
                application?.userPitch?.pitch
                  ? intl.formatMessage({
                      id: 'scenes.privateNetwork.applicationCard.kam.pitch.editedChip',
                      defaultMessage: 'Edited',
                    })
                  : undefined
              }
            />
            <MarkdownNote
              label={
                <FormattedMessage
                  id="scenes.privateNetwork.applicationCard.kam.kamNoteHeadline"
                  defaultMessage="KAM Note for Client"
                />
              }
              note={application?.kamNote?.note}
              timestamp={application?.kamNote?.updatedAt}
              updaterName="eqolot"
              editable
              onEdit={onUpdateKamNote}
            />
          </Box>
        </ChannelCardBody>
        <ChannelCardBody className={classes.body} key="insights">
          <ChannelCardPlatformLink channel={displayChannel} />
          <ChannelCardCategories channel={displayChannel} />
          <Feature feature="influencerFinderTags">
            <ChannelCardClientTags
              channel={displayChannel}
              onSubmit={onUpdateChannelTags}
              loading={loading}
            />
          </Feature>
          <ChannelCardPerformanceKPISection channel={displayChannel} />
          <ChannelCardAudienceKPISection channel={displayChannel} />
        </ChannelCardBody>
        <ChannelCardBody py={1}>
          <Typography variant="overline" color="textSecondary">
            {intl.formatMessage({
              id: 'components.ChannelCard.tabs.campaigns.lastUpdatedLabel',
              defaultMessage: 'Last Updated',
            })}
            &nbsp;
            {formatDateTime({
              isoDate: displayChannel.updatedAt,
              formatter: intl.formatDate,
            })}
          </Typography>
          <ChannelCardCampaignsList
            applications={displayChannel.applications}
          />
        </ChannelCardBody>
        <ChannelCardBody>
          <NewChannelNoteDialog
            open={newNoteDialogOpen}
            onClose={() => setNewNoteDialogOpen(false)}
            onSave={submitNewNote}
          />
          <Box display="flex" justifyContent="space-between">
            <Typography
              className={classes.internalNotesTitle}
              variant="overline"
              color="textSecondary"
            >
              {intl.formatMessage(
                {
                  id: 'components.ChannelCard.tabs.notes.title',
                  defaultMessage: '{clientName} Internal Notes',
                },
                {
                  clientName: client.name,
                },
              )}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setNewNoteDialogOpen(true)}
            >
              +
            </Button>
          </Box>
          <ChannelCardNotesList notes={displayChannel.notes} />
        </ChannelCardBody>
      </Tabs>
    </Box>
  );
};

PrivateNetworkApplicationKamCard.propTypes = {
  selectedChannel: ChannelPropType,
  channels: PropTypes.arrayOf(ChannelPropType).isRequired,
  onSelectChannel: PropTypes.func.isRequired,
  onUpdateChannelTags: PropTypes.func.isRequired,
  updateChannelNotes: PropTypes.func.isRequired,
  onUpdateKamReviewStatus: PropTypes.func.isRequired,
  onUpdateHiddenFromClient: PropTypes.func.isRequired,
  onUpdateKamNote: PropTypes.func.isRequired,
  onUpdateUserPitch: PropTypes.func.isRequired,
  application: PrivateNetworkApplicationPropType.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

PrivateNetworkApplicationKamCard.defaultProps = {
  selectedChannel: null,
  className: '',
};

export default PrivateNetworkApplicationKamCard;

import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

const INFLUENCER_ASSET_FIELDS = gql`
  fragment influencerAssetFields on InfluencerAsset {
    id
    contentType
    mediaType
    campaignTitle
    influencerName
    influencerProfileUrl
    copyright
    tags
    file
    buyOutPrice
    lastPublishedAt
    createdAt
    updatedAt
    publications {
      id
      influencerAssetId
      url
      medium
      comment
      publishedAt
    }
  }
`;

const MANY = gql`
  query InfluencerAssets($sort: InfluencerAssetsSort, $limit: Int) {
    influencerAssets(sort: $sort, limit: $limit) {
      ...influencerAssetFields
    }
  }
  ${INFLUENCER_ASSET_FIELDS}
`;

const ONE = gql`
  query InfluencerAsset($id: ID!) {
    influencerAsset(id: $id) {
      ...influencerAssetFields
    }
  }
  ${INFLUENCER_ASSET_FIELDS}
`;

const UPDATE_INFLUENCER_ASSET = gql`
  mutation UpdateInfluencerAsset(
    $id: ID!
    $payload: InfluencerAssetUpdateInput!
  ) {
    updateInfluencerAsset(id: $id, input: $payload) {
      ...influencerAssetFields
    }
  }
  ${INFLUENCER_ASSET_FIELDS}
`;

const CREATE_INFLUENCER_ASSET_PUBLICATION = gql`
  mutation CreateInfluencerAssetPublication(
    $payload: InfluencerAssetPublicationInput!
  ) {
    createInfluencerAssetPublication(input: $payload) {
      ...influencerAssetFields
    }
  }
  ${INFLUENCER_ASSET_FIELDS}
`;

const DELETE_INFLUENCER_ASSET_PUBLICATION = gql`
  mutation DeleteInfluencerAssetPublication($id: ID!) {
    deleteInfluencerAssetPublication(id: $id) {
      ...influencerAssetFields
    }
  }
  ${INFLUENCER_ASSET_FIELDS}
`;

export const useMany = (opts) => useQuery(MANY, opts);

export const useOne = ({ id }) =>
  useQuery(ONE, {
    variables: { id },
  });

export const useUpdateInfluencerAsset = () => {
  const [updateInfluencerAsset, { loading, error, data }] = useMutation(
    UPDATE_INFLUENCER_ASSET,
  );

  if (error) {
    throw new Error(`Failed to mutate tags: ${JSON.stringify(error)}`);
  }

  return {
    updateInfluencerAsset: React.useCallback(
      ({ id, payload }) =>
        updateInfluencerAsset({ variables: { id, payload } }),
      [updateInfluencerAsset],
    ),
    loading,
    data: data ? data.updateInfluencerAsset : undefined,
  };
};

export const useCreateInfluencerAssetPublication = (sort) => {
  const [createInfluencerAssetPublication, { loading, error }] = useMutation(
    CREATE_INFLUENCER_ASSET_PUBLICATION,
    {
      refetchQueries: [
        {
          query: MANY,
          variables: {
            sort,
          },
        },
      ],
    },
  );

  if (error) {
    throw new Error(
      `Failed to create influencer Asset publication: ${JSON.stringify(error)}`,
    );
  }

  return {
    createInfluencerAssetPublication: React.useCallback(
      async (payload) => {
        const { data } = await createInfluencerAssetPublication({
          variables: { payload },
        });

        return data.createInfluencerAssetPublication;
      },
      [createInfluencerAssetPublication],
    ),
    loading,
  };
};

export const useDeleteInfluencerAssetPublication = (sort) => {
  const [deleteInfluencerAssetPublication, { loading, error }] = useMutation(
    DELETE_INFLUENCER_ASSET_PUBLICATION,
    {
      refetchQueries: [
        {
          query: MANY,
          variables: {
            sort,
          },
        },
      ],
    },
  );

  if (error) {
    throw new Error(
      `Failed to delete influencer asset publication: ${JSON.stringify(error)}`,
    );
  }

  return {
    deleteInfluencerAssetPublication: React.useCallback(
      async (id) => {
        await deleteInfluencerAssetPublication({ variables: { id } });
      },
      [deleteInfluencerAssetPublication],
    ),
    loading,
  };
};

import React from 'react';
import { useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { ContentGrid, ContentGridItem } from 'layout/ContentGrid';
import LoadingState from 'components/LoadingState';
import ErrorPage404 from 'scenes/error/404';
import { useWhatsNew } from 'utils/whatsNew';

import InsiderArticleCard from '../InsiderArticleCard';
import InsiderArticleCardDetail from '../InsiderArticleCardDetail';
import InsiderPage from '../InsiderPage';

const QUERY_MANY = gql`
  query Updates {
    insiderUpdates {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      publishedAt
      publishedBy
      releasedAt
      title
      body
      slug
      coverImage
      categories
    }
  }
`;

const QUERY_ONE = gql`
  query Update($slug: String!) {
    insiderUpdate(slug: $slug) {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      publishedAt
      publishedBy
      releasedAt
      title
      body
      slug
      coverImage
      categories
    }
  }
`;

const useStyles = makeStyles({
  contentGrid: {
    overflow: 'visible',
  },
});

const WhatsNewPage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { slug } = useParams();
  const history = useHistory();
  const { hasUpdateBeenSeen, setUpdateHasBeenSeen } = useWhatsNew();

  const [selectedSlug, setSelectedSlug] = React.useState(slug);

  const { data, error, loading: loadingUpdates } = useQuery(QUERY_MANY);
  const {
    refetch: refetchSelectedUpdate,
    data: selectedUpdateData,
    loading: loadingSelectedUpdate,
  } = useQuery(QUERY_ONE, {
    variables: { slug: selectedSlug },
    skip: !selectedSlug,
  });

  const selectedUpdate = selectedUpdateData?.insiderUpdate;

  if (error) {
    throw new Error(error);
  }

  React.useEffect(() => {
    setSelectedSlug(slug);
  }, [slug]);

  React.useEffect(() => {
    if (selectedSlug) {
      refetchSelectedUpdate({ slug: selectedSlug });
    }
  }, [selectedSlug]);

  const handleClose = () => {
    setSelectedSlug(null);
    history.push('/insider/whats-new');
  };

  const handleOpen = () => {
    if (selectedUpdate) {
      setUpdateHasBeenSeen(selectedUpdate);
    }
  };

  if (selectedSlug && !loadingSelectedUpdate && !selectedUpdate) {
    return <ErrorPage404 />;
  }

  const title = intl.formatMessage({
    id: 'scenes.insider.whatsNew.title',
    defaultMessage: "What's new",
  });
  const pageTitle = intl.formatMessage({
    id: 'scenes.insider.whatsNew.pageTitle',
    defaultMessage: "What's new",
  });

  return (
    <InsiderPage title={title} pageTitle={pageTitle} showHeaderImage={false}>
      <InsiderArticleCardDetail
        loading={loadingSelectedUpdate}
        open={Boolean(selectedSlug || selectedUpdate)}
        article={selectedUpdate}
        onClose={handleClose}
        onOpen={handleOpen}
      />
      {loadingUpdates && <LoadingState height="40vh" />}
      {!loadingUpdates && (
        <ContentGrid
          className={classes.contentGrid}
          columnWidths={[768, 1024, 1280]}
        >
          {data?.insiderUpdates.map((update) => (
            <ContentGridItem key={update.id}>
              <InsiderArticleCard
                article={update}
                isNew={!hasUpdateBeenSeen(update)}
                slugRoot="/insider/whats-new"
              />
            </ContentGridItem>
          ))}
        </ContentGrid>
      )}
    </InsiderPage>
  );
};

export default WhatsNewPage;

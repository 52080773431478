import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  content: {
    width: 288,
  },
});

const NewChannelNoteDialog = ({ onSave, onClose, ...otherProps }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [note, setNote] = React.useState('');

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleClose = () => {
    setNote('');
    onClose();
  };

  const handleSave = () => {
    onSave(note);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...otherProps}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'components.NewChannelNoteDialog.title',
          defaultMessage: 'Add Note',
        })}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          variant="outlined"
          label={intl.formatMessage({
            id: 'components.NewChannelNoteDialog.input.label',
            defaultMessage: 'Note',
          })}
          onChange={handleNoteChange}
          value={note}
          fullWidth
          multiline
          minRows={2}
          maxRows={25}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage
            id="components.NewChannelNoteDialog.button.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!note}>
          <FormattedMessage
            id="components.NewChannelNoteDialog.button.save"
            defaultMessage="Save"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewChannelNoteDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

NewChannelNoteDialog.defaultProps = {};

export default NewChannelNoteDialog;

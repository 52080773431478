import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@material-ui/core/Box';

import Page from 'layout/Page';

import { ROLES } from 'utils/roles';
import InspectorDrawerContent from 'components/inspector/InspectorDrawerContent';
import TableBody from 'components/table/TableBody';
import { loadingString } from 'components/LoadingState';

import usePrivateNetworkState, {
  supportedFilterCountries,
} from './clientState';

import useTableState from '../data/useTableState';
import useTableMetaLabel from '../data/useTableMetaLabel';
import useInspectorState from '../data/useInspectorState';

import PrivateNetworkTable from '../components/PrivateNetworkTable';
import PrivateNetworkClientTableHead from './PrivateNetworkClientTableHead';
import PrivateNetworkApplicationTableRow from '../components/PrivateNetworkApplicationTableRow';
import PrivateNetworkClientFilters from './PrivateNetworkClientFilters';
import PrivateNetworkApplicationClientInspector, {
  drawerWidth as inspectorDrawerWidth,
} from './PrivateNetworkApplicationClientInspector';

const PrivateNetworkClientPage = ({ client, ...otherProps }) => {
  const intl = useIntl();

  const { state, actions } = usePrivateNetworkState();
  const { privateNetwork, loading, filters } = state;
  const { filterBy } = actions;

  const metaLabel = useTableMetaLabel(privateNetwork?.applications);
  const tableState = useTableState(privateNetwork);

  const inspectorState = useInspectorState(tableState.selectedApplication);

  const handleClickChannelRow = (applicationId, channelId) => {
    tableState.selectApplication(applicationId);
    inspectorState.selectChannel(channelId);
  };

  const handleClickApplicationRow = (applicationId) => {
    tableState.selectApplicationRow(applicationId);
    inspectorState.selectChannel(null);
  };

  const pageTitle = intl.formatMessage({
    id: 'scenes.privateNetwork.pageTitle',
    defaultMessage: 'Private Network',
  });

  return (
    <Page title={pageTitle} {...otherProps}>
      <Box display="flex" height="100%">
        <InspectorDrawerContent
          drawerOpen={!!tableState.selectedApplication}
          drawerWidth={inspectorDrawerWidth}
        >
          <PrivateNetworkClientFilters
            filters={filters}
            onFilter={filterBy}
            isInspected={!!tableState.selectedApplication}
            supportedCountries={supportedFilterCountries}
          />
          <PrivateNetworkTable
            metaLabel={loading ? intl.formatMessage(loadingString) : metaLabel}
          >
            <PrivateNetworkClientTableHead
              clientName={client.name}
              currentSort={tableState.currentSort}
              onSort={tableState.sortBy}
            />
            <TableBody loading={loading} />
            {tableState.displayApplications.map((application) => (
              <PrivateNetworkApplicationTableRow
                key={application.id}
                role={ROLES.CLIENT}
                application={application}
                onClick={() => {
                  handleClickApplicationRow(application.id);
                }}
                onExpand={() => tableState.expandApplicationRow(application.id)}
                onClickChannel={(channelId) =>
                  handleClickChannelRow(application.id, channelId)
                }
                expanded={tableState.isExpanded(application.id)}
                selected={tableState.isSelected(application.id)}
                selectedChannel={inspectorState.selectedChannel}
              />
            ))}
          </PrivateNetworkTable>
        </InspectorDrawerContent>
        <PrivateNetworkApplicationClientInspector
          selectedChannel={inspectorState.selectedChannel}
          channels={inspectorState.allChannels}
          onSelectChannel={inspectorState.selectChannel}
          application={tableState.selectedApplication}
          onClose={tableState.unselectApplication}
          loading={loading}
        />
      </Box>
    </Page>
  );
};

PrivateNetworkClientPage.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PrivateNetworkClientPage;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';
import { SortObjectPropType } from 'utils/sorting';

const PinterestApplicationTableHead = ({
  currentSort,
  onSort,
  influencerSelectionType,
  hideKamNotesColumn,
  ...otherProps
}) => (
  <TableHead {...otherProps}>
    <TableRow>
      <TableCellHead
        align="center"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="clientApplicationReview.status"
      >
        <FormattedMessage
          id="components.ApplicationsTable.clientReviewStatus"
          defaultMessage="Accept/Reject"
        />
      </TableCellHead>
      <TableCell align="left" />
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.pinterest.username.tooltip"
            defaultMessage="Pinterest @channelname handle"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.pinterest.username"
          defaultMessage="@channelname"
        />
      </TableCellHead>
      {!hideKamNotesColumn && (
        <TableCellHead align="left" noWrap>
          <FormattedMessage
            id="components.ChannelsTable.kamNote"
            defaultMessage="AM's Note"
          />
        </TableCellHead>
      )}
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.gender.tooltip"
            defaultMessage="As provided by the Influencer on the eqolot platform"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.gender"
          defaultMessage="Gender"
        />
      </TableCellHead>
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.location.tooltip"
            defaultMessage="As provided by the Influencer on the eqolot platform"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.location"
          defaultMessage="Location"
        />
      </TableCellHead>
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="channel.keyMetrics.followers"
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.pinterest.followers.tooltip"
            defaultMessage="Automatically tracked by the EQOLOT platform for registered Influencers"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.pinterest.followers"
          defaultMessage="Followers"
        />
      </TableCellHead>
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="reach"
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.pinterest.impressions.tooltip"
            defaultMessage="Median Impressions for last 10 idea pins"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.pinterest.impressions"
          defaultMessage="Impressions"
        />
      </TableCellHead>
      {influencerSelectionType === 'budget' && (
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="advertiserPrice"
        >
          <FormattedMessage
            id="components.ChannelsTable.price"
            defaultMessage="Price"
          />
        </TableCellHead>
      )}
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.categories.tooltip"
            defaultMessage="As defined by the Influencer on the eqolot platform"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.categories"
          defaultMessage="Categories"
        />
      </TableCellHead>
    </TableRow>
  </TableHead>
);

PinterestApplicationTableHead.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  influencerSelectionType: PropTypes.oneOf(['budget', 'reach']).isRequired,
  hideKamNotesColumn: PropTypes.bool,
};

PinterestApplicationTableHead.defaultProps = {
  hideKamNotesColumn: false,
};

export default PinterestApplicationTableHead;

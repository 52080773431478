import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ApplicationsTable from 'components/applications/table/submitted/ApplicationsTable';
import CampaignInfluencerSelectionButton from 'components/campaigns/CampaignInfluencerSelectionButton';
import CampaignTargetInfluencerProfile from 'components/campaigns/CampaignTargetInfluencerProfile';
import CampaignInfluencerSelectionConfirmed from 'components/campaigns/CampaignInfluencerSelectionConfirmed';

import {
  useQueryParams,
  StringParam,
  SortParam,
  withDefault,
} from 'utils/routing';
import {
  views as campaignViews,
  CampaignInfluencerSelectionLabel,
} from 'utils/campaign';
import { sumWithRounding } from 'utils/formatting';

import {
  useUpdateApplication,
  useOne as useCampaign,
  useConfirmInfluencerSelection,
  useUpdateChannelNotes,
  useUpdateChannelTags,
} from '../../data/campaignHooks';
import CampaignDetailsPageContainer from '../CampaignDetailsPageContainer';
import ApplicationInspector, {
  drawerWidth as inspectorWidth,
} from './ApplicationInspector';

const campaignFallback = { applications: [] };

const CampaignApplicationsPage = ({ campaignId }) => {
  const intl = useIntl();
  const [query, setQuery] = useQueryParams({
    inspect: StringParam,
    sort: withDefault(SortParam, { by: 'reach', direction: 'asc' }),
  });

  const { data, loading, error } = useCampaign({ id: campaignId });
  const { updateApplication } = useUpdateApplication();
  const { confirmCampaignInfluencerSelection } =
    useConfirmInfluencerSelection();
  const { updateChannelTags, loading: tagsUpdateLoading } =
    useUpdateChannelTags();
  const { updateChannelNotes, loading: notesUpdateLoading } =
    useUpdateChannelNotes();

  if (error) {
    throw new Error(`Error fetching campaign: ${error}`);
  }

  const campaign = data?.campaign || campaignFallback;

  const [selectedApplicationId, setSelectedApplicationId] = React.useState(
    query.inspect,
  );
  const [displayApplications, setDisplayApplications] = React.useState(
    campaign.applications,
  );

  const [currentSort, setCurrentSort] = React.useState(() => query.sort);

  const selectedApplication = React.useMemo(() => {
    if (!selectedApplicationId) {
      return null;
    }

    return find(campaign.applications, { id: selectedApplicationId });
  }, [selectedApplicationId, campaign.applications]);

  const inspectApplication = (id) => {
    setSelectedApplicationId(id);
    setQuery({ inspect: id });
  };

  const uninspectApplication = () => {
    setSelectedApplicationId(null);
    setQuery({ inspect: undefined });
  };

  // Sync query param with state (when back button is used, for example)
  React.useEffect(() => {
    if (query.inspect !== selectedApplicationId) {
      setSelectedApplicationId(query.inspect);
    }
  }, [query.inspect, selectedApplicationId]);

  React.useEffect(() => {
    setDisplayApplications(campaign.applications);
  }, [campaign.applications, loading]);

  const prevSort = React.useRef(null);

  React.useEffect(() => {
    if (
      isEqual(prevSort.current, currentSort) ||
      isEmpty(campaign.applications)
    ) {
      return;
    }

    prevSort.current = currentSort;

    if (currentSort.by === 'clientApplicationReview.status') {
      const filterByClientReviewStatus = (status) => {
        const filtered = campaign.applications.filter(
          (app) => app.clientApplicationReview.status === status,
        );

        const notesFirst = (a, b) =>
          !!a.clientApplicationReview.note && !b.clientApplicationReview.note
            ? -1
            : 0;

        return filtered.sort(notesFirst);
      };

      const sortedApplications = [
        ...filterByClientReviewStatus('accepted'),
        ...filterByClientReviewStatus('undecided'),
        ...filterByClientReviewStatus('pending'),
        ...filterByClientReviewStatus('rejected'),
      ];

      if (currentSort.direction === 'desc') {
        sortedApplications.reverse();
      }

      setDisplayApplications(sortedApplications);
    } else {
      const getSortValue = (application) => {
        const value = get(application, currentSort.by);
        if (currentSort.direction === 'desc') {
          return -value;
        }

        return value;
      };

      setDisplayApplications(sortBy(campaign.applications, getSortValue));
    }

    setQuery({ sort: currentSort });
  }, [campaign.applications, currentSort]);

  const acceptedApplications = React.useMemo(
    () =>
      campaign?.applications?.filter(
        (application) =>
          application.clientApplicationReview.status === 'accepted',
      ) || [],
    [campaign?.applications],
  );

  const influencerSelectionSummary = React.useMemo(() => {
    const campaignBudget =
      campaign?.clientInfluencerSelection?.campaignBudget ||
      campaign?.budget ||
      0;
    const confirmedBudget =
      campaign?.clientInfluencerSelection?.confirmedBudget ||
      sumBy(acceptedApplications, 'advertiserPrice') ||
      0;

    const campaignReach =
      campaign?.clientInfluencerSelection?.campaignReach ||
      campaign?.reach ||
      0;
    const confirmedReach =
      campaign?.clientInfluencerSelection?.confirmedReach ||
      sumWithRounding(acceptedApplications, 'reach') ||
      0;

    const defaultSelectionType = ['instagram', 'tiktok', 'pinterest'].includes(
      campaign?.platform,
    )
      ? 'reach'
      : 'budget';
    const selectionType =
      campaign?.clientInfluencerSelection?.selectionType ||
      defaultSelectionType;

    return {
      status: campaign?.clientInfluencerSelection?.status || 'pending',
      selectionType,
      acceptedApplicationsCount: acceptedApplications.length,
      campaignBudget,
      confirmedBudget,
      campaignReach,
      confirmedReach,
    };
  }, [campaign, acceptedApplications]);

  const tableHeaderContent = React.useMemo(() => {
    if (!campaign.platform) {
      return null;
    }

    if (campaign?.clientInfluencerSelection?.status === 'confirmed') {
      const { updatedAt, updatedBy } = campaign.clientInfluencerSelection;

      return (
        <CampaignInfluencerSelectionConfirmed
          confirmedAt={updatedAt}
          confirmedBy={updatedBy}
        />
      );
    }

    const hasBeenReviewed = (application) =>
      application.clientApplicationReview.status === 'accepted' ||
      application.clientApplicationReview.status === 'rejected';

    const confirmationProhibited =
      isEmpty(campaign.applications) ||
      !campaign.applications.every(hasBeenReviewed);

    return (
      <CampaignInfluencerSelectionButton
        disabled={confirmationProhibited}
        platform={campaign.platform}
        selectionSummary={influencerSelectionSummary}
        onConfirm={() => confirmCampaignInfluencerSelection({ id: campaignId })}
      />
    );
  }, [campaign, influencerSelectionSummary]);

  const tableHeaderLabel = React.useMemo(() => {
    if (!campaign.platform) {
      return null;
    }
    return (
      <CampaignInfluencerSelectionLabel
        platform={campaign.platform}
        selectionSummary={influencerSelectionSummary}
      />
    );
  }, [campaign, influencerSelectionSummary]);

  const handleApplicationUpdate = React.useCallback(
    (newClientReviewStatus) => {
      if (selectedApplicationId) {
        updateApplication({
          id: selectedApplicationId,
          payload: { clientApplicationReview: newClientReviewStatus },
        });
      }
    },
    [updateApplication, selectedApplicationId],
  );

  const noApplicationsHaveKamNotes = React.useMemo(() => {
    const allApplications = campaign?.applications || [];
    return allApplications.every((application) => !application.kamNotes?.note);
  }, [campaign.applications]);

  const handleUpdateChannelNotes = (notes) => {
    updateChannelNotes({
      id: selectedApplication?.channel?.id,
      payload: notes,
    });
  };

  const handleUpdateChannelTags = (data) => {
    updateChannelTags({
      id: selectedApplication?.channel?.id,
      payload: data.tags,
    });
  };

  return (
    <CampaignDetailsPageContainer
      drawerOpen={!!selectedApplicationId}
      drawerWidth={inspectorWidth}
      view={campaignViews.APPLICATIONS}
      renderDrawer={() => (
        <ApplicationInspector
          application={selectedApplication}
          campaign={campaign}
          onClose={uninspectApplication}
          onUpdateClientReview={handleApplicationUpdate}
          onUpdateChannelNotes={handleUpdateChannelNotes}
          onUpdateChannelTags={handleUpdateChannelTags}
          disableUpdateClientReview={
            campaign?.clientInfluencerSelection?.status === 'confirmed'
          }
          loading={tagsUpdateLoading || notesUpdateLoading || loading}
        />
      )}
      campaign={campaign}
      loading={loading}
    >
      {campaign.id && (
        <CampaignTargetInfluencerProfile campaign={campaign} mb={3} />
      )}
      <ApplicationsTable
        platform={campaign.platform}
        loading={loading}
        selectionSummary={influencerSelectionSummary}
        applications={displayApplications}
        currentSort={currentSort}
        onSort={setCurrentSort}
        selectedApplication={selectedApplication}
        onSelectApplication={inspectApplication}
        clientReviewConfirmed={
          campaign?.clientInfluencerSelection?.status === 'confirmed'
        }
        headerLabel={tableHeaderLabel}
        headerContent={tableHeaderContent}
        hideKamNotesColumn={noApplicationsHaveKamNotes}
      />
      <Box display="flex" justifyContent="center" mt={3}>
        <Typography variant="body1" align="center">
          {intl.formatMessage(
            {
              id: 'scenes.campaigns.details.preparationView.message',
              defaultMessage:
                'The campaign brief has been published and Influencers can apply to participate.{br}{br}Matching Influencers will appear here, so you can review their profile and pitch.',
            },
            { br: <br /> },
          )}
        </Typography>
      </Box>
    </CampaignDetailsPageContainer>
  );
};

CampaignApplicationsPage.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
CampaignApplicationsPage.defaultProps = {};

export default CampaignApplicationsPage;

import * as React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

const CLIENT_USERS_FIELD = gql`
  fragment clientUsersField on Client {
    users {
      id
      name
      email
      kamNote {
        note
        updatedBy
        updatedAt
      }
      salesforceId
      blocked
      createdAt
      updatedAt
      lastLoginAt
    }
  }
`;

const CLIENT_FIELDS = gql`
  fragment clientFields on Client {
    id
    name
    companyName
    theme {
      logoUrl
      brandLogoUrl
      sidebarColor
    }
    kamNote {
      note
      updatedBy
      updatedAt
    }
    manager {
      id
      avatarUrl
      name
      email
      phone
    }
    salesforceId
    features
    tags {
      influencerAssets
    }
    role
    createdAt
    updatedAt
  }
`;

const CLIENT_MANAGERS = gql`
  query ClientManagers {
    managers {
      id
      name
      email
      phone
      avatarUrl
    }
  }
`;

const MANY = gql`
  query Clients {
    clients {
      ...clientFields
    }
  }
  ${CLIENT_FIELDS}
`;

const ONE = gql`
  query Client($id: ID!) {
    client(id: $id) {
      ...clientFields
      ...clientUsersField
    }
  }
  ${CLIENT_FIELDS}
  ${CLIENT_USERS_FIELD}
`;

const UPDATE_CLIENT = gql`
  mutation UpdateClient($id: ID!, $payload: UpdateClientInput) {
    updateClient(id: $id, input: $payload) {
      ...clientFields
      ...clientUsersField
    }
  }
  ${CLIENT_FIELDS}
  ${CLIENT_USERS_FIELD}
`;

const CREATE_CLIENT = gql`
  mutation CreateClient($payload: CreateClientInput) {
    createClient(input: $payload) {
      ...clientFields
      ...clientUsersField
    }
  }
  ${CLIENT_FIELDS}
  ${CLIENT_USERS_FIELD}
`;

const UPDATE_CLIENT_USER = gql`
  mutation UpdateClientUser(
    $userId: String!
    $clientId: ID!
    $payload: UpdateClientUserInput
  ) {
    updateClientUser(userId: $userId, clientId: $clientId, input: $payload) {
      ...clientFields
      ...clientUsersField
    }
  }
  ${CLIENT_FIELDS}
  ${CLIENT_USERS_FIELD}
`;

const ADD_CLIENT_USER = gql`
  mutation AddClientUser($clientId: ID!, $payload: AddClientUserInput) {
    addClientUser(clientId: $clientId, input: $payload) {
      ...clientFields
      ...clientUsersField
    }
  }
  ${CLIENT_FIELDS}
  ${CLIENT_USERS_FIELD}
`;

const RESET_CLIENT_USER_PASSWORD = gql`
  mutation ResetClientUserPassword($userId: String!, $clientId: ID!) {
    resetClientUserPassword(userId: $userId, clientId: $clientId)
  }
`;

export const useOne = ({ id }) =>
  useQuery(ONE, {
    variables: { id },
    skip: id === 'new',
  });
export const useMany = () => useQuery(MANY);
export const useManagers = () => useQuery(CLIENT_MANAGERS);

export const useUpdateClient = () => {
  const [updateClient, { loading, error }] = useMutation(UPDATE_CLIENT);

  if (error) {
    throw new Error(`Failed to update client: ${JSON.stringify(error)}`);
  }

  return {
    updateClient: React.useCallback(
      ({ id, payload }) => updateClient({ variables: { id, payload } }),
      [updateClient],
    ),
    loading,
  };
};

export const useCreateClient = () => {
  const [createClient, { loading, error }] = useMutation(CREATE_CLIENT);

  if (error) {
    throw new Error(`Failed to create client: ${JSON.stringify(error)}`);
  }

  return {
    createClient: React.useCallback(
      ({ payload }) => createClient({ variables: { payload } }),
      [createClient],
    ),
    loading,
  };
};

export const useUpdateClientUser = () => {
  const [updateClientUser, { loading, error }] = useMutation(
    UPDATE_CLIENT_USER,
    {
      refetchQueries: ['Client'],
    },
  );

  if (error) {
    throw new Error(`Failed to update client user: ${JSON.stringify(error)}`);
  }

  return {
    updateClientUser: React.useCallback(
      ({ clientId, userId, payload }) =>
        updateClientUser({ variables: { clientId, userId, payload } }),
      [updateClientUser],
    ),
    loading,
  };
};

export const useAddClientUser = () => {
  const [addClientUser, { loading, error }] = useMutation(ADD_CLIENT_USER, {
    refetchQueries: ['Client'],
  });

  if (error) {
    throw new Error(`Failed to add client user: ${JSON.stringify(error)}`);
  }

  return {
    addClientUser: React.useCallback(
      ({ clientId, payload }) =>
        addClientUser({ variables: { clientId, payload } }),
      [addClientUser],
    ),
    loading,
  };
};

export const useResetClientUserPassword = () => {
  const [resetClientUserPassword, { loading, error }] = useMutation(
    RESET_CLIENT_USER_PASSWORD,
  );

  if (error) {
    throw new Error(
      `Failed to reset client user password: ${JSON.stringify(error)}`,
    );
  }

  return {
    resetClientUserPassword: React.useCallback(
      ({ clientId, userId }) =>
        resetClientUserPassword({ variables: { clientId, userId } }),
      [resetClientUserPassword],
    ),
    loading,
    error,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import Page from 'layout/Page';
import LoadingState from 'components/LoadingState';
import InfluencerAssetCard from 'components/influencerAssets/InfluencerAssetCard';
import MediaViewerModal from 'components/MediaViewerModal';
import ErrorPage404 from 'scenes/error/404';

import { useTags } from './data/tags';
import { useOne as useInfluencerAsset } from './data/influencerAssetHooks';

const InfluencerAssetCardPage = ({ match }) => {
  const intl = useIntl();
  const history = useHistory();
  const { getTagLabelByValue } = useTags();

  const pageTitle = intl.formatMessage({
    id: 'scenes.influencerAssets.pageTitle',
    defaultMessage: 'Influencer Assets',
  });

  const { loading, data = {} } = useInfluencerAsset({ id: match.params.id });

  const { influencerAsset } = data;

  if (!influencerAsset && !loading) {
    return <ErrorPage404 />;
  }

  const goToInspectView = () => {
    history.push(`/influencer-assets?inspect=${influencerAsset.id}`);
  };

  const goToInfluencerAssets = () => {
    history.push('/influencer-assets');
  };

  return (
    <Page title={pageTitle}>
      {loading && (
        <MediaViewerModal open onClose={goToInfluencerAssets}>
          <Box
            component={Paper}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LoadingState />
          </Box>
        </MediaViewerModal>
      )}
      {!loading && (
        <InfluencerAssetCard
          id={influencerAsset.id}
          mediaType={influencerAsset.mediaType}
          mediaSrc={influencerAsset.file}
          tags={influencerAsset.tags}
          getTagLabel={getTagLabelByValue}
          createdAt={influencerAsset.createdAt}
          buyOutPrice={influencerAsset.buyOutPrice}
          onInspect={goToInspectView}
          onClose={goToInfluencerAssets}
          fullScreen
        />
      )}
    </Page>
  );
};

InfluencerAssetCardPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default InfluencerAssetCardPage;

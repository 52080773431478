import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';
import { SortObjectPropType } from 'utils/sorting';
import { PlatformPropType } from 'utils/channel';
import { TaskPropType } from 'utils/task';

const InstagramChannelColumn = (props) => (
  <TableCellHead
    {...props}
    tooltip={
      <FormattedMessage
        id="components.ChannelsTable.instagram.username.tooltip"
        defaultMessage="Instagram @channelname handle"
      />
    }
  >
    <FormattedMessage
      id="components.ChannelsTable.instagram.username"
      defaultMessage="@channelname"
    />
  </TableCellHead>
);
const PinterestChannelColumn = (props) => (
  <TableCellHead
    {...props}
    tooltip={
      <FormattedMessage
        id="components.ChannelsTable.pinterest.username.tooltip"
        defaultMessage="Pinterest @channelname handle"
      />
    }
  >
    <FormattedMessage
      id="components.ChannelsTable.pinterest.username"
      defaultMessage="@channelname"
    />
  </TableCellHead>
);

const TiktokChannelColumn = (props) => (
  <TableCellHead
    {...props}
    tooltip={
      <FormattedMessage
        id="components.ChannelsTable.tiktok.username.tooltip"
        defaultMessage="TikTok @channelname handle"
      />
    }
  >
    <FormattedMessage
      id="components.ChannelsTable.tiktok.username"
      defaultMessage="@channelname"
    />
  </TableCellHead>
);

const BlogChannelColumn = (props) => (
  <TableCellHead {...props}>
    <FormattedMessage
      id="components.ChannelsTable.blog.website"
      defaultMessage="Website"
    />
  </TableCellHead>
);

const channelColumnByPlatform = {
  instagram: InstagramChannelColumn,
  pinterest: PinterestChannelColumn,
  tiktok: TiktokChannelColumn,
  website: BlogChannelColumn,
  ga: BlogChannelColumn,
};

const ApplicationTableHead = React.memo(
  ({
    currentSort,
    onSort,
    productShipmentEnabled,
    platform,
    hideKamNotesColumn,
    tasks,
    ...otherProps
  }) => {
    const ChannelColumn = channelColumnByPlatform[platform];

    const addressCell = React.useMemo(() => {
      if (productShipmentEnabled) {
        return (
          <TableCellHead>
            <FormattedMessage
              id="components.ApplicationsTable.deliveryAddress"
              defaultMessage="Delivery address"
            />
          </TableCellHead>
        );
      }

      return null;
    }, [productShipmentEnabled]);

    return (
      <TableHead {...otherProps}>
        <TableRow>
          <TableCell align="left" />
          <ChannelColumn />
          <TableCellHead>
            <FormattedMessage
              id="components.ApplicationsTable.missionStatus"
              defaultMessage="Status"
            />
          </TableCellHead>
          <TableCellHead
            align="right"
            onSort={onSort}
            currentSort={currentSort}
            sortProperty="mission.publishDate"
          >
            <FormattedMessage
              id="components.ApplicationsTable.publishDate"
              defaultMessage="Expected publishing date"
            />
          </TableCellHead>
          {!hideKamNotesColumn && (
            <TableCellHead noWrap align="left">
              <FormattedMessage
                id="components.ApplicationsTable.kamNote"
                defaultMessage="AM's Note"
              />
            </TableCellHead>
          )}
          <TableCellHead noWrap align="center">
            <FormattedMessage
              id="components.ApplicationsTable.clientNote"
              defaultMessage="Note for AM"
            />
          </TableCellHead>
          {productShipmentEnabled && (
            <TableCellHead
              onSort={onSort}
              currentSort={currentSort}
              sortProperty="productShipment.product"
            >
              <FormattedMessage
                id="components.ApplicationsTable.productChoice"
                defaultMessage="Product"
              />
            </TableCellHead>
          )}
          {addressCell}
          {tasks.map((task) => (
            <TableCellHead noWrap align="left" key={task.id}>
              {task.clientLabel}
            </TableCellHead>
          ))}
        </TableRow>
      </TableHead>
    );
  },
);

ApplicationTableHead.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  productShipmentEnabled: PropTypes.bool,
  hideKamNotesColumn: PropTypes.bool,
  platform: PlatformPropType.isRequired,
  tasks: PropTypes.arrayOf(TaskPropType.isRequired),
};

ApplicationTableHead.defaultProps = {
  productShipmentEnabled: false,
  hideKamNotesColumn: false,
  tasks: [],
};

export default ApplicationTableHead;

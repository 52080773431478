import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Feature } from 'utils/features';
import { InfluencerAssetPropType } from 'utils/influencerAssets';
import InfluencerAssetInspectorTags from './InfluencerAssetInspectorTags';
import InfluencerAssetInspectorHeadline from './InfluencerAssetInspectorHeadline';
import InfluencerAssetInspectorMedia from './InfluencerAssetInspectorMedia';
import InfluencerAssetInspectorAuthorData from './InfluencerAssetInspectorAuthorData';
import InfluencerAssetInspectorPublications from './InfluencerAssetInspectorPublications';
import CreatePublicationDialog from './CreatePublicationDialog';
import DeletePublicationDialog from './DeletePublicationDialog';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const InfluencerAssetInspectorContent = ({
  className,
  loading,
  influencerAsset,
  tagsOptions,
  getTagOptionLabel,
  actions,
  dialogsOpen,
}) => {
  const classes = useStyles();
  const [publicationToDelete, setPublicationToDelete] = React.useState(null);

  const handleDeleteClick = (publicationId) => {
    actions.openDialog('deletePublication');
    setPublicationToDelete(publicationId);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <InfluencerAssetInspectorHeadline />
      <InfluencerAssetInspectorMedia
        id={influencerAsset.id}
        src={influencerAsset.file}
        type={influencerAsset.mediaType}
      />
      <InfluencerAssetInspectorTags
        influencerAsset={influencerAsset}
        tagsOptions={tagsOptions}
        getTagOptionLabel={getTagOptionLabel}
        onSubmit={actions.updateInfluencerAsset}
        loading={loading}
      />
      <InfluencerAssetInspectorAuthorData
        influencerName={influencerAsset.influencerName}
        influencerProfileUrl={influencerAsset.influencerProfileUrl}
        createdAt={influencerAsset.createdAt}
        campaignTitle={influencerAsset.campaignTitle}
        buyOutPrice={influencerAsset.buyOutPrice}
        copyright={influencerAsset.copyright}
      />
      <Feature feature="publicationHistory">
        <InfluencerAssetInspectorPublications
          publications={influencerAsset.publications}
          onAddPublicationClick={() => actions.openDialog('addPublication')}
          onDeletePublicationClick={handleDeleteClick}
        />
        <CreatePublicationDialog
          open={dialogsOpen.addPublication}
          onCancel={() => actions.closeDialog('addPublication')}
          onSubmit={actions.addPublication}
        />
        <DeletePublicationDialog
          open={dialogsOpen.deletePublication}
          onCancel={() => actions.closeDialog('deletePublication')}
          onConfirm={() => actions.deletePublication(publicationToDelete)}
        />
      </Feature>
    </Box>
  );
};

InfluencerAssetInspectorContent.propTypes = {
  className: PropTypes.string,
  influencerAsset: InfluencerAssetPropType.isRequired,
  actions: PropTypes.shape({
    openDialog: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    addPublication: PropTypes.func.isRequired,
    deletePublication: PropTypes.func.isRequired,
    updateInfluencerAsset: PropTypes.func.isRequired,
  }).isRequired,
  dialogsOpen: PropTypes.shape({
    addPublication: PropTypes.bool,
    deletePublication: PropTypes.bool,
  }).isRequired,
  tagsOptions: PropTypes.arrayOf(PropTypes.string),
  getTagOptionLabel: PropTypes.func,
  loading: PropTypes.bool,
};

InfluencerAssetInspectorContent.defaultProps = {
  getTagOptionLabel: (tag) => tag,
  tagsOptions: [],
  loading: false,
  className: '',
};

export default InfluencerAssetInspectorContent;

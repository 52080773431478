import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import ChannelLink from 'components/ChannelLink';
import { ChannelPropType } from 'utils/channel';

const ChannelCardPlatformLink = ({ channel, ...otherProps }) => {
  const theme = useTheme();

  return (
    <Box mb={2} {...otherProps}>
      <ChannelLink channel={channel} color={theme.palette.primary.main} />
      <Box mt={1}>
        <Divider />
      </Box>
    </Box>
  );
};

ChannelCardPlatformLink.propTypes = {
  channel: ChannelPropType.isRequired,
};

export default ChannelCardPlatformLink;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';
import { SortObjectPropType } from 'utils/sorting';

const YoutubeTableHead = React.memo(
  ({ clientName, currentSort, onSort, ...otherProps }) => (
    <TableHead {...otherProps}>
      <TableRow>
        <TableCell align="left" />
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.youtube.username.tooltip"
              defaultMessage="Youtube channel handle"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.youtube.username"
            defaultMessage="@channelname"
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.gender.tooltip"
              defaultMessage="As provided by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.gender"
            defaultMessage="Gender"
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.location.tooltip"
              defaultMessage="As provided by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.location"
            defaultMessage="Location"
          />
        </TableCellHead>
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="reach"
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.youtube.subscribers.tooltip"
              defaultMessage="Automatically tracked by the eqolot platform for registered Influencers"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.youtube.subscribers"
            defaultMessage="Subscribers"
          />
        </TableCellHead>
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="insights.viewCount"
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.youtube.views.tooltip"
              defaultMessage="Number of views on this channel"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.youtube.views"
            defaultMessage="Views"
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.clientTags.tooltip"
              defaultMessage="The client's tags"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.clientTags"
            defaultMessage="{clientName} Tags"
            values={{
              clientName,
            }}
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.categories.tooltip"
              defaultMessage="As defined by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.categories"
            defaultMessage="Categories"
          />
        </TableCellHead>
      </TableRow>
    </TableHead>
  ),
);

YoutubeTableHead.propTypes = {
  clientName: PropTypes.string,
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
};

YoutubeTableHead.defaultProps = {
  clientName: '',
};

export default YoutubeTableHead;

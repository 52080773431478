import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { AlignPropType } from 'utils/table';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
    '& > *': {
      fontWeight: 'bold',
    },
  },
});

const TableCellText = ({
  children,
  noWrap,
  align,
  variant,
  bold,
  color,
  classes: classesProp,
  ...otherProps
}) => {
  const classes = useStyles(classesProp);

  return (
    <TableCell
      align={align}
      className={clsx(classes.root, {
        [classes.bold]: bold,
      })}
      {...otherProps}
    >
      <Typography
        noWrap={noWrap}
        variant={variant}
        color={color}
        component="div"
      >
        {children}
      </Typography>
    </TableCell>
  );
};

TableCellText.propTypes = {
  children: PropTypes.node,
  noWrap: PropTypes.bool,
  bold: PropTypes.bool,
  align: AlignPropType,
  color: PropTypes.string,
  variant: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    bold: PropTypes.string,
  }),
};

TableCellText.defaultProps = {
  noWrap: true,
  align: 'left',
  variant: 'body2',
  bold: false,
  color: undefined,
  classes: {},
};

export default TableCellText;

import React from 'react';
import isEqual from 'lodash/isEqual';

import { ChannelPropType } from 'utils/channel';

import Instagram from './instagram/TableRow';
import TikTok from './tiktok/TableRow';
import Pinterest from './pinterest/TableRow';
import Youtube from './youtube/TableRow';

const componentsByPlatform = {
  instagram: Instagram,
  tiktok: TikTok,
  pinterest: Pinterest,
  youtube: Youtube,
};

const channelPropsAreEqual = (prevProps, nextProps) => {
  const areSelectedEqual = prevProps.selected === nextProps.selected;
  const areChannelIdsEqual = prevProps.channel.id === nextProps.channel.id;
  const areTagsEqual = isEqual(prevProps.channel.tags, nextProps.channel.tags);

  return areSelectedEqual && areChannelIdsEqual && areTagsEqual;
};

const ChannelsTableRow = React.memo(({ channel, ...otherProps }) => {
  const Component = componentsByPlatform[channel.platform];

  return <Component channel={channel} {...otherProps} />;
}, channelPropsAreEqual);

ChannelsTableRow.propTypes = {
  channel: ChannelPropType.isRequired,
};

ChannelsTableRow.defaultProps = {};

export default ChannelsTableRow;

import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const SelectFilter = ({
  id,
  label,
  children,
  value,
  onChange,
  ...otherProps
}) => (
  <FormControl variant="outlined" {...otherProps}>
    <InputLabel id={`${id}-label`}>{label}</InputLabel>
    <Select
      id={id}
      labelId={`${id}-label`}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      label={label}
      margin="dense"
    >
      {children}
    </Select>
  </FormControl>
);

SelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
SelectFilter.defaultProps = {
  value: null,
};

export default SelectFilter;

import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import Page from 'layout/Page';
import ContactCard from 'components/ContactCard';
import { useClient } from 'utils/client';

import CampaignsGrid from './CampaignsGrid';
import CampaignsList from './CampaignsList';

const CampaignsListPage = (props) => {
  const intl = useIntl();
  const { client } = useClient();

  const pageTitle = intl.formatMessage({
    id: 'scenes.campaigns.pageTitle',
    defaultMessage: 'Campaigns',
  });

  const managerName = client?.manager?.name;

  const contactCardTitle =
    managerName === 'Marcus Hammer'
      ? intl.formatMessage({
          id: 'scenes.campaigns.managerInfo.male.title.',
          defaultMessage: 'Your expert for any campaign questions',
        })
      : intl.formatMessage({
          id: 'scenes.campaigns.managerInfo.female.title.',
          defaultMessage: 'Your expert for any campaign questions',
        });

  return (
    <Page
      title={pageTitle}
      actions={
        <Button
          component={Link}
          to="campaigns/new/briefing"
          color="primary"
          variant="contained"
        >
          {intl.formatMessage({
            id: 'scenes.campaigns.labels.newBriefing',
            defaultMessage: '+ New Briefing',
          })}
        </Button>
      }
      {...props}
    >
      <CampaignsGrid />
      <CampaignsList />
      <ContactCard
        title={contactCardTitle}
        width={490}
        mt={7}
        mx="auto"
        manager={client?.manager}
      />
    </Page>
  );
};

export default CampaignsListPage;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';
import { SortObjectPropType } from 'utils/sorting';
import { Feature } from 'utils/features';

const InstagramTableHead = React.memo(
  ({ clientName, currentSort, onSort, ...otherProps }) => (
    <TableHead {...otherProps}>
      <TableRow>
        <TableCell align="left" />
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.instagram.username.tooltip"
              defaultMessage="Instagram @channelname handle"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.instagram.username"
            defaultMessage="@channelname"
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.gender.tooltip"
              defaultMessage="As provided by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.gender"
            defaultMessage="Gender"
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.location.tooltip"
              defaultMessage="As provided by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.location"
            defaultMessage="Location"
          />
        </TableCellHead>
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="reach"
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.instagram.followers.tooltip"
              defaultMessage="Automatically tracked by the eqolot platform for registered Influencers"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.instagram.followers"
            defaultMessage="Followers"
          />
        </TableCellHead>
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="keyMetrics.engagementRate"
          noWrap
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.instagram.engagementRate.tooltip"
              defaultMessage="Engagement rate, as the ratio of engagements to the number of followers of the influencer"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.instagram.engagementRate"
            defaultMessage="Eng. Rate"
          />
        </TableCellHead>
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="keyMetrics.storyImpressionRatio"
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.instagram.storyImpressionRatio.tooltip"
              defaultMessage="Ratio of net impressions on stories compared with followers"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.instagram.storyImpressionRatio"
            defaultMessage="Story Impression Ratio"
          />
        </TableCellHead>
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="keyMetrics.postImpressionRatio"
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.instagram.postImpressionRatio.tooltip"
              defaultMessage="Ratio of net impressions on postings compared with followers"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.instagram.postImpressionRatio"
            defaultMessage="Post Impression Ratio"
          />
        </TableCellHead>
        <Feature feature="audienceGendersColumn">
          <TableCellHead
            align="right"
            onSort={onSort}
            currentSort={currentSort}
            sortProperty="keyMetrics.audience.gender.female"
            tooltip={
              <FormattedMessage
                id="components.ChannelsTable.instagram.audienceGenders.tooltip"
                defaultMessage="Percentage of Female viewers in the total audience"
              />
            }
          >
            <FormattedMessage
              id="components.ChannelsTable.instagram.audienceGenders"
              defaultMessage="Audience Female/Male"
            />
          </TableCellHead>
        </Feature>
        <TableCellHead
          noWrap
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.topCountry.tooltip"
              defaultMessage="Location of the Influencers Follower"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.topCountry"
            defaultMessage="Top Country"
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.clientTags.tooltip"
              defaultMessage="The client's tags"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.clientTags"
            defaultMessage="{clientName} Tags"
            values={{
              clientName,
            }}
          />
        </TableCellHead>
        <TableCellHead
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.categories.tooltip"
              defaultMessage="As defined by the Influencer on the eqolot platform"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.categories"
            defaultMessage="Categories"
          />
        </TableCellHead>
      </TableRow>
    </TableHead>
  ),
);

InstagramTableHead.propTypes = {
  clientName: PropTypes.string,
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
};

InstagramTableHead.defaultProps = {
  clientName: '',
};

export default InstagramTableHead;

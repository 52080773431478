import { defineMessages } from 'react-intl';
import memoize from 'lodash/memoize';

const defaultRange = { min: 4 };

const collaborationRatingsStrings = defineMessages({
  visualCreativity: {
    id: 'components.ChannelCard.rating.visualCreativity',
    defaultMessage: 'Visual Creativity',
  },
  copyWriting: {
    id: 'components.ChannelCard.rating.copyWriting',
    defaultMessage: 'Copy Writing',
  },
  punctuality: {
    id: 'components.ChannelCard.rating.punctuality',
    defaultMessage: 'Punctuality',
  },
  professionalism: {
    id: 'components.ChannelCard.rating.professionalism',
    defaultMessage: 'Professionalism',
  },
});

const collaborationRatingsMap = {
  visualCreativity: {
    title: collaborationRatingsStrings.visualCreativity,
    value: defaultRange,
  },
  copyWriting: {
    title: collaborationRatingsStrings.copyWriting,
    value: defaultRange,
  },
  punctuality: {
    title: collaborationRatingsStrings.punctuality,
    value: defaultRange,
  },
  professionalism: {
    title: collaborationRatingsStrings.professionalism,
    value: defaultRange,
  },
};

export const getCollaborationRatingsFilter = memoize(
  (filterId) => collaborationRatingsMap[filterId],
);

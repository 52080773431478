import React from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import useAuth from 'auth/useAuth';
import config from 'config';

import generatedFragmentTypes from '../../../fragmentTypes';

export const Context = React.createContext();

const cache = new InMemoryCache({
  possibleTypes: generatedFragmentTypes.possibleTypes,
  typePolicies: {
    ApplicationTask: {
      keyFields: ['applicationId', 'id'],
    },
    PrivateNetworkApplication: {
      fields: {
        // this will tell apollo to replace channels field instead of merging
        channels: {
          merge: false,
        },
      },
    },
    InfluencerAsset: {
      fields: {
        // this will tell apollo to replace publications field instead of merging
        publications: {
          merge: false,
        },
      },
    },
  },
});

const httpLink = createHttpLink({
  uri: config.graphQLServer.url,
});

const GraphQLProvider = ({ children }) => {
  const { checkSession, getIdTokenClaims } = useAuth();

  const authLink = setContext(async (_, { headers }) => {
    await checkSession();

    // Our auth logic on the server does not support the short token
    // returned by `getTokenSilently`, so we need to get the raw bearer token here
    const { __raw: accessToken } = await getIdTokenClaims();

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

  const apolloClient = React.useMemo(
    () =>
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache,
      }),
    [checkSession, getIdTokenClaims, config.graphQLServer.url],
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

GraphQLProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GraphQLProvider;

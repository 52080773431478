import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import InspectorDrawer from 'components/inspector/InspectorDrawer';

import { ChannelPropType } from 'utils/channel';
import { REVIEW_STATUS } from 'utils/kamReviewStatuses';

import {
  useUpdateChannelTags,
  useUpdateChannelNotes,
  useUpdateApplicationKamReview,
  useUpdateApplicationHiddenFromClient,
  useUpdateApplicationKamNote,
  useUpdateApplicationUserPitch,
} from '../data/privateNetworkHooks';
import PrivateNetworkApplicationKamCard from './PrivateNetworkApplicationKamCard';

export const drawerWidth = 320;

const PrivateNetworkApplicationKamInspector = ({
  selectedChannel,
  channels,
  onSelectChannel,
  application,
  loading,
  ...otherProps
}) => {
  const { updateChannelTags, loading: tagsUpdateLoading } =
    useUpdateChannelTags();

  const { updateChannelNotes, loading: notesUpdateLoading } =
    useUpdateChannelNotes();

  const { updateApplicationKamReview, loading: kamReviewUpdateLoading } =
    useUpdateApplicationKamReview();

  const {
    updateApplicationHiddenFromClient,
    loading: hiddenFromClientUpdateLoading,
  } = useUpdateApplicationHiddenFromClient();

  const { updateApplicationKamNote, loading: kamNoteUpdateLoading } =
    useUpdateApplicationKamNote();

  const { updateApplicationUserPitch, loading: userPitchUpdateLoading } =
    useUpdateApplicationUserPitch();

  const handleUpdateChannelTags = (data) => {
    if (selectedChannel) {
      updateChannelTags({
        id: selectedChannel.id,
        payload: data.tags,
      });
    }
  };

  const handleUpdateChannelNotes = (notes) => {
    updateChannelNotes({
      id: selectedChannel?.id,
      payload: notes,
    });
  };

  const handleKamReviewUpdate = React.useCallback(
    (newKamReviewStatus) => {
      if (application) {
        updateApplicationKamReview({
          id: application.id,
          payload: {
            status:
              newKamReviewStatus === REVIEW_STATUS.NONE
                ? null
                : newKamReviewStatus,
          },
        });
      }
    },
    [updateApplicationKamReview, application],
  );

  const handleHiddenFromClientUpdate = React.useCallback(
    (newHiddenFlag) => {
      if (application) {
        updateApplicationHiddenFromClient({
          id: application.id,
          payload: {
            hiddenFromClient: newHiddenFlag,
          },
        });
      }
    },
    [updateApplicationHiddenFromClient, application],
  );

  const handleKamNoteUpdate = React.useCallback(
    (newNote) => {
      if (application) {
        updateApplicationKamNote({
          id: application.id,
          payload: {
            note: newNote,
          },
        });
      }
    },
    [updateApplicationKamNote, application],
  );

  const handleUserPitchUpdate = React.useCallback(
    (newPitch) => {
      if (application) {
        updateApplicationUserPitch({
          id: application.id,
          payload: {
            pitch: newPitch,
          },
        });
      }
    },
    [updateApplicationUserPitch, application],
  );

  const combinedLoading = [
    loading,
    tagsUpdateLoading,
    notesUpdateLoading,
    kamReviewUpdateLoading,
    hiddenFromClientUpdateLoading,
    kamNoteUpdateLoading,
    userPitchUpdateLoading,
  ].some(Boolean);

  return (
    <InspectorDrawer isOpen={!!application} width={drawerWidth} {...otherProps}>
      {application && (
        <Box>
          <PrivateNetworkApplicationKamCard
            selectedChannel={selectedChannel}
            channels={channels}
            onSelectChannel={onSelectChannel}
            application={application}
            onUpdateChannelTags={handleUpdateChannelTags}
            updateChannelNotes={handleUpdateChannelNotes}
            onUpdateKamReviewStatus={handleKamReviewUpdate}
            onUpdateHiddenFromClient={handleHiddenFromClientUpdate}
            onUpdateKamNote={handleKamNoteUpdate}
            onUpdateUserPitch={handleUserPitchUpdate}
            loading={combinedLoading}
            height="100%"
          />
        </Box>
      )}
    </InspectorDrawer>
  );
};

PrivateNetworkApplicationKamInspector.propTypes = {
  selectedChannel: ChannelPropType,
  channels: PropTypes.arrayOf(ChannelPropType),
  onSelectChannel: PropTypes.func.isRequired,
  application: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

PrivateNetworkApplicationKamInspector.defaultProps = {
  application: undefined,
  channels: [],
  selectedChannel: null,
};

export default PrivateNetworkApplicationKamInspector;

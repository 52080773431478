import React from 'react';
import { useIntl, defineMessage } from 'react-intl';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const loadingString = defineMessage({
  id: 'general.loading',
  defaultMessage: 'Loading...',
});

const LoadingState = (props) => {
  const intl = useIntl();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      {...props}
    >
      <Typography>{intl.formatMessage(loadingString)}</Typography>
      <Box mb={2} />
      <CircularProgress />
    </Box>
  );
};

export default LoadingState;

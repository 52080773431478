import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';
import { SortObjectPropType } from 'utils/sorting';
import { Feature } from 'utils/features';

const InstagramApplicationTableHead = ({
  currentSort,
  onSort,
  influencerSelectionType,
  hideKamNotesColumn,
  ...otherProps
}) => (
  <TableHead {...otherProps}>
    <TableRow>
      <TableCellHead
        align="center"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="clientApplicationReview.status"
      >
        <FormattedMessage
          id="components.ApplicationsTable.clientReviewStatus"
          defaultMessage="Accept/Reject"
        />
      </TableCellHead>
      <TableCell align="left" />
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.instagram.username.tooltip"
            defaultMessage="Instagram @channelname handle"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.instagram.username"
          defaultMessage="@channelname"
        />
      </TableCellHead>
      {!hideKamNotesColumn && (
        <TableCellHead align="left" noWrap>
          <FormattedMessage
            id="components.ChannelsTable.kamNote"
            defaultMessage="AM's Note"
          />
        </TableCellHead>
      )}
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.gender.tooltip"
            defaultMessage="As provided by the Influencer on the eqolot platform"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.gender"
          defaultMessage="Gender"
        />
      </TableCellHead>
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.location.tooltip"
            defaultMessage="As provided by the Influencer on the eqolot platform"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.location"
          defaultMessage="Location"
        />
      </TableCellHead>
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="reach"
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.instagram.followers.tooltip"
            defaultMessage="Automatically tracked by the eqolot platform for registered Influencers"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.instagram.followers"
          defaultMessage="Followers"
        />
      </TableCellHead>
      {influencerSelectionType === 'budget' && (
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="advertiserPrice"
        >
          <FormattedMessage
            id="components.ChannelsTable.price"
            defaultMessage="Price"
          />
        </TableCellHead>
      )}
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="channel.keyMetrics.engagementRate"
        noWrap
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.instagram.engagementRate.tooltip"
            defaultMessage="Engagement rate, as the ratio of engagements to the number of followers of the influencer"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.instagram.engagementRate"
          defaultMessage="Eng. Rate"
        />
      </TableCellHead>
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="channel.keyMetrics.storyImpressionRatio"
        tooltip={
          <FormattedMessage
            id="components.ApplicationsTable.instagram.storyImpressionRatio.tooltip"
            defaultMessage="TODO"
          />
        }
      >
        <FormattedMessage
          id="components.ApplicationsTable.instagram.storyImpressionRatio"
          defaultMessage="Story Impression Ratio"
        />
      </TableCellHead>
      <TableCellHead
        align="right"
        onSort={onSort}
        currentSort={currentSort}
        sortProperty="channel.keyMetrics.postImpressionRatio"
        tooltip={
          <FormattedMessage
            id="components.ApplicationsTable.instagram.postImpressionRatio.tooltip"
            defaultMessage="TODO"
          />
        }
      >
        <FormattedMessage
          id="components.ApplicationsTable.instagram.postImpressionRatio"
          defaultMessage="Post Impression Ratio"
        />
      </TableCellHead>
      <Feature feature="audienceGendersColumn">
        <TableCellHead
          align="right"
          onSort={onSort}
          currentSort={currentSort}
          sortProperty="channel.keyMetrics.audience.gender.female"
          tooltip={
            <FormattedMessage
              id="components.ChannelsTable.instagram.audienceGenders.tooltip"
              defaultMessage="Percentage of Female viewers in the total audience"
            />
          }
        >
          <FormattedMessage
            id="components.ChannelsTable.instagram.audienceGenders"
            defaultMessage="Audience Female/Male"
          />
        </TableCellHead>
      </Feature>
      <TableCellHead
        noWrap
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.topCountry.tooltip"
            defaultMessage="Location of the Influencers Follower"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.topCountry"
          defaultMessage="Top Country"
        />
      </TableCellHead>
      <TableCellHead
        tooltip={
          <FormattedMessage
            id="components.ChannelsTable.categories.tooltip"
            defaultMessage="As defined by the Influencer on the eqolot platform"
          />
        }
      >
        <FormattedMessage
          id="components.ChannelsTable.categories"
          defaultMessage="Categories"
        />
      </TableCellHead>
    </TableRow>
  </TableHead>
);

InstagramApplicationTableHead.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  influencerSelectionType: PropTypes.oneOf(['budget', 'reach']).isRequired,
  hideKamNotesColumn: PropTypes.bool,
};

InstagramApplicationTableHead.defaultProps = {
  hideKamNotesColumn: false,
};

export default InstagramApplicationTableHead;

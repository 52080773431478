import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { headTitle } from 'utils/pages';

import PageWrapper from './PageWrapper';
import PageTitle from './PageTitle';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 0),
    height: '100%',
  },
}));

const Page = ({
  title,
  pageTitle,
  pageTitleRoot,
  showTitle,
  icon,
  actions,
  className,
  children,
  loading,
  fullWidth,
}) => {
  const classes = useStyles();

  return (
    <PageWrapper
      title={title}
      pageTitle={headTitle(pageTitle || title, pageTitleRoot)}
      className={className}
      loading={loading}
      fullWidth={fullWidth}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        {showTitle && <PageTitle icon={icon}>{title}</PageTitle>}
        {actions}
      </Box>
      <Box className={classes.container}>{children}</Box>
    </PageWrapper>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  pageTitle: PropTypes.string,
  pageTitleRoot: PropTypes.string,
  showTitle: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  actions: PropTypes.node,
};

Page.defaultProps = {
  title: '',
  pageTitle: '',
  pageTitleRoot: undefined,
  showTitle: true,
  icon: null,
  actions: null,
  className: '',
  loading: false,
  fullWidth: false,
};

export default Page;

import React from 'react';
import { useIntl } from 'react-intl';

import Page from 'layout/Page';
import LoadingPage from 'layout/LoadingPage';
import { useClient } from 'utils/client';

import InfluencerAssetsGrid from './InfluencerAssetsGrid';

const InfluencerAssetsPage = (props) => {
  const intl = useIntl();
  const { loading: loadingClient } = useClient();

  const pageTitle = intl.formatMessage({
    id: 'scenes.influencerAssets.pageTitle',
    defaultMessage: 'Influencer Assets',
  });

  if (loadingClient) {
    return <LoadingPage withSidebar />;
  }

  return (
    <Page title={pageTitle} {...props}>
      <InfluencerAssetsGrid />
    </Page>
  );
};

export default InfluencerAssetsPage;

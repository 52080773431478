import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import PlatformIcon from 'icons/Platform';
import { PlatformPropType } from 'utils/channel';

const useStyles = makeStyles({
  root: {
    backgroundColor: (props) => props.color,
    width: (props) => props.size,
    height: (props) => props.size,
  },
});

const PlatformAvatar = (props) => {
  const { platform, color, ...otherProps } = props;

  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <Avatar className={classes.root} {...otherProps}>
      <PlatformIcon
        platform={platform}
        color={theme.palette.getContrastText(color)}
      />
    </Avatar>
  );
};

PlatformAvatar.propTypes = {
  platform: PlatformPropType.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
};

PlatformAvatar.defaultProps = {
  size: 48,
};

export default PlatformAvatar;

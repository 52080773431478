import React from 'react';
import PropTypes from 'prop-types';

import SvgIconWrapper from './SvgIconWrapper';

const BlogGA = ({ color, ...otherProps }) => (
  <SvgIconWrapper stroke={color} {...otherProps}>
    <path
      d="M2 5.63636C2 3.62806 3.62806 2 5.63636 2H18.3636C20.3719 2 22 3.62806 22 5.63636V18.3636C22 20.3719 20.3719 22 18.3636 22H5.63636C3.62806 22 2 20.3719 2 18.3636V5.63636Z"
      strokeWidth="2"
    />
    <path
      d="M9.12351 17.0019C9.12351 17.0019 11.0938 13.1336 11.5455 11.7612C11.9971 10.3887 13.3376 4.75467 11.8464 4.31338C10.8768 4.02648 9.57806 7.45455 9.12351 11.5455C8.67959 15.5407 8.97578 18.3214 10.0157 19.2746C11.2634 20.4183 13.3241 19.253 14.1618 18.3286C15.2623 17.1142 15.4631 15.9124 15.6364 14.7179C15.8083 13.5336 14.9016 12.0801 14.0104 11.5455C13.1193 11.0108 11.7639 11.293 11.4149 12.1218C10.9104 13.32 11.8497 15.0243 13.5263 15.1305C15.8837 15.2798 18.1195 14.1234 19.0879 13.3758"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconWrapper>
);

BlogGA.propTypes = {
  color: PropTypes.string,
};

BlogGA.defaultProps = {
  color: '#000',
};

export default BlogGA;

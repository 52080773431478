import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MuiTableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  label: {
    fontWeight: 'bold',
  },
});

const TableContainer = ({
  label,
  headerContent,
  actions,
  children,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <MuiTableContainer component={Paper} elevation={12} {...otherProps}>
      <Box display="flex" alignItems="center" p={2}>
        <Typography
          component="div"
          variant="subtitle1"
          className={classes.label}
        >
          {label}
        </Typography>
        <Box ml={3}>{headerContent}</Box>
        <Box ml="auto">{actions}</Box>
      </Box>
      {children}
    </MuiTableContainer>
  );
};

TableContainer.propTypes = {
  headerContent: PropTypes.node,
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
};

TableContainer.defaultProps = {
  headerContent: null,
  actions: null,
};

export default TableContainer;

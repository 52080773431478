import React from 'react';
import PropTypes from 'prop-types';

import SvgIconWrapper from './SvgIconWrapper';

const Edit = ({ color, ...otherProps }) => (
  <SvgIconWrapper {...otherProps}>
    <path
      d="M20 14.66V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H9.34"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 2L22 6L12 16H8V12L18 2Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconWrapper>
);

Edit.propTypes = {
  color: PropTypes.string,
};

Edit.defaultProps = {
  color: '#2196F3',
};
export default Edit;

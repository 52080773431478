import { defineMessages } from 'react-intl';

export const categoriesStrings = defineMessages({
  noCategory: {
    id: 'preferences.categories.noCategory',
    defaultMessage: 'General',
  },
  IAB1: {
    id: 'preferences.categories.IAB1',
    defaultMessage: 'Arts & Entertainment',
  },
  'IAB1-6': { id: 'preferences.categories.IAB1-6', defaultMessage: 'Music' },
  IAB2: { id: 'preferences.categories.IAB2', defaultMessage: 'Automotive' },
  IAB3: { id: 'preferences.categories.IAB3', defaultMessage: 'Business' },
  IAB4: { id: 'preferences.categories.IAB4', defaultMessage: 'Careers' },
  IAB6: {
    id: 'preferences.categories.IAB6',
    defaultMessage: 'Family & Parenting',
  },
  IAB7: {
    id: 'preferences.categories.IAB7',
    defaultMessage: 'Health & Fitness',
  },
  IAB8: { id: 'preferences.categories.IAB8', defaultMessage: 'Food & Drink' },
  IAB9: {
    id: 'preferences.categories.IAB9',
    defaultMessage: 'Hobbies & Interests',
  },
  'IAB9-2': {
    id: 'preferences.categories.IAB9-2',
    defaultMessage: 'Arts & Crafts',
  },
  'IAB9-23': {
    id: 'preferences.categories.IAB9-23',
    defaultMessage: 'Photography',
  },
  'IAB9-30': {
    id: 'preferences.categories.IAB9-30',
    defaultMessage: 'Video & Computer Games',
  },
  IAB10: {
    id: 'preferences.categories.IAB10',
    defaultMessage: 'Home & Garden',
  },
  IAB12: { id: 'preferences.categories.IAB12', defaultMessage: 'News' },
  IAB13: {
    id: 'preferences.categories.IAB13',
    defaultMessage: 'Personal Finance',
  },
  IAB14: { id: 'preferences.categories.IAB14', defaultMessage: 'Society' },
  IAB16: { id: 'preferences.categories.IAB16', defaultMessage: 'Pets' },
  IAB17: { id: 'preferences.categories.IAB17', defaultMessage: 'Sports' },
  IAB18: {
    id: 'preferences.categories.IAB18',
    defaultMessage: 'Style & Fashion',
  },
  'IAB18-1': { id: 'preferences.categories.IAB18-1', defaultMessage: 'Beauty' },
  IAB19: {
    id: 'preferences.categories.IAB19',
    defaultMessage: 'Technology & Computing',
  },
  IAB20: { id: 'preferences.categories.IAB20', defaultMessage: 'Travel' },
  'IAB1-1': {
    id: 'preferences.categories.IAB1-1',
    defaultMessage: 'Books & Literature',
  },
  'IAB1-2': {
    id: 'preferences.categories.IAB1-2',
    defaultMessage: 'Celebrity Fan/Gossip',
  },
  'IAB1-3': { id: 'preferences.categories.IAB1-3', defaultMessage: 'Fine Art' },
  'IAB1-4': { id: 'preferences.categories.IAB1-4', defaultMessage: 'Humor' },
  'IAB1-5': { id: 'preferences.categories.IAB1-5', defaultMessage: 'Movies' },
  'IAB1-7': {
    id: 'preferences.categories.IAB1-7',
    defaultMessage: 'Television',
  },
  'IAB10-1': {
    id: 'preferences.categories.IAB10-1',
    defaultMessage: 'Appliances',
  },
  'IAB10-2': {
    id: 'preferences.categories.IAB10-2',
    defaultMessage: 'Entertaining',
  },
  'IAB10-3': {
    id: 'preferences.categories.IAB10-3',
    defaultMessage: 'Environmental Safety',
  },
  'IAB10-4': {
    id: 'preferences.categories.IAB10-4',
    defaultMessage: 'Gardening',
  },
  'IAB10-5': {
    id: 'preferences.categories.IAB10-5',
    defaultMessage: 'Home Repair',
  },
  'IAB10-6': {
    id: 'preferences.categories.IAB10-6',
    defaultMessage: 'Home Theater',
  },
  'IAB10-7': {
    id: 'preferences.categories.IAB10-7',
    defaultMessage: 'Interior Decorating',
  },
  'IAB10-8': {
    id: 'preferences.categories.IAB10-8',
    defaultMessage: 'Landscaping',
  },
  'IAB10-9': {
    id: 'preferences.categories.IAB10-9',
    defaultMessage: 'Remodeling & Construction',
  },
  IAB11: {
    id: 'preferences.categories.IAB11',
    defaultMessage: 'Law, Gov’t & Politics',
  },
  'IAB11-1': {
    id: 'preferences.categories.IAB11-1',
    defaultMessage: 'Immigration',
  },
  'IAB11-2': {
    id: 'preferences.categories.IAB11-2',
    defaultMessage: 'Legal Issues',
  },
  'IAB11-3': {
    id: 'preferences.categories.IAB11-3',
    defaultMessage: 'U.S. Government Resources',
  },
  'IAB11-4': {
    id: 'preferences.categories.IAB11-4',
    defaultMessage: 'Politics',
  },
  'IAB11-5': {
    id: 'preferences.categories.IAB11-5',
    defaultMessage: 'Commentary',
  },
  'IAB12-1': {
    id: 'preferences.categories.IAB12-1',
    defaultMessage: 'International News',
  },
  'IAB12-2': {
    id: 'preferences.categories.IAB12-2',
    defaultMessage: 'National News',
  },
  'IAB12-3': {
    id: 'preferences.categories.IAB12-3',
    defaultMessage: 'Local News',
  },
  'IAB13-1': {
    id: 'preferences.categories.IAB13-1',
    defaultMessage: 'Beginning Investing',
  },
  'IAB13-10': {
    id: 'preferences.categories.IAB13-10',
    defaultMessage: 'Retirement Planning',
  },
  'IAB13-11': {
    id: 'preferences.categories.IAB13-11',
    defaultMessage: 'Stocks',
  },
  'IAB13-12': {
    id: 'preferences.categories.IAB13-12',
    defaultMessage: 'Tax Planning',
  },
  'IAB13-2': {
    id: 'preferences.categories.IAB13-2',
    defaultMessage: 'Credit/Debt & Loans',
  },
  'IAB13-3': {
    id: 'preferences.categories.IAB13-3',
    defaultMessage: 'Financial News',
  },
  'IAB13-4': {
    id: 'preferences.categories.IAB13-4',
    defaultMessage: 'Financial Planning',
  },
  'IAB13-5': {
    id: 'preferences.categories.IAB13-5',
    defaultMessage: 'Hedge Fund',
  },
  'IAB13-6': {
    id: 'preferences.categories.IAB13-6',
    defaultMessage: 'Insurance',
  },
  'IAB13-7': {
    id: 'preferences.categories.IAB13-7',
    defaultMessage: 'Investing',
  },
  'IAB13-8': {
    id: 'preferences.categories.IAB13-8',
    defaultMessage: 'Mutual Funds',
  },
  'IAB13-9': {
    id: 'preferences.categories.IAB13-9',
    defaultMessage: 'Options',
  },
  'IAB14-1': { id: 'preferences.categories.IAB14-1', defaultMessage: 'Dating' },
  'IAB14-2': {
    id: 'preferences.categories.IAB14-2',
    defaultMessage: 'Divorce Support',
  },
  'IAB14-3': {
    id: 'preferences.categories.IAB14-3',
    defaultMessage: 'Gay Lifestyle',
  },
  'IAB14-4': {
    id: 'preferences.categories.IAB14-4',
    defaultMessage: 'Marriage',
  },
  'IAB14-5': {
    id: 'preferences.categories.IAB14-5',
    defaultMessage: 'Senior Living',
  },
  'IAB14-6': { id: 'preferences.categories.IAB14-6', defaultMessage: 'Teens' },
  'IAB14-7': {
    id: 'preferences.categories.IAB14-7',
    defaultMessage: 'Weddings',
  },
  'IAB14-8': {
    id: 'preferences.categories.IAB14-8',
    defaultMessage: 'Ethnic Specific',
  },
  IAB15: { id: 'preferences.categories.IAB15', defaultMessage: 'Science' },
  'IAB15-1': {
    id: 'preferences.categories.IAB15-1',
    defaultMessage: 'Astrology',
  },
  'IAB15-10': {
    id: 'preferences.categories.IAB15-10',
    defaultMessage: 'Weather',
  },
  'IAB15-2': {
    id: 'preferences.categories.IAB15-2',
    defaultMessage: 'Biology',
  },
  'IAB15-3': {
    id: 'preferences.categories.IAB15-3',
    defaultMessage: 'Chemistry',
  },
  'IAB15-4': {
    id: 'preferences.categories.IAB15-4',
    defaultMessage: 'Geology',
  },
  'IAB15-5': {
    id: 'preferences.categories.IAB15-5',
    defaultMessage: 'Paranormal Phenomena',
  },
  'IAB15-6': {
    id: 'preferences.categories.IAB15-6',
    defaultMessage: 'Physics',
  },
  'IAB15-7': {
    id: 'preferences.categories.IAB15-7',
    defaultMessage: 'Space/Astronomy',
  },
  'IAB15-8': {
    id: 'preferences.categories.IAB15-8',
    defaultMessage: 'Geography',
  },
  'IAB15-9': { id: 'preferences.categories.IAB15-9', defaultMessage: 'Botany' },
  'IAB16-1': {
    id: 'preferences.categories.IAB16-1',
    defaultMessage: 'Aquariums',
  },
  'IAB16-2': { id: 'preferences.categories.IAB16-2', defaultMessage: 'Birds' },
  'IAB16-3': { id: 'preferences.categories.IAB16-3', defaultMessage: 'Cats' },
  'IAB16-4': { id: 'preferences.categories.IAB16-4', defaultMessage: 'Dogs' },
  'IAB16-5': {
    id: 'preferences.categories.IAB16-5',
    defaultMessage: 'Large Animals',
  },
  'IAB16-6': {
    id: 'preferences.categories.IAB16-6',
    defaultMessage: 'Reptiles',
  },
  'IAB16-7': {
    id: 'preferences.categories.IAB16-7',
    defaultMessage: 'Veterinary Medicine',
  },
  'IAB17-1': {
    id: 'preferences.categories.IAB17-1',
    defaultMessage: 'Auto Racing',
  },
  'IAB17-10': {
    id: 'preferences.categories.IAB17-10',
    defaultMessage: 'Figure Skating',
  },
  'IAB17-11': {
    id: 'preferences.categories.IAB17-11',
    defaultMessage: 'Fly Fishing',
  },
  'IAB17-12': {
    id: 'preferences.categories.IAB17-12',
    defaultMessage: 'Football',
  },
  'IAB17-13': {
    id: 'preferences.categories.IAB17-13',
    defaultMessage: 'Freshwater Fishing',
  },
  'IAB17-14': {
    id: 'preferences.categories.IAB17-14',
    defaultMessage: 'Game & Fish',
  },
  'IAB17-15': { id: 'preferences.categories.IAB17-15', defaultMessage: 'Golf' },
  'IAB17-16': {
    id: 'preferences.categories.IAB17-16',
    defaultMessage: 'Horse Racing',
  },
  'IAB17-17': {
    id: 'preferences.categories.IAB17-17',
    defaultMessage: 'Horses',
  },
  'IAB17-18': {
    id: 'preferences.categories.IAB17-18',
    defaultMessage: 'Hunting/Shooting',
  },
  'IAB17-19': {
    id: 'preferences.categories.IAB17-19',
    defaultMessage: 'Inline Skating',
  },
  'IAB17-2': {
    id: 'preferences.categories.IAB17-2',
    defaultMessage: 'Baseball',
  },
  'IAB17-20': {
    id: 'preferences.categories.IAB17-20',
    defaultMessage: 'Martial Arts',
  },
  'IAB17-21': {
    id: 'preferences.categories.IAB17-21',
    defaultMessage: 'Mountain Biking',
  },
  'IAB17-22': {
    id: 'preferences.categories.IAB17-22',
    defaultMessage: 'NASCAR Racing',
  },
  'IAB17-23': {
    id: 'preferences.categories.IAB17-23',
    defaultMessage: 'Olympics',
  },
  'IAB17-24': {
    id: 'preferences.categories.IAB17-24',
    defaultMessage: 'Paintball',
  },
  'IAB17-25': {
    id: 'preferences.categories.IAB17-25',
    defaultMessage: 'Power & Motorcycles',
  },
  'IAB17-26': {
    id: 'preferences.categories.IAB17-26',
    defaultMessage: 'Pro Basketball',
  },
  'IAB17-27': {
    id: 'preferences.categories.IAB17-27',
    defaultMessage: 'Pro Ice Hockey',
  },
  'IAB17-28': {
    id: 'preferences.categories.IAB17-28',
    defaultMessage: 'Rodeo',
  },
  'IAB17-29': {
    id: 'preferences.categories.IAB17-29',
    defaultMessage: 'Rugby',
  },
  'IAB17-3': {
    id: 'preferences.categories.IAB17-3',
    defaultMessage: 'Bicycling',
  },
  'IAB17-30': {
    id: 'preferences.categories.IAB17-30',
    defaultMessage: 'Running/Jogging',
  },
  'IAB17-31': {
    id: 'preferences.categories.IAB17-31',
    defaultMessage: 'Sailing',
  },
  'IAB17-32': {
    id: 'preferences.categories.IAB17-32',
    defaultMessage: 'Saltwater Fishing',
  },
  'IAB17-33': {
    id: 'preferences.categories.IAB17-33',
    defaultMessage: 'Scuba Diving',
  },
  'IAB17-34': {
    id: 'preferences.categories.IAB17-34',
    defaultMessage: 'Skateboarding',
  },
  'IAB17-35': {
    id: 'preferences.categories.IAB17-35',
    defaultMessage: 'Skiing',
  },
  'IAB17-36': {
    id: 'preferences.categories.IAB17-36',
    defaultMessage: 'Snowboarding',
  },
  'IAB17-37': {
    id: 'preferences.categories.IAB17-37',
    defaultMessage: 'Surfing/Bodyboarding',
  },
  'IAB17-38': {
    id: 'preferences.categories.IAB17-38',
    defaultMessage: 'Swimming',
  },
  'IAB17-39': {
    id: 'preferences.categories.IAB17-39',
    defaultMessage: 'Table Tennis/Ping-Pong',
  },
  'IAB17-4': {
    id: 'preferences.categories.IAB17-4',
    defaultMessage: 'Bodybuilding',
  },
  'IAB17-40': {
    id: 'preferences.categories.IAB17-40',
    defaultMessage: 'Tennis',
  },
  'IAB17-41': {
    id: 'preferences.categories.IAB17-41',
    defaultMessage: 'Volleyball',
  },
  'IAB17-42': {
    id: 'preferences.categories.IAB17-42',
    defaultMessage: 'Walking',
  },
  'IAB17-43': {
    id: 'preferences.categories.IAB17-43',
    defaultMessage: 'Waterski/Wakeboard',
  },
  'IAB17-44': {
    id: 'preferences.categories.IAB17-44',
    defaultMessage: 'Soccer',
  },
  'IAB17-5': { id: 'preferences.categories.IAB17-5', defaultMessage: 'Boxing' },
  'IAB17-6': {
    id: 'preferences.categories.IAB17-6',
    defaultMessage: 'Canoeing/Kayaking',
  },
  'IAB17-7': {
    id: 'preferences.categories.IAB17-7',
    defaultMessage: 'Cheerleading',
  },
  'IAB17-8': {
    id: 'preferences.categories.IAB17-8',
    defaultMessage: 'Climbing',
  },
  'IAB17-9': {
    id: 'preferences.categories.IAB17-9',
    defaultMessage: 'Cricket',
  },
  'IAB18-2': {
    id: 'preferences.categories.IAB18-2',
    defaultMessage: 'Body Art',
  },
  'IAB18-3': {
    id: 'preferences.categories.IAB18-3',
    defaultMessage: 'Fashion',
  },
  'IAB18-4': {
    id: 'preferences.categories.IAB18-4',
    defaultMessage: 'Jewelry',
  },
  'IAB18-5': {
    id: 'preferences.categories.IAB18-5',
    defaultMessage: 'Clothing',
  },
  'IAB18-6': {
    id: 'preferences.categories.IAB18-6',
    defaultMessage: 'Accessories',
  },
  'IAB19-1': {
    id: 'preferences.categories.IAB19-1',
    defaultMessage: '3-D Graphics',
  },
  'IAB19-10': {
    id: 'preferences.categories.IAB19-10',
    defaultMessage: 'Computer Reviews',
  },
  'IAB19-11': {
    id: 'preferences.categories.IAB19-11',
    defaultMessage: 'Data Centers',
  },
  'IAB19-12': {
    id: 'preferences.categories.IAB19-12',
    defaultMessage: 'Databases',
  },
  'IAB19-13': {
    id: 'preferences.categories.IAB19-13',
    defaultMessage: 'Desktop Publishing',
  },
  'IAB19-14': {
    id: 'preferences.categories.IAB19-14',
    defaultMessage: 'Desktop Video',
  },
  'IAB19-15': {
    id: 'preferences.categories.IAB19-15',
    defaultMessage: 'Email',
  },
  'IAB19-16': {
    id: 'preferences.categories.IAB19-16',
    defaultMessage: 'Graphics Software',
  },
  'IAB19-17': {
    id: 'preferences.categories.IAB19-17',
    defaultMessage: 'Home Video/DVD',
  },
  'IAB19-18': {
    id: 'preferences.categories.IAB19-18',
    defaultMessage: 'Internet Technology',
  },
  'IAB19-19': { id: 'preferences.categories.IAB19-19', defaultMessage: 'Java' },
  'IAB19-2': {
    id: 'preferences.categories.IAB19-2',
    defaultMessage: 'Animation',
  },
  'IAB19-20': {
    id: 'preferences.categories.IAB19-20',
    defaultMessage: 'JavaScript',
  },
  'IAB19-21': {
    id: 'preferences.categories.IAB19-21',
    defaultMessage: 'Mac Support',
  },
  'IAB19-22': {
    id: 'preferences.categories.IAB19-22',
    defaultMessage: 'MP3/MIDI',
  },
  'IAB19-23': {
    id: 'preferences.categories.IAB19-23',
    defaultMessage: 'Net Conferencing',
  },
  'IAB19-24': {
    id: 'preferences.categories.IAB19-24',
    defaultMessage: 'Net for Beginners',
  },
  'IAB19-25': {
    id: 'preferences.categories.IAB19-25',
    defaultMessage: 'Network Security',
  },
  'IAB19-26': {
    id: 'preferences.categories.IAB19-26',
    defaultMessage: 'Palmtops/PDAs',
  },
  'IAB19-27': {
    id: 'preferences.categories.IAB19-27',
    defaultMessage: 'PC Support',
  },
  'IAB19-28': {
    id: 'preferences.categories.IAB19-28',
    defaultMessage: 'Portable',
  },
  'IAB19-29': {
    id: 'preferences.categories.IAB19-29',
    defaultMessage: 'Entertainment',
  },
  'IAB19-3': {
    id: 'preferences.categories.IAB19-3',
    defaultMessage: 'Antivirus Software',
  },
  'IAB19-30': {
    id: 'preferences.categories.IAB19-30',
    defaultMessage: 'Shareware/Freeware',
  },
  'IAB19-31': { id: 'preferences.categories.IAB19-31', defaultMessage: 'Unix' },
  'IAB19-32': {
    id: 'preferences.categories.IAB19-32',
    defaultMessage: 'Visual Basic',
  },
  'IAB19-33': {
    id: 'preferences.categories.IAB19-33',
    defaultMessage: 'Web Clip Art',
  },
  'IAB19-34': {
    id: 'preferences.categories.IAB19-34',
    defaultMessage: 'Web Design/HTML',
  },
  'IAB19-35': {
    id: 'preferences.categories.IAB19-35',
    defaultMessage: 'Web Search',
  },
  'IAB19-36': {
    id: 'preferences.categories.IAB19-36',
    defaultMessage: 'Windows',
  },
  'IAB19-4': { id: 'preferences.categories.IAB19-4', defaultMessage: 'C/C++' },
  'IAB19-5': {
    id: 'preferences.categories.IAB19-5',
    defaultMessage: 'Cameras & Camcorders',
  },
  'IAB19-6': {
    id: 'preferences.categories.IAB19-6',
    defaultMessage: 'Cell Phones',
  },
  'IAB19-7': {
    id: 'preferences.categories.IAB19-7',
    defaultMessage: 'Computer Certification',
  },
  'IAB19-8': {
    id: 'preferences.categories.IAB19-8',
    defaultMessage: 'Computer Networking',
  },
  'IAB19-9': {
    id: 'preferences.categories.IAB19-9',
    defaultMessage: 'Computer Peripherals',
  },
  'IAB2-1': {
    id: 'preferences.categories.IAB2-1',
    defaultMessage: 'Auto Parts',
  },
  'IAB2-10': {
    id: 'preferences.categories.IAB2-10',
    defaultMessage: 'Electric Vehicle',
  },
  'IAB2-11': {
    id: 'preferences.categories.IAB2-11',
    defaultMessage: 'Hatchback',
  },
  'IAB2-12': { id: 'preferences.categories.IAB2-12', defaultMessage: 'Hybrid' },
  'IAB2-13': { id: 'preferences.categories.IAB2-13', defaultMessage: 'Luxury' },
  'IAB2-14': {
    id: 'preferences.categories.IAB2-14',
    defaultMessage: 'MiniVan',
  },
  'IAB2-15': {
    id: 'preferences.categories.IAB2-15',
    defaultMessage: 'Mororcycles',
  },
  'IAB2-16': {
    id: 'preferences.categories.IAB2-16',
    defaultMessage: 'Off-Road Vehicles',
  },
  'IAB2-17': {
    id: 'preferences.categories.IAB2-17',
    defaultMessage: 'Performance Vehicles',
  },
  'IAB2-18': { id: 'preferences.categories.IAB2-18', defaultMessage: 'Pickup' },
  'IAB2-19': {
    id: 'preferences.categories.IAB2-19',
    defaultMessage: 'Road-Side Assistance',
  },
  'IAB2-2': {
    id: 'preferences.categories.IAB2-2',
    defaultMessage: 'Auto Repair',
  },
  'IAB2-20': { id: 'preferences.categories.IAB2-20', defaultMessage: 'Sedan' },
  'IAB2-21': {
    id: 'preferences.categories.IAB2-21',
    defaultMessage: 'Trucks & Accessories',
  },
  'IAB2-22': {
    id: 'preferences.categories.IAB2-22',
    defaultMessage: 'Vintage Cars',
  },
  'IAB2-23': { id: 'preferences.categories.IAB2-23', defaultMessage: 'Wagon' },
  'IAB2-3': {
    id: 'preferences.categories.IAB2-3',
    defaultMessage: 'Buying/Selling Cars',
  },
  'IAB2-4': {
    id: 'preferences.categories.IAB2-4',
    defaultMessage: 'Car Culture',
  },
  'IAB2-5': {
    id: 'preferences.categories.IAB2-5',
    defaultMessage: 'Certified Pre-Owned',
  },
  'IAB2-6': {
    id: 'preferences.categories.IAB2-6',
    defaultMessage: 'Convertible',
  },
  'IAB2-7': { id: 'preferences.categories.IAB2-7', defaultMessage: 'Coupe' },
  'IAB2-8': {
    id: 'preferences.categories.IAB2-8',
    defaultMessage: 'Crossover',
  },
  'IAB2-9': { id: 'preferences.categories.IAB2-9', defaultMessage: 'Diesel' },
  'IAB20-1': {
    id: 'preferences.categories.IAB20-1',
    defaultMessage: 'Adventure Travel',
  },
  'IAB20-10': {
    id: 'preferences.categories.IAB20-10',
    defaultMessage: 'Canada',
  },
  'IAB20-11': {
    id: 'preferences.categories.IAB20-11',
    defaultMessage: 'Caribbean',
  },
  'IAB20-12': {
    id: 'preferences.categories.IAB20-12',
    defaultMessage: 'Cruises',
  },
  'IAB20-13': {
    id: 'preferences.categories.IAB20-13',
    defaultMessage: 'Eastern Europe',
  },
  'IAB20-14': {
    id: 'preferences.categories.IAB20-14',
    defaultMessage: 'Europe',
  },
  'IAB20-15': {
    id: 'preferences.categories.IAB20-15',
    defaultMessage: 'France',
  },
  'IAB20-16': {
    id: 'preferences.categories.IAB20-16',
    defaultMessage: 'Greece',
  },
  'IAB20-17': {
    id: 'preferences.categories.IAB20-17',
    defaultMessage: 'Honeymoons/Getaways',
  },
  'IAB20-18': {
    id: 'preferences.categories.IAB20-18',
    defaultMessage: 'Hotels',
  },
  'IAB20-19': {
    id: 'preferences.categories.IAB20-19',
    defaultMessage: 'Italy',
  },
  'IAB20-2': { id: 'preferences.categories.IAB20-2', defaultMessage: 'Africa' },
  'IAB20-20': {
    id: 'preferences.categories.IAB20-20',
    defaultMessage: 'Japan',
  },
  'IAB20-21': {
    id: 'preferences.categories.IAB20-21',
    defaultMessage: 'Mexico & Central America',
  },
  'IAB20-22': {
    id: 'preferences.categories.IAB20-22',
    defaultMessage: 'National Parks',
  },
  'IAB20-23': {
    id: 'preferences.categories.IAB20-23',
    defaultMessage: 'South America',
  },
  'IAB20-24': { id: 'preferences.categories.IAB20-24', defaultMessage: 'Spas' },
  'IAB20-25': {
    id: 'preferences.categories.IAB20-25',
    defaultMessage: 'Theme Parks',
  },
  'IAB20-26': {
    id: 'preferences.categories.IAB20-26',
    defaultMessage: 'Traveling with Kids',
  },
  'IAB20-27': {
    id: 'preferences.categories.IAB20-27',
    defaultMessage: 'United Kingdom',
  },
  'IAB20-3': {
    id: 'preferences.categories.IAB20-3',
    defaultMessage: 'Air Travel',
  },
  'IAB20-4': {
    id: 'preferences.categories.IAB20-4',
    defaultMessage: 'Australia & New Zealand',
  },
  'IAB20-5': {
    id: 'preferences.categories.IAB20-5',
    defaultMessage: 'Bed & Breakfasts',
  },
  'IAB20-6': {
    id: 'preferences.categories.IAB20-6',
    defaultMessage: 'Budget Travel',
  },
  'IAB20-7': {
    id: 'preferences.categories.IAB20-7',
    defaultMessage: 'Business Travel',
  },
  'IAB20-8': {
    id: 'preferences.categories.IAB20-8',
    defaultMessage: 'By US Locale',
  },
  'IAB20-9': {
    id: 'preferences.categories.IAB20-9',
    defaultMessage: 'Camping',
  },
  IAB21: { id: 'preferences.categories.IAB21', defaultMessage: 'Real Estate' },
  'IAB21-1': {
    id: 'preferences.categories.IAB21-1',
    defaultMessage: 'Apartments',
  },
  'IAB21-2': {
    id: 'preferences.categories.IAB21-2',
    defaultMessage: 'Architects',
  },
  'IAB21-3': {
    id: 'preferences.categories.IAB21-3',
    defaultMessage: 'Buying/Selling Homes',
  },
  IAB22: { id: 'preferences.categories.IAB22', defaultMessage: 'Shopping' },
  'IAB22-1': {
    id: 'preferences.categories.IAB22-1',
    defaultMessage: 'Contests & Freebies',
  },
  'IAB22-2': {
    id: 'preferences.categories.IAB22-2',
    defaultMessage: 'Couponing',
  },
  'IAB22-3': {
    id: 'preferences.categories.IAB22-3',
    defaultMessage: 'Comparison',
  },
  'IAB22-4': {
    id: 'preferences.categories.IAB22-4',
    defaultMessage: 'Engines',
  },
  IAB23: {
    id: 'preferences.categories.IAB23',
    defaultMessage: 'Religion & Spirituality',
  },
  'IAB23-1': {
    id: 'preferences.categories.IAB23-1',
    defaultMessage: 'Alternative Religions',
  },
  'IAB23-10': {
    id: 'preferences.categories.IAB23-10',
    defaultMessage: 'Pagan/Wiccan',
  },
  'IAB23-2': {
    id: 'preferences.categories.IAB23-2',
    defaultMessage: 'Atheism/Agnosticism',
  },
  'IAB23-3': {
    id: 'preferences.categories.IAB23-3',
    defaultMessage: 'Buddhism',
  },
  'IAB23-4': {
    id: 'preferences.categories.IAB23-4',
    defaultMessage: 'Catholicism',
  },
  'IAB23-5': {
    id: 'preferences.categories.IAB23-5',
    defaultMessage: 'Christianity',
  },
  'IAB23-6': {
    id: 'preferences.categories.IAB23-6',
    defaultMessage: 'Hinduism',
  },
  'IAB23-7': { id: 'preferences.categories.IAB23-7', defaultMessage: 'Islam' },
  'IAB23-8': {
    id: 'preferences.categories.IAB23-8',
    defaultMessage: 'Judaism',
  },
  'IAB23-9': {
    id: 'preferences.categories.IAB23-9',
    defaultMessage: 'Latter-Day Saints',
  },
  IAB24: {
    id: 'preferences.categories.IAB24',
    defaultMessage: 'Uncategorized',
  },
  IAB25: {
    id: 'preferences.categories.IAB25',
    defaultMessage: 'Non-Standard Content',
  },
  'IAB25-1': {
    id: 'preferences.categories.IAB25-1',
    defaultMessage: 'Unmoderated UGC',
  },
  'IAB25-2': {
    id: 'preferences.categories.IAB25-2',
    defaultMessage: 'Extreme Graphic/Explicit Violence',
  },
  'IAB25-3': {
    id: 'preferences.categories.IAB25-3',
    defaultMessage: 'Pornography',
  },
  'IAB25-4': {
    id: 'preferences.categories.IAB25-4',
    defaultMessage: 'Profane Content',
  },
  'IAB25-5': {
    id: 'preferences.categories.IAB25-5',
    defaultMessage: 'Hate Content',
  },
  'IAB25-6': {
    id: 'preferences.categories.IAB25-6',
    defaultMessage: 'Under Construction',
  },
  'IAB25-7': {
    id: 'preferences.categories.IAB25-7',
    defaultMessage: 'Incentivized',
  },
  IAB26: {
    id: 'preferences.categories.IAB26',
    defaultMessage: 'Illegal Content',
  },
  'IAB26-1': {
    id: 'preferences.categories.IAB26-1',
    defaultMessage: 'Illegal Content',
  },
  'IAB26-2': { id: 'preferences.categories.IAB26-2', defaultMessage: 'Warez' },
  'IAB26-3': {
    id: 'preferences.categories.IAB26-3',
    defaultMessage: 'Spyware/Malware',
  },
  'IAB26-4': {
    id: 'preferences.categories.IAB26-4',
    defaultMessage: 'Copyright Infringement',
  },
  'IAB3-1': {
    id: 'preferences.categories.IAB3-1',
    defaultMessage: 'Advertising',
  },
  'IAB3-10': {
    id: 'preferences.categories.IAB3-10',
    defaultMessage: 'Logistics',
  },
  'IAB3-11': {
    id: 'preferences.categories.IAB3-11',
    defaultMessage: 'Marketing',
  },
  'IAB3-12': { id: 'preferences.categories.IAB3-12', defaultMessage: 'Metals' },
  'IAB3-2': {
    id: 'preferences.categories.IAB3-2',
    defaultMessage: 'Agriculture',
  },
  'IAB3-3': {
    id: 'preferences.categories.IAB3-3',
    defaultMessage: 'Biotech/Biomedical',
  },
  'IAB3-4': {
    id: 'preferences.categories.IAB3-4',
    defaultMessage: 'Business Software',
  },
  'IAB3-5': {
    id: 'preferences.categories.IAB3-5',
    defaultMessage: 'Construction',
  },
  'IAB3-6': { id: 'preferences.categories.IAB3-6', defaultMessage: 'Forestry' },
  'IAB3-7': {
    id: 'preferences.categories.IAB3-7',
    defaultMessage: 'Government',
  },
  'IAB3-8': {
    id: 'preferences.categories.IAB3-8',
    defaultMessage: 'Green Solutions',
  },
  'IAB3-9': {
    id: 'preferences.categories.IAB3-9',
    defaultMessage: 'Human Resources',
  },
  'IAB4-1': {
    id: 'preferences.categories.IAB4-1',
    defaultMessage: 'Career Planning',
  },
  'IAB4-10': {
    id: 'preferences.categories.IAB4-10',
    defaultMessage: 'U.S. Military',
  },
  'IAB4-11': {
    id: 'preferences.categories.IAB4-11',
    defaultMessage: 'Career Advice',
  },
  'IAB4-2': { id: 'preferences.categories.IAB4-2', defaultMessage: 'College' },
  'IAB4-3': {
    id: 'preferences.categories.IAB4-3',
    defaultMessage: 'Financial Aid',
  },
  'IAB4-4': {
    id: 'preferences.categories.IAB4-4',
    defaultMessage: 'Job Fairs',
  },
  'IAB4-5': {
    id: 'preferences.categories.IAB4-5',
    defaultMessage: 'Job Search',
  },
  'IAB4-6': {
    id: 'preferences.categories.IAB4-6',
    defaultMessage: 'Resume Writing/Advice',
  },
  'IAB4-7': { id: 'preferences.categories.IAB4-7', defaultMessage: 'Nursing' },
  'IAB4-8': {
    id: 'preferences.categories.IAB4-8',
    defaultMessage: 'Scholarships',
  },
  'IAB4-9': {
    id: 'preferences.categories.IAB4-9',
    defaultMessage: 'Telecommuting',
  },
  IAB5: { id: 'preferences.categories.IAB5', defaultMessage: 'Education' },
  'IAB5-1': {
    id: 'preferences.categories.IAB5-1',
    defaultMessage: '7-12 Education',
  },
  'IAB5-10': {
    id: 'preferences.categories.IAB5-10',
    defaultMessage: 'Homeschooling',
  },
  'IAB5-11': {
    id: 'preferences.categories.IAB5-11',
    defaultMessage: 'Homework/Study Tips',
  },
  'IAB5-12': {
    id: 'preferences.categories.IAB5-12',
    defaultMessage: 'K-6 Educators',
  },
  'IAB5-13': {
    id: 'preferences.categories.IAB5-13',
    defaultMessage: 'Private School',
  },
  'IAB5-14': {
    id: 'preferences.categories.IAB5-14',
    defaultMessage: 'Special Education',
  },
  'IAB5-15': {
    id: 'preferences.categories.IAB5-15',
    defaultMessage: 'Studying Business',
  },
  'IAB5-2': {
    id: 'preferences.categories.IAB5-2',
    defaultMessage: 'Adult Education',
  },
  'IAB5-3': {
    id: 'preferences.categories.IAB5-3',
    defaultMessage: 'Art History',
  },
  'IAB5-4': {
    id: 'preferences.categories.IAB5-4',
    defaultMessage: 'College Administration',
  },
  'IAB5-5': {
    id: 'preferences.categories.IAB5-5',
    defaultMessage: 'College Life',
  },
  'IAB5-6': {
    id: 'preferences.categories.IAB5-6',
    defaultMessage: 'Distance Learning',
  },
  'IAB5-7': {
    id: 'preferences.categories.IAB5-7',
    defaultMessage: 'English as a 2nd Language',
  },
  'IAB5-8': {
    id: 'preferences.categories.IAB5-8',
    defaultMessage: 'Language Learning',
  },
  'IAB5-9': {
    id: 'preferences.categories.IAB5-9',
    defaultMessage: 'Graduate School',
  },
  'IAB6-1': { id: 'preferences.categories.IAB6-1', defaultMessage: 'Adoption' },
  'IAB6-2': {
    id: 'preferences.categories.IAB6-2',
    defaultMessage: 'Babies & Toddlers',
  },
  'IAB6-3': {
    id: 'preferences.categories.IAB6-3',
    defaultMessage: 'Daycare/Pre School',
  },
  'IAB6-4': {
    id: 'preferences.categories.IAB6-4',
    defaultMessage: 'Family Internet',
  },
  'IAB6-5': {
    id: 'preferences.categories.IAB6-5',
    defaultMessage: 'Parenting - K-6 Kids',
  },
  'IAB6-6': {
    id: 'preferences.categories.IAB6-6',
    defaultMessage: 'Parenting teens',
  },
  'IAB6-7': {
    id: 'preferences.categories.IAB6-7',
    defaultMessage: 'Pregnancy',
  },
  'IAB6-8': {
    id: 'preferences.categories.IAB6-8',
    defaultMessage: 'Special Needs Kids',
  },
  'IAB6-9': {
    id: 'preferences.categories.IAB6-9',
    defaultMessage: 'Eldercare',
  },
  'IAB7-1': { id: 'preferences.categories.IAB7-1', defaultMessage: 'Exercise' },
  'IAB7-10': {
    id: 'preferences.categories.IAB7-10',
    defaultMessage: 'Brain Tumor',
  },
  'IAB7-11': { id: 'preferences.categories.IAB7-11', defaultMessage: 'Cancer' },
  'IAB7-12': {
    id: 'preferences.categories.IAB7-12',
    defaultMessage: 'Cholesterol',
  },
  'IAB7-13': {
    id: 'preferences.categories.IAB7-13',
    defaultMessage: 'Chronic Fatigue Syndrome',
  },
  'IAB7-14': {
    id: 'preferences.categories.IAB7-14',
    defaultMessage: 'Chronic Pain',
  },
  'IAB7-15': {
    id: 'preferences.categories.IAB7-15',
    defaultMessage: 'Cold & Flu',
  },
  'IAB7-16': {
    id: 'preferences.categories.IAB7-16',
    defaultMessage: 'Deafness',
  },
  'IAB7-17': {
    id: 'preferences.categories.IAB7-17',
    defaultMessage: 'Dental Care',
  },
  'IAB7-18': {
    id: 'preferences.categories.IAB7-18',
    defaultMessage: 'Depression',
  },
  'IAB7-19': {
    id: 'preferences.categories.IAB7-19',
    defaultMessage: 'Dermatology',
  },
  'IAB7-2': { id: 'preferences.categories.IAB7-2', defaultMessage: 'A.D.D.' },
  'IAB7-20': {
    id: 'preferences.categories.IAB7-20',
    defaultMessage: 'Diabetes',
  },
  'IAB7-21': {
    id: 'preferences.categories.IAB7-21',
    defaultMessage: 'Epilepsy',
  },
  'IAB7-22': {
    id: 'preferences.categories.IAB7-22',
    defaultMessage: 'GERD/Acid Reflux',
  },
  'IAB7-23': {
    id: 'preferences.categories.IAB7-23',
    defaultMessage: 'Headaches/Migraines',
  },
  'IAB7-24': {
    id: 'preferences.categories.IAB7-24',
    defaultMessage: 'Heart Disease',
  },
  'IAB7-25': {
    id: 'preferences.categories.IAB7-25',
    defaultMessage: 'Herbs for Health',
  },
  'IAB7-26': {
    id: 'preferences.categories.IAB7-26',
    defaultMessage: 'Holistic Healing',
  },
  'IAB7-27': {
    id: 'preferences.categories.IAB7-27',
    defaultMessage: 'IBS/Crohn’s Disease',
  },
  'IAB7-28': {
    id: 'preferences.categories.IAB7-28',
    defaultMessage: 'Incest/Abuse Support',
  },
  'IAB7-29': {
    id: 'preferences.categories.IAB7-29',
    defaultMessage: 'Incontinence',
  },
  'IAB7-3': { id: 'preferences.categories.IAB7-3', defaultMessage: 'AIDS/HIV' },
  'IAB7-30': {
    id: 'preferences.categories.IAB7-30',
    defaultMessage: 'Infertility',
  },
  'IAB7-31': {
    id: 'preferences.categories.IAB7-31',
    defaultMessage: 'Men’s Health',
  },
  'IAB7-32': {
    id: 'preferences.categories.IAB7-32',
    defaultMessage: 'Nutrition',
  },
  'IAB7-33': {
    id: 'preferences.categories.IAB7-33',
    defaultMessage: 'Orthopedics',
  },
  'IAB7-34': {
    id: 'preferences.categories.IAB7-34',
    defaultMessage: 'Panic/Anxiety Disorders',
  },
  'IAB7-35': {
    id: 'preferences.categories.IAB7-35',
    defaultMessage: 'Pediatrics',
  },
  'IAB7-36': {
    id: 'preferences.categories.IAB7-36',
    defaultMessage: 'Physical Therapy',
  },
  'IAB7-37': {
    id: 'preferences.categories.IAB7-37',
    defaultMessage: 'Psychology/Psychiatry',
  },
  'IAB7-38': {
    id: 'preferences.categories.IAB7-38',
    defaultMessage: 'Senor Health',
  },
  'IAB7-39': {
    id: 'preferences.categories.IAB7-39',
    defaultMessage: 'Sexuality',
  },
  'IAB7-4': {
    id: 'preferences.categories.IAB7-4',
    defaultMessage: 'Allergies',
  },
  'IAB7-40': {
    id: 'preferences.categories.IAB7-40',
    defaultMessage: 'Sleep Disorders',
  },
  'IAB7-41': {
    id: 'preferences.categories.IAB7-41',
    defaultMessage: 'Smoking Cessation',
  },
  'IAB7-42': {
    id: 'preferences.categories.IAB7-42',
    defaultMessage: 'Substance Abuse',
  },
  'IAB7-43': {
    id: 'preferences.categories.IAB7-43',
    defaultMessage: 'Thyroid Disease',
  },
  'IAB7-44': {
    id: 'preferences.categories.IAB7-44',
    defaultMessage: 'Weight Loss',
  },
  'IAB7-45': {
    id: 'preferences.categories.IAB7-45',
    defaultMessage: 'Women’s Health',
  },
  'IAB7-5': {
    id: 'preferences.categories.IAB7-5',
    defaultMessage: 'Alternative Medicine',
  },
  'IAB7-6': {
    id: 'preferences.categories.IAB7-6',
    defaultMessage: 'Arthritis',
  },
  'IAB7-7': { id: 'preferences.categories.IAB7-7', defaultMessage: 'Asthma' },
  'IAB7-8': {
    id: 'preferences.categories.IAB7-8',
    defaultMessage: 'Autism/PDD',
  },
  'IAB7-9': {
    id: 'preferences.categories.IAB7-9',
    defaultMessage: 'Bipolar Disorder',
  },
  'IAB8-1': {
    id: 'preferences.categories.IAB8-1',
    defaultMessage: 'American Cuisine',
  },
  'IAB8-10': {
    id: 'preferences.categories.IAB8-10',
    defaultMessage: 'Food Allergies',
  },
  'IAB8-11': {
    id: 'preferences.categories.IAB8-11',
    defaultMessage: 'French Cuisine',
  },
  'IAB8-12': {
    id: 'preferences.categories.IAB8-12',
    defaultMessage: 'Health/Lowfat Cooking',
  },
  'IAB8-13': {
    id: 'preferences.categories.IAB8-13',
    defaultMessage: 'Italian Cuisine',
  },
  'IAB8-14': {
    id: 'preferences.categories.IAB8-14',
    defaultMessage: 'Japanese Cuisine',
  },
  'IAB8-15': {
    id: 'preferences.categories.IAB8-15',
    defaultMessage: 'Mexican Cuisine',
  },
  'IAB8-16': { id: 'preferences.categories.IAB8-16', defaultMessage: 'Vegan' },
  'IAB8-17': {
    id: 'preferences.categories.IAB8-17',
    defaultMessage: 'Vegetarian',
  },
  'IAB8-18': { id: 'preferences.categories.IAB8-18', defaultMessage: 'Wine' },
  'IAB8-2': {
    id: 'preferences.categories.IAB8-2',
    defaultMessage: 'Barbecues & Grilling',
  },
  'IAB8-3': {
    id: 'preferences.categories.IAB8-3',
    defaultMessage: 'Cajun/Creole',
  },
  'IAB8-4': {
    id: 'preferences.categories.IAB8-4',
    defaultMessage: 'Chinese Cuisine',
  },
  'IAB8-5': {
    id: 'preferences.categories.IAB8-5',
    defaultMessage: 'Cocktails/Beer',
  },
  'IAB8-6': {
    id: 'preferences.categories.IAB8-6',
    defaultMessage: 'Coffee/Tea',
  },
  'IAB8-7': {
    id: 'preferences.categories.IAB8-7',
    defaultMessage: 'Cuisine-Specific',
  },
  'IAB8-8': {
    id: 'preferences.categories.IAB8-8',
    defaultMessage: 'Desserts & Baking',
  },
  'IAB8-9': {
    id: 'preferences.categories.IAB8-9',
    defaultMessage: 'Dining Out',
  },
  'IAB9-1': {
    id: 'preferences.categories.IAB9-1',
    defaultMessage: 'Art/Technology',
  },
  'IAB9-10': {
    id: 'preferences.categories.IAB9-10',
    defaultMessage: 'Collecting',
  },
  'IAB9-11': {
    id: 'preferences.categories.IAB9-11',
    defaultMessage: 'Comic Books',
  },
  'IAB9-12': {
    id: 'preferences.categories.IAB9-12',
    defaultMessage: 'Drawing/Sketching',
  },
  'IAB9-13': {
    id: 'preferences.categories.IAB9-13',
    defaultMessage: 'Freelance Writing',
  },
  'IAB9-14': {
    id: 'preferences.categories.IAB9-14',
    defaultMessage: 'Genealogy',
  },
  'IAB9-15': {
    id: 'preferences.categories.IAB9-15',
    defaultMessage: 'Getting Published',
  },
  'IAB9-16': { id: 'preferences.categories.IAB9-16', defaultMessage: 'Guitar' },
  'IAB9-17': {
    id: 'preferences.categories.IAB9-17',
    defaultMessage: 'Home Recording',
  },
  'IAB9-18': {
    id: 'preferences.categories.IAB9-18',
    defaultMessage: 'Investors & Patents',
  },
  'IAB9-19': {
    id: 'preferences.categories.IAB9-19',
    defaultMessage: 'Jewelry Making',
  },
  'IAB9-20': {
    id: 'preferences.categories.IAB9-20',
    defaultMessage: 'Magic & Illusion',
  },
  'IAB9-21': {
    id: 'preferences.categories.IAB9-21',
    defaultMessage: 'Needlework',
  },
  'IAB9-22': {
    id: 'preferences.categories.IAB9-22',
    defaultMessage: 'Painting',
  },
  'IAB9-24': { id: 'preferences.categories.IAB9-24', defaultMessage: 'Radio' },
  'IAB9-25': {
    id: 'preferences.categories.IAB9-25',
    defaultMessage: 'Roleplaying Games',
  },
  'IAB9-26': {
    id: 'preferences.categories.IAB9-26',
    defaultMessage: 'Sci-Fi & Fantasy',
  },
  'IAB9-27': {
    id: 'preferences.categories.IAB9-27',
    defaultMessage: 'Scrapbooking',
  },
  'IAB9-28': {
    id: 'preferences.categories.IAB9-28',
    defaultMessage: 'Screenwriting',
  },
  'IAB9-29': {
    id: 'preferences.categories.IAB9-29',
    defaultMessage: 'Stamps & Coins',
  },
  'IAB9-3': { id: 'preferences.categories.IAB9-3', defaultMessage: 'Beadwork' },
  'IAB9-31': {
    id: 'preferences.categories.IAB9-31',
    defaultMessage: 'Woodworking',
  },
  'IAB9-4': {
    id: 'preferences.categories.IAB9-4',
    defaultMessage: 'Birdwatching',
  },
  'IAB9-5': {
    id: 'preferences.categories.IAB9-5',
    defaultMessage: 'Board Games/Puzzles',
  },
  'IAB9-6': {
    id: 'preferences.categories.IAB9-6',
    defaultMessage: 'Candle & Soap Making',
  },
  'IAB9-7': {
    id: 'preferences.categories.IAB9-7',
    defaultMessage: 'Card Games',
  },
  'IAB9-8': { id: 'preferences.categories.IAB9-8', defaultMessage: 'Chess' },
  'IAB9-9': { id: 'preferences.categories.IAB9-9', defaultMessage: 'Cigars' },
  'IAB1-X': {
    id: 'preferences.categories.IAB1-X',
    defaultMessage: 'Arts & Entertainment: Others',
  },
  'IAB2-X': {
    id: 'preferences.categories.IAB2-X',
    defaultMessage: 'Automotive: Others',
  },
  'IAB3-X': {
    id: 'preferences.categories.IAB3-X',
    defaultMessage: 'Business: Others',
  },
  'IAB4-X': {
    id: 'preferences.categories.IAB4-X',
    defaultMessage: 'Careers: Others',
  },
  'IAB5-X': {
    id: 'preferences.categories.IAB5-X',
    defaultMessage: 'Education: Others',
  },
  'IAB6-X': {
    id: 'preferences.categories.IAB6-X',
    defaultMessage: 'Family & Parenting: Others',
  },
  'IAB7-X': {
    id: 'preferences.categories.IAB7-X',
    defaultMessage: 'Health & Fitness: Others',
  },
  'IAB8-X': {
    id: 'preferences.categories.IAB8-X',
    defaultMessage: 'Food & Drink: Others',
  },
  'IAB9-X': {
    id: 'preferences.categories.IAB9-X',
    defaultMessage: 'Hobbies & Interests: Others',
  },
  'IAB10-X': {
    id: 'preferences.categories.IAB10-X',
    defaultMessage: 'Home & Garden: Others',
  },
  'IAB11-X': {
    id: 'preferences.categories.IAB11-X',
    defaultMessage: 'Law, Gov’t & Politics: Others',
  },
  'IAB12-X': {
    id: 'preferences.categories.IAB12-X',
    defaultMessage: 'News: Others',
  },
  'IAB13-X': {
    id: 'preferences.categories.IAB13-X',
    defaultMessage: 'Personal Finance: Others',
  },
  'IAB14-X': {
    id: 'preferences.categories.IAB14-X',
    defaultMessage: 'Society: Others',
  },
  'IAB15-X': {
    id: 'preferences.categories.IAB15-X',
    defaultMessage: 'Science: Others',
  },
  'IAB16-X': {
    id: 'preferences.categories.IAB16-X',
    defaultMessage: 'Pets: Others',
  },
  'IAB17-X': {
    id: 'preferences.categories.IAB17-X',
    defaultMessage: 'Sports: Others',
  },
  'IAB18-X': {
    id: 'preferences.categories.IAB18-X',
    defaultMessage: 'Style & Fashion: Others',
  },
  'IAB19-X': {
    id: 'preferences.categories.IAB19-X',
    defaultMessage: 'Technology & Computing: Others',
  },
  'IAB20-X': {
    id: 'preferences.categories.IAB20-X',
    defaultMessage: 'Travel: Others',
  },
  'IAB21-X': {
    id: 'preferences.categories.IAB21-X',
    defaultMessage: 'Real Estate: Others',
  },
  'IAB22-X': {
    id: 'preferences.categories.IAB22-X',
    defaultMessage: 'Shopping: Others',
  },
  'IAB23-X': {
    id: 'preferences.categories.IAB23-X',
    defaultMessage: 'Religion & Spirituality: Others',
  },
  'IAB24-X': {
    id: 'preferences.categories.IAB24-X',
    defaultMessage: 'Uncategorized: Others',
  },
  'IAB25-X': {
    id: 'preferences.categories.IAB25-X',
    defaultMessage: 'Non-Standard Content: Others',
  },
  'IAB26-X': {
    id: 'preferences.categories.IAB26-X',
    defaultMessage: 'Illegal Content: Others',
  },
});

const categories = [
  {
    code: 'IAB1',
    parent: null,
    active: true,
  },
  {
    code: 'IAB1-6',
    parent: 'IAB1',
    active: true,
  },
  {
    code: 'IAB2',
    parent: null,
    active: true,
  },
  {
    code: 'IAB3',
    parent: null,
    active: true,
  },
  {
    code: 'IAB4',
    parent: null,
    active: true,
  },
  {
    code: 'IAB6',
    parent: null,
    active: true,
  },
  {
    code: 'IAB7',
    parent: null,
    active: true,
  },
  {
    code: 'IAB8',
    parent: null,
    active: true,
  },
  {
    code: 'IAB9',
    parent: null,
    active: true,
  },
  {
    code: 'IAB9-2',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-23',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-30',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB10',
    parent: null,
    active: true,
  },
  {
    code: 'IAB12',
    parent: null,
    active: true,
  },
  {
    code: 'IAB13',
    parent: null,
    active: true,
  },
  {
    code: 'IAB14',
    parent: null,
    active: true,
  },
  {
    code: 'IAB16',
    parent: null,
    active: true,
  },
  {
    code: 'IAB17',
    parent: null,
    active: true,
  },
  {
    code: 'IAB18',
    parent: null,
    active: true,
  },
  {
    code: 'IAB18-1',
    parent: 'IAB18',
    active: true,
  },
  {
    code: 'IAB19',
    parent: null,
    active: true,
  },
  {
    code: 'IAB20',
    parent: null,
    active: true,
  },
  {
    code: 'IAB1-1',
    parent: 'IAB1',
    active: true,
  },
  {
    code: 'IAB1-2',
    parent: 'IAB1',
    active: true,
  },
  {
    code: 'IAB1-3',
    parent: 'IAB1',
    active: false,
  },
  {
    code: 'IAB1-4',
    parent: 'IAB1',
    active: true,
  },
  {
    code: 'IAB1-5',
    parent: 'IAB1',
    active: true,
  },
  {
    code: 'IAB1-7',
    parent: 'IAB1',
    active: false,
  },
  {
    code: 'IAB10-1',
    parent: 'IAB10',
    active: false,
  },
  {
    code: 'IAB10-2',
    parent: 'IAB10',
    active: false,
  },
  {
    code: 'IAB10-3',
    parent: 'IAB10',
    active: false,
  },
  {
    code: 'IAB10-4',
    parent: 'IAB10',
    active: true,
  },
  {
    code: 'IAB10-5',
    parent: 'IAB10',
    active: true,
  },
  {
    code: 'IAB10-6',
    parent: 'IAB10',
    active: false,
  },
  {
    code: 'IAB10-7',
    parent: 'IAB10',
    active: true,
  },
  {
    code: 'IAB10-8',
    parent: 'IAB10',
    active: false,
  },
  {
    code: 'IAB10-9',
    parent: 'IAB10',
    active: false,
  },
  {
    code: 'IAB11',
    parent: null,
    active: true,
  },
  {
    code: 'IAB11-1',
    parent: 'IAB11',
    active: false,
  },
  {
    code: 'IAB11-2',
    parent: 'IAB11',
    active: true,
  },
  {
    code: 'IAB11-3',
    parent: 'IAB11',
    active: false,
  },
  {
    code: 'IAB11-4',
    parent: 'IAB11',
    active: true,
  },
  {
    code: 'IAB11-5',
    parent: 'IAB11',
    active: true,
  },
  {
    code: 'IAB12-1',
    parent: 'IAB12',
    active: true,
  },
  {
    code: 'IAB12-2',
    parent: 'IAB12',
    active: true,
  },
  {
    code: 'IAB12-3',
    parent: 'IAB12',
    active: true,
  },
  {
    code: 'IAB13-1',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-10',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-11',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-12',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-2',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-3',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-4',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-5',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-6',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-7',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-8',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB13-9',
    parent: 'IAB13',
    active: false,
  },
  {
    code: 'IAB14-1',
    parent: 'IAB14',
    active: true,
  },
  {
    code: 'IAB14-2',
    parent: 'IAB14',
    active: false,
  },
  {
    code: 'IAB14-3',
    parent: 'IAB14',
    active: true,
  },
  {
    code: 'IAB14-4',
    parent: 'IAB14',
    active: false,
  },
  {
    code: 'IAB14-5',
    parent: 'IAB14',
    active: false,
  },
  {
    code: 'IAB14-6',
    parent: 'IAB14',
    active: false,
  },
  {
    code: 'IAB14-7',
    parent: 'IAB14',
    active: true,
  },
  {
    code: 'IAB14-8',
    parent: 'IAB14',
    active: false,
  },
  {
    code: 'IAB15',
    parent: null,
    active: false,
  },
  {
    code: 'IAB15-1',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-10',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-2',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-3',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-4',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-5',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-6',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-7',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-8',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB15-9',
    parent: 'IAB15',
    active: false,
  },
  {
    code: 'IAB16-1',
    parent: 'IAB16',
    active: true,
  },
  {
    code: 'IAB16-2',
    parent: 'IAB16',
    active: true,
  },
  {
    code: 'IAB16-3',
    parent: 'IAB16',
    active: true,
  },
  {
    code: 'IAB16-4',
    parent: 'IAB16',
    active: true,
  },
  {
    code: 'IAB16-5',
    parent: 'IAB16',
    active: true,
  },
  {
    code: 'IAB16-6',
    parent: 'IAB16',
    active: true,
  },
  {
    code: 'IAB16-7',
    parent: 'IAB16',
    active: true,
  },
  {
    code: 'IAB17-1',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-10',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-11',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-12',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-13',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-14',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-15',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-16',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-17',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-18',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-19',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-2',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-20',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-21',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-22',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-23',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-24',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-25',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-26',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-27',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-28',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-29',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-3',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-30',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-31',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-32',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-33',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-34',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-35',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-36',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-37',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-38',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-39',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-4',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-40',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-41',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-42',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-43',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-44',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB17-5',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-6',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-7',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-8',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB17-9',
    parent: 'IAB17',
    active: false,
  },
  {
    code: 'IAB18-2',
    parent: 'IAB18',
    active: false,
  },
  {
    code: 'IAB18-3',
    parent: 'IAB18',
    active: true,
  },
  {
    code: 'IAB18-4',
    parent: 'IAB18',
    active: true,
  },
  {
    code: 'IAB18-5',
    parent: 'IAB18',
    active: true,
  },
  {
    code: 'IAB18-6',
    parent: 'IAB18',
    active: true,
  },
  {
    code: 'IAB19-1',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-10',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-11',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-12',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-13',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-14',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-15',
    parent: 'IAB19',
    active: true,
  },
  {
    code: 'IAB19-16',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-17',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-18',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-19',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-2',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-20',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-21',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-22',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-23',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-24',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-25',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-26',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-27',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-28',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-29',
    parent: 'IAB19',
    active: true,
  },
  {
    code: 'IAB19-3',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-30',
    parent: 'IAB19',
    active: true,
  },
  {
    code: 'IAB19-31',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-32',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-33',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-34',
    parent: 'IAB19',
    active: true,
  },
  {
    code: 'IAB19-35',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-36',
    parent: 'IAB19',
    active: true,
  },
  {
    code: 'IAB19-4',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-5',
    parent: 'IAB19',
    active: true,
  },
  {
    code: 'IAB19-6',
    parent: 'IAB19',
    active: true,
  },
  {
    code: 'IAB19-7',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-8',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB19-9',
    parent: 'IAB19',
    active: false,
  },
  {
    code: 'IAB2-1',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-10',
    parent: 'IAB2',
    active: true,
  },
  {
    code: 'IAB2-11',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-12',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-13',
    parent: 'IAB2',
    active: true,
  },
  {
    code: 'IAB2-14',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-15',
    parent: 'IAB2',
    active: true,
  },
  {
    code: 'IAB2-16',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-17',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-18',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-19',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-2',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-20',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-21',
    parent: 'IAB2',
    active: true,
  },
  {
    code: 'IAB2-22',
    parent: 'IAB2',
    active: true,
  },
  {
    code: 'IAB2-23',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-3',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-4',
    parent: 'IAB2',
    active: true,
  },
  {
    code: 'IAB2-5',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-6',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-7',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-8',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB2-9',
    parent: 'IAB2',
    active: false,
  },
  {
    code: 'IAB20-1',
    parent: 'IAB20',
    active: true,
  },
  {
    code: 'IAB20-10',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-11',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-12',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-13',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-14',
    parent: 'IAB20',
    active: true,
  },
  {
    code: 'IAB20-15',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-16',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-17',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-18',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-19',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-2',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-20',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-21',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-22',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-23',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-24',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-25',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-26',
    parent: 'IAB20',
    active: true,
  },
  {
    code: 'IAB20-27',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-3',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-4',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-5',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-6',
    parent: 'IAB20',
    active: true,
  },
  {
    code: 'IAB20-7',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-8',
    parent: 'IAB20',
    active: false,
  },
  {
    code: 'IAB20-9',
    parent: 'IAB20',
    active: true,
  },
  {
    code: 'IAB21',
    parent: null,
    active: true,
  },
  {
    code: 'IAB21-1',
    parent: 'IAB21',
    active: false,
  },
  {
    code: 'IAB21-2',
    parent: 'IAB21',
    active: false,
  },
  {
    code: 'IAB21-3',
    parent: 'IAB21',
    active: false,
  },
  {
    code: 'IAB22',
    parent: null,
    active: true,
  },
  {
    code: 'IAB22-1',
    parent: 'IAB22',
    active: false,
  },
  {
    code: 'IAB22-2',
    parent: 'IAB22',
    active: true,
  },
  {
    code: 'IAB22-3',
    parent: 'IAB22',
    active: false,
  },
  {
    code: 'IAB22-4',
    parent: 'IAB22',
    active: false,
  },
  {
    code: 'IAB23',
    parent: null,
    active: false,
  },
  {
    code: 'IAB23-1',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-10',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-2',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-3',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-4',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-5',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-6',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-7',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-8',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB23-9',
    parent: 'IAB23',
    active: false,
  },
  {
    code: 'IAB24',
    parent: null,
    active: false,
  },
  {
    code: 'IAB25',
    parent: null,
    active: false,
  },
  {
    code: 'IAB25-1',
    parent: 'IAB25',
    active: false,
  },
  {
    code: 'IAB25-2',
    parent: 'IAB25',
    active: false,
  },
  {
    code: 'IAB25-3',
    parent: 'IAB25',
    active: false,
  },
  {
    code: 'IAB25-4',
    parent: 'IAB25',
    active: false,
  },
  {
    code: 'IAB25-5',
    parent: 'IAB25',
    active: false,
  },
  {
    code: 'IAB25-6',
    parent: 'IAB25',
    active: false,
  },
  {
    code: 'IAB25-7',
    parent: 'IAB25',
    active: false,
  },
  {
    code: 'IAB26',
    parent: null,
    active: false,
  },
  {
    code: 'IAB26-1',
    parent: 'IAB26',
    active: false,
  },
  {
    code: 'IAB26-2',
    parent: 'IAB26',
    active: false,
  },
  {
    code: 'IAB26-3',
    parent: 'IAB26',
    active: false,
  },
  {
    code: 'IAB26-4',
    parent: 'IAB26',
    active: false,
  },
  {
    code: 'IAB3-1',
    parent: 'IAB3',
    active: true,
  },
  {
    code: 'IAB3-10',
    parent: 'IAB3',
    active: false,
  },
  {
    code: 'IAB3-11',
    parent: 'IAB3',
    active: true,
  },
  {
    code: 'IAB3-12',
    parent: 'IAB3',
    active: true,
  },
  {
    code: 'IAB3-2',
    parent: 'IAB3',
    active: false,
  },
  {
    code: 'IAB3-3',
    parent: 'IAB3',
    active: true,
  },
  {
    code: 'IAB3-4',
    parent: 'IAB3',
    active: true,
  },
  {
    code: 'IAB3-5',
    parent: 'IAB3',
    active: false,
  },
  {
    code: 'IAB3-6',
    parent: 'IAB3',
    active: false,
  },
  {
    code: 'IAB3-7',
    parent: 'IAB3',
    active: false,
  },
  {
    code: 'IAB3-8',
    parent: 'IAB3',
    active: true,
  },
  {
    code: 'IAB3-9',
    parent: 'IAB3',
    active: true,
  },
  {
    code: 'IAB4-1',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-10',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-11',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-2',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-3',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-4',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-5',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-6',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-7',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-8',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB4-9',
    parent: 'IAB4',
    active: false,
  },
  {
    code: 'IAB5',
    parent: null,
    active: true,
  },
  {
    code: 'IAB5-1',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-10',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-11',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-12',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-13',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-14',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-15',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-2',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-3',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-4',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-5',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-6',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-7',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-8',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB5-9',
    parent: 'IAB5',
    active: false,
  },
  {
    code: 'IAB6-1',
    parent: 'IAB6',
    active: false,
  },
  {
    code: 'IAB6-2',
    parent: 'IAB6',
    active: true,
  },
  {
    code: 'IAB6-3',
    parent: 'IAB6',
    active: true,
  },
  {
    code: 'IAB6-4',
    parent: 'IAB6',
    active: false,
  },
  {
    code: 'IAB6-5',
    parent: 'IAB6',
    active: true,
  },
  {
    code: 'IAB6-6',
    parent: 'IAB6',
    active: true,
  },
  {
    code: 'IAB6-7',
    parent: 'IAB6',
    active: true,
  },
  {
    code: 'IAB6-8',
    parent: 'IAB6',
    active: true,
  },
  {
    code: 'IAB6-9',
    parent: 'IAB6',
    active: true,
  },
  {
    code: 'IAB7-1',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-10',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-11',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-12',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-13',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-14',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-15',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-16',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-17',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-18',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-19',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-2',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-20',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-21',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-22',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-23',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-24',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-25',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-26',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-27',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-28',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-29',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-3',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-30',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-31',
    parent: 'IAB7',
    active: true,
  },
  {
    code: 'IAB7-32',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-33',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-34',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-35',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-36',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-37',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-38',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-39',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-4',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-40',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-41',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-42',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-43',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-44',
    parent: 'IAB7',
    active: true,
  },
  {
    code: 'IAB7-45',
    parent: 'IAB7',
    active: true,
  },
  {
    code: 'IAB7-5',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-6',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-7',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-8',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB7-9',
    parent: 'IAB7',
    active: false,
  },
  {
    code: 'IAB8-1',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-10',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-11',
    parent: 'IAB8',
    active: false,
  },
  {
    code: 'IAB8-12',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-13',
    parent: 'IAB8',
    active: false,
  },
  {
    code: 'IAB8-14',
    parent: 'IAB8',
    active: false,
  },
  {
    code: 'IAB8-15',
    parent: 'IAB8',
    active: false,
  },
  {
    code: 'IAB8-16',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-17',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-18',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-2',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-3',
    parent: 'IAB8',
    active: false,
  },
  {
    code: 'IAB8-4',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-5',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-6',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-7',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-8',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB8-9',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB9-1',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-10',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-11',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-12',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-13',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-14',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-15',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-16',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-17',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-18',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-19',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-20',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-21',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-22',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-24',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-25',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-26',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-27',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-28',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-29',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-3',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-31',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB9-4',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-5',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-6',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-7',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-8',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB9-9',
    parent: 'IAB9',
    active: false,
  },
  {
    code: 'IAB1-X',
    parent: 'IAB1',
    active: true,
  },
  {
    code: 'IAB2-X',
    parent: 'IAB2',
    active: true,
  },
  {
    code: 'IAB3-X',
    parent: 'IAB3',
    active: true,
  },
  {
    code: 'IAB4-X',
    parent: 'IAB4',
    active: true,
  },
  {
    code: 'IAB5-X',
    parent: 'IAB5',
    active: true,
  },
  {
    code: 'IAB6-X',
    parent: 'IAB6',
    active: true,
  },
  {
    code: 'IAB7-X',
    parent: 'IAB7',
    active: true,
  },
  {
    code: 'IAB8-X',
    parent: 'IAB8',
    active: true,
  },
  {
    code: 'IAB9-X',
    parent: 'IAB9',
    active: true,
  },
  {
    code: 'IAB10-X',
    parent: 'IAB10',
    active: true,
  },
  {
    code: 'IAB11-X',
    parent: 'IAB11',
    active: true,
  },
  {
    code: 'IAB12-X',
    parent: 'IAB12',
    active: true,
  },
  {
    code: 'IAB13-X',
    parent: 'IAB13',
    active: true,
  },
  {
    code: 'IAB14-X',
    parent: 'IAB14',
    active: true,
  },
  {
    code: 'IAB15-X',
    parent: 'IAB15',
    active: true,
  },
  {
    code: 'IAB16-X',
    parent: 'IAB16',
    active: true,
  },
  {
    code: 'IAB17-X',
    parent: 'IAB17',
    active: true,
  },
  {
    code: 'IAB18-X',
    parent: 'IAB18',
    active: true,
  },
  {
    code: 'IAB19-X',
    parent: 'IAB19',
    active: true,
  },
  {
    code: 'IAB20-X',
    parent: 'IAB20',
    active: true,
  },
  {
    code: 'IAB21-X',
    parent: 'IAB21',
    active: true,
  },
  {
    code: 'IAB22-X',
    parent: 'IAB22',
    active: true,
  },
  {
    code: 'IAB23-X',
    parent: 'IAB23',
    active: true,
  },
  {
    code: 'IAB24-X',
    parent: 'IAB24',
    active: true,
  },
  {
    code: 'IAB25-X',
    parent: 'IAB25',
    active: true,
  },
  {
    code: 'IAB26-X',
    parent: 'IAB26',
    active: true,
  },
];

export default categories;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { ContentGrid, ContentGridItem } from 'layout/ContentGrid';
import LoadingState from 'components/LoadingState';
import CampaignCard from 'components/campaigns/CampaignCard';

import { supportedStatuses } from 'utils/campaign';
import { useMany as useCampaigns } from '../data/campaignHooks';

const useStyles = makeStyles({
  contentGrid: {
    overflow: 'visible',
  },
});

const CampaignsGrid = () => {
  const classes = useStyles();
  const { data: { campaigns = [] } = {}, loading } = useCampaigns({
    campaignsFilter: 'byActive',
  });

  const supportedCampaigns = campaigns.filter((campaign) =>
    supportedStatuses.includes(campaign.status),
  );

  return (
    <Box>
      {loading && <LoadingState />}
      {!loading && (
        <ContentGrid
          columnWidths={[1024, 1600, 1900, 2200, 2560]}
          className={classes.contentGrid}
        >
          {supportedCampaigns.map((campaign) => (
            <ContentGridItem key={campaign.id}>
              <CampaignCard campaign={campaign} />
            </ContentGridItem>
          ))}
        </ContentGrid>
      )}
    </Box>
  );
};

export default CampaignsGrid;

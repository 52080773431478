import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ChannelSelect from 'components/ChannelSelect';
import ChannelAvatar from 'components/avatars/ChannelAvatar';

import { LocationLabel, GenderLabel, UserPropType } from 'utils/user';
import { ChannelPropType } from 'utils/channel';
import { ClientReviewStatusPropType } from 'utils/clientReviewStatuses';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: theme.palette.secondary.light,
  },
  info: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginLeft: theme.spacing(2),
  },
  personal: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: theme.spacing(2),
    width: theme.typography.pxToRem(170),
  },
  avatarTooltip: {
    top: '-55px !important',
  },
}));

const PrivateNetworkApplicationCardHeader = ({
  user,
  channels,
  reviewStatus,
  selectedChannel,
  onSelectChannel,
  hideSelection,
  hideChannelName,
  className,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Box p={2} className={clsx(classes.root, className)} {...otherProps}>
      <Box display="flex">
        <ChannelAvatar
          classes={{
            tooltip: classes.avatarTooltip,
          }}
          channel={selectedChannel}
          user={user}
        />
        <Box className={classes.info}>
          <Box className={classes.personal}>
            <Typography>
              {hideChannelName ? user.firstname : selectedChannel.name}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <GenderLabel user={user} />
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <LocationLabel user={user} />
            </Typography>
          </Box>
          <Divider />
        </Box>
      </Box>
      <ChannelSelect
        channels={channels}
        selectedChannel={selectedChannel}
        onSelectChannel={onSelectChannel}
        hideSelection={hideSelection}
      />
    </Box>
  );
};

PrivateNetworkApplicationCardHeader.propTypes = {
  selectedChannel: ChannelPropType.isRequired,
  channels: PropTypes.arrayOf(ChannelPropType).isRequired,
  reviewStatus: ClientReviewStatusPropType,
  onSelectChannel: PropTypes.func.isRequired,
  user: UserPropType.isRequired,
  hideSelection: PropTypes.bool,
  hideChannelName: PropTypes.bool,
  className: PropTypes.string,
};

PrivateNetworkApplicationCardHeader.defaultProps = {
  hideSelection: false,
  hideChannelName: false,
  className: '',
  reviewStatus: 'pending',
};

export default PrivateNetworkApplicationCardHeader;

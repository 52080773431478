import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import countries from 'i18n-iso-countries';

import TagsInput from 'components/TagsInput';
import CountryLabel from 'components/CountryLabel';

const CountriesFilter = ({
  label,
  value,
  onChange,
  supportedCountries,
  ...otherProps
}) => {
  const intl = useIntl();

  const handleChange = (event, newValues) => {
    onChange(newValues);
  };

  const filterOptions = React.useCallback(
    (options, { inputValue }) => {
      const filterFn = (countryCode) => {
        const fullName = countries.getName(countryCode, intl.locale);
        return (
          fullName && fullName.toLowerCase().includes(inputValue.toLowerCase())
        );
      };
      return options.filter(filterFn);
    },
    [countries, intl.locale],
  );

  return (
    <TagsInput
      value={value}
      options={supportedCountries}
      renderOption={(countryCode) => <CountryLabel countryCode={countryCode} />}
      getTagLabel={(countryCode) => (
        <CountryLabel countryCode={countryCode} short />
      )}
      onChange={handleChange}
      label={label}
      filterOptions={filterOptions}
      {...otherProps}
    />
  );
};

CountriesFilter.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  supportedCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};
CountriesFilter.defaultProps = {
  value: [],
};

export default CountriesFilter;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import PlatformIcon from 'icons/Platform';
import { PlatformPropType, ChannelPropType } from 'utils/channel';

import PlatformLinkIcon from './PlatformLinkIcon';
import PlatformAvatar from './PlatformAvatar';

const PlatformButton = ({ platform, selected, onClick }) => {
  const theme = useTheme();

  if (selected) {
    return (
      <PlatformAvatar
        platform={platform}
        color={theme.palette.secondary.main}
      />
    );
  }

  return (
    <IconButton onClick={onClick}>
      <PlatformIcon platform={platform} color={theme.palette.secondary.main} />
    </IconButton>
  );
};

PlatformButton.propTypes = {
  platform: PlatformPropType.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PlatformButton.defaultProps = {
  selected: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
}));

const ChannelSelect = ({
  channels,
  selectedChannel,
  onSelectChannel,
  hideSelection,
}) => {
  const classes = useStyles();

  if (!onSelectChannel) {
    return (
      <Box className={classes.root}>
        {channels.map((channel) => (
          <PlatformLinkIcon
            key={channel.id}
            platform={channel.platform}
            url={channel.data.url || ''}
            selected={selectedChannel?.id === channel?.id}
          />
        ))}
      </Box>
    );
  }

  const handleSelect = (channelId) => {
    if (selectedChannel.id !== channelId || hideSelection) {
      onSelectChannel(channelId);
    }
  };

  return (
    <Box className={classes.root}>
      {channels.map((channel) => (
        <PlatformButton
          key={channel.id}
          platform={channel.platform}
          selected={!hideSelection && selectedChannel.id === channel.id}
          onClick={() => handleSelect(channel.id)}
        />
      ))}
    </Box>
  );
};

ChannelSelect.propTypes = {
  channels: PropTypes.arrayOf(ChannelPropType).isRequired,
  selectedChannel: ChannelPropType.isRequired,
  onSelectChannel: PropTypes.func,
  hideSelection: PropTypes.bool,
};
ChannelSelect.defaultProps = {
  hideSelection: false,
};

export default ChannelSelect;

import countries from 'i18n-iso-countries';

export const browserLocale =
  typeof navigator !== 'undefined' && navigator.language.split(/[-_]/)[0];
export const defaultLocale = 'de';

export const supportedLocales = ['de', 'en'];

export const getStrings = (locale) => {
  if (supportedLocales.includes(locale)) {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`./${locale}.json`);
  }

  // eslint-disable-next-line import/no-dynamic-require, global-require
  return require(`./${defaultLocale}.json`);
};

supportedLocales.forEach((locale) => {
  // eslint-disable-next-line import/no-dynamic-require, global-require
  countries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`));
});

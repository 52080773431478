import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import assign from 'lodash/assign';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import ApplicationCard from 'components/applications/card/ApplicationCard';
import ApplicationReviewButton from 'components/applications/ApplicationReviewButton';
import InspectorDrawer from 'components/inspector/InspectorDrawer';

import { ApplicationPropType } from 'utils/application';
import { CampaignPropType } from 'utils/campaign';

export const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  reviewButton: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: (drawerWidth - 275) / 2,
  },
}));

const ApplicationInspector = ({
  campaign,
  application,
  onUpdateClientReview,
  onUpdateChannelTags,
  onUpdateChannelNotes,
  disableUpdateClientReview,
  loading,
  onClose,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const applicationChannel = assign(
    {},
    application?.crmChannel,
    application?.channel,
  );
  const clientApplicationReview = application?.clientApplicationReview || {};

  const handleClose = () => {
    onClose();
  };

  return (
    <InspectorDrawer
      isOpen={!!application}
      width={drawerWidth}
      onClose={handleClose}
      {...otherProps}
    >
      {application && (
        <Box>
          <ApplicationCard
            campaign={campaign}
            application={application}
            selectedChannel={applicationChannel}
            channels={[applicationChannel]}
            loadingChannels={false}
            updateChannelNotes={onUpdateChannelNotes}
            updateChannelTags={onUpdateChannelTags}
            loading={loading}
            height="100%"
          />
          <ApplicationReviewButton
            className={classes.reviewButton}
            status={clientApplicationReview.status}
            note={clientApplicationReview.note}
            description={intl.formatMessage({
              id: 'components.ApplicationReviewButton.dialog.info_MD',
              defaultMessage:
                'Accept or Reject this Influencer’s application to the campaign. If you reject an Influencer, please explain briefly the reason for your decision.',
            })}
            title={intl.formatMessage({
              id: 'components.ApplicationReviewButton.dialog.title.reviewApplication',
              defaultMessage: 'Review Application',
            })}
            onUpdate={onUpdateClientReview}
            disabled={disableUpdateClientReview}
            disabledTooltip={
              <FormattedMessage
                id="components.ApplicationReviewButton.reviewUpdateDisabled.tooltip"
                defaultMessage="Influencer selection has been confirmed and can’t be changed anymore."
              />
            }
          />
        </Box>
      )}
    </InspectorDrawer>
  );
};

ApplicationInspector.propTypes = {
  onUpdateClientReview: PropTypes.func.isRequired,
  onUpdateChannelNotes: PropTypes.func.isRequired,
  onUpdateChannelTags: PropTypes.func.isRequired,
  disableUpdateClientReview: PropTypes.bool,
  application: ApplicationPropType,
  campaign: CampaignPropType,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
ApplicationInspector.defaultProps = {
  application: null,
  disableUpdateClientReview: false,
  loading: false,
};

export default ApplicationInspector;

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const PageTitle = ({ children, icon }) => (
  <Box display="flex" alignItems="center">
    {icon && <Box mr={1}>{icon}</Box>}
    <Typography variant="h2" color="textPrimary">
      {children}
    </Typography>
  </Box>
);

PageTitle.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
};

PageTitle.defaultProps = {
  icon: null,
};

export default PageTitle;

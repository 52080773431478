import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import fileSaver from 'file-saver';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import CardMedia from 'components/CardMedia';
import { useGA } from 'utils/gtm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.light,
  },
  card: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.secondary.light,
  },
  media: {
    height: theme.typography.pxToRem(180),
    width: 'auto',
  },
}));

const InfluencerAssetInspectorMedia = ({ id, src, type }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { trackEvent } = useGA();

  const handleDownload = React.useCallback(
    (e) => {
      e.stopPropagation();

      fileSaver.saveAs(src);

      trackEvent({
        action: 'influencer_asset_inspector_download_triggered',
        label: `Influencer Asset Card: ${id}`,
      });
    },
    [trackEvent, id, src],
  );

  return (
    <Box className={classes.root}>
      <Card className={classes.card} elevation={2}>
        <CardMedia
          src={src}
          type={type}
          className={classes.media}
          playable={false}
          clickable={false}
        />
      </Card>
      <Button
        startIcon={<Icons.Download />}
        color="primary"
        onClick={handleDownload}
      >
        {intl.formatMessage({
          id: 'components.InfluencerAssetInspector.labels.download',
          defaultMessage: 'Download',
        })}
      </Button>
    </Box>
  );
};

InfluencerAssetInspectorMedia.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['image', 'video']).isRequired,
};

export default InfluencerAssetInspectorMedia;

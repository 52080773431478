import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import clsx from 'clsx';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import { FormattedMessage } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Tags from 'components/Tags';
import TagsInput from 'components/TagsInput';

const useStyles = makeStyles(() => ({
  tagsInputContainer: {
    paddingBottom: 0,
  },
  clickable: {
    cursor: 'pointer',
  },
}));

const TagsInputForm = ({
  loading,
  formData,
  tagsOptions,
  getTagOptionLabel,
  onSubmit,
  allowNew,
  label,
  header,
}) => {
  let inputRef;
  const classes = useStyles();
  const [editEnabled, setEditEnabled] = React.useState(false);

  const showEdit = () => setEditEnabled(true);
  const hideEdit = () => setEditEnabled(false);

  const { handleSubmit, reset, getValues, formState, control } = useForm({
    defaultValues: formData,
  });

  const formValues = getValues();

  const submitForm = (data) => {
    onSubmit(pick(data, 'tags'));
    reset(data);
    hideEdit();
  };

  const resetToDefaultValues = () => {
    reset();
    hideEdit();
  };

  React.useEffect(() => {
    reset(formData);
    hideEdit();
  }, [formData.id]);

  React.useEffect(() => {
    if (editEnabled) {
      inputRef?.focus();
    }
  }, [editEnabled]);

  return (
    <Box component="form" width="100%" onSubmit={handleSubmit(submitForm)}>
      <Typography variant="overline" color="textSecondary">
        {header}
      </Typography>
      <Box
        className={clsx(classes.tagsInputContainer, {
          [classes.clickable]: !editEnabled,
        })}
        onClick={!editEnabled ? showEdit : noop}
      >
        {(loading || editEnabled) && (
          <Box mt={2}>
            <Controller
              name="tags"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TagsInput
                  options={tagsOptions}
                  getTagLabel={getTagOptionLabel}
                  renderOption={getTagOptionLabel}
                  value={value || []}
                  label={label}
                  allowNew={allowNew}
                  onChange={(event, newTags) => onChange(newTags)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={label}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={(input) => {
                        inputRef = input;
                      }}
                    />
                  )}
                />
              )}
            />
          </Box>
        )}
        {!loading && !editEnabled && (
          <Box>
            {isEmpty(formValues.tags) && (
              <Box mb={-3} mt={1}>
                <Typography>
                  <FormattedMessage
                    id="components.TagsInputForm.emptyLabel"
                    defaultMessage="No Tags"
                  />
                </Typography>
              </Box>
            )}
            <Tags
              classes={{ chip: classes.clickable }}
              wrap
              mt={1}
              variant="default"
              tags={formValues.tags}
              getTagLabel={getTagOptionLabel}
              className={clsx({
                [classes.clickable]: !editEnabled,
              })}
            />
            <Typography variant="body2" color="textSecondary">
              {formState.submitCount === 0 && (
                <FormattedMessage
                  id="components.TagsInputForm.editLabel"
                  defaultMessage="Click to edit"
                />
              )}
              {formState.submitCount > 0 && (
                <FormattedMessage
                  id="components.TagsInputForm.savedLabel"
                  defaultMessage="Saved"
                />
              )}
            </Typography>
          </Box>
        )}
      </Box>
      {editEnabled && (
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" type="reset" onClick={resetToDefaultValues}>
            <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
          </Button>
          <Button disabled={!formState.isDirty} color="primary" type="submit">
            <FormattedMessage id="general.save" defaultMessage="Save" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

TagsInputForm.propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  tagsOptions: PropTypes.arrayOf(PropTypes.string),
  getTagOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  allowNew: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.string,
  header: PropTypes.string,
};

TagsInputForm.defaultProps = {
  tagsOptions: [],
  allowNew: false,
  loading: false,
};

export default TagsInputForm;

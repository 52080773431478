import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import TagsInput from 'components/TagsInput';

const useStyles = makeStyles((theme) => ({
  tagLabel: {
    maxWidth: theme.typography.pxToRem(220),
  },
}));

const CampaignsFilter = ({ options, value, onChange, ...otherProps }) => {
  const intl = useIntl();
  const classes = useStyles();

  const allIds = React.useMemo(() => options.map((c) => c.id), [options]);

  const filterOptions = React.useCallback(
    (_, { inputValue }) =>
      options
        .filter((c) => c.name.toLowerCase().includes(inputValue.toLowerCase()))
        .map((c) => c.id),
    [options],
  );

  const handleChange = (event, newIds) => {
    onChange({
      ids: newIds,
    });
  };

  const getCampaignLabel = React.useCallback(
    (id) => options.find((c) => c.id === id).name,
    [options],
  );

  return (
    <TagsInput
      value={(options.length > 0 && value?.ids) || []}
      options={allIds}
      getTagLabel={getCampaignLabel}
      renderOption={getCampaignLabel}
      onChange={handleChange}
      label={intl.formatMessage({
        id: 'components.CampaignsFilter.label',
        defaultMessage: 'Campaigns',
      })}
      tagClasses={{ root: classes.tagLabel }}
      maxTags={5}
      maxTagsMessage={intl.formatMessage({
        id: 'components.CampaignsFilter.maxTagsMessage',
        defaultMessage:
          'Select up to 5 campaigns. Remove a campaign to choose another.',
      })}
      filterOptions={filterOptions}
      disableCloseOnSelect
      showTooltip
      {...otherProps}
    />
  );
};

CampaignsFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
};

CampaignsFilter.defaultProps = {
  options: [],
  value: {},
};

export default CampaignsFilter;

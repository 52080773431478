import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import PreviewMarkdown from './PreviewMarkdown';

const DataShape = PropTypes.shape({
  headline: PropTypes.string,
  mission: PropTypes.string,
  goal: PropTypes.string,
});

const InsetContainer = ({ children }) => (
  <Box display="flex">
    <Box display="flex" mr={2}>
      <Divider orientation="vertical" flexItem />
    </Box>
    {children}
  </Box>
);

InsetContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}));

const CampaignPreview = ({ data }) => {
  const classes = useStyles();
  const intl = useIntl();

  const placeholders = {
    headline: intl.formatMessage({
      id: 'scenes.campaigns.form.headline.label',
      defaultMessage: 'Campaign Headline',
    }),
    mission: intl.formatMessage({
      id: 'scenes.campaigns.form.mission.placeholder',
      defaultMessage:
        'EXAMPLE: Make your followers aware of the cooperation with our brand…',
    }),
    goal: intl.formatMessage({
      id: 'scenes.campaigns.form.goal.placeholder',
      defaultMessage:
        'EXAMPLE: Introduce your followers to our product and highlight its features…',
    }),
  };

  const { headline, mission, goal } = data;

  return (
    <Box className={classes.root}>
      <Typography variant="h2">{headline || placeholders.headline}</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {intl.formatMessage({
          id: 'scenes.campaigns.preview.mission.title',
          defaultMessage: 'Mission',
        })}
      </Typography>
      <InsetContainer>
        <PreviewMarkdown source={mission || placeholders.mission} />
      </InsetContainer>
      <Typography variant="subtitle1" color="textSecondary">
        {intl.formatMessage({
          id: 'scenes.campaigns.preview.goal.title',
          defaultMessage: 'Goal',
        })}
      </Typography>
      <InsetContainer>
        <PreviewMarkdown source={goal || placeholders.goal} />
      </InsetContainer>
    </Box>
  );
};

CampaignPreview.propTypes = {
  data: DataShape,
};

CampaignPreview.defaultProps = {
  data: {},
};

export default CampaignPreview;

import React from 'react';
import { useIntl } from 'react-intl';

import ButtonSwitch from 'components/ButtonSwitch';

const GridTypeFilters = (props) => {
  const intl = useIntl();

  const filters = React.useMemo(
    () => [
      {
        value: 'all',
        label: intl.formatMessage({
          id: 'scenes.influencerAssets.grid.filter.all',
          defaultMessage: 'All',
        }),
      },
      {
        value: 'image',
        label: intl.formatMessage({
          id: 'scenes.influencerAssets.grid.filter.images',
          defaultMessage: 'Images',
        }),
      },
      {
        value: 'video',
        label: intl.formatMessage({
          id: 'scenes.influencerAssets.grid.filter.videos',
          defaultMessage: 'Videos',
        }),
      },
    ],
    [intl],
  );

  return <ButtonSwitch filters={filters} {...props} />;
};

GridTypeFilters.propTypes = {};

GridTypeFilters.defaultProps = {};

export default GridTypeFilters;

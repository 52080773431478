import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TagsInput from 'components/TagsInput';

const ClientChannelTagsFilter = ({
  client,
  value,
  options,
  onChange,
  ...otherProps
}) => {
  const intl = useIntl();
  const filterOptions = React.useCallback(
    (options, { inputValue }) =>
      options.filter((tagOption) =>
        tagOption.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    [],
  );

  const handleChange = (event, newTags) => {
    onChange({
      clientId: client.id,
      values: newTags,
    });
  };

  const noOptionsText =
    options.length === 0
      ? intl.formatMessage(
          {
            id: 'components.ClientChannelTagsFilter.noOptionsText',
            defaultMessage:
              'No {client} tags are present. To add tags to a channel, select it in the results table and edit it on the sidebar inspector.',
          },
          { client: client?.name },
        )
      : undefined;

  return (
    <TagsInput
      value={value?.values || []}
      options={options}
      onChange={handleChange}
      label={`${client.name} Tags`}
      maxTags={5}
      maxTagsMessage={intl.formatMessage({
        id: 'components.ClientChannelTagsFilter.maxTagsMessage',
        defaultMessage: 'Select up to 5 tags. Remove a tag to choose another.',
      })}
      noOptionsText={noOptionsText}
      filterOptions={filterOptions}
      disableCloseOnSelect
      {...otherProps}
    />
  );
};

ClientChannelTagsFilter.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.string),
    clientId: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

ClientChannelTagsFilter.defaultProps = {
  value: {},
  options: [],
};

export default ClientChannelTagsFilter;

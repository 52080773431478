import React from 'react';

import { PlatformPropType } from 'utils/channel';
import { useClient } from 'utils/client';

import Instagram from './instagram/TableHead';
import TikTok from './tiktok/TableHead';
import Pinterest from './pinterest/TableHead';
import Youtube from './youtube/TableHead';

const componentsByPlatform = {
  instagram: Instagram,
  tiktok: TikTok,
  pinterest: Pinterest,
  youtube: Youtube,
};

const ChannelsTableHead = React.memo(({ platform, ...otherProps }) => {
  const { client } = useClient();

  const Component = componentsByPlatform[platform];

  return <Component {...otherProps} clientName={client?.name} />;
});

ChannelsTableHead.propTypes = {
  platform: PlatformPropType.isRequired,
};

ChannelsTableHead.defaultProps = {};

export default ChannelsTableHead;

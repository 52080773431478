import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { reachFilterSteps } from 'utils/kpi';
import { Feature, useFeatures } from 'utils/features';
import { useClient } from 'utils/client';
import { PlatformPropType } from 'utils/channel';

import ReachFilter from 'components/filters/ReachFilter';
import GenderFilter from 'components/filters/GenderFilter';
import CountriesFilter from 'components/filters/CountriesFilter';
import CategoriesFilter from 'components/filters/CategoriesFilter';
import CampaignsFilter from 'components/filters/CampaignsFilter';
import SearchFilter from 'components/filters/SearchFilter';
import AudienceGendersFilter from 'components/filters/AudienceGendersFilter';
import ClientChannelTagsFilter from 'components/filters/ClientChannelTagsFilter';
import CollaborationRatingsFilter from 'components/filters/CollaborationRatingsFilter';
import CollaboratorFilter from 'components/filters/CollaboratorFilter';
import FiltersContainer, {
  useStyles as useFilterStyles,
} from 'components/filters/FiltersContainer';

import PlatformSelect from './PlatformSelect';
import { drawerWidth as channelInspectorDrawerWidth } from './ChannelInspector';

const supportedPlatforms = [
  {
    label: 'Instagram',
    value: 'instagram',
  },
  {
    label: 'TikTok',
    value: 'tiktok',
  },
  {
    label: 'Pinterest',
    value: 'pinterest',
  },
  {
    label: 'Youtube',
    value: 'youtube',
  },
];

const reachFilterLabelsStrings = defineMessages({
  instagram: {
    id: 'scenes.influencers.filter.reach.instagram',
    defaultMessage: 'Followers',
  },
  pinterest: {
    id: 'scenes.influencers.filter.reach.pinterest',
    defaultMessage: 'Impressions',
  },
  tiktok: {
    id: 'scenes.influencers.filter.reach.tiktok',
    defaultMessage: 'Followers',
  },
  youtube: {
    id: 'scenes.influencers.filter.reach.youtube',
    defaultMessage: 'Subscribers',
  },
});

const InfluencersSearchFilters = ({
  platform,
  onChangePlatform,
  filters,
  onFilter,
  supportedCountries,
  clientCampaigns,
  isInspected,
}) => {
  const filterClasses = useFilterStyles();
  const intl = useIntl();
  const { client, tags: clientTags } = useClient();
  const { isFeatureAvailable } = useFeatures();

  const createFilterBy = (key) => (value) => {
    onFilter({
      key,
      value,
    });
  };

  const extendedReachFilterSteps = React.useMemo(() => {
    if (isFeatureAvailable('collaboratorFilter')) {
      return [
        {
          value: 0,
          label: '0',
        },
        ...reachFilterSteps,
      ];
    }

    return reachFilterSteps;
  }, [reachFilterSteps, isFeatureAvailable]);

  const campaignsFilterOptions = React.useMemo(
    () =>
      clientCampaigns.map((campaign) => ({
        id: campaign.id,
        name: campaign.details.headline,
      })),
    [clientCampaigns],
  );

  return (
    <FiltersContainer
      isInspected={isInspected}
      inspectorWidth={channelInspectorDrawerWidth}
    >
      <PlatformSelect
        className={filterClasses.filter}
        platforms={supportedPlatforms}
        value={platform}
        onChange={onChangePlatform}
      />
      <SearchFilter
        className={filterClasses.filter}
        onChange={createFilterBy('name')}
        value={filters.name}
        label={intl.formatMessage({
          id: 'components.SearchFilter.channelName.label',
          defaultMessage: '@channelname',
        })}
      />
      <ReachFilter
        label={intl.formatMessage(reachFilterLabelsStrings[platform])}
        className={clsx(filterClasses.filter, filterClasses.reachFilter)}
        steps={extendedReachFilterSteps}
        value={filters.reach}
        onChange={createFilterBy('reach')}
        valueLabelDisplay="on"
      />
      <GenderFilter
        className={filterClasses.filter}
        onChange={createFilterBy('salutation')}
        value={filters.salutation}
      />
      <Feature feature="collaboratorFilter">
        <CollaboratorFilter
          className={clsx(filterClasses.filter, filterClasses.noMinWidth)}
          onChange={createFilterBy('collaborator')}
          value={filters.collaborator}
        />
      </Feature>
      <CountriesFilter
        label={intl.formatMessage({
          id: 'scenes.influencers.filter.userCountries',
          defaultMessage: "Influencer's Location",
        })}
        className={filterClasses.filter}
        onChange={createFilterBy('countries')}
        value={filters.countries}
        supportedCountries={supportedCountries}
      />
      {platform === 'instagram' && (
        <Feature feature="topCountriesFilter">
          <CountriesFilter
            label={intl.formatMessage({
              id: 'scenes.influencers.filter.topCountries',
              defaultMessage: 'Audience Top Country',
            })}
            className={filterClasses.filter}
            onChange={createFilterBy('topCountries')}
            value={filters.topCountries}
            supportedCountries={supportedCountries}
          />
        </Feature>
      )}
      {client && (
        <Feature feature="influencerFinderTags">
          <ClientChannelTagsFilter
            className={filterClasses.filter}
            client={client}
            onChange={createFilterBy('tags')}
            value={filters.tags}
            options={clientTags}
          />
        </Feature>
      )}
      <Feature feature="collaboratorFilter">
        <CampaignsFilter
          className={filterClasses.filter}
          onChange={createFilterBy('campaigns')}
          value={filters.campaigns}
          options={campaignsFilterOptions}
        />
      </Feature>
      <CategoriesFilter
        className={filterClasses.filter}
        value={filters.categories}
        onChange={createFilterBy('categories')}
      />
      {platform === 'instagram' && (
        <Feature feature="audienceGendersFilter">
          <AudienceGendersFilter
            className={filterClasses.filter}
            value={filters.audienceGenders}
            onChange={createFilterBy('audienceGenders')}
          />
        </Feature>
      )}
      <CollaborationRatingsFilter
        className={filterClasses.filter}
        value={filters.collaborationRatings}
        onChange={createFilterBy('collaborationRatings')}
      />
    </FiltersContainer>
  );
};

InfluencersSearchFilters.propTypes = {
  platform: PlatformPropType.isRequired,
  onChangePlatform: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
    topCountries: PropTypes.arrayOf(PropTypes.string),
    salutation: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    reach: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
    audienceGenders: PropTypes.arrayOf(PropTypes.string),
    collaborationRatings: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.shape({
      clientId: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
    campaigns: PropTypes.shape({
      ids: PropTypes.arrayOf(PropTypes.string),
    }),
    collaborator: PropTypes.string,
  }),
  onFilter: PropTypes.func.isRequired,
  supportedCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
  clientCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      details: PropTypes.shape({
        headline: PropTypes.string.isRequired,
      }),
    }),
  ),
  isInspected: PropTypes.bool,
};
InfluencersSearchFilters.defaultProps = {
  filters: {
    name: '',
    countries: [],
    topCountries: [],
    categories: [],
    reach: {},
    tags: {},
    campaigns: [],
    audienceGenders: [],
    collaborationRatings: [],
    collaborator: true,
  },
  isInspected: false,
};

export default InfluencersSearchFilters;

import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {
  useQueryParam,
  StringParam,
  ArrayParam,
  withDefault,
} from 'utils/routing';
import { ContentGrid, ContentGridItem } from 'layout/ContentGrid';
import LoadingState from 'components/LoadingState';
import ErrorPage404 from 'scenes/error/404';

import ArticlesControls from './ArticlesControls';
import InsiderArticleCard from '../InsiderArticleCard';
import InsiderArticleCardDetail from '../InsiderArticleCardDetail';
import InsiderPage from '../InsiderPage';

const QUERY_MANY = gql`
  query Articles {
    insiderArticles {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      publishedAt
      publishedBy
      title
      body
      slug
      coverImage
      categories
    }
  }
`;

const QUERY_ONE = gql`
  query Article($slug: String!) {
    insiderArticle(slug: $slug) {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      publishedAt
      publishedBy
      title
      body
      slug
      coverImage
      categories
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  contentGrid: {
    overflow: 'visible',
  },
  searchField: {
    fontWeight: 'bold',
    '& input': {
      color: theme.palette.primary.main,
    },
  },
}));

const ArticlesPage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { slug } = useParams();
  const history = useHistory();
  const [querySearchTerm, setQuerySearchTerm] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
  );
  const [queryCategories, setQueryCategories] = useQueryParam(
    'categories',
    withDefault(ArrayParam, []),
  );

  const [selectedCategories, setSelectedCategories] = React.useState(
    queryCategories || [],
  );
  const [searchTerm, setSearchTerm] = React.useState(querySearchTerm);
  const [selectedSlug, setSelectedSlug] = React.useState(slug);

  const {
    data: allArticlesData,
    error,
    loading: loadingArticles,
  } = useQuery(QUERY_MANY);
  const {
    refetch: refetchSelectedArticle,
    data: selectedArticleData,
    loading: loadingSelectedArticle,
  } = useQuery(QUERY_ONE, {
    variables: { slug: selectedSlug },
    skip: !selectedSlug,
  });

  const selectedArticle = selectedArticleData?.insiderArticle;

  if (error) {
    throw new Error(error);
  }

  const displayArticles = React.useMemo(() => {
    const allArticles = allArticlesData?.insiderArticles || [];

    if (!searchTerm && isEmpty(selectedCategories)) {
      return allArticles;
    }

    if (searchTerm) {
      return allArticles.filter((article) => {
        const cleanSearchTerm = searchTerm.toLowerCase().trim();
        const titleMatch = article.title
          .toLowerCase()
          .includes(cleanSearchTerm);
        const bodyMatch = article.body.toLowerCase().includes(cleanSearchTerm);
        const categoriesMatch = article.categories.some((category) =>
          category.toLowerCase().includes(cleanSearchTerm),
        );

        return titleMatch || bodyMatch || categoriesMatch;
      });
    }

    return allArticles.filter((article) =>
      selectedCategories.some((selectedCategory) =>
        article.categories.includes(selectedCategory),
      ),
    );
  }, [searchTerm, selectedCategories, allArticlesData?.insiderArticles]);

  const allCategories = React.useMemo(() => {
    const allArticles = allArticlesData?.insiderArticles || [];

    return uniq(allArticles.map((article) => article.categories).flat());
  }, [allArticlesData?.insiderArticles]);

  React.useEffect(() => {
    setSelectedSlug(slug);
  }, [slug]);

  React.useEffect(() => {
    if (selectedSlug) {
      refetchSelectedArticle({ slug: selectedSlug });
    }
  }, [selectedSlug]);

  const handleClose = () => {
    setSelectedSlug(null);
    history.push('/insider/articles');
  };

  const resetSearch = () => {
    setSearchTerm('');
    setQuerySearchTerm(undefined);
  };

  const resetCategories = () => {
    setSelectedCategories([]);
    setQueryCategories([]);
  };

  const handleSearch = (newSearchTerm) => {
    resetCategories();

    setSearchTerm(newSearchTerm);
    if (!newSearchTerm) {
      setQuerySearchTerm(undefined);
    } else {
      setQuerySearchTerm(newSearchTerm);
    }
  };

  const handleSelectCategory = (newSelectedCategories) => {
    resetSearch();

    setSelectedCategories(newSelectedCategories);
    setQueryCategories(newSelectedCategories);
  };

  if (selectedSlug && !loadingSelectedArticle && !selectedArticle) {
    return <ErrorPage404 />;
  }

  const title = intl.formatMessage({
    id: 'scenes.insider.articles.title',
    defaultMessage: 'Bring your marketing to a new level',
  });
  const subtitle = intl.formatMessage({
    id: 'scenes.insider.articles.subtitle',
    defaultMessage: 'let us tell you more details about influencer marketing',
  });

  return (
    <InsiderPage
      title={title}
      pageTitle={selectedArticle?.title}
      subtitle={subtitle}
    >
      <InsiderArticleCardDetail
        loading={loadingSelectedArticle}
        open={Boolean(selectedSlug || selectedArticle)}
        article={selectedArticle}
        onClose={handleClose}
      />
      {loadingArticles && <LoadingState height="40vh" />}
      {!loadingArticles && (
        <>
          <ArticlesControls
            searchTerm={searchTerm}
            onSearch={handleSearch}
            categories={allCategories}
            selectedCategories={selectedCategories}
            onSelectCategory={handleSelectCategory}
            numberOfResults={displayArticles.length}
            mb={1}
          />
          {!isEmpty(displayArticles) && (
            <ContentGrid
              className={classes.contentGrid}
              columnWidths={[768, 1024, 1280]}
            >
              {displayArticles.map((article) => (
                <ContentGridItem key={article.id}>
                  <InsiderArticleCard
                    article={article}
                    slugRoot="/insider/articles"
                  />
                </ContentGridItem>
              ))}
            </ContentGrid>
          )}
          {isEmpty(displayArticles) && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="400px"
              width="100%"
            >
              <Typography variant="h2" color="primary" gutterBottom>
                <FormattedMessage
                  id="scenes.insider.articles.search.noResults"
                  defaultMessage="No results found"
                />
              </Typography>
              <Typography variant="subtitle1" color="primary">
                <FormattedMessage
                  id="scenes.insider.articles.search.reviewSetting"
                  defaultMessage="Please review the filter settings"
                />
              </Typography>
            </Box>
          )}
        </>
      )}
    </InsiderPage>
  );
};

export default ArticlesPage;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MuiStepper from '@material-ui/core/Stepper';
import MuiStep from '@material-ui/core/Step';
import MuiStepLabel from '@material-ui/core/StepLabel';
import MuiStepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';

const getTitleColor = (step, activeStep, disabledSteps, nonLinear) => {
  if (disabledSteps.length) {
    if (disabledSteps.includes(step)) {
      return 'textDisabled';
    }
  }
  if (step < activeStep) {
    return 'textSecondary';
  }
  if (step === activeStep) {
    return 'textPrimary';
  }

  return nonLinear ? 'textSecondary' : 'textDisabled';
};

const getSubtitleColor = (step, activeStep, disabledSteps, nonLinear) => {
  if (disabledSteps.length) {
    if (disabledSteps.includes(step)) {
      return 'textDisabled';
    }
  }
  if (step <= activeStep) {
    return 'textSecondary';
  }

  return nonLinear ? 'textSecondary' : 'textDisabled';
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    backgroundColor: 'transparent',
  },
  hidden: {
    display: 'none',
  },
  defaultCursor: {
    cursor: 'default',
  },
  stepButton: {
    textAlign: 'left',
  },
  stepLabel: {
    maxWidth: theme.typography.pxToRem(130),
    display: 'flex',
    flexDirection: 'column',
  },
  textPrimary: {
    color: `${theme.palette.text.primary} !important`,
  },
  textSecondary: {
    color: `${theme.palette.text.secondary} !important`,
  },
  textDisabled: {
    color: `${theme.palette.text.disabled} !important`,
  },
  primary: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const Stepper = ({
  steps,
  activeStep,
  withSubtitle,
  hideIcons,
  className,
  onClick,
  disabledSteps,
}) => {
  const classes = useStyles();
  const combinedClasses = clsx(classes.root, className);
  const isDisabled = (step) => disabledSteps.includes(step);
  const nonLinear = !!onClick;

  return (
    <MuiStepper
      className={combinedClasses}
      activeStep={activeStep}
      nonLinear={nonLinear}
    >
      {steps.map((step, index) => {
        const { title, subtitle } = step;

        const titleClass = getTitleColor(
          index,
          activeStep,
          disabledSteps,
          nonLinear,
        );
        const subtitleClass = getSubtitleColor(
          index,
          activeStep,
          disabledSteps,
          nonLinear,
        );

        const handleOnClick = () => {
          if (onClick && !isDisabled(index)) {
            onClick(step, index);
          }
        };

        const optional = (
          <Typography variant="caption" className={classes[subtitleClass]}>
            {subtitle}
          </Typography>
        );

        const stepLabel = (
          <MuiStepLabel
            optional={withSubtitle && optional}
            classes={{
              labelContainer: clsx(classes.stepLabel, {
                [classes.defaultCursor]: isDisabled(index),
              }),
            }}
            StepIconProps={{
              classes: {
                root: clsx({ [classes.hidden]: hideIcons }),
                completed: classes.textSecondary,
                active: classes.primary,
              },
            }}
          >
            <Typography variant="subtitle1" className={classes[titleClass]}>
              {title}
            </Typography>
          </MuiStepLabel>
        );

        return (
          <MuiStep key={title}>
            {onClick && !isDisabled(index) ? (
              <MuiStepButton
                className={classes.stepButton}
                optional={withSubtitle && optional}
                onClick={handleOnClick}
              >
                {stepLabel}
              </MuiStepButton>
            ) : (
              stepLabel
            )}
          </MuiStep>
        );
      })}
    </MuiStepper>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
  ),
  disabledSteps: PropTypes.arrayOf(PropTypes.number),
  activeStep: PropTypes.number,
  className: PropTypes.string,
  withSubtitle: PropTypes.bool,
  hideIcons: PropTypes.bool,
  onClick: PropTypes.func,
};

Stepper.defaultProps = {
  steps: [],
  disabledSteps: [],
  activeStep: 0,
  className: '',
  withSubtitle: false,
  hideIcons: false,
};

export default Stepper;

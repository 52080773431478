import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { formatDate } from 'utils/formatting';

export const ArticlePropType = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  publishedAt: PropTypes.string,
  publishedBy: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  slug: PropTypes.string,
  coverImage: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
});

export const getInfoLine = ({ intl, article }) => {
  const isoDate =
    article.releasedAt || article.publishedAt || article.createdAt;

  if (!isoDate) {
    return '';
  }

  const publishDate = formatDate({
    isoDate,
    formatter: intl.formatDate,
  });

  if (article.categories)
    if (!isEmpty(article.categories)) {
      const formattedCategories = article.categories.join(', ');

      return `${formattedCategories} · ${publishDate}`;
    }

  return publishDate;
};

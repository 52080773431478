import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import PageSection from 'layout/PageSection';
import { useClient } from 'utils/client';
import { useShowToast } from 'utils/toasts';
import {
  views as campaignViews,
  statuses as campaignStatuses,
} from 'utils/campaign';

import CampaignDetailsPageContainer from '../CampaignDetailsPageContainer';
import {
  useOneLazy as useCampaign,
  useCreateOne as useCreateCampaign,
  useEditOne as useEditCampaign,
} from '../../data/campaignHooks';
import CampaignDraftConfirmed from './CampaignDraftConfirmed';
import CampaignDraftForm from './CampaignDraftForm';
import CampaignPreview from './CampaignPreview';

const useStyles = makeStyles((theme) => ({
  formSection: {
    borderRadius: '4px 0 0 4px',
    flex: '1 0',
  },
  previewSection: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '0 4px 4px 0',
    flex: '1 0',
  },
  snackbar: {
    left: `calc(50% + ${theme.sidebar.width / 2}px)`,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const editOrSaveSuccessString = defineMessage({
  id: 'scenes.campaigns.createEdit.success',
  defaultMessage: 'Your changes have been saved.',
});

const CampaignDraftView = ({ campaignId, isNew }) => {
  const intl = useIntl();
  const { client } = useClient();
  const history = useHistory();
  const classes = useStyles();
  const showToast = useShowToast();
  const [preview, setPreview] = React.useState({});

  const [fetchCampaign, { data, loading, error }] = useCampaign();
  const { createDraftCampaign } = useCreateCampaign();
  const { editDraftCampaign } = useEditCampaign();

  if (error) {
    throw new Error(`Error fetching campaign: ${error}`);
  }

  React.useEffect(() => {
    if (!isNew) {
      fetchCampaign({
        variables: {
          id: campaignId,
        },
      });
    }
  }, [campaignId, isNew]);

  const campaign = data?.campaign;
  const isEditable = campaign?.status === campaignStatuses.BRIEFING || isNew;

  React.useEffect(() => {
    setPreview(campaign?.details);
  }, [campaign?.details]);

  const goToCampaigns = () => history.push('/campaigns');

  const handleSubmit = async (formData) => {
    if (isNew) {
      await createDraftCampaign({
        payload: {
          ...formData,
          name: `NEW BRIEFING FOR ${client.name} - ${formData.headline}`,
          language: 'de',
          type: 'instagram',
          pricing: 'budget',
        },
      });
      showToast({
        key: 'createSuccess',
        type: 'success',
        color: 'success',
        message: intl.formatMessage(editOrSaveSuccessString),
        autoHideDuration: 5000,
      });
      goToCampaigns();
    } else {
      await editDraftCampaign({
        id: campaign.id,
        payload: formData,
      });
      showToast({
        key: 'editSuccess',
        type: 'success',
        color: 'success',
        message: intl.formatMessage(editOrSaveSuccessString),
        autoHideDuration: 5000,
      });
      goToCampaigns();
    }
  };

  return (
    <CampaignDetailsPageContainer
      campaign={campaign}
      isNew={isNew}
      drawerOpen={false}
      loading={loading}
      view={campaignViews.BRIEFING}
      expandWithChildren
    >
      <Box display="flex" minWidth={1024} maxWidth={1500} mx="auto">
        <PageSection
          title={intl.formatMessage({
            id: 'scenes.campaigns.form.title',
            defaultMessage: 'Campaign Briefing',
          })}
          TitleProps={{ variant: 'subtitle1' }}
          className={classes.formSection}
        >
          {isEditable && (
            <CampaignDraftForm
              formData={campaign?.details}
              onSubmit={handleSubmit}
              onCancel={goToCampaigns}
              onChange={setPreview}
            />
          )}
          {!isEditable && <CampaignDraftConfirmed />}
        </PageSection>
        <PageSection
          title={intl.formatMessage({
            id: 'scenes.campaigns.preview.title',
            defaultMessage: 'Preview',
          })}
          TitleProps={{ variant: 'subtitle1', color: 'textSecondary' }}
          className={classes.previewSection}
        >
          <CampaignPreview data={preview} />
        </PageSection>
      </Box>
    </CampaignDetailsPageContainer>
  );
};

CampaignDraftView.propTypes = {
  campaignId: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
};
CampaignDraftView.defaultProps = {};

export default CampaignDraftView;

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import Box from '@material-ui/core/Box';

import LoadingState, { loadingString } from 'components/LoadingState';

import PageWrapper from './PageWrapper';

const LoadingPage = ({ withSidebar, ...otherProps }) => {
  const intl = useIntl();

  if (withSidebar) {
    return <PageWrapper title={intl.formatMessage(loadingString)} loading />;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      {...otherProps}
    >
      <Helmet>
        <title>{intl.formatMessage(loadingString)}</title>
      </Helmet>
      <LoadingState />
    </Box>
  );
};

LoadingPage.propTypes = {
  withSidebar: PropTypes.bool,
};

LoadingPage.defaultProps = {
  withSidebar: false,
};

export default LoadingPage;

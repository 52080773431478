import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import ChannelCard from 'components/channels/card/ChannelCard';
import InspectorDrawer from 'components/inspector/InspectorDrawer';

import { ChannelPropType, useUserChannelsState } from 'utils/channel';

export const drawerWidth = 320;

const ChannelInspector = ({
  channel,
  updateChannelTags,
  updateChannelNotes,
  loading,
  onClose,
  ...otherProps
}) => {
  const {
    loading: loadingUserChannels,
    userChannels,
    selectedChannel,
    selectChannel,
    unselectChannel,
  } = useUserChannelsState(
    channel?.user?.id,
    channel,
    false, // return all user channels regardless of CRM feature
  );

  const handleClose = () => {
    unselectChannel();
    onClose();
  };

  return (
    <InspectorDrawer
      isOpen={!!selectedChannel}
      width={drawerWidth}
      onClose={handleClose}
      {...otherProps}
    >
      {!isEmpty(selectedChannel) && (
        <ChannelCard
          user={channel?.user}
          selectedChannel={selectedChannel}
          channels={userChannels}
          loadingChannels={loadingUserChannels}
          onSelectChannel={selectChannel}
          loading={loading}
          updateChannelTags={updateChannelTags}
          updateChannelNotes={updateChannelNotes}
        />
      )}
    </InspectorDrawer>
  );
};

ChannelInspector.propTypes = {
  onClose: PropTypes.func.isRequired,
  channel: ChannelPropType,
  loading: PropTypes.bool.isRequired,
  updateChannelTags: PropTypes.func.isRequired,
  updateChannelNotes: PropTypes.func.isRequired,
};

ChannelInspector.defaultProps = {
  channel: null,
};

export default ChannelInspector;

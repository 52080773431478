import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';

import TableCell from '@material-ui/core/TableCell';

import { AlignPropType } from 'utils/table';
import { REVIEW_STATUS } from 'utils/kamReviewStatuses';

import KamReviewStatusButton from './KamReviewStatusButton';

const TableCellKamReview = ({ align, kamReview, ...otherProps }) => (
  <TableCell align={align} {...otherProps}>
    <KamReviewStatusButton kamReview={kamReview} />
  </TableCell>
);

TableCellKamReview.propTypes = {
  align: AlignPropType,
  kamReview: PropTypes.shape({
    status: PropTypes.oneOf(values(REVIEW_STATUS)),
  }),
};

TableCellKamReview.defaultProps = {
  align: 'center',
  kamReview: {
    status: REVIEW_STATUS.NEW,
  },
};

export default TableCellKamReview;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useStatus as useCampaignStatus } from 'utils/campaign';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: (props) => props.statusColor,
    borderRadius: 25,
  },
  label: {
    color: (props) => theme.palette.getContrastText(props.statusColor),
  },
}));

const CampaignStatus = ({ value, className, ...otherProps }) => {
  const status = useCampaignStatus(value);
  const classes = useStyles({ statusColor: status.color });

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <Typography className={classes.label} variant="subtitle1">
        {status.label}
      </Typography>
    </Box>
  );
};

CampaignStatus.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CampaignStatus.defaultProps = {
  className: '',
};

export default CampaignStatus;

import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => {
  const edgeSpacing = 2;

  return {
    clickable: {
      cursor: 'pointer',
    },
    wrapper: {
      margin: theme.spacing(edgeSpacing),
      height: `calc(100% - ${theme.spacing(edgeSpacing * 2)})`,
      '&:focus': {
        outline: 'none',
      },
    },
  };
});

const MediaViewerModal = ({ children, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Modal {...otherProps}>
      <Box className={classes.wrapper}>
        <Box
          right={0}
          top={0}
          position="absolute"
          zIndex={1}
          className={classes.clickable}
        >
          <Icons.XCircle color="white" onClick={otherProps.onClose} />
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

MediaViewerModal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MediaViewerModal;

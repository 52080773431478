import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  clickable: {
    cursor: 'pointer',
  },
});

const Toggle = React.forwardRef(
  ({ label, value, onChange, ...otherProps }, ref) => {
    const theme = useTheme();
    const classes = useStyles();

    const handleChange = () => {
      onChange(!value);
    };

    return (
      <Box
        display="flex"
        alignItems="center"
        onClick={handleChange}
        ref={ref}
        className={classes.clickable}
        {...otherProps}
      >
        <Box display="flex" mr={1}>
          {!value ? (
            <Icons.ToggleLeft color={theme.palette.primary.main} />
          ) : (
            <Icons.ToggleRight
              color={theme.palette.primary.main}
              fill={theme.palette.primary.light}
            />
          )}
        </Box>
        <Box>
          <Typography variant="body2">{label}</Typography>
        </Box>
      </Box>
    );
  },
);

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
Toggle.defaultProps = {};

export default Toggle;

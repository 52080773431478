import PropTypes from 'prop-types';

export const InfluencerAssetPublicationPropType = PropTypes.shape({
  publishedAt: PropTypes.string.isRequired,
  medium: PropTypes.string,
  url: PropTypes.string,
  comment: PropTypes.string,
});

export const InfluencerAssetPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
  campaignTitle: PropTypes.string,
  copyright: PropTypes.string,
  influencerName: PropTypes.string,
  influencerProfileUrl: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  buyOutPrice: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  publications: PropTypes.arrayOf(InfluencerAssetPublicationPropType),
});

import React from 'react';
import PropTypes from 'prop-types';

import { useClient } from 'utils/client';

export const useFeatures = () => {
  const { client } = useClient();

  return React.useMemo(
    () => ({
      isFeatureAvailable: (feature) => !!client?.features[feature],
      features: client?.features,
    }),
    [client],
  );
};

export const Feature = ({ feature, children, otherwise }) => {
  const { isFeatureAvailable } = useFeatures();

  if (isFeatureAvailable(feature)) {
    return children;
  }
  if (otherwise) {
    return otherwise;
  }

  return null;
};

Feature.propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  otherwise: PropTypes.node,
};

import React from 'react';

import TableCell from '@material-ui/core/TableCell';

import GenderIcon from 'icons/Gender';
import { ChannelPropType } from 'utils/channel';
import { LocationLabel } from 'utils/user';
import { useCategories } from 'utils/categories';
import { getChannelMetrics } from 'utils/kpi';

import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellNumber from 'components/table/TableCellNumber';
import TableCellChannelAvatar from 'components/table/TableCellChannelAvatar';
import TableCellChannel from 'components/table/TableCellChannel';
import TableCellTags from 'components/table/TableCellTags';

const YoutubeTableRow = ({ channel, ...otherProps }) => {
  const { categories, user, tags } = channel;

  const { getFullCategories } = useCategories();

  const fullCategories = React.useMemo(
    () => getFullCategories(categories),
    [categories, getFullCategories],
  );

  const metrics = getChannelMetrics(channel);

  return (
    <TableRow {...otherProps}>
      <TableCellChannelAvatar channel={channel} user={channel.user} />
      <TableCellChannel channel={channel} />
      <TableCell align="center" sortDirection={false}>
        <GenderIcon gender={user.salutation} />
      </TableCell>
      <TableCellText>
        <LocationLabel user={user} />
      </TableCellText>
      <TableCellNumber value={metrics.reach} roundingDigits={2} />
      <TableCellNumber value={metrics.views} roundingDigits={2} />
      <TableCellTags tags={tags} variant="default" />
      <TableCellTags
        tags={fullCategories}
        getTagValue={(tag) => tag.code}
        getTagLabel={(tag) => tag.label}
        sortBy="weight"
      />
    </TableRow>
  );
};

YoutubeTableRow.propTypes = {
  channel: ChannelPropType.isRequired,
};

YoutubeTableRow.defaultProps = {};

export default YoutubeTableRow;

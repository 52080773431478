import React from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';

import { useClient } from 'utils/client';

import createTheme from './createTheme';

const loadingTheme = createTheme({
  loading: true,
});

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = React.useState(loadingTheme);
  const { client } = useClient();

  React.useEffect(() => {
    if (client) {
      const clientTheme = createTheme({ clientOverrides: client.theme });
      setTheme(clientTheme);
    }
  }, [client]);

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStaticListItemStyles = makeStyles((theme) => ({
  root: {
    cursor: 'auto',
    paddingBottom: 0,
    paddingTop: (props) => (props.disableTopPadding ? 0 : theme.spacing(1)),
  },
}));

const StaticListItem = ({
  label,
  value,
  children,
  disableTopPadding,
  ...otherProps
}) => {
  const classes = useStaticListItemStyles({ disableTopPadding });

  return (
    <ListItem className={classes.root} {...otherProps}>
      <ListItemText
        primary={value || '—'}
        secondary={label || '—'}
        secondaryTypographyProps={{ noWrap: true }}
      />
      {children}
    </ListItem>
  );
};

StaticListItem.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  value: PropTypes.node,
  disableTopPadding: PropTypes.bool,
  children: PropTypes.node,
};

export default StaticListItem;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';

import Typography from '@material-ui/core/Typography';

const useSubheaderStyles = makeStyles((theme) => ({
  root: {
    lineHeight: theme.typography.pxToRem(26),
  },
}));

const Subheader = ({ label, children }) => {
  const classes = useSubheaderStyles();

  return (
    <ListSubheader className={classes.root}>
      <Typography variant="overline">{label}</Typography>
      {children}
    </ListSubheader>
  );
};

Subheader.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.node,
};

export default Subheader;

import React from 'react';
import PropTypes from 'prop-types';
import last from 'lodash/last';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { ApplicationPropType } from 'utils/application';
import ChannelAvatar from 'components/avatars/ChannelAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *:not(:first-child)': {
      marginLeft: theme.typography.pxToRem(-4),
    },
  },
  avatar: {
    border: '2px solid #fff',
  },
  groupedAvatar: {
    letterSpacing: -1,
    border: '2px solid #fff',
    backgroundColor: theme.palette.primary.main,
    width: (props) => theme.typography.pxToRem(props.size),
    height: (props) => theme.typography.pxToRem(props.size),
  },
}));

const ChannelAvatarGroup = ({ applications, max, size }) => {
  const classes = useStyles({ size });

  const groupedNumber = applications.slice(max).length;
  const groupedLabel = `+ ${groupedNumber}`;
  const groupedTooltip =
    groupedNumber > 1
      ? applications
          .slice(max)
          .map(({ channel }) => channel.name)
          .join(', ')
      : undefined;

  return (
    <Box className={classes.root} display="flex">
      {applications.slice(0, max).map(({ id, user, channel }) => (
        <ChannelAvatar
          key={id}
          channel={channel}
          user={user}
          size={size}
          textVariant="body1"
          badgeSize="small"
          tooltipTitle={channel.name}
          className={classes.avatar}
        />
      ))}
      {groupedNumber === 1 && (
        <ChannelAvatar
          channel={last(applications).channel}
          user={last(applications).user}
          size={size}
          textVariant="body1"
          badgeSize="small"
          tooltipTitle={last(applications).channel.name}
          className={classes.avatar}
        />
      )}
      {groupedNumber > 1 && (
        <Tooltip title={groupedTooltip} placement="top">
          <Avatar className={classes.groupedAvatar} variant="circular">
            <Typography variant="body1">{groupedLabel}</Typography>
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

ChannelAvatarGroup.propTypes = {
  applications: PropTypes.arrayOf(ApplicationPropType),
  max: PropTypes.number,
  size: PropTypes.number,
};

ChannelAvatarGroup.defaultProps = {
  applications: [],
  max: undefined,
  size: 50,
};

export default ChannelAvatarGroup;

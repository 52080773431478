import React from 'react';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import Box from '@material-ui/core/Box';

import CampaignsTable from 'components/campaigns/table/CampaignsTable';
import CampaignsTableMetaLabel from 'components/campaigns/table/CampaignsTableMetaLabel';

import { useMany as useCampaigns } from '../data/campaignHooks';

const defaultCampaigns = [];

const CampaignsList = () => {
  const { data: { campaigns = defaultCampaigns } = {}, loading } = useCampaigns(
    {
      campaignsFilter: 'byCompleted',
      applicationsFilter: 'byPublishedContent',
      withApplications: true,
    },
  );

  const [displayCampaigns, setDisplayCampaigns] = React.useState(campaigns);

  const [currentSort, setCurrentSort] = React.useState({
    by: 'firstPostAt',
    direction: 'desc',
  });

  const prevSort = React.useRef(currentSort);

  React.useEffect(() => {
    setDisplayCampaigns(campaigns);
  }, [campaigns, loading]);

  React.useEffect(() => {
    if (isEqual(prevSort.current, currentSort)) {
      return;
    }

    let sortedCampaigns = displayCampaigns;

    prevSort.current = currentSort;

    if (currentSort.by === 'channelsNumber') {
      sortedCampaigns = sortBy(
        campaigns,
        (campaign) => campaign.applications.length,
      );
    } else if (currentSort.by === 'channelsReach') {
      sortedCampaigns = sortBy(campaigns, (campaign) =>
        sumBy(campaign.applications, 'reach'),
      );
    } else {
      sortedCampaigns = sortBy(campaigns, currentSort.by);
    }

    setDisplayCampaigns(
      currentSort.direction === 'desc'
        ? sortedCampaigns.reverse()
        : sortedCampaigns,
    );
  }, [campaigns, currentSort]);

  return (
    <Box mt={3}>
      {campaigns.length > 0 && (
        <CampaignsTable
          campaigns={displayCampaigns}
          currentSort={currentSort}
          onSort={setCurrentSort}
          headerLabel={<CampaignsTableMetaLabel campaigns={campaigns} />}
        />
      )}
    </Box>
  );
};

export default CampaignsList;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

import { ChannelPropType, PlatformPropType } from 'utils/channel';
import { SortObjectPropType } from 'utils/sorting';

import TableContainer from 'components/table/TableContainer';
import TableBody from 'components/table/TableBody';

import ChannelsTableRow from './ChannelsTableRow';
import ChannelsTableHead from './ChannelsTableHead';

const useStyles = makeStyles({
  table: {
    position: 'relative',
  },
});

const ChannelsTable = ({
  platform,
  loading,
  meta,
  channels,
  selectedChannel,
  onSelectChannel,
  currentSort,
  onSort,
  headerLabel,
  ...otherProps
}) => {
  const classes = useStyles();

  const isSelectedRow = (channel) =>
    !!selectedChannel && channel.id === selectedChannel.id;

  const channelSelector = (id) => () => onSelectChannel(id);

  return (
    <TableContainer label={headerLabel}>
      <Table
        className={classes.table}
        aria-label="Channels Table"
        size="small"
        {...otherProps}
      >
        <ChannelsTableHead
          platform={platform}
          currentSort={currentSort}
          onSort={onSort}
        />
        <TableBody loading={loading}>
          {channels.map((channel) => (
            <ChannelsTableRow
              key={channel.id}
              channel={channel}
              onClick={channelSelector(channel.id)}
              selected={isSelectedRow(channel)}
              hover
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ChannelsTable.propTypes = {
  platform: PlatformPropType.isRequired,
  loading: PropTypes.bool,
  meta: PropTypes.shape({
    count: PropTypes.number.isRequired,
    reach: PropTypes.number.isRequired,
  }),
  channels: PropTypes.arrayOf(ChannelPropType),
  selectedChannel: ChannelPropType,
  onSelectChannel: PropTypes.func.isRequired,
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  headerLabel: PropTypes.node,
};

ChannelsTable.defaultProps = {
  loading: false,
  selectedChannel: null,
  channels: [],
  meta: {},
  headerLabel: '',
};

export default ChannelsTable;

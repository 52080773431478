import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TagsInputForm from 'components/TagsInputForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0, 0, 0),
  },
}));

const InfluencerAssetInspectorTags = ({
  influencerAsset,
  tagsOptions,
  getTagOptionLabel,
  onSubmit,
  loading,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const header = intl.formatMessage({
    id: 'components.InfluencerAssetInspector.labels.categoriesHeader',
    defaultMessage: 'Categories',
  });
  const label = intl.formatMessage({
    id: 'components.InfluencerAssetInspector.labels.categoriesLabel',
    defaultMessage: 'Edit Asset categories',
  });

  return (
    <List className={classes.root}>
      <ListItem>
        <TagsInputForm
          formData={influencerAsset}
          tagsOptions={tagsOptions}
          getTagOptionLabel={getTagOptionLabel}
          onSubmit={onSubmit}
          loading={loading}
          label={label}
          header={header}
        />
      </ListItem>
    </List>
  );
};

InfluencerAssetInspectorTags.propTypes = {
  influencerAsset: PropTypes.object.isRequired,
  tagsOptions: PropTypes.arrayOf(PropTypes.string),
  getTagOptionLabel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default InfluencerAssetInspectorTags;

import React from 'react';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
  },
}));

const InfluencerAssetInspectorHeadline = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <List disablePadding>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary">
          {intl.formatMessage({
            id: 'components.InfluencerAssetInspector.headline',
            defaultMessage: 'Info',
          })}
        </Typography>
      </ListItem>
    </List>
  );
};

export default InfluencerAssetInspectorHeadline;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

import { PlatformPropType } from 'utils/channel';
import { ApplicationPropType } from 'utils/application';
import { SortObjectPropType } from 'utils/sorting';
import { CampaignInfluencerSelectionSummaryPropType } from 'utils/campaign';

import TableContainer from 'components/table/TableContainer';
import TableBody from 'components/table/TableBody';

import ApplicationsTableHead from './ApplicationsTableHead';
import ApplicationsTableRow from './ApplicationsTableRow';

const useStyles = makeStyles({
  table: {
    position: 'relative',
  },
});

const ApplicationsTable = ({
  platform,
  loading,
  applications,
  selectionSummary,
  currentSort,
  onSort,
  selectedApplication,
  onSelectApplication,
  clientReviewConfirmed,
  headerContent,
  headerLabel,
  hideKamNotesColumn,
  ...otherProps
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSelectedRow = (application) =>
    !!selectedApplication && application.id === selectedApplication.id;

  const selector = (id) => () => onSelectApplication(id);

  return (
    <TableContainer label={headerLabel} headerContent={headerContent}>
      <Table
        className={classes.table}
        aria-label="Applications Table"
        size="small"
        {...otherProps}
      >
        <ApplicationsTableHead
          platform={platform}
          currentSort={currentSort}
          onSort={onSort}
          influencerSelectionType={selectionSummary.selectionType}
          hideKamNotesColumn={hideKamNotesColumn}
        />
        <TableBody loading={loading}>
          {applications.map((application) => (
            <ApplicationsTableRow
              key={application.id}
              platform={platform}
              application={application}
              onClick={selector(application.id)}
              selected={isSelectedRow(application)}
              hideKamNotesColumn={hideKamNotesColumn}
              color={
                clientReviewConfirmed ? theme.palette.primary.light : undefined
              }
              gravatarBackgroundColor={
                clientReviewConfirmed ? theme.palette.primary.main : undefined
              }
              influencerSelectionType={selectionSummary.selectionType}
              hover
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ApplicationsTable.propTypes = {
  platform: PlatformPropType,
  loading: PropTypes.bool,
  applications: PropTypes.arrayOf(ApplicationPropType),
  selectionSummary: CampaignInfluencerSelectionSummaryPropType,
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  selectedApplication: ApplicationPropType,
  onSelectApplication: PropTypes.func.isRequired,
  clientReviewConfirmed: PropTypes.bool,
  headerContent: PropTypes.node,
  headerLabel: PropTypes.node,
  hideKamNotesColumn: PropTypes.bool,
};

ApplicationsTable.defaultProps = {
  loading: false,
  applications: [],
  headerLabel: null,
  headerContent: null,
  clientReviewConfirmed: false,
  selectionSummary: {},
  hideKamNotesColumn: false,
};

export default ApplicationsTable;

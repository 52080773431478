import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Link from '@material-ui/core/Link';

import { useCookiesPolicy } from 'utils/cookies';

import config from 'config';

export const GTMMarkup = () => {
  const { cookiesAllowed } = useCookiesPolicy();

  if (config.gtm.disabled || !cookiesAllowed) {
    return null;
  }

  return (
    <Helmet>
      <script>dataLayer = [];</script>
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${config.gtm.containerId}');
        `}
      </script>
    </Helmet>
  );
};

export const useGA = () => {
  const { cookiesAllowed } = useCookiesPolicy();

  const trackEvent = React.useCallback(
    ({ action, category, label, value }) => {
      if (!window.dataLayer || !cookiesAllowed) {
        return;
      }

      window.dataLayer.push({
        event: action,
        event_category: category,
        event_label: label,
        value,
      });
    },
    [cookiesAllowed, window.dataLayer],
  );

  return { trackEvent };
};

export const TrackedLink = React.forwardRef(
  ({ eventLabel, ...otherProps }, ref) => {
    const { trackEvent } = useGA();

    const handleTrack = () => {
      trackEvent({
        action: 'external_link_clicked',
        label: eventLabel,
      });
    };

    return <Link {...otherProps} onClick={handleTrack} ref={ref} />;
  },
);

TrackedLink.propTypes = {
  eventLabel: PropTypes.string.isRequired,
};

TrackedLink.defaultProps = {};

export default useGA;

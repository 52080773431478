import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCellHead from 'components/table/TableCellHead';
import { SortObjectPropType } from 'utils/sorting';

const CampaignsTableHead = ({ currentSort, onSort, ...otherProps }) => (
  <TableHead {...otherProps}>
    <TableRow>
      <TableCell align="left" />
      <TableCellHead
        currentSort={currentSort}
        onSort={onSort}
        sortProperty="name"
      >
        <FormattedMessage
          id="components.CampaignsTable.campaign"
          defaultMessage="Campaign"
        />
      </TableCellHead>
      <TableCellHead
        currentSort={currentSort}
        onSort={onSort}
        sortProperty="firstPostAt"
        align="right"
      >
        <FormattedMessage
          id="components.CampaignsTable.firstPublication"
          defaultMessage="First Publication"
        />
      </TableCellHead>
      <TableCellHead
        currentSort={currentSort}
        onSort={onSort}
        sortProperty="channelsNumber"
      >
        <FormattedMessage
          id="components.CampaignsTable.influencers"
          defaultMessage="Influencers"
        />
      </TableCellHead>
      <TableCellHead
        currentSort={currentSort}
        onSort={onSort}
        sortProperty="channelsReach"
      >
        <FormattedMessage
          id="components.CampaignsTable.influencerReach"
          defaultMessage="Influencer Reach"
        />
      </TableCellHead>
    </TableRow>
  </TableHead>
);

CampaignsTableHead.propTypes = {
  currentSort: SortObjectPropType.isRequired,
  onSort: PropTypes.func.isRequired,
};

CampaignsTableHead.defaultProps = {};

export default CampaignsTableHead;

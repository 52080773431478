import React from 'react';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { views as clientViews } from 'utils/client';
import Stepper from 'components/Stepper';

const useStyles = makeStyles((theme) => ({
  stepper: {
    maxWidth: theme.typography.pxToRem(256),
  },
}));

const ClientDetailsViewStepper = ({
  selectedView,
  disableUsers,
  ...otherProps
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const steps = React.useMemo(
    () => [
      {
        title: intl.formatMessage(clientViews.SETTINGS.title),
        subtitle: intl.formatMessage(clientViews.SETTINGS.subtitle),
        value: clientViews.SETTINGS.id,
      },
      {
        title: intl.formatMessage(clientViews.USERS.title),
        subtitle: intl.formatMessage(clientViews.USERS.subtitle),
        value: clientViews.USERS.id,
      },
    ],
    [intl, clientViews],
  );

  const activeStep = findIndex(steps, (step) => step.value === selectedView);

  const onStepClick = ({ value }) => {
    history.push(value);
  };

  return (
    <Box display="flex" justifyContent="center" mb={3} {...otherProps}>
      <Stepper
        className={classes.stepper}
        steps={steps}
        activeStep={activeStep}
        disabledSteps={disableUsers ? [1] : []}
        withSubtitle
        hideIcons
        onClick={onStepClick}
      />
    </Box>
  );
};

ClientDetailsViewStepper.propTypes = {
  disableUsers: PropTypes.bool,
  selectedView: PropTypes.string.isRequired,
};
ClientDetailsViewStepper.defaultProps = {
  disableUsers: false,
};

export default ClientDetailsViewStepper;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import noop from 'lodash/noop';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Markdown from 'components/Markdown';
import EditIcon from 'icons/Edit';

const EditableText = ({ label, value, onSave, onCancel, ...otherProps }) => {
  const intl = useIntl();

  const [noteEditText, setNoteEditText] = React.useState(value);

  const handleNoteChange = (event) => {
    setNoteEditText(event.target.value);
  };

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSave(noteEditText);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setNoteEditText(value);
    onCancel();
  };

  return (
    <Box display="flex" flexDirection="column" {...otherProps}>
      <TextField
        variant="outlined"
        label={label}
        onChange={handleNoteChange}
        value={noteEditText}
        fullWidth
        multiline
        minRows={2}
        maxRows={25}
        autoFocus
      />
      <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
        <Button color="primary" onClick={handleCancel}>
          {intl.formatMessage({
            id: 'components.ApplicationCardNote.clientNoteInput.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button color="primary" onClick={handleSave}>
          {intl.formatMessage({
            id: 'components.ApplicationCardNote.clientNoteInput.save',
            defaultMessage: 'Save',
          })}
        </Button>
      </Box>
    </Box>
  );
};

EditableText.propTypes = {
  label: PropTypes.node,
  value: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};
EditableText.defaultProps = {
  label: '',
  value: '',
  onSave: noop,
  onCancel: noop,
};

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
  markdown: {},
  textArea: {},
  contentArea: {
    position: 'relative',
    paddingRight: 28,
  },
  editIcon: {
    position: 'absolute',
    top: -2,
    right: 0,
  },
});

const EditableMarkdown = (props) => {
  const {
    className,
    content,
    label,
    value,
    onChange,
    cancelOnOutsideClick,
    classes: classesProp,
    ...otherProps
  } = props;

  const classes = useStyles({ classes: classesProp });
  const rootRef = React.useRef(null);

  const [isEditing, setIsEditing] = React.useState(false);

  const handleSave = (newNote) => {
    onChange(newNote);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  React.useEffect(() => {
    if (cancelOnOutsideClick) {
      const handleClickOutside = (event) => {
        if (rootRef.current && !rootRef.current.contains(event.target)) {
          setIsEditing(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }

    return () => {};
  }, [rootRef]);

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      onClick={() => setIsEditing(true)}
      className={clsx(classes.root, className)}
      ref={rootRef}
      {...otherProps}
    >
      {!isEditing && (
        <Box className={classes.contentArea}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            mb={0.5}
          >
            {content}
          </Box>
          <Box className={classes.editIcon}>
            <EditIcon />
          </Box>
        </Box>
      )}
      {isEditing && (
        <EditableText
          className={classes.textArea}
          label={label}
          value={value}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
      {!isEditing && (
        <Markdown
          className={classes.markdown}
          source={value}
          renderers={{
            // eslint-disable-next-line react/prop-types
            paragraph: ({ children }) => (
              <Typography variant="body1" component="div" paragraph>
                {children}
              </Typography>
            ),
          }}
        />
      )}
    </Box>
  );
};

EditableMarkdown.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  content: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  cancelOnOutsideClick: PropTypes.bool,
};
EditableMarkdown.defaultProps = {
  className: '',
  cancelOnOutsideClick: true,
  classes: {},
};

export default EditableMarkdown;

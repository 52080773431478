import React from 'react';

import SvgIconWrapper from './SvgIconWrapper';

export default (props) => (
  <SvgIconWrapper {...props}>
    <path
      d="M14 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V14M14 20L20 14M14 20V14H20M8 8H16M8 11H12"
      stroke="#657C98"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconWrapper>
);

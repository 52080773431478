import React from 'react';
import PropTypes from 'prop-types';
import { Image, Transformation } from 'cloudinary-react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import config from 'config';

const useStyles = makeStyles({
  logo: {
    width: 200,
    height: '100%',
  },
  title: {
    fontWeight: 400,
  },
  subtitle: {
    fontWeight: 300,
    fontSize: 35,
    lineHeight: '42px',
  },
});

const HeaderSection = ({ title, subtitle, showImage }) => {
  const classes = useStyles();

  const isFullBackground = !title && !subtitle;

  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection={isFullBackground ? 'column' : 'row'}
      >
        <Box
          display="flex"
          flexDirection="column"
          marginRight={1}
          flex="1 0 40%"
        >
          <Box mb={2}>
            <Image
              className={classes.logo}
              secure
              cloudName={config.cloudinary.cloudName}
              publicId="bm-assets/jip7duadqhm8exhtxhzi"
              width="100%"
            >
              <Transformation transformation={['bm-insider-asset']} />
            </Image>
          </Box>
          {title && (
            <Box mb={4}>
              <Typography className={classes.title} variant="h1">
                {title}
              </Typography>
            </Box>
          )}
          {subtitle && (
            <Typography className={classes.subtitle} variant="h2">
              {subtitle}
            </Typography>
          )}
        </Box>
        {showImage && (
          <Box>
            <Image
              secure
              cloudName={config.cloudinary.cloudName}
              publicId="bm-assets/lxrayvnede4untazxjfq"
              width="100%"
            >
              <Transformation transformation={['bm-insider-asset']} />
            </Image>
          </Box>
        )}
      </Box>
    </Box>
  );
};

HeaderSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showImage: PropTypes.bool,
};
HeaderSection.defaultProps = {
  title: '',
  subtitle: '',
  showImage: true,
};

export default HeaderSection;

import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';

import SelectFilter from 'components/filters/SelectFilter';

const StatusFilter = ({ options, ...otherProps }) => (
  <SelectFilter {...otherProps}>
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </SelectFilter>
);

StatusFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};
StatusFilter.defaultProps = {};

export default StatusFilter;

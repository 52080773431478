import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import cheerio from 'cheerio';
import words from 'lodash/words';
import * as Icons from 'react-feather';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ContentPreviewPropType } from 'utils/application';

import PreviewContentModal from '../PreviewContentModal';

const useStyles = makeStyles(() => {
  const getWidthWidthInspector = (props) =>
    `calc(100% - ${props.drawerWidth}px)`;

  return {
    selectedItemModal: {
      width: getWidthWidthInspector,
    },
    selectedItemModalBackdrop: {
      width: getWidthWidthInspector,
    },
    htmlContainer: {
      display: 'flex',
      flexFlow: 'column nowrap',
      padding: '1rem 12%',
      boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.33)',
      height: '100%',
      backgroundColor: '#fff',
      overflowY: 'scroll',
      maxWidth: '70em',
      margin: 'auto',

      '& table': {
        borderCollapse: 'collapse',
        borderSpacing: 0,
      },

      '& iframe,td,th': {
        border: '1px dotted #DDD',
        padding: '.25em .5em',
      },

      '& div,h1,h2,h3,h4,h5,h6,p,td,th,li': {
        fontFamily: 'sans-serif',
        color: '#555',
        margin: '.5em 0',
      },

      '& div,p,td,th,li': {
        lineHeight: '1.65em',
      },

      '& img': {
        width: '100%',
        height: 'auto',
      },

      '& li': {
        margin: '.2em 0',
      },

      '& a': {
        color: '#336',
        textDecoration: 'none',
        borderBottom: '1px dotted #CCF',
      },
    },
    htmlContent: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
  };
});

const POST_TEXT_MAX_CHARACTERS = 140;

const InspectorBody = (props) => {
  const { preview, drawerWidth, ...otherProps } = props;

  const classes = useStyles(props);
  const intl = useIntl();

  const [viewContentModalOpen, setViewContentModalOpen] = React.useState(false);

  const cleanPostText = React.useMemo(
    () => preview?.articleSanitized?.replace(/<(.|\n)*?>/g, ''),
    [preview.articleSanitized],
  );

  const postText = React.useMemo(() => {
    const truncatedText = cleanPostText?.substring(0, POST_TEXT_MAX_CHARACTERS);

    if (!truncatedText) {
      return intl.formatMessage({
        id: 'components.ContentPreviewInspectorCard.postText.noContent',
        defaultMessage: 'No text content submitted',
      });
    }

    return `${truncatedText} ...`;
  }, [cleanPostText]);

  const summaryText = React.useMemo(() => {
    const $ = cheerio.load(preview.articleSanitized);

    const numberOfWords = words(cleanPostText).length;
    const numberOfImages = $('img')?.length;
    const numberOfVideos = $('video')?.length + $('iframe')?.length;

    const numberOfWordsString = intl.formatMessage(
      {
        id: 'components.ContentPreviewInspectorCard.summaryText.words',
        defaultMessage:
          '{numberOfItems, plural, one {One word} other {# words}}',
      },
      { numberOfItems: numberOfWords },
    );

    let message = numberOfWordsString;

    if (numberOfImages > 0) {
      const numberOfImagesString = intl.formatMessage(
        {
          id: 'components.ContentPreviewInspectorCard.summaryText.images',
          defaultMessage:
            '{numberOfItems, plural, =0 {} one {# image} other {# images}}',
        },
        { numberOfItems: numberOfImages },
      );

      message += `, ${numberOfImagesString}`;
    }

    if (numberOfVideos > 0) {
      const numberOfVideosString = intl.formatMessage(
        {
          id: 'components.ContentPreviewInspectorCard.summaryText.videos',
          defaultMessage:
            '{numberOfItems, plural, =0 {} one {# video} other {# videos}}',
        },
        { numberOfItems: numberOfVideos },
      );

      message += `, ${numberOfVideosString}`;
    }

    return message;
  }, [preview.articleSanitized, cleanPostText]);

  const closeViewContentModal = () => {
    setViewContentModalOpen(false);
  };

  const toggleViewContentModal = () => {
    setViewContentModalOpen(!viewContentModalOpen);
  };

  const viewContentModalTriggerLabel = viewContentModalOpen
    ? intl.formatMessage({
        id: 'components.ContentPreviewInspectorCard.viewContentModalTrigger.close',
        defaultMessage: 'Hide Preview',
      })
    : intl.formatMessage({
        id: 'components.ContentPreviewInspectorCard.viewContentModalTrigger.open',
        defaultMessage: 'Show Preview',
      });

  return (
    <Box {...otherProps}>
      {viewContentModalOpen && (
        <PreviewContentModal
          open
          onClose={closeViewContentModal}
          className={classes.selectedItemModal}
          BackdropProps={{
            className: classes.selectedItemModalBackdrop,
          }}
        >
          <Box className={classes.htmlContainer}>
            {preview.title && (
              <Box mb={3}>
                <Typography variant="h2">{preview.title}</Typography>
              </Box>
            )}
            <Box
              className={classes.htmlContent}
              dangerouslySetInnerHTML={{ __html: preview.articleSanitized }}
            />
          </Box>
        </PreviewContentModal>
      )}
      {preview.title && (
        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="overline" color="textSecondary">
            {intl.formatMessage({
              id: 'components.ContentPreviewInspectorCard.postTitle',
              defaultMessage: 'Post Title',
            })}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1">
            {preview.title}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="column" mb={2}>
        <Typography variant="overline" color="textSecondary">
          {intl.formatMessage({
            id: 'components.ContentPreviewInspectorCard.postText',
            defaultMessage: 'Post Text',
          })}
        </Typography>
        <Typography color="textSecondary">{postText}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" mb={2}>
        <Typography variant="subtitle1">{summaryText}</Typography>
      </Box>
      <Box mb={2}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Icons.Eye />}
          onClick={toggleViewContentModal}
        >
          {viewContentModalTriggerLabel}
        </Button>
      </Box>
    </Box>
  );
};

InspectorBody.propTypes = {
  preview: ContentPreviewPropType.isRequired,
  drawerWidth: PropTypes.number.isRequired,
};

InspectorBody.defaultProps = {};

export default InspectorBody;

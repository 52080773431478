import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import ContentPreviewInspectorCard from 'components/contentPreview/inspector/ContentPreviewInspectorCard';
import InspectorDrawer from 'components/inspector/InspectorDrawer';
import ApplicationReviewButton from 'components/applications/ApplicationReviewButton';
import { ApplicationPropType } from 'utils/application';
import { PlatformPropType } from 'utils/channel';

import { useUpdateApplicationClientContentReview } from '../../data/campaignHooks';

export const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  reviewButton: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: (drawerWidth - 275) / 2,
  },
}));

const CampaignContentReviewInspector = ({
  platform,
  application,
  onClose,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { updateApplicationClientContentReview } =
    useUpdateApplicationClientContentReview();

  const handleApplicationUpdate = React.useCallback(
    (newClientReviewStatus) => {
      if (application) {
        updateApplicationClientContentReview({
          id: application.id,
          payload: newClientReviewStatus,
        });
      }
    },
    [updateApplicationClientContentReview, application],
  );

  return (
    <InspectorDrawer
      isOpen={!!application}
      width={drawerWidth}
      onClose={onClose}
      {...otherProps}
    >
      {application && (
        <Box>
          <ContentPreviewInspectorCard
            application={application}
            platform={platform}
            drawerWidth={drawerWidth}
          />
          <ApplicationReviewButton
            className={classes.reviewButton}
            status={application?.clientContentReview?.status}
            note={application?.clientContentReview?.note}
            disabled={application?.contentPreview?.status === 'readonly'}
            disabledTooltip={intl.formatMessage({
              id: 'contentReviewButton.disabledTooltip',
              defaultMessage:
                'The content has been accepted. The Influencer will publish it shortly.',
            })}
            title={intl.formatMessage({
              id: 'components.ApplicationReviewButton.dialog.title.contentSubmission',
              defaultMessage: 'Content Submission',
            })}
            onUpdate={handleApplicationUpdate}
          />
        </Box>
      )}
    </InspectorDrawer>
  );
};

CampaignContentReviewInspector.propTypes = {
  onClose: PropTypes.func.isRequired,
  platform: PlatformPropType,
  application: ApplicationPropType,
};
CampaignContentReviewInspector.defaultProps = {
  application: null,
  platform: 'instagram',
};

export default CampaignContentReviewInspector;

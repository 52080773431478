import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import PageWrapper from 'layout/PageWrapper';
import PageTitle from 'layout/PageTitle';
import ErrorPage404 from 'scenes/error/404';
import LoadingPage from 'layout/LoadingPage';
import PlatformAvatar from 'components/PlatformAvatar';
import {
  CampaignPropType,
  CampaignViewPropType,
  views as campaignViews,
} from 'utils/campaign';
import { formatDate } from 'utils/formatting';
import InspectorDrawerContent from 'components/inspector/InspectorDrawerContent';

import CampaignProgressStepper from './CampaignProgressStepper';

const CampaignDates = ({ campaign, ...otherProps }) => {
  const intl = useIntl();

  if (!campaign.applicationsOpenedAt || !campaign.firstPostAt) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      {...otherProps}
    >
      <Box display="flex">
        <Typography noWrap>
          {intl.formatMessage({
            id: 'scenes.campaigns.details.startOfApplicationsLabel',
            defaultMessage: 'Open Applications:',
          })}
          &nbsp;
        </Typography>
        <Typography variant="subtitle1">
          {formatDate({
            isoDate: campaign.applicationsOpenedAt,
            formatter: intl.formatDate,
          })}
        </Typography>
      </Box>
      <Box display="flex">
        <Typography noWrap>
          {intl.formatMessage({
            id: 'scenes.campaigns.details.firstPublicationLabel',
            defaultMessage: 'First Publication:',
          })}
          &nbsp;
        </Typography>
        <Typography variant="subtitle1">
          {formatDate({
            isoDate: campaign.firstPostAt,
            formatter: intl.formatDate,
          })}
        </Typography>
      </Box>
    </Box>
  );
};

CampaignDates.propTypes = {
  campaign: CampaignPropType,
};

CampaignDates.defaultProps = {
  campaign: {},
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 0),
    height: '100%',
  },
}));

const CampaignDetailsPageContainer = ({
  campaign,
  isNew,
  drawerOpen,
  drawerWidth,
  renderDrawer,
  view,
  children,
  loading,
  expandWithChildren,
  className,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  const isContentReviewStep = view === campaignViews.CONTENT_REVIEW;

  const title = React.useMemo(() => {
    if (isNew) {
      return intl.formatMessage({
        id: 'scenes.campaigns.new.pageTitle',
        defaultMessage: 'New Campaign',
      });
    }

    if (!isNew && campaign?.status === 'briefing') {
      return intl.formatMessage({
        id: 'scenes.campaigns.edit.pageTitle',
        defaultMessage: 'Edit Campaign',
      });
    }

    return campaign?.name;
  }, [campaign, intl]);

  if (!isNew) {
    if (loading || isUndefined(campaign)) {
      return <LoadingPage withSidebar />;
    }

    if (!loading && isNull(campaign)) {
      return <ErrorPage404 />;
    }

    if (
      !loading &&
      !campaign?.hasClientReviewableContent &&
      isContentReviewStep
    ) {
      return <ErrorPage404 />;
    }
  }

  return (
    <PageWrapper
      title={title}
      className={className}
      loading={loading}
      display={expandWithChildren ? 'inline-block' : 'block'}
    >
      <Box width="100%" display="flex">
        <InspectorDrawerContent
          drawerOpen={drawerOpen}
          drawerWidth={drawerWidth}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <PageTitle
              icon={
                <PlatformAvatar
                  // fallback to instagram until 'New Briefing' for other platforms is not implemented
                  platform={campaign?.platform || 'instagram'}
                  color={theme.palette.primary.main}
                  size={40}
                />
              }
            >
              {title}
            </PageTitle>
            {campaign && <CampaignDates campaign={campaign} ml={2} />}
          </Box>
          <Box className={classes.container}>
            <CampaignProgressStepper
              currentStep={view}
              status={campaign?.status || 'briefing'}
              hideContentReviewStep={!campaign?.hasClientReviewableContent}
            />
            {children}
          </Box>
        </InspectorDrawerContent>
        {isFunction(renderDrawer) && renderDrawer({ drawerOpen, drawerWidth })}
      </Box>
    </PageWrapper>
  );
};

CampaignDetailsPageContainer.propTypes = {
  campaign: PropTypes.oneOfType([
    CampaignPropType,
    PropTypes.shape({
      applications: PropTypes.array,
    }),
  ]),
  isNew: PropTypes.bool,
  view: CampaignViewPropType.isRequired,
  drawerOpen: PropTypes.bool,
  drawerWidth: PropTypes.number,
  renderDrawer: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  expandWithChildren: PropTypes.bool,
};

CampaignDetailsPageContainer.defaultProps = {
  className: '',
  loading: false,
  isNew: false,
  drawerOpen: false,
  drawerWidth: 0,
  expandWithChildren: false,
};

export default CampaignDetailsPageContainer;

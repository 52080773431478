import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { views as clientViews } from 'utils/client';

import {
  useOne as useClient,
  useManagers,
  useUpdateClient,
  useCreateClient,
} from './data/clientHooks';
import ClientDetailsPageContainer from './components/ClientDetailsPageContainer';
import ClientForm from './components/ClientForm';

const ClientSettingsPage = ({ clientId }) => {
  const isNew = clientId === 'new';
  const history = useHistory();
  const {
    data: clientData,
    loading: loadingClient,
    error: clientError,
  } = useClient({ id: clientId });
  const {
    data: managersData,
    loading: loadingManagers,
    error: managersError,
  } = useManagers();
  const { updateClient } = useUpdateClient();
  const { createClient } = useCreateClient();

  const goToClients = () => history.push('/clients');
  const goToClient = (id) => history.push(`/clients/${id}/users`);

  if (clientError) {
    throw new Error(`Error fetching client: ${clientError}`);
  }

  if (managersError) {
    throw new Error(`Error fetching managers: ${managersError}`);
  }

  const client = clientData?.client || {
    manager: {
      id: '',
    },
    features: {},
  };
  const managers = managersData?.managers || [];

  const handleUpdateClient = (payload) => {
    if (client?.id) {
      updateClient({
        id: client?.id,
        payload,
      });
    }
  };

  const handleCreateClient = async (payload) => {
    if (isNew) {
      const { data } = await createClient({
        payload,
      });

      const newClient = data?.createClient;

      if (newClient) {
        goToClient(newClient.id);
      }
    }
  };

  return (
    <ClientDetailsPageContainer
      client={client}
      isNew={isNew}
      view={clientViews.SETTINGS}
      loading={loadingClient || loadingManagers}
    >
      <Paper elevation={1}>
        <Box
          py={4}
          px={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%" maxWidth={625}>
            <ClientForm
              formData={client}
              managers={managers}
              onSubmit={isNew ? handleCreateClient : handleUpdateClient}
              onCancel={goToClients}
            />
          </Box>
        </Box>
      </Paper>
    </ClientDetailsPageContainer>
  );
};

ClientSettingsPage.propTypes = {
  clientId: PropTypes.string.isRequired,
};
ClientSettingsPage.defaultProps = {};

export default ClientSettingsPage;

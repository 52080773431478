import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';

import CountryLabel from 'components/CountryLabel';
import { formatNumber as numberFormatter } from 'utils/formatting';
import { getChannelMetrics, getPlatformReachLabel } from 'utils/kpi';
import {
  getAvgRating as getAvgChannelRating,
  ChannelPropType,
} from 'utils/channel';

import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellNumber from 'components/table/TableCellNumber';
import TableCellChannel from 'components/table/TableCellChannel';
import TableCellTags from 'components/table/TableCellTags';

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeft: `10px solid ${theme.palette.primary.light}`,
  },
  selected: {
    borderLeft: `10px solid ${theme.palette.secondary.main}`,
  },
}));

const PrivateNetworkChannelTableRow = React.memo(
  ({ channel, selected, multiStatus, ...otherProps }) => {
    const intl = useIntl();
    const classes = useStyles();

    const [metrics, avgRating, topCountryCode] = React.useMemo(() => {
      const metrics = getChannelMetrics(channel);

      return [
        metrics,
        getAvgChannelRating(channel),
        !isEmpty(metrics.topCountry) ? keys(metrics.topCountry)[0] : null,
      ];
    }, [channel.id]);

    const formatPercentage = React.useCallback(
      (number) =>
        numberFormatter({
          formatter: intl.formatNumber,
          number,
          format: 'percent',
        }),
      [intl],
    );

    return (
      <TableRow
        className={clsx(classes.root, {
          [classes.selected]: selected,
        })}
        selected={selected}
        {...otherProps}
      >
        <TableCell colSpan={multiStatus ? 2 : 1} />
        <TableCellChannel channel={channel} bold withAvatar colSpan={2} />
        <TableCell align="left">
          {topCountryCode ? (
            <CountryLabel countryCode={topCountryCode}>
              <Typography noWrap>
                {formatPercentage(metrics.topCountry[topCountryCode])}
              </Typography>
            </CountryLabel>
          ) : (
            <Typography noWrap>—</Typography>
          )}
        </TableCell>
        <TableCellNumber
          align="right"
          value={metrics.reach}
          roundingDigits={2}
          label={intl.formatMessage(getPlatformReachLabel(channel.platform))}
        />
        <TableCellText align="left">
          {avgRating ? (
            <Rating value={avgRating} precision={0.5} size="small" readOnly />
          ) : (
            '—'
          )}
        </TableCellText>
        <TableCellTags tags={channel.tags} align="left" variant="default" />
      </TableRow>
    );
  },
  (prevProps, nextProps) =>
    prevProps.channel.id === nextProps.channel.id &&
    prevProps.channel.tags === nextProps.channel.tags &&
    prevProps.selected === nextProps.selected,
);

PrivateNetworkChannelTableRow.propTypes = {
  channel: ChannelPropType,
  selected: PropTypes.bool,
  multiStatus: PropTypes.bool,
};

PrivateNetworkChannelTableRow.defaultProps = {
  channel: {},
  selected: false,
  multiStatus: false,
};

export default PrivateNetworkChannelTableRow;

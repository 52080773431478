import React from 'react';

import TableRow from 'components/table/TableRow';
import TableCellCampaignName from 'components/table/TableCellCampaignName';
import TableCellInfluencers from 'components/table/TableCellInfluencers';
import TableCellInfluencersReach from 'components/table/TableCellInfluencersReach';
import TableCellImage from 'components/table/TableCellImage';
import TableCellDate from 'components/table/TableCellDate';

import { CampaignPropType } from 'utils/campaign';

const CampaignsTableRow = ({ campaign, ...otherProps }) => {
  const { firstPostAt, previewUrl, applications } = campaign;

  return (
    <TableRow {...otherProps}>
      <TableCellImage src={previewUrl} width={96} height={56} />
      <TableCellCampaignName campaign={campaign} />
      <TableCellDate value={firstPostAt} align="right" />
      <TableCellInfluencers applications={applications} align="left" />
      <TableCellInfluencersReach
        applications={applications}
        platform={campaign.platform}
      />
    </TableRow>
  );
};

CampaignsTableRow.propTypes = {
  campaign: CampaignPropType.isRequired,
};

CampaignsTableRow.defaultProps = {};

export default CampaignsTableRow;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import parseISO from 'date-fns/parseISO';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const urlPrefixRegEx = /^(?:https?:\/\/)?(?:www\.)?/i;
export const trimUrl = (url) => (url || '').replace(urlPrefixRegEx, '');
export const removeQueryStringFromUrl = (url) => {
  const split = (url || '').split('?');

  if (split.length > 1) {
    split.pop();
  }

  return split.join('');
};

export const dateMonthFormat = {
  month: 'long',
};
export const dateFormat = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

export const dateTimeFormat = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const timeFormat = {
  hour: 'numeric',
  minute: 'numeric',
};

export const dateLocaleMap = {
  en: enLocale,
  de: deLocale,
};

export const dateLocaleFormatMap = {
  en: 'MM/dd/yyyy',
  de: 'dd.MM.yyyy',
};

export const formatDate = ({ isoDate, formatter, options = {} }) =>
  formatter(parseISO(isoDate), { ...dateFormat, ...options });

export const formatDateMonth = ({ isoDate, formatter, options = {} }) =>
  formatter(parseISO(isoDate), { ...dateMonthFormat, ...options });

export const formatDateTime = ({ isoDate, formatter, options = {} }) =>
  formatter(parseISO(isoDate), { ...dateTimeFormat, ...options });

export const formatTime = ({ isoDate, formatter, options = {} }) =>
  formatter(parseISO(isoDate), { ...timeFormat, ...options });

export const intlUnitFormats = {
  percent: {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  currency: {
    style: 'currency',
    currency: 'EUR',
  },
};

export const NumberFormatsPropType = PropTypes.oneOf(
  Object.keys(intlUnitFormats),
);

export const formatNumber = ({ number, format, formatter }) =>
  formatter(number, intlUnitFormats[format] || {});

export const formatRounding = (number, digits) => {
  const n = 10 ** digits;
  return Math.trunc(number / n) * n;
};

export const sumWithRounding = (objects, field, digits = 2) =>
  objects.reduce((sum, object) => {
    const value = get(object, field, 0);
    sum += formatRounding(value, digits);
    return sum;
  }, 0);

const FormattedLabel = ({ value, label, color, refProp, ...otherProps }) => {
  const renderedValue = (
    <Typography variant="body2" color={color}>
      {value}
    </Typography>
  );

  if (label) {
    return (
      <Box display="flex" {...otherProps} ref={refProp}>
        {label}&nbsp;
        {renderedValue}
      </Box>
    );
  }

  return (
    <Box {...otherProps} ref={refProp}>
      {renderedValue}
    </Box>
  );
};

FormattedLabel.propTypes = {
  refProp: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.node,
  color: PropTypes.string,
};

export const DateLabel = React.forwardRef(
  ({ date, label, color, ...otherProps }, ref) => {
    const intl = useIntl();

    const formattedDate = !isNil(date)
      ? formatDate({
          isoDate: date,
          formatter: intl.formatDate,
        })
      : '—';

    return (
      <FormattedLabel
        value={formattedDate}
        label={label}
        color={color}
        refProp={ref}
        {...otherProps}
      />
    );
  },
);

DateLabel.propTypes = {
  date: PropTypes.string.isRequired,
  label: PropTypes.node,
  color: PropTypes.string,
};

DateLabel.defaultProps = {
  label: null,
  color: 'textPrimary',
};

export const PriceLabel = React.forwardRef(
  ({ value, label, color, ...otherProps }, ref) => {
    const intl = useIntl();

    const formattedPrice = !isNil(value)
      ? formatNumber({
          number: value,
          format: 'currency',
          formatter: intl.formatNumber,
        })
      : '—';

    return (
      <FormattedLabel
        value={formattedPrice}
        label={label}
        color={color}
        refProp={ref}
        {...otherProps}
      />
    );
  },
);

PriceLabel.propTypes = {
  value: PropTypes.number,
  label: PropTypes.node,
  color: PropTypes.string,
};

PriceLabel.defaultProps = {
  label: null,
  color: 'textPrimary',
};

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ApplicationPropType } from 'utils/application';
import { loadingString } from 'components/LoadingState';

const filterBy = (status) => (application) =>
  application.clientContentReview.status === status;

const GridStatusMessage = ({ applications, loading, ...otherProps }) => {
  const intl = useIntl();

  const formattedMessage = React.useMemo(() => {
    if (loading) {
      return (
        <Typography variant="subtitle1">
          {intl.formatMessage(loadingString)}
        </Typography>
      );
    }

    const numberOfInfluencers = applications.length;
    const numberOfAccepted = applications.filter(filterBy('accepted')).length;
    const numberOfRejected = applications.filter(filterBy('rejected')).length;
    const numberOfUndecided = applications.filter(filterBy('undecided')).length;
    const numberOfPending = applications.filter(filterBy('pending')).length;

    const influencersMessage = `${intl.formatMessage(
      {
        id: 'scenes.campaigns.clientContentPreview.statusMessage.influencers',
        defaultMessage:
          '{numberOfInfluencers, plural, one {# Influencer} other {# Influencers}}',
      },
      { numberOfInfluencers },
    )} ${intl.formatMessage({
      id: 'scenes.campaigns.clientContentPreview.statusMessage.influencers.title',
      defaultMessage: 'created content for this campaign',
    })}`;

    const accepted = intl.formatMessage(
      {
        id: 'scenes.campaigns.clientContentPreview.statusMessage.accepted',
        defaultMessage: '{numberOfAccepted} approved',
      },
      { numberOfAccepted },
    );
    const undecided = intl.formatMessage(
      {
        id: 'scenes.campaigns.clientContentPreview.statusMessage.undecided',
        defaultMessage: '{numberOfUndecided} undecided',
      },
      { numberOfUndecided },
    );
    const rejected = intl.formatMessage(
      {
        id: 'scenes.campaigns.clientContentPreview.statusMessage.rejected',
        defaultMessage: '{numberOfRejected} rejected',
      },
      { numberOfRejected },
    );
    const pending = intl.formatMessage(
      {
        id: 'scenes.campaigns.clientContentPreview.statusMessage.pending',
        defaultMessage: '{numberOfPending} not yet submitted',
      },
      { numberOfPending },
    );

    const statusMessage = `${accepted}, ${undecided}, ${rejected}, ${pending}`;

    return (
      <>
        <Typography variant="subtitle1">{influencersMessage}</Typography>
        <Typography variant="body1">{statusMessage}</Typography>
      </>
    );
  }, [applications, loading, intl]);

  return <Box {...otherProps}>{formattedMessage}</Box>;
};

GridStatusMessage.propTypes = {
  loading: PropTypes.bool,
  applications: PropTypes.arrayOf(ApplicationPropType),
};

GridStatusMessage.defaultProps = {
  loading: false,
  applications: [],
};

export default GridStatusMessage;

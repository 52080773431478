import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { ClientReviewStatusPropType } from 'utils/clientReviewStatuses';

const iconsMap = {
  pending: Icons.Circle,
  undecided: Icons.Minus,
  rejected: Icons.ThumbsDown,
  accepted: Icons.ThumbsUp,
};

const getIconColor = (status, theme) => {
  if (status === 'pending') {
    return theme.palette.secondary.main;
  }

  if (status === 'undecided') {
    return theme.palette.info.main;
  }

  if (status === 'rejected') {
    return theme.palette.error.main;
  }

  return theme.palette.success.main;
};

const ApplicationClientReviewIcon = ({
  status,
  color: colorProp,
  ...otherProps
}) => {
  const theme = useTheme();

  const color = colorProp || getIconColor(status, theme);

  const Icon = iconsMap[status] || Box;
  const ownProps = status === 'pending' ? { fill: color, size: 6 } : {};

  return <Icon color={color} {...ownProps} {...otherProps} />;
};

ApplicationClientReviewIcon.propTypes = {
  status: ClientReviewStatusPropType.isRequired,
  color: PropTypes.string,
};

ApplicationClientReviewIcon.defaultProps = {
  color: undefined,
};

export default ApplicationClientReviewIcon;

import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { formatDate } from 'utils/formatting';

import CardValue from './CardValue';

const CardDate = ({ value, label, className, ...otherProps }) => {
  const intl = useIntl();

  const formattedDate = value
    ? formatDate({
        isoDate: value,
        formatter: intl.formatDate,
      })
    : '—';

  return (
    <CardValue label={label} {...otherProps}>
      {formattedDate}
    </CardValue>
  );
};

CardDate.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CardDate.defaultProps = {
  className: '',
};

export default CardDate;

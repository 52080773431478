import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isString from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useDateStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  date: {
    marginBottom: theme.spacing(0.75),
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
}));

const PreviewDate = ({ title: titleProp, date, className, ...otherProps }) => {
  const classes = useDateStyles();

  const title = React.useMemo(() => {
    if (isString(title)) {
      return (
        <Typography
          className={classes.title}
          variant="body2"
          color="textSecondary"
        >
          {titleProp}
        </Typography>
      );
    }

    return titleProp;
  }, [titleProp]);

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <Typography className={classes.date}>{date}</Typography>
      {title}
      <Divider />
    </Box>
  );
};

PreviewDate.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  date: PropTypes.string.isRequired,
  className: PropTypes.string,
};
PreviewDate.defaultProps = {
  className: '',
};

export default PreviewDate;

import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { ContentGrid, ContentGridItem } from 'layout/ContentGrid';
import Page from 'layout/Page';
import LoadingState from 'components/LoadingState';

import { useMany as useClients } from './data/clientHooks';

import ClientCard from './components/ClientCard';

const useStyles = makeStyles({
  contentGrid: {
    overflow: 'visible',
  },
});

const ClientsListPage = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { loading, error, data } = useClients();

  if (error) {
    throw new Error(`Error fetching clients: ${error}`);
  }

  const pageTitle = intl.formatMessage({
    id: 'scenes.clients.pageTitle',
    defaultMessage: 'Clients',
  });

  return (
    <Page
      title={pageTitle}
      actions={
        <Button
          component={Link}
          to="clients/new/settings"
          color="primary"
          variant="contained"
        >
          {intl.formatMessage({
            id: 'scenes.clients.newClient',
            defaultMessage: '+ New Client',
          })}
        </Button>
      }
      {...props}
    >
      <Box height="75vh" display="flex" align="center" justifyContent="center">
        {loading && <LoadingState />}
        {!loading && (
          <ContentGrid
            columnWidths={[1024, 1280, 1536, 1900, 2048]}
            className={classes.contentGrid}
          >
            {data?.clients?.map((client) => (
              <ContentGridItem key={client.id}>
                <ClientCard client={client} />
              </ContentGridItem>
            ))}
          </ContentGrid>
        )}
      </Box>
    </Page>
  );
};

export default ClientsListPage;

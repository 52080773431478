import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import { AlignPropType } from 'utils/table';
import { formatDateTime } from 'utils/formatting';

const TableCellDateTime = ({ value, align, ...otherProps }) => {
  const intl = useIntl();

  return (
    <TableCell align={align} {...otherProps}>
      <Typography noWrap variant="body2">
        {value
          ? formatDateTime({
              isoDate: value,
              formatter: intl.formatDate,
            })
          : '—'}
      </Typography>
    </TableCell>
  );
};

TableCellDateTime.propTypes = {
  value: PropTypes.string,
  align: AlignPropType,
};

TableCellDateTime.defaultProps = {
  align: 'center',
};

export default TableCellDateTime;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { defineMessages, useIntl } from 'react-intl';
import omit from 'lodash/omit';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Feature, useFeatures } from 'utils/features';
import { ChannelPropType } from 'utils/channel';
import { UserPropType } from 'utils/user';
import { formatDateTime } from 'utils/formatting';
import { useClient } from 'utils/client';
import useAuth from 'auth/useAuth';

import Tabs from 'components/Tabs';

import ChannelCardBody from './ChannelCardBody';
import ChannelCardHeader from './ChannelCardHeader';
import ChannelCardCollaborationRatingsSection from './ChannelCardCollaborationRatingsSection';
import ChannelCardPerformanceKPISection from './ChannelCardPerformanceKPISection';
import ChannelCardAudienceKPISection from './ChannelCardAudienceKPISection';
import ChannelCardPlatformLink from './ChannelCardPlatformLink';
import ChannelCardCategories from './ChannelCardCategories';
import ChannelCardClientTags from './ChannelCardClientTags';
import ChannelCardCampaignsList from './ChannelCardCampaignsList';
import ChannelCardNotesList from './ChannelCardNotesList';
import NewChannelNoteDialog from './NewChannelNoteDialog';

const FeatureTabsWrap = ({ tabLabels, children, ...otherProps }) => {
  const { isFeatureAvailable } = useFeatures();

  if (isFeatureAvailable('collaboratorFilter')) {
    return (
      <Tabs labels={tabLabels} {...otherProps}>
        {children}
      </Tabs>
    );
  }

  return children;
};
FeatureTabsWrap.propTypes = {
  tabLabels: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: theme.typography.pxToRem(320),
    backgroundColor: '#fff',
  },
  internalNotesTitle: {
    marginTop: theme.spacing(1),
  },
}));

const ChannelCard = ({
  user,
  selectedChannel,
  channels,
  onSelectChannel,
  loadingChannels,
  className,
  loading,
  updateChannelTags,
  updateChannelNotes,
  ...otherProps
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { client } = useClient();
  const { user: appUser } = useAuth();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [newNoteDialogOpen, setNewNoteDialogOpen] = React.useState(false);

  const submitNewNote = (newNote) => {
    const newNotePayload = {
      createdAt: new Date(),
      createdBy: appUser.email,
      creatorName: appUser.name,
      note: newNote,
    };

    const cleanNotes = selectedChannel.notes.map((note) =>
      omit(note, '__typename'),
    );

    updateChannelNotes([...cleanNotes, newNotePayload]);
  };

  const tabLabelsStrings = defineMessages({
    insights: {
      id: 'components.ChannelCard.tabs.insights',
      defaultMessage: 'Insights',
    },
    campaigns: {
      id: 'components.ChannelCard.tabs.campaigns',
      defaultMessage: 'Campaigns ({numberOfApplications})',
    },
    notes: {
      id: 'components.ChannelCard.tabs.notes',
      defaultMessage: 'Notes ({numberOfNotes})',
    },
  });

  const tabLabels = [
    intl.formatMessage(tabLabelsStrings.insights),
    intl.formatMessage(tabLabelsStrings.campaigns, {
      numberOfApplications: selectedChannel.applications.length,
    }),
    intl.formatMessage(tabLabelsStrings.notes, {
      numberOfNotes: selectedChannel.notes.length,
    }),
  ];

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      <ChannelCardHeader
        user={user}
        selectedChannel={selectedChannel}
        channels={channels}
        onSelectChannel={onSelectChannel}
        loading={loadingChannels}
      />
      <FeatureTabsWrap
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
        tabLabels={tabLabels}
      >
        <ChannelCardBody>
          <ChannelCardPlatformLink channel={selectedChannel} />
          <ChannelCardCategories channel={selectedChannel} />
          <Feature feature="influencerFinderTags">
            <ChannelCardClientTags
              channel={selectedChannel}
              onSubmit={updateChannelTags}
              loading={loading}
            />
          </Feature>
          <ChannelCardPerformanceKPISection channel={selectedChannel} />
          <ChannelCardCollaborationRatingsSection channel={selectedChannel} />
          <ChannelCardAudienceKPISection channel={selectedChannel} />
        </ChannelCardBody>
        <Feature feature="collaboratorFilter">
          <ChannelCardBody py={1}>
            <Typography variant="overline" color="textSecondary">
              {intl.formatMessage({
                id: 'components.ChannelCard.tabs.campaigns.lastUpdatedLabel',
                defaultMessage: 'Last Updated',
              })}
              &nbsp;
              {formatDateTime({
                isoDate: selectedChannel.updatedAt,
                formatter: intl.formatDate,
              })}
            </Typography>
            <ChannelCardCampaignsList
              applications={selectedChannel.applications}
            />
          </ChannelCardBody>
        </Feature>
        <Feature feature="collaboratorFilter">
          <ChannelCardBody>
            <NewChannelNoteDialog
              open={newNoteDialogOpen}
              onClose={() => setNewNoteDialogOpen(false)}
              onSave={submitNewNote}
            />
            <Box display="flex" justifyContent="space-between">
              <Typography
                className={classes.internalNotesTitle}
                variant="overline"
                color="textSecondary"
              >
                {intl.formatMessage(
                  {
                    id: 'components.ChannelCard.tabs.notes.title',
                    defaultMessage: '{clientName} Internal Notes',
                  },
                  {
                    clientName: client.name,
                  },
                )}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setNewNoteDialogOpen(true)}
              >
                +
              </Button>
            </Box>
            <ChannelCardNotesList notes={selectedChannel.notes} />
          </ChannelCardBody>
        </Feature>
      </FeatureTabsWrap>
    </Box>
  );
};

ChannelCard.propTypes = {
  user: UserPropType.isRequired,
  selectedChannel: ChannelPropType.isRequired,
  channels: PropTypes.arrayOf(ChannelPropType),
  onSelectChannel: PropTypes.func.isRequired,
  loadingChannels: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  updateChannelTags: PropTypes.func.isRequired,
  updateChannelNotes: PropTypes.func.isRequired,
};

ChannelCard.defaultProps = {
  className: '',
  loadingChannels: false,
  channels: [],
};

export default ChannelCard;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MuiCardMedia from '@material-ui/core/CardMedia';
import TableCell from '@material-ui/core/TableCell';

import { AlignPropType } from 'utils/table';

const useStyles = makeStyles({
  media: {
    height: 'inherit',
  },
});

const TableCellImage = ({ src, align, ...otherProps }) => {
  const classes = useStyles();

  return (
    <TableCell padding="none" align={align} {...otherProps}>
      <MuiCardMedia className={classes.media} component="img" src={src} />
    </TableCell>
  );
};

TableCellImage.propTypes = {
  align: AlignPropType,
  src: PropTypes.string.isRequired,
};

TableCellImage.defaultProps = {
  align: 'left',
};

export default TableCellImage;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MuiTableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => props.color,
    '&$selected': {
      backgroundColor: (props) => props.color,
    },
  },
  // This logic keeps the background color for hover the same
  // while applying the hover color on the TDs, this creates a kind of
  // overlay effect that lightens whatever color you set
  hover: {
    cursor: (props) => (props.selectable ? 'pointer' : 'default'),
    '&:hover': {
      backgroundColor: (props) => `${props.color} !important`,
    },
    '&:hover > td': {
      backgroundColor: theme.palette.action.hover,
    },
    '&$selected:hover > td': {
      backgroundColor: theme.palette.action.selectedDark,
    },
  },
  selected: {
    backgroundColor: (props) => props.color,
    '& > td': {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

const TableRow = (props) => {
  const { selectable, selectedColor, color, ...otherProps } = props;

  const classes = useStyles(props);

  return <MuiTableRow classes={classes} {...otherProps} />;
};

TableRow.propTypes = {
  color: PropTypes.string,
  selectedColor: PropTypes.string,
  selectable: PropTypes.bool,
};

TableRow.defaultProps = {
  color: 'inherit',
  selectedColor: 'inherit',
  selectable: true,
};

export default TableRow;

import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Toggle from 'components/Toggle';

const HiddenToggle = React.forwardRef(
  ({ label, value, onChange, ...otherProps }, ref) => {
    const theme = useTheme();

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        ref={ref}
        {...otherProps}
      >
        <Toggle label={label} value={value} onChange={onChange} />
        {!value ? (
          <Icons.EyeOff color={theme.palette.primary.light} />
        ) : (
          <Icons.Eye color={theme.palette.primary.main} />
        )}
      </Box>
    );
  },
);

HiddenToggle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
HiddenToggle.defaultProps = {};

export default HiddenToggle;

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: 0,
  },
  tab: {
    backgroundColor: theme.palette.secondary.light,
    textTransform: 'none',
  },
}));

const Tabs = ({ selectedTab, onSelectTab, children, labels }) => {
  const classes = useStyles();

  const getColor = (index) =>
    index === selectedTab ? 'textPrimary' : 'primary';

  return (
    <TabContext value={String(selectedTab)}>
      <TabList
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, value) => onSelectTab(Number(value))}
      >
        {labels.map((label, index) => (
          <Tab
            key={label}
            label={
              <Typography variant="subtitle1" color={getColor(index)}>
                {label}
              </Typography>
            }
            value={String(index)}
            className={classes.tab}
            disableFocusRipple
            disableRipple
          />
        ))}
      </TabList>
      {React.Children.map(children, (child, index) => (
        <TabPanel
          key={labels[index]}
          value={String(index)}
          className={classes.tabPanel}
        >
          {React.cloneElement(child, {
            label: labels[index],
            value: index,
          })}
        </TabPanel>
      ))}
    </TabContext>
  );
};

Tabs.propTypes = {
  selectedTab: PropTypes.number,
  onSelectTab: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};
Tabs.defaultProps = {
  selectedTab: 0,
};

export default Tabs;

import React from 'react';
import isEqual from 'lodash/isEqual';

import { useFeatures } from 'utils/features';
import { sortToQuery } from 'utils/sorting';
import {
  useQueryParams,
  SortParam,
  StringParam,
  ArrayParam,
  withDefault,
} from 'utils/routing';

import {
  useMany as useInfluencerAssets,
  useCreateInfluencerAssetPublication,
  useDeleteInfluencerAssetPublication,
} from './influencerAssetHooks';

const defaultState = {
  influencerAssets: [],
  inspectedInfluencerAsset: null,
  selectedMediaType: 'all',
  selectedTags: [],
  sort: {
    by: 'createdAt',
    direction: 'desc',
  },
  size: 'full',
  dialogsOpen: {
    addPublication: false,
    deletePublication: false,
  },
};

const actionTypes = {
  INSPECT: 'inspect',
  SET_DATA: 'set_data',
  FILTER_BY_MEDIA_TYPE: 'filter_by_media_type',
  FILTER_BY_TAGS: 'filter_by_tags',
  SET_SIZE: 'set_size',
  SORT_BY: 'sort_by',
  ADD_PUBLICATION: 'add_publication',
  DELETE_PUBLICATION: 'delete_publication',
  OPEN_DIALOG: 'open_dialog',
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.INSPECT: {
      const { influencerAssets } = state;
      const id = action.payload;

      if (!id) {
        return {
          ...state,
          inspectedInfluencerAsset: null,
          size: 'full',
        };
      }

      const influencerAsset = influencerAssets.find((cp) => cp.id === id);

      if (!influencerAsset) {
        return {
          ...state,
          inspectedInfluencerAsset: null,
        };
      }

      return {
        ...state,
        inspectedInfluencerAsset: influencerAsset,
        size: 'reduced',
      };
    }

    case actionTypes.SET_DATA: {
      const { loading, data: { influencerAssets = [] } = {} } = action.payload;

      return {
        ...state,
        loading,
        influencerAssets,
      };
    }

    case actionTypes.FILTER_BY_MEDIA_TYPE: {
      const newFilterValue = action.payload;

      if (state.selectedMediaType === newFilterValue) {
        return state;
      }

      return {
        ...state,
        selectedMediaType: newFilterValue,
      };
    }

    case actionTypes.FILTER_BY_TAGS: {
      const newTags = action.payload;

      return {
        ...state,
        selectedTags: newTags,
      };
    }

    case actionTypes.SORT_BY: {
      const newSort = action.payload;

      if (isEqual(newSort, state.sort)) {
        return state;
      }

      return {
        ...state,
        sort: newSort,
      };
    }

    case actionTypes.SET_SIZE: {
      const newSize = action.payload;

      return {
        ...state,
        size: newSize,
      };
    }

    case actionTypes.OPEN_DIALOG: {
      const { open, name } = action.payload;

      return {
        ...state,
        dialogsOpen: {
          ...state.dialogsOpen,
          [name]: open,
        },
      };
    }

    case actionTypes.ADD_PUBLICATION: {
      const inspectedInfluencerAsset = state.influencerAssets.find(
        (cp) => cp.id === state.inspectedInfluencerAsset.id,
      );

      return {
        ...state,
        inspectedInfluencerAsset,
        dialogsOpen: {
          ...state.dialogsOpen,
          addPublication: false,
        },
      };
    }

    case actionTypes.DELETE_PUBLICATION: {
      const inspectedInfluencerAsset = state.influencerAssets.find(
        (cp) => cp.id === state.inspectedInfluencerAsset.id,
      );

      return {
        ...state,
        inspectedInfluencerAsset,
        dialogsOpen: {
          ...state.dialogsOpen,
          deletePublication: false,
        },
      };
    }

    default:
      throw new Error(`Action "${action.type}" is not defined`);
  }
};

export default () => {
  const [query, setQuery] = useQueryParams({
    inspect: StringParam,
    tags: withDefault(ArrayParam, []),
    mediaType: withDefault(StringParam, 'all'),
    sort: withDefault(SortParam, defaultState.sort),
  });
  const { isFeatureAvailable } = useFeatures();

  const [state, dispatch] = React.useReducer(reducer, {
    ...defaultState,
    influencerAssets: [],
    loading: true,
    selectedTags: query.tags,
    selectedMediaType: query.mediaType,
    sort: query.sort,
  });

  const { createInfluencerAssetPublication } =
    useCreateInfluencerAssetPublication(sortToQuery(state.sort));
  const { deleteInfluencerAssetPublication } =
    useDeleteInfluencerAssetPublication(sortToQuery(state.sort));

  const { loading, error, data } = useInfluencerAssets({
    variables: {
      sort: isFeatureAvailable('influencerAssetsSort')
        ? sortToQuery(state.sort)
        : null,
      limit: 300,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    throw new Error(
      `Failed to fetch influencer assets: ${JSON.stringify(error)}`,
    );
  }

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_DATA,
      payload: {
        loading,
        data,
      },
    });
  }, [loading, data]);

  const setSize = React.useCallback((newSize) => {
    dispatch({
      type: actionTypes.SET_SIZE,
      payload: newSize,
    });
  }, []);

  // Inspect
  const inspectCard = React.useCallback((id) => {
    dispatch({
      type: actionTypes.INSPECT,
      payload: id,
    });
    setQuery({ inspect: id || undefined });
  }, []);

  React.useEffect(() => {
    if (!loading) {
      if (query.inspect !== state?.inspectedInfluencerAsset?.id) {
        dispatch({
          type: actionTypes.INSPECT,
          payload: query.inspect,
        });
      }
    }
  }, [loading, query.inspect, state.inspectedInfluencerAsset]);

  // Media Type
  const filterByMediaType = React.useCallback((newFilterType) => {
    dispatch({
      type: actionTypes.FILTER_BY_MEDIA_TYPE,
      payload: newFilterType,
    });
    setQuery({ mediaType: newFilterType });
  }, []);

  React.useEffect(() => {
    if (query.mediaType !== state.selectedMediaType) {
      dispatch({
        type: actionTypes.FILTER_BY_MEDIA_TYPE,
        payload: query.mediaType,
      });
    }
  }, [query.mediaType, state.selectedMediaType]);

  // Tags
  const filterByTags = React.useCallback((_, newTags) => {
    dispatch({
      type: actionTypes.FILTER_BY_TAGS,
      payload: newTags,
    });
    setQuery({ tags: newTags });
  }, []);

  React.useEffect(() => {
    if (!isEqual(query.tags, state.selectedTags)) {
      dispatch({
        type: actionTypes.FILTER_BY_TAGS,
        payload: query.tags,
      });
    }
  }, [query.tags, state.selectedTags]);

  // Sort
  const sortBy = React.useCallback((newSort) => {
    dispatch({
      type: actionTypes.SORT_BY,
      payload: newSort,
    });
    if (isFeatureAvailable('influencerAssetsSort')) {
      setQuery({ sort: newSort });
    }
  }, []);

  React.useEffect(() => {
    if (isFeatureAvailable('influencerAssetsSort')) {
      if (!isEqual(query.sort, state.sort)) {
        dispatch({
          type: actionTypes.SORT_BY,
          payload: query.sort,
        });
      }
    }
  }, [query.sort, state.sort]);

  const openDialog = React.useCallback(
    (name) => {
      dispatch({
        type: actionTypes.OPEN_DIALOG,
        payload: { name, open: true },
      });
    },
    [dispatch],
  );

  const closeDialog = React.useCallback(
    (name) => {
      dispatch({
        type: actionTypes.OPEN_DIALOG,
        payload: { name, open: false },
      });
    },
    [dispatch],
  );

  const addPublication = React.useCallback(
    async (data) => {
      const updatedPublication = await createInfluencerAssetPublication({
        ...data,
        influencerAssetId: state.inspectedInfluencerAsset.id,
      });

      dispatch({
        type: actionTypes.ADD_PUBLICATION,
        payload: {
          publication: updatedPublication,
        },
      });
    },
    [
      dispatch,
      createInfluencerAssetPublication,
      state.inspectedInfluencerAsset,
    ],
  );

  const deletePublication = React.useCallback(
    async (publicationId) => {
      await deleteInfluencerAssetPublication(publicationId);

      dispatch({
        type: actionTypes.DELETE_PUBLICATION,
        payload: { publicationId },
      });
    },
    [dispatch, deleteInfluencerAssetPublication],
  );

  const actions = React.useMemo(
    () => ({
      inspectCard,
      filterByMediaType,
      filterByTags,
      setSize,
      sortBy,
      openDialog,
      closeDialog,
      addPublication,
      deletePublication,
    }),
    [
      inspectCard,
      filterByMediaType,
      filterByTags,
      setSize,
      sortBy,
      openDialog,
      closeDialog,
      addPublication,
      deletePublication,
    ],
  );

  return {
    state,
    actions,
  };
};

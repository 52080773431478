import { defineMessages, defineMessage } from 'react-intl';
import PropTypes from 'prop-types';
import memoize from 'lodash/memoize';

import { useTheme } from '@material-ui/core/styles';

const statusLabels = defineMessages({
  all: {
    id: 'privateNetwork.clientReview.all',
    defaultMessage: 'All',
  },
  undecided: {
    id: 'privateNetwork.clientReview.undecided',
    defaultMessage: 'Undecided',
  },
  accepted: {
    id: 'application.clientReview.accepted',
    defaultMessage: 'Accepted',
  },
  rejected: {
    id: 'application.clientReview.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'application.clientReview.pending',
    defaultMessage: 'New',
  },
});

export const ClientReviewStatusPropType = PropTypes.oneOf([
  'undecided',
  'accepted',
  'rejected',
  'pending',
]);

export const getStatusLabel = memoize((status) => {
  if (!status) {
    return statusLabels.all;
  }

  return statusLabels[status];
});

export const useStatusBadge = (status) => {
  const theme = useTheme();

  if (status === 'accepted') {
    return {
      label: defineMessage({
        id: 'privateNetworkApplication.client.statusBadge.accepted.label',
        defaultMessage: 'In the Private Network',
      }),
      tooltip: defineMessage({
        id: 'privateNetworkApplication.client.statusBadge.accepted.tooltip',
        defaultMessage:
          "You've accepted this influencer's request to join your Private Network",
      }),
      color: theme.palette.eqolot.softGreen,
    };
  }

  if (status === 'rejected') {
    return {
      label: defineMessage({
        id: 'privateNetworkApplication.client.statusBadge.rejected.label',
        defaultMessage: 'Rejected from Private Network',
      }),
      tooltip: defineMessage({
        id: 'privateNetworkApplication.client.statusBadge.rejected.tooltip',
        defaultMessage:
          "You've rejected this influencer's request to join your Private Network",
      }),
      color: theme.palette.text.secondary,
    };
  }

  return {
    label: defineMessage({
      id: 'privateNetworkApplication.client.statusBadge.pending.label',
      defaultMessage: 'Pending request',
    }),
    tooltip: defineMessage({
      id: 'privateNetworkApplication.client.statusBadge.pending.tooltip',
      defaultMessage:
        'This influencer has requested to join the Private Network',
    }),
    color: theme.palette.eqolot.infoBlue,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import InternalInfluencerBadge from 'images/internalInfluencerBadge.svg';
import { ChannelPropType } from 'utils/channel';
import Avatar from './Avatar';

const useStyles = makeStyles({
  badgeBase: {
    background: `url('${InternalInfluencerBadge}')`,
    backgroundSize: 'cover',
    borderRadius: 0,
  },
  smallBadge: {
    top: 5,
    right: 5,
    transform: 'scale(0.4) translate(50%, -50%)',
  },
  mediumBadge: {
    top: 10,
    right: 10,
    transform: 'scale(0.8) translate(50%, -50%)',
  },
  tooltip: {},
});

const ChannelAvatar = ({
  gravatarBackgroundColor,
  channel,
  user,
  withBadge,
  badgeSize,
  tooltipTitle,
  classes: classesProp,
  ...otherProps
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesProp });

  const { name: channelName, avatarUrl } = channel;

  const avatar = (
    <Avatar
      url={avatarUrl}
      email={user.email}
      fallbackName={user.name || channelName}
      backgroundColor={gravatarBackgroundColor}
      {...otherProps}
    />
  );

  if (!withBadge) {
    return (
      <Tooltip
        classes={{
          popper: classes.tooltip,
        }}
        title={tooltipTitle}
        placement="top"
      >
        <Box>{avatar}</Box>
      </Tooltip>
    );
  }

  if (!channel.external || tooltipTitle) {
    const badgeClasses = clsx(classes.badgeBase, {
      [classes.smallBadge]: badgeSize === 'small',
      [classes.mediumBadge]: badgeSize === 'medium',
    });

    const title =
      tooltipTitle ||
      intl.formatMessage({
        id: 'components.InfluencerAvatar.internalBadge',
        defaultMessage: 'Registered on the eqolot platform',
      });

    return (
      <Tooltip
        classes={{
          popper: classes.tooltip,
        }}
        title={title}
        placement="top"
      >
        <Badge
          classes={{ badge: badgeClasses }}
          color="secondary"
          badgeContent=" "
        >
          {avatar}
        </Badge>
      </Tooltip>
    );
  }

  return avatar;
};

ChannelAvatar.propTypes = {
  user: PropTypes.shape({
    gravatar: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  gravatarBackgroundColor: PropTypes.string,
  channel: ChannelPropType.isRequired,
  withBadge: PropTypes.bool,
  badgeSize: PropTypes.oneOf(['small', 'medium']),
  tooltipTitle: PropTypes.string,
  classes: PropTypes.object,
};

ChannelAvatar.defaultProps = {
  user: {},
  gravatarBackgroundColor: undefined,
  withBadge: true,
  badgeSize: 'medium',
  tooltipTitle: '',
};

export default ChannelAvatar;

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import InfluencerAssetsCard from 'components/influencerAssets/InfluencerAssetCard';
import { ContentGrid, ContentGridItem } from 'layout/ContentGrid';
import TagsInput from 'components/TagsInput';
import LoadingState from 'components/LoadingState';
import { Feature } from 'utils/features';
import InspectorDrawerContent from 'components/inspector/InspectorDrawerContent';

import useGridState from './data/useGridState';
import { useTags } from './data/tags';
import GridTypeFilters from './GridTypeFilters';
import InfluencerAssetInspector, {
  drawerWidth as influencerAssetInspectorDrawerWidth,
} from './InfluencerAssetInspector';
import GridStatusMessage from './GridStatusMessage';
import InfluencerAssetsSort from './InfluencerAssetsSort';

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
  controlsRow: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(4),
    },
  },
  hidden: {
    display: 'none',
  },
  tagsFilter: {
    minWidth: theme.typography.pxToRem(320),
  },
  contentGrid: {
    overflow: 'visible',
  },
}));

const InfluencerAssetsGrid = () => {
  const { state, actions } = useGridState();
  const {
    influencerAssets,
    loading,
    inspectedInfluencerAsset,
    selectedMediaType,
    selectedTags,
    size,
    sort,
    dialogsOpen,
  } = state;

  const {
    filterByMediaType,
    filterByTags,
    inspectCard,
    sortBy,
    addPublication,
    deletePublication,
    openDialog,
    closeDialog,
  } = actions;

  const influencerAssetInspectorActions = React.useMemo(
    () => ({
      addPublication,
      deletePublication,
      inspectCard,
      openDialog,
      closeDialog,
    }),
    [actions],
  );

  const classes = useStyles();
  const intl = useIntl();
  const { tags, getTagLabelByValue } = useTags();

  const shouldShowByTags = ({ tags }) =>
    isEmpty(selectedTags) || !isEmpty(intersection(tags, selectedTags));
  const shouldShowByMediaType = ({ mediaType }) =>
    selectedMediaType === 'all' || selectedMediaType === mediaType;
  const shouldShowInfluencerAsset = (influencerAsset) =>
    shouldShowByMediaType(influencerAsset) && shouldShowByTags(influencerAsset);

  const isSelected = (id) =>
    !!inspectedInfluencerAsset && inspectedInfluencerAsset.id === id;

  return (
    <Box>
      <Box className={classes.controls}>
        <Box className={classes.controlsRow}>
          <GridTypeFilters
            value={selectedMediaType}
            onChange={filterByMediaType}
          />
          <TagsInput
            className={classes.tagsFilter}
            value={selectedTags}
            options={tags}
            onChange={filterByTags}
            renderOption={getTagLabelByValue}
            getTagLabel={getTagLabelByValue}
            label={intl.formatMessage({
              id: 'scenes.influencerAssets.grid.filter.categories',
              defaultMessage: 'Categories',
            })}
          />
        </Box>
        <Box className={classes.controlsRow}>
          <GridStatusMessage
            influencerAssets={influencerAssets.filter(
              shouldShowInfluencerAsset,
            )}
            loading={loading}
          />
          <Feature feature="influencerAssetsSort">
            <InfluencerAssetsSort currentSort={sort} onSort={sortBy} />
          </Feature>
        </Box>
      </Box>
      {loading && <LoadingState mt="30%" />}
      <Box display="flex">
        <InspectorDrawerContent
          drawerOpen={!!inspectedInfluencerAsset}
          drawerWidth={influencerAssetInspectorDrawerWidth}
        >
          {!loading && (
            <ContentGrid size={size} className={classes.contentGrid}>
              {influencerAssets.map(
                ({ id, mediaType, file, tags, createdAt, buyOutPrice }) => (
                  <ContentGridItem
                    key={id}
                    className={clsx({
                      [classes.hidden]: !shouldShowInfluencerAsset({
                        mediaType,
                        tags,
                      }),
                    })}
                  >
                    <InfluencerAssetsCard
                      id={id}
                      mediaType={mediaType}
                      mediaSrc={file}
                      tags={tags}
                      getTagLabel={getTagLabelByValue}
                      createdAt={createdAt}
                      buyOutPrice={buyOutPrice}
                      selected={isSelected(id)}
                      onInspect={inspectCard}
                    />
                  </ContentGridItem>
                ),
              )}
            </ContentGrid>
          )}
        </InspectorDrawerContent>
        <InfluencerAssetInspector
          influencerAsset={inspectedInfluencerAsset}
          onClose={() => inspectCard(null)}
          dialogsOpen={dialogsOpen}
          actions={influencerAssetInspectorActions}
        />
      </Box>
    </Box>
  );
};

export default InfluencerAssetsGrid;

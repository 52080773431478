import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import LineClamp from 'shiitake';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

import { views as clientViews } from 'utils/client';
import CardDate from 'components/CardDate';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  mediaContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
    background: (props) => props.sidebarColor,
  },
  media: {
    height: '50%',
    width: '100%',
    objectFit: 'contain',
  },
  title: {
    maxWidth: '90%',
    textAlign: 'left',
  },
  content: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    // By default MUI applies bottom padding of spacing(3) if
    // content is the last child
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  contentRow: {
    display: 'flex',
    '& > *:first-child': {
      marginRight: '30%',
    },
  },
}));

const ClientCard = ({ client, className, ...otherProps }) => {
  const classes = useStyles({ sidebarColor: client.theme.sidebarColor });
  const intl = useIntl();

  const settingsLink = `/clients/${client.id}/${clientViews.SETTINGS.id}`;

  return (
    <Card className={clsx(classes.root, className)} {...otherProps}>
      <CardActionArea component={Link} to={settingsLink}>
        <Box className={classes.mediaContainer}>
          <img
            className={classes.media}
            src={client.theme.logoUrl}
            alt={client.name}
          />
        </Box>
        <CardContent className={classes.content}>
          <Box className={classes.titleRow}>
            <Typography className={classes.title} color="primary" variant="h6">
              <LineClamp lines={1}>{client.name}</LineClamp>
            </Typography>
          </Box>
          <Box className={classes.contentRow}>
            <CardDate
              value={client.createdAt}
              label={intl.formatMessage({
                id: 'scenes.clients.ClientCard.createdAt',
                defaultMessage: 'Created',
              })}
            />
            <CardDate
              value={client.updatedAt}
              label={intl.formatMessage({
                id: 'scenes.clients.ClientCard.updatedAt',
                defaultMessage: 'Last Update',
              })}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ClientCard.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    theme: PropTypes.shape({
      logoUrl: PropTypes.string,
      sidebarColor: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

ClientCard.defaultProps = {
  className: '',
};

export default ClientCard;

import PropTypes from 'prop-types';

export const TaskPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  list: PropTypes.string,
  headline: PropTypes.string,
  body: PropTypes.string,
  clientLabel: PropTypes.string,
  individual: PropTypes.bool,
  clientAccess: PropTypes.bool,
  proxy: PropTypes.bool,
  completed: PropTypes.bool,
  updated: PropTypes.bool,
  updatedBy: PropTypes.string,
});

import React from 'react';

import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import intersection from 'lodash/intersection';

import { useQueryParams, JsonParam } from 'utils/routing';
import { getCollaborationRatingsFilter } from 'utils/collaborationRatings';

import { useOneClient as usePrivateNetwork } from '../data/privateNetworkHooks';

export const supportedFilterCountries = ['de', 'at', 'ch'];

const channelPlatforms = ['instagram', 'tiktok', 'pinterest', 'youtube', 'ga'];

const defaultState = {
  privateNetwork: {},
  loading: true,
  filters: {
    // channels filters
    userName: '',
    channelName: '',
    platforms: [],
    salutation: '',
    countries: [],
    collaborationRatings: [],
    tags: {},
    // applications filters
    clientReviewStatus: [],
  },
};

const actionTypes = {
  SET_DATA: 'set_data',
  FILTER_BY: 'filter_by',
};

const defaultQueryFilters = {};

const mergeFilterIntoState = (filtersState, newFilter) => {
  const { key, value } = newFilter;

  if (isEmpty(value)) {
    unset(filtersState, key);

    return {
      ...filtersState,
    };
  }

  return {
    ...filtersState,
    [key]: value,
  };
};

const parseFilters = (filtersString) => {
  if (filtersString) {
    const parsedFilters = JSON.parse(filtersString);

    if (!isEmpty(parsedFilters.countries)) {
      const sanitizedCountries = intersection(
        supportedFilterCountries,
        parsedFilters.countries.map(lowerCase),
      );
      set(parsedFilters, 'countries', sanitizedCountries);
    }

    return parsedFilters;
  }

  return defaultState.filters;
};

const filtersToQuery = (filters) => {
  const queryFilters = {
    ...defaultQueryFilters,
    // useQuery checks the object reference here, if it remains the same
    // it will not detect updates to the filter
  };

  if (!isEmpty(filters.countries)) {
    set(queryFilters, 'user.countries', filters.countries);
  } else {
    set(queryFilters, 'user.countries', supportedFilterCountries);
  }

  if (!isEmpty(filters.collaborationRatings)) {
    const values = filters.collaborationRatings.reduce((memo, filterId) => {
      memo[filterId] = getCollaborationRatingsFilter(filterId).value;
      return memo;
    }, {});
    set(queryFilters, 'collaborationRatings', values);
  } else {
    unset(queryFilters, 'collaborationRatings');
  }

  if (filters?.tags?.clientId && !isEmpty(filters?.tags?.values)) {
    set(queryFilters, 'tags', filters.tags);
  } else {
    unset(queryFilters, 'tags');
  }

  if (filters.userName) {
    set(queryFilters, 'user.firstname', filters.userName);
  } else {
    unset(queryFilters, 'user.firstname');
  }

  if (filters.channelName) {
    set(queryFilters, 'name', filters.channelName);
  } else {
    unset(queryFilters, 'name');
  }

  if (isEmpty(filters.platforms)) {
    set(queryFilters, 'platforms', channelPlatforms);
  } else {
    set(queryFilters, 'platforms', filters.platforms);
  }

  if (isEmpty(filters.clientReviewStatus)) {
    unset(queryFilters, 'clientReviewStatus');
  } else {
    set(queryFilters, 'clientReviewStatus', filters.clientReviewStatus);
  }

  if (filters.salutation) {
    set(queryFilters, 'user.salutations', [filters.salutation]);
  } else {
    unset(queryFilters, 'user.salutations');
  }

  return queryFilters;
};

const getQueryParams = ({ filters }) => {
  const query = filtersToQuery(filters);

  const { clientReviewStatus, ...channels } = query;

  return {
    channels,
    clientReviewStatus,
  };
};

const FiltersParam = {
  encode: JsonParam.encode,
  decode: parseFilters,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_DATA: {
      const { loading, data } = action.payload;
      const privateNetwork = get(data, 'privateNetwork', { applications: [] });

      return {
        ...state,
        loading,
        privateNetwork,
      };
    }

    case actionTypes.FILTER_BY: {
      return {
        ...state,
        filters: mergeFilterIntoState(state.filters, action.payload),
      };
    }

    default:
      throw new Error(`Action "${action.type}" is not defined`);
  }
};

export default () => {
  const [query, setQuery] = useQueryParams({
    filters: FiltersParam,
  });

  const [state, dispatch] = React.useReducer(reducer, {
    ...defaultState,
    filters: {
      ...query.filters,
    },
  });

  const searchParams = getQueryParams(state);

  const { loading, error, data } = usePrivateNetwork({
    variables: {
      applicationsQuery: searchParams,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    throw new Error(
      `Failed to fetch channels search: ${JSON.stringify(error)}`,
    );
  }

  // Filters Data
  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_DATA,
      payload: {
        loading,
        data,
      },
    });
  }, [loading, data]);

  // Filters
  const filterBy = React.useCallback((newFilter) => {
    dispatch({
      type: actionTypes.FILTER_BY,
      payload: newFilter,
    });
  }, []);

  React.useEffect(() => {
    setQuery({ filters: state.filters }, 'replaceIn');
  }, [state.filters]);

  const actions = React.useMemo(() => ({ filterBy }), [filterBy]);

  return {
    state,
    actions,
  };
};

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import values from 'lodash/values';
import isNumber from 'lodash/isNumber';
import find from 'lodash/find';
import { useIntl } from 'react-intl';

import { formatNumber as numberFormatter } from 'utils/formatting';
import { useQueryParams, StringParam } from 'utils/routing';

export const platforms = [
  'instagram',
  'tiktok',
  'website',
  'ga',
  'twitter',
  'facebook',
  'pinterest',
  'youtube',
];

export const getChannelLink = (channel) => {
  switch (channel.platform) {
    case 'instagram':
      return `https://instagram.com/${channel.name}`;
    case 'tiktok':
      return `https://tiktok.com/@${channel.name}`;
    case 'pinterest':
      return channel.data.url;
    case 'youtube':
      return channel.data.url;
    case 'website':
      return channel.data.url;
    case 'ga':
      return channel.data.url;
    default:
      return null;
  }
};

export const getAvgRating = (channel) => {
  const allRatingsValues = values(channel.collaborationRatings);
  const allRatings = compact(allRatingsValues).filter(isNumber);

  if (isEmpty(allRatings)) {
    return null;
  }

  return (
    allRatings.reduce(
      (overallRating, ratingValue) => overallRating + ratingValue,
      0,
    ) / allRatings.length
  );
};

export const ChannelsMetaLabel = ({ platform, meta }) => {
  const intl = useIntl();

  const metaLabel = React.useMemo(() => {
    const channelsLabel = intl.formatMessage(
      {
        id: 'components.ChannelsTable.metaLabel.channelsCount',
        defaultMessage:
          '{channelsCount, plural, one {channel reaching} other {channels reaching}}',
      },
      {
        channelsCount: meta.count,
      },
    );

    const followersLabel = intl.formatMessage(
      {
        id: 'components.ChannelsTable.metaLabel.followersCount',
        defaultMessage: '{reach, plural, one {follower} other {followers}}',
      },
      {
        reach: meta.reach,
      },
    );

    const impressionsLabel = intl.formatMessage(
      {
        id: 'components.ChannelsTable.metaLabel.impressionsCount',
        defaultMessage: '{reach, plural, one {impression} other {impressions}}',
      },
      {
        reach: meta.reach,
      },
    );

    const formatNumber = (number) =>
      numberFormatter({ formatter: intl.formatNumber, number });

    return [
      formatNumber(meta.count),
      channelsLabel,
      formatNumber(meta.reach),
      platform === 'pinterest' ? impressionsLabel : followersLabel,
    ].join(' ');
  }, [meta, intl]);

  return metaLabel;
};

ChannelsMetaLabel.propTypes = {
  meta: PropTypes.shape({
    reach: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
};

export const PlatformPropType = PropTypes.oneOf(platforms);
export const CategoryPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  weight: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
});
export const ChannelNotePropType = PropTypes.shape({
  note: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedAt: PropTypes.string,
  creatorName: PropTypes.string,
});
export const ChannelPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  platform: PlatformPropType.isRequired,
  categories: PropTypes.arrayOf(CategoryPropType),
  kpi: PropTypes.object,
  reach: PropTypes.number,
  collaborationRatings: PropTypes.shape({
    visualCreativity: PropTypes.number,
    copyWriting: PropTypes.number,
    punctuality: PropTypes.number,
    professionalism: PropTypes.number,
  }),
  avatarUrl: PropTypes.string,
});
export const CRMChannelPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  notes: PropTypes.arrayOf(ChannelNotePropType),
  updatedAt: PropTypes.string,
});

const USER_CHANNELS = gql`
  query ChannelSearch(
    $page: Int = 1
    $query: ChannelSearchQuery
    $sort: ChannelSearchSort
  ) {
    channelSearch(page: $page, query: $query, sort: $sort) {
      data {
        id
        name
        reach
        platform
        external
        tags
        avatarUrl
        data {
          url
        }
        categories {
          code
          weight
        }
        insights {
          ... on YoutubeInsights {
            viewCount
            commentCount
            subscriberCount
            hiddenSubscriberCount
            videoCount
            date
          }
          ... on BlogInsights {
            pageviews
            countries {
              country
              visits
              percentage
            }
          }
        }
        kpi {
          ... on TikTokKPI {
            audienceStatistic {
              genders
            }
            geoStatistic {
              countries
            }
            followers
            topCountry
            viewsPerVideo
            viewsPerVideoMedian
            viewsTotal
            updatedByAt
          }
        }
        keyMetrics {
          ... on InstagramKeyMetrics {
            metricsValidAt
            source
            followers
            engagementRate
            followersHistory {
              month
              followers
            }
            audience {
              countries
              gender
              ageRange
            }
            topCountry
            storiesImpressions
            postsImpressions
            storyImpressionMedian
            postImpressionMedian
            storyImpressionRatio
            postImpressionRatio
            updatedByAt
          }
          ... on PinterestKeyMetrics {
            followers
            impressionMedian
            engagementMedian
            engagementRatio
            pinClickMedian
            pinClickRatio
            saveMedian
            saveRatio
            updatedAt
          }
        }
        collaborationRatings {
          visualCreativity
          copyWriting
          punctuality
          professionalism
        }
        updatedAt
        applications {
          applicationId
          campaignId
          campaignName
          campaignStatus
          campaignSummaryStatus
          applicationStatus
          clientApplicationReview {
            status
            note
            updatedBy
            updatedAt
          }
          createdAt
        }
        notes {
          note
          createdBy
          createdAt
          creatorName
        }
      }
    }
  }
`;

export const useUserChannels = (userId, collaboratorsOnly) => {
  const { error, data, loading } = useQuery(USER_CHANNELS, {
    fetchPolicy: 'no-cache',
    skip: !userId,
    variables: {
      query: {
        collaborator: collaboratorsOnly || undefined,
        platforms: ['instagram', 'youtube', 'tiktok', 'ga', 'pinterest'],
        user: {
          ids: [Number(userId)],
        },
      },
    },
  });

  if (error) {
    throw new Error('Failed to get user channels');
  }

  return {
    data: data?.channelSearch?.data || [],
    loading,
  };
};

export const useUserChannelsState = (
  userId,
  defaultChannel,
  collaboratorsOnly = true,
) => {
  const [query, setQuery] = useQueryParams({
    inspectChannel: StringParam,
  });

  const { loading, data: userChannels } = useUserChannels(
    userId,
    collaboratorsOnly,
  );
  const [selectedChannelId, setSelectedChannelId] = React.useState(
    query?.inspectChannel || defaultChannel?.id,
  );

  const selectedChannel = React.useMemo(
    () => find(userChannels, { id: selectedChannelId }) || defaultChannel,
    [selectedChannelId, userChannels, defaultChannel],
  );

  React.useEffect(() => {
    setQuery({
      inspectChannel: selectedChannel?.id,
    });
  }, [selectedChannel?.id]);

  return {
    loading,
    userChannels,
    selectedChannel,
    selectChannel: setSelectedChannelId,
    unselectChannel: () => setSelectedChannelId(null),
  };
};

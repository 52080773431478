import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import find from 'lodash/find';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useQueryParams, StringParam } from 'utils/routing';
import { ContentPreviewPropType } from 'utils/application';

import CardMedia from 'components/CardMedia';

import PreviewContentModal from '../PreviewContentModal';
import MediaGrid from '../MediaGrid';

const useStyles = makeStyles({
  selectedItemModal: {
    width: (props) => `calc(100% - ${props.drawerWidth}px)`,
    backgroundColor: '#000',
  },
  selectedItemMedia: {
    position: 'absolute',
    objectFit: 'contain',
    backgroundColor: '#000',
  },
  selectedItemMediaVideo: {
    width: '100%',
  },
  postText: {
    whiteSpace: 'pre-wrap',
  },
});

const InspectorBody = (props) => {
  const { preview, drawerWidth, ...otherProps } = props;

  const classes = useStyles(props);
  const intl = useIntl();

  const [query, setQuery] = useQueryParams({
    inspectMedia: StringParam,
  });

  const [selectedMediaItemId, setSelectedMediaItemId] = React.useState(
    query.inspectMedia,
  );

  const mediaItems = React.useMemo(() => {
    if (!preview) {
      return [];
    }

    return preview.media.map((mediaItem, idx) => ({
      ...mediaItem,
      id: String(idx),
    }));
  }, [preview]);

  const selectedMediaItem = React.useMemo(() => {
    if (!selectedMediaItemId) {
      return null;
    }

    return find(mediaItems, { id: selectedMediaItemId });
  }, [mediaItems, selectedMediaItemId]);

  // Sync query param with state (when back button is used, for example)
  React.useEffect(() => {
    if (query.inspectMedia !== selectedMediaItemId) {
      setSelectedMediaItemId(query.inspectMedia);
    }
  }, [query.inspectMedia]);

  const uninspectMediaItem = () => {
    setSelectedMediaItemId(null);
    setQuery({ inspectMedia: undefined });
  };

  const inspectMediaItem = (id) => {
    setSelectedMediaItemId(id);
    setQuery({ inspectMedia: id });
  };

  return (
    <Box {...otherProps}>
      {selectedMediaItem && (
        <PreviewContentModal
          open
          onClose={uninspectMediaItem}
          hideBackdrop
          className={classes.selectedItemModal}
        >
          <CardMedia
            type={selectedMediaItem.type}
            src={selectedMediaItem.url}
            assetId={selectedMediaItem.assetId}
            playable
            autoPlay
            clickable={false}
            height="100%"
            className={clsx(classes.selectedItemMedia, {
              [classes.selectedItemMediaVideo]:
                selectedMediaItem.type === 'video',
            })}
          />
        </PreviewContentModal>
      )}
      <MediaGrid
        items={mediaItems}
        selectedItem={selectedMediaItem}
        onSelectItem={inspectMediaItem}
        mb={2}
      />
      <Box display="flex" flexDirection="column" mb={2}>
        <Typography variant="overline" color="textSecondary">
          {intl.formatMessage({
            id: 'components.ContentPreviewInspectorCard.postText',
            defaultMessage: 'Post Text',
          })}
        </Typography>
        <Typography className={classes.postText}>{preview.caption}</Typography>
      </Box>
    </Box>
  );
};

InspectorBody.propTypes = {
  preview: ContentPreviewPropType.isRequired,
  drawerWidth: PropTypes.number.isRequired,
};

InspectorBody.defaultProps = {};

export default InspectorBody;

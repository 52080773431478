import React from 'react';

import { ApplicationPropType } from 'utils/application';
import { LocationLabel } from 'utils/user';
import { trimUrl } from 'utils/formatting';

import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellNumber from 'components/table/TableCellNumber';
import TableCellChannelAvatar from 'components/table/TableCellChannelAvatar';
import TableCellLink from 'components/table/TableCellLink';
import TableCellDate from 'components/table/TableCellDate';

const PublishedBlogApplicationsTableRow = ({ application, ...otherProps }) => {
  const { channel, user, contentPublication, publishedAt, reach } = application;

  return (
    <TableRow {...otherProps}>
      <TableCellChannelAvatar channel={channel} user={user} />
      <TableCellText variant="subtitle2">{trimUrl(channel.name)}</TableCellText>
      <TableCellText>
        <LocationLabel user={user} />
      </TableCellText>
      <TableCellNumber value={reach} roundingDigits={2} />
      <TableCellDate value={publishedAt} align="right" />
      <TableCellLink
        platform={channel.platform}
        url={contentPublication?.url}
      />
    </TableRow>
  );
};

PublishedBlogApplicationsTableRow.propTypes = {
  application: ApplicationPropType.isRequired,
};

PublishedBlogApplicationsTableRow.defaultProps = {};

export default PublishedBlogApplicationsTableRow;

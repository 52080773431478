import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { useIntl } from 'react-intl';
import useCopy from '@react-hook/copy';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import PlatformIcon from 'icons/Platform';
import Url from 'components/Url';

import { AlignPropType } from 'utils/table';
import { PlatformPropType, platforms } from 'utils/channel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    '& > svg:first-child': {
      marginRight: theme.spacing(1),
    },
    '& > svg:last-child': {
      marginLeft: theme.spacing(1),
    },
    '&:hover $copyIcon': {
      visibility: 'visible',
    },
  },
  platformIcon: {
    minWidth: 24,
  },
  copyIcon: {
    visibility: 'hidden',
    minWidth: 24,
  },
}));

const TableCellLink = ({ platform, url, align, ...otherProps }) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const { copy, copied, reset } = useCopy(url);
  const tooltipTitle = intl.formatMessage({
    id: 'general.copied',
    defaultMessage: 'Copied',
  });

  return (
    <TableCell align={align} {...otherProps}>
      <Box className={classes.root}>
        {platforms.includes(platform) && (
          <PlatformIcon
            className={classes.platformIcon}
            platform={platform}
            color={theme.palette.secondary.main}
          />
        )}
        <Url color={theme.palette.secondary.main} url={url} />
        <Tooltip
          title={tooltipTitle}
          placement="top"
          onClose={reset}
          open={copied}
        >
          <IconButton edge="end" onClick={copy}>
            <Icons.Copy
              className={classes.copyIcon}
              color={theme.palette.primary.main}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </TableCell>
  );
};

TableCellLink.propTypes = {
  align: AlignPropType,
  url: PropTypes.string,
  platform: PlatformPropType,
};

TableCellLink.defaultProps = {
  align: 'left',
};

export default TableCellLink;

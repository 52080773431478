import { defineMessages } from 'react-intl';

export default defineMessages({
  contentItems: {
    id: 'components.PublishedApplicationsTable.metaLabel.contentItems',
    defaultMessage:
      '{contentItemsNumber, plural, one {content item} other {content items}}',
  },

  publishedBy: {
    id: 'components.PublishedApplicationsTable.metaLabel.publishedBy',
    defaultMessage: 'published by',
  },

  influencers: {
    id: 'components.PublishedApplicationsTable.metaLabel.influencers',
    defaultMessage:
      '{influencersNumber, plural, one {influencer} other {influencers}}',
  },

  with: {
    id: 'components.PublishedApplicationsTable.metaLabel.with',
    defaultMessage: 'with',
  },

  followersCount: {
    id: 'components.PublishedApplicationsTable.metaLabel.followersCount',
    defaultMessage:
      '{followersNumber, plural, one {follower} other {followers}}',
  },

  impressionsCount: {
    id: 'components.PublishedApplicationsTable.metaLabel.impressionsNumber',
    defaultMessage:
      '{impressionsNumber, plural, one {impression} other {impressions}}',
  },

  pageviewsCount: {
    id: 'components.PublishedApplicationsTable.metaLabel.pageviewsCount',
    defaultMessage:
      '{pageviewsCount, plural, one {monthly page view} other {monthly page views}}',
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: '50%',
    right: 'auto',
    bottom: theme.spacing(4),
    transform: 'translateX(-50%)',
  },
}));

const BottomCenterFloat = ({ children, className, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...otherProps}>
      {children}
    </Box>
  );
};

BottomCenterFloat.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BottomCenterFloat.defaultProps = {
  className: '',
};

export default BottomCenterFloat;

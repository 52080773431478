import React from 'react';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';

const CAMPAIGN_FIELDS = gql`
  fragment campaignFields on Campaign {
    id
    name
    status
    budget
    reach
    targetInfluencerProfile
    previewUrl
    platform
    hasClientReviewableContent
    influencersApplied
    influencersAccepted
    influencersPublished
    applicationsOpenedAt
    firstPostAt
    createdAt
    productShipment {
      enabled
    }
    tasks {
      id
      body
      clientLabel
      individual
      clientAccess
    }
  }
`;

const CAMPAIGN_APPLICATIONS_FIELD = gql`
  fragment campaignApplicationsField on Application {
    id
    status
    pitch
    reach
    campaignId
    createdAt
    publishedAt
    advertiserPrice
    kamNotes {
      note
      updatedBy
      updatedAt
    }
    clientNotes {
      note
      updatedBy
      updatedAt
      updaterName
    }
    productShipment {
      status
      product
      address {
        city
        country
        firstname
        lastname
        postalCode
        street
        streetNumber
      }
    }
    tasks {
      id
      body
      clientLabel
      individual
      clientAccess
      applicationId
    }
    mission {
      status
      publishDate
    }
    user {
      id
      salutation
      city
      country
      gravatar
    }
    channel {
      id
      name
      platform
      reach
      avatarUrl
      categories {
        code
        weight
      }
      keyMetrics {
        ... on InstagramKeyMetrics {
          metricsValidAt
          source
          followers
          engagementRate
          followersHistory {
            month
            followers
          }
          audience {
            countries
            gender
            ageRange
          }
          topCountry
          storiesImpressions
          postsImpressions
          storyImpressionMedian
          postImpressionMedian
          storyImpressionRatio
          postImpressionRatio
          updatedByAt
        }
        ... on PinterestKeyMetrics {
          followers
          impressionMedian
          engagementMedian
          engagementRatio
          pinClickMedian
          pinClickRatio
          saveMedian
          saveRatio
          updatedAt
        }
      }
      kpi {
        ... on TikTokKPI {
          audienceStatistic {
            genders
            ageGroups
          }
          geoStatistic {
            countries
          }
          followers
          topCountry
          viewsPerVideo
          viewsPerVideoMedian
          viewsTotal
          updatedByAt
        }
      }
      insights {
        ... on InstagramInsights {
          followedBy
          follows
          media
          date
        }
        ... on BlogInsights {
          pageviews
          countries {
            country
            percentage
          }
        }
      }
      data {
        url
      }
    }
    channels {
      id
      name
      platform
      data {
        url
      }
    }
    contentPreview {
      ... on InstagramContentPreview {
        caption
        updatedAt
        media {
          type
          url
          assetId
        }
        status
      }
      ... on BlogContentPreview {
        title
        articleSanitized
        articleWordsCount
        updatedAt
        status
      }
    }
    contentPublication {
      url
    }
    clientApplicationReview {
      status
      note
    }
    clientContentReview {
      status
      note
      verified
    }
  }
`;

const CAMPAIGN_DETAILS_FIELDS = gql`
  fragment campaignDetailsFields on Campaign {
    details {
      headline
      mission
      goal
    }
  }
`;

const CAMPAIGN_INFLUENCER_SELECTION_FIELDS = gql`
  fragment campaignInfluencerSelectionFields on Campaign {
    clientInfluencerSelection {
      status
      selectionType
      campaignBudget
      confirmedBudget
      campaignReach
      confirmedReach
      updatedBy
      updatedAt
    }
  }
`;

const APPLICATION_CRM_CHANNEL_FIELD = gql`
  fragment crmChannel on Application {
    crmChannel {
      id
      tags
      updatedAt
      notes {
        note
        createdBy
        createdAt
        creatorName
      }
      avatarUrl
      applications {
        applicationId
        campaignId
        campaignName
        campaignStatus
        campaignSummaryStatus
        applicationStatus
        clientApplicationReview {
          status
          note
          updatedBy
          updatedAt
        }
        createdAt
      }
    }
  }
`;

const MANY = gql`
  query Campaigns(
    $page: Int
    $sort: CampaignsSort
    $campaignsFilter: CampaignsFilter
    $applicationsFilter: ApplicationsFilter
  ) {
    campaigns(
      page: $page
      sort: $sort
      campaignsFilter: $campaignsFilter
      applicationsFilter: $applicationsFilter
    ) {
      ...campaignFields
    }
  }
  ${CAMPAIGN_FIELDS}
`;

const MANY_WITH_APPLICATIONS = gql`
  query Campaigns(
    $page: Int
    $sort: CampaignsSort
    $campaignsFilter: CampaignsFilter
    $applicationsFilter: ApplicationsFilter
  ) {
    campaigns(
      page: $page
      sort: $sort
      campaignsFilter: $campaignsFilter
      applicationsFilter: $applicationsFilter
    ) {
      ...campaignFields
      applications(applicationsFilter: $applicationsFilter) {
        ...campaignApplicationsField
      }
    }
  }
  ${CAMPAIGN_FIELDS}
  ${CAMPAIGN_APPLICATIONS_FIELD}
`;

const ONE = gql`
  query Campaign($id: ID!, $applicationsFilter: ApplicationsFilter) {
    campaign(id: $id, applicationsFilter: $applicationsFilter) {
      ...campaignFields
      ...campaignDetailsFields
      ...campaignInfluencerSelectionFields
      applications(applicationsFilter: $applicationsFilter) {
        ...campaignApplicationsField
        ...crmChannel
      }
    }
  }
  ${CAMPAIGN_FIELDS}
  ${CAMPAIGN_DETAILS_FIELDS}
  ${CAMPAIGN_INFLUENCER_SELECTION_FIELDS}
  ${CAMPAIGN_APPLICATIONS_FIELD}
  ${APPLICATION_CRM_CHANNEL_FIELD}
`;

const CREATE_ONE = gql`
  mutation CreateDraftCampaign($payload: CampaignInput!) {
    createDraftCampaign(input: $payload) {
      ...campaignFields
      ...campaignDetailsFields
      ...campaignInfluencerSelectionFields
    }
  }
  ${CAMPAIGN_FIELDS}
  ${CAMPAIGN_DETAILS_FIELDS}
  ${CAMPAIGN_INFLUENCER_SELECTION_FIELDS}
`;

const EDIT_ONE = gql`
  mutation EditDraftCampaign($id: ID!, $payload: CampaignInput!) {
    editDraftCampaign(id: $id, input: $payload) {
      ...campaignFields
      ...campaignDetailsFields
      ...campaignInfluencerSelectionFields
    }
  }
  ${CAMPAIGN_FIELDS}
  ${CAMPAIGN_DETAILS_FIELDS}
  ${CAMPAIGN_INFLUENCER_SELECTION_FIELDS}
`;

const CONFIRM_INFLUENCER_SELECTION = gql`
  mutation ConfirmCampaignInfluencerSelection($id: ID!) {
    confirmCampaignInfluencerSelection(id: $id) {
      ...campaignFields
      ...campaignDetailsFields
      ...campaignInfluencerSelectionFields
    }
  }
  ${CAMPAIGN_FIELDS}
  ${CAMPAIGN_DETAILS_FIELDS}
  ${CAMPAIGN_INFLUENCER_SELECTION_FIELDS}
`;

const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($id: ID!, $payload: ApplicationUpdateInput!) {
    updateApplication(id: $id, input: $payload) {
      id
      clientApplicationReview {
        status
        note
        updatedBy
        updatedAt
      }
    }
  }
`;

const UPDATE_APPLICATION_CLIENT_CONTENT_REVIEW = gql`
  mutation UpdateApplicationClientContentReview(
    $id: ID!
    $payload: ApplicationClientContentReviewUpdateInput!
  ) {
    updateApplicationClientContentReview(id: $id, input: $payload) {
      id
      clientContentReview {
        status
        note
        updatedBy
        updatedAt
      }
    }
  }
`;

const UPDATE_CHANNEL_NOTES = gql`
  mutation UpdateChannelNotes($id: ID!, $payload: [ChannelNoteInput]!) {
    updateChannelNotes(id: $id, input: $payload)
  }
`;

const UPDATE_CHANNEL_TAGS = gql`
  mutation UpdateChannelTags($id: ID!, $payload: [String]) {
    updateChannelTags(id: $id, input: $payload)
  }
`;

const UPDATE_CLIENT_NOTE = gql`
  mutation UpdateApplicationClientNote(
    $id: ID!
    $payload: ApplicationClientNoteUpdateInput
  ) {
    updateApplicationClientNote(id: $id, input: $payload) {
      id
      clientNotes {
        note
        updatedBy
        updatedAt
        updaterName
      }
    }
  }
`;

const UPDATE_CAMPAIGN_TASK = gql`
  mutation UpdateCampaignTask(
    $id: ID!
    $taskId: String!
    $payload: CampaignTaskUpdateInput
  ) {
    updateCampaignTask(id: $id, taskId: $taskId, input: $payload)
  }
`;

const UPDATE_APPLICATION_TASK = gql`
  mutation UpdateApplicationTask(
    $id: ID!
    $taskId: String!
    $payload: ApplicationTaskUpdateInput
  ) {
    updateApplicationTask(id: $id, taskId: $taskId, input: $payload)
  }
`;

export const useMany = ({
  page,
  sort,
  campaignsFilter,
  applicationsFilter,
  withApplications,
} = {}) => {
  const QUERY = withApplications ? MANY_WITH_APPLICATIONS : MANY;
  return useQuery(QUERY, {
    variables: { page, sort, campaignsFilter, applicationsFilter },
  });
};

export const useOne = ({ id, applicationsFilter }) =>
  useQuery(ONE, {
    variables: { id, applicationsFilter },
  });

export const useOneLazy = (options = {}) => useLazyQuery(ONE, options);

export const useCreateOne = () => {
  const [createOne, { loading, error, data }] = useMutation(CREATE_ONE, {
    refetchQueries: [
      {
        query: MANY,
        variables: {
          campaignsFilter: 'byActive',
        },
      },
    ],
  });

  if (error) {
    throw new Error(`Failed to create campaign: ${JSON.stringify(error)}`);
  }

  return {
    createDraftCampaign: React.useCallback(
      ({ payload }) => createOne({ variables: { payload } }),
      [createOne],
    ),
    loading,
    data: data ? data.createDraftCampaign : undefined,
  };
};

export const useEditOne = () => {
  const [editOne, { loading, error, data }] = useMutation(EDIT_ONE);

  if (error) {
    throw new Error(`Failed to edit campaign: ${JSON.stringify(error)}`);
  }

  return {
    editDraftCampaign: React.useCallback(
      ({ id, payload }) => editOne({ variables: { id, payload } }),
      [editOne],
    ),
    loading,
    data: data ? data.editDraftCampaign : undefined,
  };
};

export const useConfirmInfluencerSelection = () => {
  const [confirmSelection, { loading, error, data }] = useMutation(
    CONFIRM_INFLUENCER_SELECTION,
  );

  if (error) {
    throw new Error(
      `Failed to confirm campaign's influencer selection: ${JSON.stringify(
        error,
      )}`,
    );
  }

  return {
    confirmCampaignInfluencerSelection: React.useCallback(
      ({ id }) => confirmSelection({ variables: { id } }),
      [confirmSelection],
    ),
    loading,
    data: data ? data.confirmCampaignInfluencerSelection : undefined,
  };
};

export const useUpdateApplication = () => {
  const [updateApplication, { loading, error, data }] =
    useMutation(UPDATE_APPLICATION);

  if (error) {
    throw new Error(`Failed to update application: ${JSON.stringify(error)}`);
  }

  return {
    updateApplication: React.useCallback(
      ({ id, payload }) => updateApplication({ variables: { id, payload } }),
      [updateApplication],
    ),
    loading,
    data: data ? data.updateApplication : undefined,
  };
};

export const useUpdateApplicationClientContentReview = () => {
  const [updateApplicationClientContentReview, { loading, error, data }] =
    useMutation(UPDATE_APPLICATION_CLIENT_CONTENT_REVIEW);

  if (error) {
    throw new Error(`Failed to update application: ${JSON.stringify(error)}`);
  }

  return {
    updateApplicationClientContentReview: React.useCallback(
      ({ id, payload }) =>
        updateApplicationClientContentReview({ variables: { id, payload } }),
      [updateApplicationClientContentReview],
    ),
    loading,
    data: data ? data.updateApplicationClientContentReview : undefined,
  };
};

export const useUpdateChannelNotes = () => {
  const [updateChannelNotes, { loading, error }] = useMutation(
    UPDATE_CHANNEL_NOTES,
    {
      refetchQueries: ['Campaign'],
    },
  );

  if (error) {
    throw new Error(`Failed to mutate notes: ${JSON.stringify(error)}`);
  }

  return {
    updateChannelNotes: React.useCallback(
      ({ id, payload }) => updateChannelNotes({ variables: { id, payload } }),
      [updateChannelNotes],
    ),
    loading,
  };
};

export const useUpdateChannelTags = () => {
  const [updateChannelTags, { loading, error }] = useMutation(
    UPDATE_CHANNEL_TAGS,
    {
      refetchQueries: ['ChannelTags', 'Campaign'],
    },
  );

  if (error) {
    throw new Error(`Failed to mutate tags: ${JSON.stringify(error)}`);
  }

  return {
    updateChannelTags: React.useCallback(
      ({ id, payload }) => updateChannelTags({ variables: { id, payload } }),
      [updateChannelTags],
    ),
    loading,
  };
};

export const useUpdateClientNote = () => {
  const [updateClientNote, { loading, error }] =
    useMutation(UPDATE_CLIENT_NOTE);

  if (error) {
    throw new Error(`Failed to mutate clientNotes: ${JSON.stringify(error)}`);
  }

  return {
    updateClientNote: React.useCallback(
      ({ id, payload }) => updateClientNote({ variables: { id, payload } }),
      [updateClientNote],
    ),
    loading,
  };
};

export const useUpdateCampaignTask = () => {
  const [updateCampaignTask, { loading, error }] = useMutation(
    UPDATE_CAMPAIGN_TASK,
    {
      refetchQueries: ['Campaign'],
    },
  );

  if (error) {
    throw new Error(`Failed to mutate campaign task: ${JSON.stringify(error)}`);
  }

  return {
    updateCampaignTask: React.useCallback(
      ({ id, taskId, payload }) =>
        updateCampaignTask({ variables: { id, taskId, payload } }),
      [updateCampaignTask],
    ),
    loading,
  };
};

export const useUpdateApplicationTask = () => {
  const [updateApplicationTask, { loading, error }] = useMutation(
    UPDATE_APPLICATION_TASK,
    {
      refetchQueries: ['Campaign'],
    },
  );

  if (error) {
    throw new Error(
      `Failed to mutate application task: ${JSON.stringify(error)}`,
    );
  }

  return {
    updateApplicationTask: React.useCallback(
      ({ id, taskId, payload }) =>
        updateApplicationTask({ variables: { id, taskId, payload } }),
      [updateApplicationTask],
    ),
    loading,
  };
};

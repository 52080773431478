import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    cursor: 'default',
  },
  title: {
    fontWeight: (props) => (props.bold ? 'bold' : 'inherit'),
    color: (props) => props.color,
  },
  tooltipPopper: {
    top: '15px !important',
  },
});

const ChannelCardRating = (props) => {
  const {
    bold,
    label: labelProp,
    value: valueProp,
    withUnderline,
    tooltip,
    className,
    ...otherProps
  } = props;
  const classes = useStyles(props);
  const intl = useIntl();

  const value = React.useMemo(() => {
    if (!valueProp || isNil(valueProp) || !isNumber(valueProp)) {
      return '—';
    }

    return <Rating value={valueProp} readOnly />;
  }, [valueProp, classes, intl]);

  let label = labelProp;

  if (isString(labelProp)) {
    label = (
      <Typography color="textSecondary" variant="caption">
        {labelProp}
      </Typography>
    );
  }

  const rendered = (
    <Grid item xs className={clsx(classes.root, className)} {...otherProps}>
      {value}
      {label}
      {withUnderline && <Divider />}
    </Grid>
  );

  if (tooltip) {
    return (
      <Tooltip
        title={tooltip}
        placement="top"
        classes={{ popper: classes.tooltipPopper }}
      >
        {rendered}
      </Tooltip>
    );
  }

  return rendered;
};

ChannelCardRating.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.number,
  withUnderline: PropTypes.bool,
  className: PropTypes.string,
  bold: PropTypes.bool,
  tooltip: PropTypes.string,
};

ChannelCardRating.defaultProps = {
  className: '',
  withUnderline: true,
  bold: false,
  tooltip: '',
};

export default ChannelCardRating;

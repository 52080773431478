import React from 'react';
import clsx from 'clsx';

import { makeStyles, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Markdown from 'components/Markdown';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1.5),
  },
  h1: {
    fontSize: 30,
    lineHeight: '130%',
  },
  h2: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '130%',
  },
  h3: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '160%',
    marginBottom: theme.spacing(0.5),
  },
  paragraph: {
    fontSize: 14,
    lineHeight: '20px',
  },
  list: {
    padding: '0 0 0 5px',
    marginBottom: theme.spacing(2),
    marginTop: 0,
    'ul& > li': {
      listStyleType: 'none',
      marginLeft: theme.spacing(2),
      textIndent: theme.spacing(-2),
      '&:before': {
        content: '""',
        backgroundColor: theme.palette.secondary.main,
        display: 'inline-block',
        width: '7px',
        height: '7px',
        borderRadius: '100px',
        marginRight: theme.spacing(1),
      },
    },
  },
  blockquote: {
    fontSize: 11,
    lineHeight: '130%',
    color: alpha(theme.palette.text.primary, 0.4),
  },
  divider: {
    color: alpha(theme.palette.text.primary, 0.2),
  },
  image: {
    maxWidth: '100%',
    height: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const InsiderMarkdown = (props) => {
  const classes = useStyles();

  const renderers = React.useMemo(
    () => ({
      /* eslint-disable react/prop-types */
      heading: ({ level, children }) => {
        const variant = `h${level}`;
        const className = classes[variant];

        return (
          <Typography
            variant={variant}
            className={clsx(classes.heading, className)}
          >
            {children}
          </Typography>
        );
      },
      paragraph: ({ children }) => (
        <Typography className={classes.paragraph} variant="body1" paragraph>
          {children}
        </Typography>
      ),
      list: ({ children, node }) => (
        <Box className={classes.list} component={node.ordered ? 'ol' : 'ul'}>
          {children}
        </Box>
      ),
      image: ({ src, alt, title }) => (
        <img src={src} alt={alt} title={title} className={classes.image} />
      ),
      blockquote: ({ children }) => (
        <blockquote className={classes.blockquote}>{children}</blockquote>
      ),
      thematicBreak: () => <Divider className={classes.divider} />,
      /* eslint-enable react/prop-types */
    }),
    [classes],
  );

  return <Markdown {...props} renderers={renderers} />;
};

InsiderMarkdown.propTypes = {};
InsiderMarkdown.defaultProps = {};

export default InsiderMarkdown;

import PropTypes from 'prop-types';
import first from 'lodash/first';

export const sortToQuery = ({ by, direction }) => ({
  [by]: direction === 'asc' ? 1 : -1,
});

export const serializeSort = ({ by, direction }) => {
  const sign = direction === 'desc' ? '-' : '';

  return `${sign}${by}`;
};

export const parseSort = (sortString) => {
  if (first(sortString) === '-') {
    return {
      by: sortString.substring(1),
      direction: 'desc',
    };
  }

  if (!sortString) {
    return undefined;
  }

  return {
    by: sortString,
    direction: 'asc',
  };
};

export const SortObjectPropType = PropTypes.shape({
  by: PropTypes.string,
  direction: PropTypes.oneOf(['asc', 'desc', false]),
});

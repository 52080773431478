import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import { getInitials } from 'utils/user';
import TableRow from 'components/table/TableRow';
import TableCellText from 'components/table/TableCellText';
import TableCellDateTime from 'components/table/TableCellDateTime';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '48px',
    height: '48px',
    backgroundColor: theme.palette.eqolot.infoBlue,
  },
}));

const ClientUsersTableRow = ({ user, selected, ...otherProps }) => {
  const classes = useStyles();

  return (
    <TableRow selected={selected} selectable hover {...otherProps}>
      <TableCell align="center">
        <Avatar className={classes.avatar} alt={user.name}>
          {getInitials(user.name)}
        </Avatar>
      </TableCell>
      <TableCellText color="secondary" bold>
        {user.name}
      </TableCellText>
      <TableCellText>{user.email}</TableCellText>
      <TableCellDateTime value={user.createdAt} align="center" />
      <TableCellDateTime value={user.lastLoginAt} align="left" />
    </TableRow>
  );
};

ClientUsersTableRow.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    createdAt: PropTypes.string,
    lastLoginAt: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
};

ClientUsersTableRow.defaultProps = {
  selected: false,
};

export default ClientUsersTableRow;

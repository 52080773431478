import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import {
  formatNumber as numberFormatter,
  sumWithRounding,
} from 'utils/formatting';

export default (applications) => {
  const intl = useIntl();

  if (isEmpty(applications)) {
    return intl.formatMessage({
      id: 'privateNetwork.table.metaLabel.noResults',
      defaultMessage: 'No results found',
    });
  }

  const numberOfInfluencers = applications.length;

  if (numberOfInfluencers === 0) {
    return intl.formatMessage({
      id: 'privateNetwork.table.metaLabel.empty',
      defaultMessage: 'No influencers have applied to this Private Network yet',
    });
  }

  const formatNumber = (number) =>
    numberFormatter({ formatter: intl.formatNumber, number });

  const allChannels = map(applications, 'channels').flat();
  const numberOfChannels = allChannels.length;
  const totalReach = formatNumber(sumWithRounding(allChannels, 'reach'));

  return intl.formatMessage(
    {
      id: 'privateNetwork.table.metaLabel',
      defaultMessage:
        '{numberOfInfluencers, plural, one {# influencer} other {# influencers}} with {numberOfChannels, plural, one {# channel} other {# channels}} and a total reach of {totalReach}',
    },
    { numberOfInfluencers, numberOfChannels, totalReach },
  );
};

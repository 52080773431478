// This needs to be 'bare' because excelJS add their own
// global polyfills in the non-bare version, which messes
// with auth0
import ExcelJS from 'exceljs/dist/exceljs.bare';
import fileSaver from 'file-saver';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import get from 'lodash/get';
import head from 'lodash/head';
import last from 'lodash/last';
import forEach from 'lodash/forEach';

class Column {
  constructor({
    name,
    key,
    data,
    defaultValue = '',
    cellProperties,
    ...other
  }) {
    this.name = name;
    this.key = key;
    this.data = data;
    this.defaultValue = defaultValue;
    this.cellProperties = cellProperties;
    this.options = other;
  }

  getName() {
    if (isFunction(this.name)) {
      return this.name();
    }

    return this.name;
  }

  getData(entity) {
    if (isFunction(this.data)) {
      return this.data(entity) || this.defaultValue;
    }

    if (isString(this.data)) {
      return get(entity, this.data, this.defaultValue);
    }

    return get(entity, this.key, this.defaultValue);
  }

  export() {
    return {
      header: this.getName(),
      key: this.key,
      ...this.options,
    };
  }
}

class SpreadSheet {
  constructor({ name, columns }) {
    this.name = name.replace(/[*?:\\/[\]]/gi, '-');
    this.columns = columns.map((col) => new Column(col));
    this.workbook = new ExcelJS.Workbook();
    this.sheet = this.workbook.addWorksheet(this.name);
    this.sheet.columns = this.columns.map((col) => col.export());
  }

  insertData(data = []) {
    data.forEach((entity) => {
      const newRow = this.sheet.addRow(
        this.columns.map((col) => col.getData(entity)),
      );
      newRow.commit();
    });

    // Apply extra cell properties to each cell of the column
    this.columns.forEach((col) => {
      if (col.cellProperties) {
        const sheetCol = this.sheet.getColumn(col.key);
        sheetCol.eachCell((cell) => {
          forEach(col.cellProperties, (value, key) => {
            cell[key] = value;
          });
        });
      }
    });

    // Wrap text on all all column headers
    this.sheet.getRow(1).eachCell((cell) => {
      cell.alignment = { wrapText: true };
    });
  }

  addMergedRow(value = {}, style = {}) {
    const firstCol = head(this.sheet.columns);
    const lastCol = last(this.sheet.columns);

    const newTextRow = this.sheet.addRow();

    const firstCellAddress = `${firstCol.letter}${newTextRow.number}`;
    const lastCellAddress = `${lastCol.letter}${newTextRow.number}`;

    this.sheet.mergeCells(`${firstCellAddress}:${lastCellAddress}`);

    const writeCell = this.sheet.getCell(firstCellAddress);

    writeCell.value = value;
    writeCell.style = style;
  }

  async download(name = this.name) {
    const filename = `${name}.xlsx`;
    const buffer = await this.workbook.xlsx.writeBuffer();

    const asBlob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fileSaver.saveAs(asBlob, filename);
  }
}

export default SpreadSheet;

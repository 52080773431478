import React from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FloatingTablePagination from 'components/FloatingTablePagination';
import ChannelsTable from 'components/channels/table/ChannelsTable';
import InspectorDrawerContent from 'components/inspector/InspectorDrawerContent';
import { ChannelsMetaLabel } from 'utils/channel';

import InfluencersSearchFilters from './InfluencersSearchFilters';
import ChannelInspector, {
  drawerWidth as channelInspectorDrawerWidth,
} from './ChannelInspector';
import useInfluencersSearchState, {
  supportedFilterCountries,
} from './data/useInfluencersSearchState';
import {
  useUpdateChannelTags,
  useUpdateChannelNotes,
  useClientCampaigns,
} from './data/channelsSearchHooks';

const useStyles = makeStyles((theme) => ({
  lastPageMessage: {
    textAlign: 'center',
  },
  pagination: {
    left: `calc(50% + ${theme.sidebar.width / 2}px)`,
    transition: theme.drawer.transitions.createShrink('left'),
  },
  paginationShift: {
    left: `calc(50% + ${
      (theme.sidebar.width - channelInspectorDrawerWidth) / 2
    }px)`,
    transition: theme.drawer.transitions.createGrow('left'),
  },
}));

const InfluencersSearch = () => {
  const { state, actions } = useInfluencersSearchState();
  const { inspectChannel, filterBy, sortBy, setPage, setPlatform } = actions;

  const {
    channels,
    meta,
    loading,
    inspectedChannel,
    filters,
    sort,
    currentPage,
    platform,
  } = state;

  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const clientCampaigns = useClientCampaigns();

  const { updateChannelTags, loading: tagsUpdateLoading } =
    useUpdateChannelTags();

  const { updateChannelNotes, loading: notesUpdateLoading } =
    useUpdateChannelNotes();

  const handleChangePage = (event, newPage) => {
    // MUI starts counting at 0, we start at 1
    setPage(newPage + 1);
  };

  const handleUpdateChannelTags = (data) => {
    updateChannelTags({
      id: inspectedChannel.id,
      payload: data.tags,
    });
  };

  const handleUpdateChannelNotes = (notes) => {
    updateChannelNotes({
      id: inspectedChannel.id,
      payload: notes,
    });
  };

  const isLastPage = currentPage * 30 >= meta.count;

  return (
    <Box display="flex" height="100%" pb={6.5}>
      <InspectorDrawerContent
        drawerOpen={!!inspectedChannel}
        drawerWidth={channelInspectorDrawerWidth}
      >
        <InfluencersSearchFilters
          platform={platform}
          onChangePlatform={setPlatform}
          filters={filters}
          onFilter={filterBy}
          supportedCountries={supportedFilterCountries}
          clientCampaigns={clientCampaigns}
          isInspected={!!inspectedChannel}
        />
        <ChannelsTable
          platform={platform}
          loading={loading}
          channels={channels}
          meta={meta}
          onSelectChannel={inspectChannel}
          selectedChannel={inspectedChannel}
          currentSort={sort}
          onSort={sortBy}
          headerLabel={<ChannelsMetaLabel meta={meta} platform={platform} />}
        />
        {isLastPage && !loading && (
          <Box mx="auto" mt={6} mb={3} maxWidth={theme.typography.pxToRem(640)}>
            <Typography variant="body1" className={classes.lastPageMessage}>
              {intl.formatMessage({
                id: 'components.ChannelsTable.lastPageMessage',
                defaultMessage:
                  'The eqolot team is always onboarding new amazing Influencers. If what you’re looking for is not here, please get in touch with your key account manager and we will find the perfect match for your campaign.',
              })}
            </Typography>
          </Box>
        )}
      </InspectorDrawerContent>
      <ChannelInspector
        updateChannelTags={handleUpdateChannelTags}
        updateChannelNotes={handleUpdateChannelNotes}
        loading={tagsUpdateLoading || notesUpdateLoading || loading}
        channel={inspectedChannel}
        onClose={() => inspectChannel(null)}
      />
      <FloatingTablePagination
        classes={{
          root: clsx(classes.pagination, {
            [classes.paginationShift]: !!inspectedChannel,
          }),
        }}
        count={meta.count || -1}
        onPageChange={handleChangePage}
        // MUI starts counting at 0, we start at 1
        page={currentPage - 1}
        rowsPerPage={30}
        // Needs at least two options here to show the control
        // Since the select is currently disabled, users will
        // not see the 0
        rowsPerPageOptions={[30, 0]}
        SelectProps={{
          disabled: true,
        }}
      />
    </Box>
  );
};

export default InfluencersSearch;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ListItemText from '@material-ui/core/ListItemText';

import { getCollaborationRatingsFilter } from 'utils/collaborationRatings';

import TagsInput from 'components/TagsInput';

const options = [
  'visualCreativity',
  'copyWriting',
  'punctuality',
  'professionalism',
];

const CollaborationRatingsFilter = ({ value, onChange, ...otherProps }) => {
  const intl = useIntl();

  const handleChange = (event, newValues) => {
    onChange(newValues);
  };

  const filterOptions = React.useCallback(
    (options, { inputValue }) =>
      options.filter((filterId) => {
        const filterOption = getCollaborationRatingsFilter(filterId);

        const title = intl.formatMessage(filterOption.title);

        const lowercaseInputValue = inputValue.toLowerCase();
        return title.toLowerCase().includes(lowercaseInputValue);
      }),
    [intl, getCollaborationRatingsFilter],
  );

  const renderOption = React.useCallback(
    (filterId) => {
      const filterOption = getCollaborationRatingsFilter(filterId);

      const title = intl.formatMessage(filterOption.title);

      return <ListItemText primary={title} />;
    },
    [intl, getCollaborationRatingsFilter],
  );

  const getTagLabel = React.useCallback(
    (filterId) => {
      const filterOption = getCollaborationRatingsFilter(filterId);
      return intl.formatMessage(filterOption.title);
    },
    [intl, getCollaborationRatingsFilter],
  );

  return (
    <TagsInput
      value={value}
      options={options}
      renderOption={renderOption}
      getTagLabel={getTagLabel}
      onChange={handleChange}
      label={intl.formatMessage({
        id: 'components.ChannelCard.ratingsSection.title',
        defaultMessage: 'Collaboration Ratings',
      })}
      filterOptions={filterOptions}
      {...otherProps}
    />
  );
};

CollaborationRatingsFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};
CollaborationRatingsFilter.defaultProps = {
  value: [],
};

export default CollaborationRatingsFilter;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MuiTableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles((theme) => ({
  overlay: {
    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      backgroundColor: theme.palette.primary.light,
      zIndex: 1,
      opacity: 0.7,
    },
  },
}));

const TableBody = ({ className, children, loading }) => {
  const classes = useStyles();

  return (
    <MuiTableBody className={clsx(className, { [classes.overlay]: loading })}>
      {children}
    </MuiTableBody>
  );
};

TableBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

TableBody.defaultProps = {
  className: '',
  loading: false,
};

export default TableBody;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import LineClamp from 'shiitake';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import CardDate from 'components/CardDate';
import PlatformAvatar from 'components/PlatformAvatar';
import {
  CampaignPropType,
  useStatus as useCampaignStatus,
} from 'utils/campaign';

import CampaignStatus from './CampaignStatus';
import CampaignCardKPI from './CampaignCardKPI';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  media: {
    height: theme.typography.pxToRem(200),
  },
  title: {
    maxWidth: '90%',
  },
  content: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    // By default MUI applies bottom padding of spacing(3) if
    // content is the last child
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    // Title can wrap to multiple lines, keep height consistent
    // to keep card height consistent
    height: theme.typography.pxToRem(45),
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flex: '0 0 30%',
    },
  },
  overlay: {
    position: 'absolute',
    backgroundColor: '#fff',
    opacity: 0.66,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
}));

const CampaignCard = ({ campaign, className, ...otherProps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { view } = useCampaignStatus(campaign.status);

  const campaignUrl = `/campaigns/${campaign.id}/${view}`;

  return (
    <Card className={clsx(classes.root, className)} {...otherProps}>
      {campaign.status === 'completed' && <Box className={classes.overlay} />}
      <CardActionArea component={Link} to={campaignUrl}>
        <CardMedia
          className={classes.media}
          image={campaign.previewUrl}
          title={campaign.name}
          component="div"
        />
        <CardContent className={classes.content}>
          <Box className={classes.titleRow}>
            <PlatformAvatar
              platform={campaign.platform}
              color={theme.palette.primary.main}
              size={40}
            />
            <Typography className={classes.title} color="primary" variant="h6">
              <LineClamp lines={3}>{campaign.name}</LineClamp>
            </Typography>
          </Box>
          <Box className={classes.contentRow}>
            <CardDate
              value={campaign.applicationsOpenedAt}
              label={intl.formatMessage({
                id: 'components.CampaignsCard.labels.openApplicationsDate',
                defaultMessage: 'Open for Applications',
              })}
            />
            <CardDate
              value={campaign.firstPostAt}
              label={intl.formatMessage({
                id: 'components.CampaignsCard.labels.firstPublicationDate',
                defaultMessage: 'First Publication',
              })}
            />
            <CampaignStatus value={campaign.status} />
          </Box>
          <Box className={classes.contentRow}>
            <CampaignCardKPI
              value={campaign.influencersApplied}
              label={intl.formatMessage({
                id: 'components.CampaignsCard.labels.numberOfApplications',
                defaultMessage: 'Applied',
              })}
            />
            <CampaignCardKPI
              value={campaign.influencersAccepted}
              label={intl.formatMessage({
                id: 'components.CampaignsCard.labels.numberOfAcceptedApplications',
                defaultMessage: 'Selected',
              })}
            />
            <CampaignCardKPI
              value={campaign.influencersPublished}
              label={intl.formatMessage({
                id: 'components.CampaignsCard.labels.numberOfPublishedPosts',
                defaultMessage: 'Published',
              })}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CampaignCard.propTypes = {
  campaign: CampaignPropType.isRequired,
  className: PropTypes.string,
};

CampaignCard.defaultProps = {
  className: '',
};

export default CampaignCard;

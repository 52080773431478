import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@material-ui/core/Box';

import MarkdownNote from 'components/MarkdownNote';
import { ApplicationPropType } from 'utils/application';

import ApplicationCardTask from './ApplicationCardTask';

const ApplicationCardNotes = ({
  isPreparationStep,
  application,
  onEditClientNote,
  onEditCampaignTask,
  onEditApplicationTask,
}) => {
  const intl = useIntl();

  const markdownEditLabel = intl.formatMessage({
    id: 'components.ApplicationCardNotes.editLabel',
    defaultMessage: 'Only shown to the Account Manager',
  });

  return (
    <Box display="flex" flexDirection="column">
      {application?.kamNotes?.note && (
        <MarkdownNote
          label={
            <FormattedMessage
              id="components.ApplicationCard.kamNoteHeadline"
              defaultMessage="Account Manager's Note"
            />
          }
          editLabel={markdownEditLabel}
          note={application?.kamNotes?.note}
          timestamp={application?.kamNotes?.updatedAt}
          updaterName="eqolot"
        />
      )}
      {isPreparationStep && (
        <>
          <MarkdownNote
            editable
            label={
              <FormattedMessage
                id="components.ApplicationCard.clientNoteHeadline"
                defaultMessage="Note for Account Manager"
              />
            }
            editLabel={markdownEditLabel}
            note={application?.clientNotes?.note}
            timestamp={application?.clientNotes?.updatedAt}
            updaterEmail={application?.clientNotes?.updatedBy}
            updaterName={application?.clientNotes?.updaterName}
            onEdit={onEditClientNote}
          />
          {(application.tasks || []).map((task) => (
            <ApplicationCardTask
              key={task.id}
              label={task.clientLabel}
              inputLabel={
                task.individual
                  ? intl.formatMessage({
                      id: 'components.ApplicationCardNotes.editable.inputLabel.individualTaskLabel',
                      defaultMessage:
                        'This value is personalized for each Influencer',
                    })
                  : intl.formatMessage({
                      id: 'components.ApplicationCardNotes.editable.inputLabel.mutualTaskLabel',
                      defaultMessage:
                        'This value is mutual for each Influencer',
                    })
              }
              value={task.body || ''}
              onEdit={
                task.individual
                  ? (newTaskBody) => onEditApplicationTask(task.id, newTaskBody)
                  : (newTaskBody) => onEditCampaignTask(task.id, newTaskBody)
              }
            />
          ))}
        </>
      )}
      {!isPreparationStep && (
        <MarkdownNote
          label={
            <FormattedMessage
              id="components.ApplicationCard.pitchHeadline"
              defaultMessage="Influencer's Message"
            />
          }
          editLabel={markdownEditLabel}
          note={application?.pitch}
          timestamp={application?.createdAt}
        />
      )}
    </Box>
  );
};

ApplicationCardNotes.propTypes = {
  isPreparationStep: PropTypes.bool.isRequired,
  application: ApplicationPropType.isRequired,
  onEditClientNote: PropTypes.func.isRequired,
  onEditCampaignTask: PropTypes.func.isRequired,
  onEditApplicationTask: PropTypes.func.isRequired,
};
ApplicationCardNotes.defaultProps = {};

export default ApplicationCardNotes;
